import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import NoUserHeader from '../../AreaLivre/NoUserHeader';
import TextField from '../../TextField';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';

function UpdatePhone() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const validationSchema = Yup.object({
    fone: Yup.string()
      .required('Telefone é obrigatório')
      .trim()
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido'),
  });

  const initialValues = { fone: '' };

  function HandleSubmit(values) {
    navigate('/recuperacao/codigo', {
      state: {
        mustUpdatePhone: true,
        name: state?.name,
        phone: values.fone,
        cpf: state.cpf,
        paciente_id: state.paciente_id,
      },
    });
  }

  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={HandleSubmit}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">Agora vamos atualizar seu número de telefone</Heading>
          <TextField name="fone" type="tel" placeholder="Celular com DD" mask="(99) 99999-9999" />
          <Text fontSize="small">Um código de confirmação será enviado para o número que você informar</Text>
          <Button colorScheme="teal" type="submit" isFullWidth>
            Continuar
          </Button>
        </VStack>
      </Formik>
    </>
  );
}

export default UpdatePhone;
