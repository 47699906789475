import { Img, VStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { AccountContext } from 'components/AccountContext';

import SpinSVG from '../../../../images/spin.svg';
import './css/loading.css';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Loading() {
  const { state } = useLocation();
  console.log(state);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { user, setUser } = useContext(AccountContext);
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  var paciente = state.paciente;
  const auth_tuotempo = { user: 'gGKWlHHtIV', password: 'H0vDHauMdt' };

  let FormData = state.data.toLocaleDateString('pt');

  let FormName = state.paciente.nome.substring(0, 6);

  let procedimento = state.item[0].nome;

  const sendWhatsapp = async () => {
    const url = ENDPOINT + '/whatsapp/agendamentoprocedimento';
    const payload = {
      numero: user.phone1,
      procedimento: procedimento,
      nome: state.paciente.nome,
      clinica: state.empresa.empresa_nome,
      turno: state.turno,
      data: FormData,
      hora: '',
      paid: true,
    };

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };
    fetch(url, options);
  };

  async function postData(url, payload, cb) {
    try {
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();
      if (postsData.result === 'OK' || postsData.success) {
        sendWhatsapp();
        navigate('/alerts/sucesso/agendamento-exame');
      } else {
        setLoad(false);
      }
    } catch (e) {
      setLoad(false);
      console.log(`Type: ${e.name}, Message: ${e.message}`);
    }
  }

  const addAppointment = async () => {
    const url = ENDPOINT + '/schedule/appointment_exame';
    const payload = {
      cpf: onlyNumbers(paciente.cpf),
      clientId: paciente.paciente_id,
      cart: state.item,
      valor: state.valor,
      empresa: state.empresa,
      data: state.data,
      convenio: state.convenio,
      horario: state.horario,
    };

    postData(url, payload);
  };

  if (load) addAppointment();

  function Spin() {
    return (
      <VStack w={{ base: '90%', md: '500px' }} m="50% auto" justify="center">
        <Img size="sm" margin="auto" src={SpinSVG} className="rotate" />
      </VStack>
    );
  }

  return load ? Spin() : navigate('/alerts/erro/agendamento');
}

export default Loading;
