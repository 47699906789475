import { Image, Box, HStack, chakra, useMediaQuery, Text, Flex, Center, toast, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';

import '../css/carouselBanner.css';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AccountContext } from 'components/AccountContext';
import PopupDependenteSemCpf from './Atendimento/PopupDependenteSemCpf';
import DrawerServico from './DrawerServico';
import '../../Dashboard/Main/carousel.css';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function BannerClubeVantagens() {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const { clubeVantagens } = useContext(AccountContext);

  const handleFocusChange = (currentItemIndex) => {
    setFocusedIndex(currentItemIndex);
  };

  const navigate = useNavigate();
  const { user, toastInadimplente } = useContext(AccountContext);
  const dataNascimento = new Date(user.data_aniv);
  const idade = user.data_aniv !== null ? new Date().getFullYear() - dataNascimento.getFullYear() : null;
  const dadosPac = {
    paciente_id: user.id_cliente_deovita,
    nome: user.name,
    cpf: user.cpf,
    nascimento: user.data_aniv,
    cep: user.cep,
    code_ibge: user.codigo_ibge,
    logradouro: user.logradouro,
    numero: user.numero,
    bairro: user.bairro,
    complemento: user.complemento,
    municipio: user.municipio,
    estado: user.uf,
    celular: user.phone1 ? user.phone1 : user.phone2,
    sexo: user.sexo,
    type: 'empreendedor',
  };
  const disclosure_popup = useDisclosure();
  const disclosure = useDisclosure();
  function callToast(title, descricao) {
    toast({
      title: title,
      description: descricao,
      status: 'warning',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  const StyledImage = styled.img`
    width: ${(props) => '150px'};
  `;
  const [mdv_link, setMdvLinkl] = useState('https://clube.deovita.com.br');
  async function fetchMdv() {
    const url2 = ENDPOINT + '/clients/getAppConfigTelemedicina';
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({}),
    });
    const resp = await response.json();
    console.log(resp);
    setMdvLinkl(resp);
    return resp;
  }

  let usuario = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuario = true;

  const itensUltimo = clubeVantagens?.filter((option) => option.ultimo);
  const itensNaoUltimo = clubeVantagens?.filter((option) => !option.ultimo);
  const itensOrdenados = [...itensNaoUltimo, ...itensUltimo];

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }

  const dataAtual = new Date();

  return (
    <>
      <PopupDependenteSemCpf disclosure={disclosure_popup} datas={dadosPac} />
      <DrawerServico disclosure={disclosure} />
      <Box w="100vw" mt={'2em'}>
        <HStack alignItems="center" justify="space-between" mb={'1em'} mr="0.7em">
          <Text color={'#585858'} fontSize={'18px'} ml={'5%'} fontWeight={'bold'}>
            Clube de vantagens
          </Text>
          <chakra.span
            fontSize="14"
            color={'white'}
            bg={usuario ? '#EB8B2A' : '#529C94'}
            borderRadius={'1em'}
            padding={'0.3em'}
            h={'1.9em'}
            onClick={() => {
              if (!usuario) {
                navigate('/mdv-nao-assinante');
                return;
              }
              fetchMdv().then(async (resposta) => {
                console.log(resposta);
                window?.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    service: true,
                    openInBrowser: true,
                    url: mdv_link,
                  })
                );
              });
            }}
            cursor="pointer"
          >
            Ver mais
          </chakra.span>
        </HStack>

        <Carousel
          itemsToShow={1.1}
          showArrows={false}
          focusOnSelect={true}
          pagination={true}
          itemPadding={[0, 10]}
          renderPagination={({ pages, activePage, onClick }) => (
            <div className={usuario ? 'carousel-pagination' : 'carousel-pagination-deslogado'}>
              {pages.map((page) => (
                <span
                  key={page}
                  className={`carousel-pagination-item ${activePage === page ? 'active' : usuario ? '' : 'desactive'}`}
                  onClick={() => onClick(page)}
                />
              ))}
            </div>
          )}
        >
          {itensOrdenados.map((option, index) => {
            const svgDataUrl = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(option.svg_code)))}`;
            const svgDataUrlInterna = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(option.svg_code_interno)))}`;
            const svg_imagem_deslogado = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(option.svg_image_deslogado)))}`;

            return (
              <HStack key={index} >
                <HStack id={`date${index}`} backgroundColor="white" height={miniLargeHeight ? '6em' : "8em"} minWidth={miniLargeHeight ? '14em' :'19em'} mt="1em" mb="2em">
                  <Box
                    w="100%"
                    p={'0.3125em'}
                    shadow="md"
                    borderRadius="1em"
                    onClick={() => {
                     
                      if (option.ultimo) {
                        window?.ReactNativeWebView?.postMessage(
                          JSON.stringify({
                            service: true,
                            openInBrowser: true,
                            url: option.link_vantagem,
                          })
                        );
                        return;
                      }
                      if (option.destaque) {
                        if (!usuario) {
                          navigate('/mdv-nao-assinante');
                          return;
                        }
                        if (user && user.loggedIn) {
                          if (
                            ((user.titular === true && user.id_contrato > 0) || (user.titular === false && user.id_contrato === 0)) &&
                            idade !== null &&
                            idade >= 18
                          ) {
                            if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
                              toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                              return;
                            } else {
                              navigate('/empreendedor');
                            }
                          } else if (user.data_aniv !== null && idade !== null && idade < 18) {
                            callToast('Importante', 'É necessário ser maior de idade para acessar a aba de empreendedor');
                          } else if (user.data_aniv === null && idade === null) {
                            disclosure_popup.onOpen();
                          } else {
                            callToast('Atenção', 'Ocorreu um erro inesperado. Entre em contato com o suporte');
                          }
                        } else {
                          disclosure.onOpen();
                        }
                      } else {
                        if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
                          toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                          return;
                        }
                        navigate('/descontos-ClubeVatagens', {
                          state: {
                            url_externa: option?.url_externa,
                            url_interna: svgDataUrlInterna,
                            cupom: option?.cupom,
                            link_vantagem: option?.link_vantagem,
                            descricao: option?.descricao,
                            valor_cupom: option?.valor_cupom,
                            percentual: option?.percentual,
                            nome_site: option?.nome_site
                          },
                        });
                      }
                    }}
                  >
                    <Center w="100%">
                      <Image
                        borderRadius="1em"
                        bgColor="white"
                        height={miniLargeHeight ? '8em' :"10.4em"}
                        minWidth={option.destaque === true ? '16em' : '15em'}
                        objectFit={'cover'}
                        src={usuario ? svgDataUrl : svg_imagem_deslogado}
                        fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
                        alt="banner"
                      />
                    </Center>
                  </Box>
                </HStack>
              </HStack>
            );
          })}
        </Carousel>
      </Box>
    </>
  );
}

export default BannerClubeVantagens;
