import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  DrawerOverlay,
  Image,
  Button,
  useDisclosure,
  VStack,
  Heading,
  ListItem,
  chakra,
  Flex,
  Text,
  List,
  useToast,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdClear, MdCalendarToday } from 'react-icons/md';
import { useEffect, useState } from 'react';
import DrawerHorario from './DrawerHorario';
import DrawerAgendamentoDependente from './DrawerAgendamentoDependente';
import { useContext } from 'react';
import { AccountContext } from 'components/AccountContext';
import { formatDate } from 'components/Utils/Functions/DateFunctions';

function DrawerProcedimento(props) {
  const disclosure = props.disclosure;
  const unidade = props.data;
  const { empresa, empresa_id, numero, municipio, estado, endereco } = unidade[0];
  const { user, setUser } = useContext(AccountContext);
  const drawerHorarios = useDisclosure();
  const drawerDependente = useDisclosure();
  const [pedido, setPedido] = useState();
  const [procedurePick, setProcedurePick] = useState(false);
  const navigate = useNavigate();
  const { id_contrato, titular, cart } = user;
  const toast = useToast();
  const state = useLocation();

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }
  const dataAtual = new Date();

  useEffect(() => {
    if (procedurePick) {
      drawerDependente.onOpen();
    }
  }, [procedurePick]);

  const ShowToast = (title, description, status, duration, isClosable, position, colorScheme) => {
    toast({
      title,
      description,
      status,
      duration,
      isClosable,
      position,
      colorScheme,
      containerStyle: {
        paddingBottom: '5em',
      },
    });
  };

  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
      ? '#EB8B2A'
      : titular === true && id_contrato > 0
      ? '#EB8B2A'
      : '#529C94';

  let total_deovita = 0;
  let total_particular = 0;

  function checkObjects(array, obj) {
    const itemsArray = obj.itens;

    // Check if all objects in 'itens' have 'dia_selecionado' as a string
    const allHaveDiaSelecionado = array.every((item) => {
      return item.hasOwnProperty('dia_selecionado') && typeof item.dia_selecionado === 'string';
    });

    // Check if all objects in 'itens' are also present in the outer array
    const allInArray = array.every((item) => itemsArray.some((arrItem) => arrItem.procedimento_tuss_id === item.procedimento_tuss_id));

    // Return true if both conditions are met
    return allHaveDiaSelecionado && allInArray;
  }
  //excluir item do carrinho
  const removerItem = (itemId) => {
    const novoCarrinhoUser = user?.cart?.itens?.filter((procedure) => procedure.procedimento_tuss_id !== itemId);
    setUser((prevState) => ({ ...prevState, cart: { itens: novoCarrinhoUser } }));

    const novoCarrinhoPick = props.data.filter((procedure) => procedure.procedimento_tuss_id !== itemId);
    props.setPick(novoCarrinhoPick);

    if (novoCarrinhoPick.length == 0) {
      navigate('/');
    }
  };

  const useProcedure = unidade.map((procedure) => {
    const itemProcedure = user.cart.itens.filter((item) => item.procedimento_tuss_id === procedure.procedimento_tuss_id)[0];
    procedure = { ...procedure, ...itemProcedure };
    total_deovita += parseFloat(procedure.valor_deovita);
    total_particular += parseFloat(procedure.valor_particular);
    return (
      <Flex backgroundColor="#F3F3F3" direction="column" borderRadius={6} p="1em" shadow="md">
        <Flex direction="row" justifyContent="space-between" width="100%" marginBottom={2}>
          <Flex alignItems="center">{/* Conteúdo à esquerda, se houver */}</Flex>
          <Flex
            direction="row"
            alignItems="center"
            onClick={() => {
              removerItem(procedure.procedimento_tuss_id);
            }}
          >
            <Text color="#D60000" fontSize={14}>
              Excluir
            </Text>
            <MdClear color="#D60000" size={20} />
          </Flex>
        </Flex>

        <Flex direction="row" justifyContent="space-between" width="100%" borderBottomWidth={1} borderBottomColor="#818181">
          <Flex alignItems="center">
            <Text color="#585858" fontWeight="bold">
              {procedure.nome}
            </Text>
          </Flex>
          <Flex
            direction="row"
            alignItems="center"
            marginBottom={2}
            onClick={() => {
              setProcedurePick(procedure);
            }}
          >
            {/*Aqui, é pra msotrar o horario quando selecionado */}
            <Text
              color={procedure.medico_selecionado ? 'green' : !procedure.hora_selecionada_display ? 'red.500' : 'green'}
              mr={2}
              align="right"
              fontSize={12}
            >
              {!procedure.dia_selecionado ? `Agendar${' '}` : formatDate(procedure.dia_selecionado) === "01/01/1970" ? `Agendar${' '}` : formatDate(procedure.dia_selecionado)}
              <Text whiteSpace="pre-line" textAlign="right">
                {!procedure.hora_selecionada_display ? 'Data e horário' : procedure.hora_selecionada_display}
              </Text>
            </Text>

            <Box borderRadius={4} backgroundColor="#529C94" p={1}>
              <MdCalendarToday size={24} color="#FFF" />
            </Box>
          </Flex>
        </Flex>

        <Flex direction="row" justifyContent="space-between" width="100%">
          <Flex alignItems="center">
            <Text color="#818181" mt={1}>
              Valor do procedimento:
            </Text>
          </Flex>
          <Flex direction="row" alignItems="center">
            <Text fontSize="sm" color="#818181" alignSelf="flex-end">
              {user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)
                ? user?.qntdeParcelas?.countParcelas && user?.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela
                  ? parseFloat(procedure.valor_particular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                  : parseFloat(procedure.valor_deovita).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                : parseFloat(procedure.valor_particular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  });

  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        {procedurePick && <DrawerAgendamentoDependente disclosure={drawerDependente} procedure={procedurePick} empresa={unidade} />}
        <DrawerHorario
          disclosure={drawerHorarios}
          empresa={unidade}
          valor={
            (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
            (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0')
              ? total_deovita
              : total_particular
          }
          economia={total_particular - total_deovita}
        />

        <DrawerOverlay />
        <DrawerContent borderTopRadius={20} width="100%" h="80%">
          <DrawerBody pb="10%">
            <Stack direction="column" spacing={4} paddingTop={30} align="center">
              <Flex direction="row" w="100%">
                <Text fontSize={20} color="#585858" flex={9} textAlign="center">
                  Detalhes do pedido
                </Text>
                <Flex
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    disclosure.onClose();
                  }}
                >
                  <MdClear size={26} />
                </Flex>
              </Flex>

              <List w="100%" spacing={4}>
                <ListItem fontWeight="bold" borderRadius="6px" shadow="md" mt="0.8em" width="100%" backgroundColor="#F3F3F3" h="5em" p="0.5em">
                  <Flex w="100%" minH="40%" maxH="50%">
                    <Image
                      src={`https://backoffice.deovita.com.br/deovita//upload/fachada/` + empresa_id + '/fachada.png'}
                      fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
                      backgroundColor="white"
                      border="1px"
                      h="4em"
                      w="7.0em"
                      borderRadius="1.5em"
                    />

                    <VStack ml={2} align="right" h="100%" w="100%">
                      <Text fontSize="xs" textColor="#585858" textAlign="start" maxW="80" lineHeight={1}>
                        {empresa}
                      </Text>

                      <Text fontSize="xs" fontWeight={5} textColor="#818181" lineHeight={1}>
                        {String(endereco).trim()}, {numero}
                      </Text>

                      <Text fontSize="xs" fontWeight={5} textColor="#818181" lineHeight={1}>
                        {municipio} / {estado}
                      </Text>
                    </VStack>
                  </Flex>
                </ListItem>
              </List>

              <Heading color="#585858" size="sm" w="100%">
                Exames adicionados
              </Heading>
            </Stack>

            <List w="100%" h="40%" overflow="auto" spacing={4} mt={2}>
              {useProcedure}
            </List>

            <VStack w="100%">
              :
              <Flex backgroundColor="#F3F3F3" direction="row" borderRadius={6} p="0.5em" mb={3} shadow="md" mt={6} w="100%">
                <Flex flex={1} direction="column" mb="">
                  <Text color="#585858" fontWeight="bold">
                    Valor Total:
                  </Text>
                  <chakra.span color="#585858" fontWeight="bold">
                    {user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)
                      ? user?.qntdeParcelas?.countParcelas && user?.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela
                      ? parseFloat(total_particular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                      : parseFloat(total_deovita).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                      : parseFloat(total_particular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </chakra.span>
                </Flex>

                {user.id_contrato === 0 && user.titular ? (
                  <>
                    <Flex
                      flex={1}
                      onClick={() => {
                        navigate('/cadastro/plano', {
                          state: {
                            client_id: user.id_cliente_deovita,
                            name: user.name,
                            cpf: user.cpf,
                          },
                        });
                      }}
                    >
                      <Flex p={1} borderRadius={4} backgroundColor={'#EB8B2A'} justifyContent="center" alignItems="center">
                        <Text fontSize={12} fontWeight="bold" color="#FFF" textAlign={'center'}>
                          Assine o plano Deovita e pague apenas:
                          {parseFloat(total_deovita).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
              </Flex>
              <Button
                width="100%"
                bgColor={backgroundColor}
                color="white"
                onClick={() => {
                  // Verificar se os procedimentos estão com a flag "verificado" como true
                  let procedureList = [];
                  const useProcedure = unidade.map((procedure) => {
                    const itemProcedure = user.cart.itens.filter((item) => item.procedimento_tuss_id === procedure.procedimento_tuss_id)[0];
                    procedureList.push(itemProcedure);
                  });
                  console.log(procedureList);
                  //const allProceduresCheck = user.cart.itens.every((item) => item.dia_selecionado);
                  const isAllPicked = checkObjects(procedureList, user.cart);

                  if (!isAllPicked) {
                    ShowToast('Procedimentos pendentes', 'Verifique o agendamento dos procedimentos', 'warning', 4000, true, 'bottom', 'green');
                    return;
                  } else {
                    // prosseguir
                    drawerHorarios.onOpen();
                  }
                }}
              >
                Continuar
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerProcedimento;
