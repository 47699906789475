import { Box, Image, Text, VStack, Button, HStack, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import img4 from '../../images/ad4.png';
import PopUpAdicional from './PopUpAdicional';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import { useContext, useEffect, useState } from 'react';
import { AccountContext } from '../AccountContext';
import Loading from '../LoadingWithHeader';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Adicionais() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setPage } = useContext(AccountContext);
  const [adicionais, setAdicionais] = useState(false);
  const [carrinho, setCarrinho] = useState([
    { id: 0, nome: state.nome_plano, valor: state.valor_plano_formatado, valor_numerico: state.valor_plano },
  ]);

  async function fetchData() {
    let url = ENDPOINT + '/extras/byPlanId/' + state.id_plano;
    try {
      let response = await fetch(url, { method: 'GET' });
      let res = await response.json();
      setAdicionais(res);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  setPage('Adicionais');

  const Adicional = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    let data = props.data;

    let selected = carrinho.some((item) => item.nome === data.nome && item.valor === data.valor);

    return (
      <>
        <PopUpAdicional isOpen={isOpen} onClose={onClose} data={data} carrinho={carrinho} addCarrinho={setCarrinho} />
        <Box
          width="100%"
          padding="2"
          borderRadius="20"
          border="0.063rem solid"
          borderColor={selected ? 'green' : '#DCDDE0'}
          boxShadow="lg"
          backgroundColor="#fff"
          textAlign="start"
          marginTop="2em"
          onClick={selected ? () => {} : onOpen}
        >
          <HStack>
            <Image src={img4} />
            <Box>
              <Text fontSize="1.25rem" fontWeight="600" casing="capitalize">
                {typeof data.nome === 'string' ? data.nome : '-'}
              </Text>
              <Text fontSize="0.8em" color="#737373">
                clique para ver mais detalhes
              </Text>
              <Text fontSize="sm" mt="2" casing="lowercase">
                {typeof data.descricao === 'string' ? data.descricao : ''}
              </Text>
            </Box>
          </HStack>
        </Box>
      </>
    );
  };

  return adicionais ? (
    <>
      <NoUserHeader />
      <VStack w={{ base: '90%', md: '300px' }} m="auto" justify="top" marginBottom="5%" spacing="1rem" align="center">
        {adicionais.map((adicional) => (
          <Adicional data={adicional} />
        ))}
        <Button
          colorScheme="teal"
          mt="12"
          variant="solid"
          isFullWidth
          height="2.75rem"
          onClick={() => navigate('/cadastro/resumo-pagamento', { state: { ...state, carrinho: carrinho } })}
        >
          Continuar
        </Button>
      </VStack>
    </>
  ) : (
    <Loading />
  );
}

export default Adicionais;
