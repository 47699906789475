import { React, useContext, useEffect, useState } from 'react';
import {
  chakra,
  Button,
  Heading,
  VStack,
  Text,
  Center,
  Stack,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Image,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { FaCircleInfo } from 'react-icons/fa6';
import ModalAtualizacaoAssinante from '../../../images/ModalAtualizacao/ModalAtualizacaoAssinante.svg';
import ModalAtualizacaoNaoAssinante from '../../../images/ModalAtualizacao/ModalAtualizacaoNaoAssinante.svg';
import iconListaAssinante from '../../../images/ModalAtualizacao/iconListaAssinante.svg';
import iconListaNaoAssinante from '../../../images/ModalAtualizacao/iconListaNaoAssinante.svg';
import { AccountContext } from 'components/AccountContext';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
function ModalAtualizacaoV5(props) {
  const disclosure = props.disclosure;
  const { user, setUser } = useContext(AccountContext);
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  const toast = useToast();
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;

  async function confirmNotificacaoAtualicao() {
    const postsData = await fetch(`${ENDPOINT}/clients/salvarNotificaoAtualizacao`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ paciente_id: user.id_cliente_deovita, cpf: user.username, versao: 'v5' }),
    });
    if (postsData.status === 200) {
      setUser((prevState) => ({ ...prevState, aceite_versao: true }));
      disclosure.onClose();
    } else {
      toast({
        title: 'Importante',
        description: 'Não foi possível concluir a solicitação. Tente novamente mais tarde.',
        status: 'warning',
        duration: 4000,
        isClosable: true,
        position: 'bottom',
        colorScheme: 'red',
        containerStyle: {
          paddingTop: '50px',
        },
      });
    }
  }
  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered size={miniLargeHeight ? 'sm' : 'xs'}>
        <ModalOverlay />
        <ModalContent  borderRadius="0.8em">
          <ModalBody>
            <ModalCloseButton bg={'#C6CACE'} borderRadius={'2em'} color={'white'} onClick={() => confirmNotificacaoAtualicao()} />
            <VStack mt={'1em'} align="left">
              <Image src={usuarioAssinante ? ModalAtualizacaoAssinante : ModalAtualizacaoNaoAssinante} />
              <VStack align="left" mt={'1em'}>
                <chakra.span fontSize="20px" color={usuarioAssinante ? '#EB8B2A' : '#529C94'} fontWeight={'bold'} ml={'1.8em'}>
                  Nota de atualização
                </chakra.span>
              </VStack>
              <VStack align="left" mt={'1em'}>
                <Center bg={usuarioAssinante ? '#FDF6EC' : '#E7F7F6'} w="100%">
                  <Image src={usuarioAssinante ? iconListaAssinante : iconListaNaoAssinante} ml="-9.5em" />
                  <chakra.span fontSize="16px" color={'#585858'} ml={'1.2em'}>
                    Cashback
                  </chakra.span>
                </Center>
                <Center bg={usuarioAssinante ? '#FDF6EC' : '#E7F7F6'} w="100%">
                  <Image src={usuarioAssinante ? iconListaAssinante : iconListaNaoAssinante} ml="-20" />
                  <chakra.span fontSize="16px" color={'#585858'} ml={'1.2em'}>
                    Débito automático
                  </chakra.span>
                </Center>
              </VStack>
            </VStack>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalAtualizacaoV5;
