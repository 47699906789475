import { Badge, Box, chakra, Text, Flex, Stack, HStack, Avatar, Center, VStack, AvatarBadge, AvatarGroup } from '@chakra-ui/react';

import React, { useContext, useState } from 'react';

function info(props) {
  const { data } = props;

  console.log(data.nome);
  const firstName = data.nome.split(' ')[0];
  const secondName = data.nome.split(' ')[1];
  const name = secondName ? `${firstName} ${secondName}` : firstName;
  const valor = data.valor_adesao ? parseFloat(data.valor_adesao.replace(',', '.')) : '';

  const valorReceber = parseFloat((valor / 100) * 40) * data.qtd;
  const date = data.data_cadastro ? data.data_cadastro.split('T')[0] : 'Sem data';
  let color = '';
  if (data.nivel === 1) {
    color = '#1DC7AC';
  }
  if (data.nivel === 2) {
    color = '#FAAD39';
  }
  if (data.nivel === 3) {
    color = '#FE4A54';
  }
  if (data.nivel === 4) {
    color = '#623CE7';
  }
  if (data.nivel === 5) {
    color = '#42cef5';
  }

  return (
    <>
      <VStack
        align="center"
        width="90%"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        mr="4"
        mb="7"
      >
        <HStack w="90%" spacing="1rem">
          <AvatarGroup size="md" spacing="6">
            <Avatar src={`https://backoffice.deovita.com.br/deovita/upload/webcam/pacientes/${data.cpf}.jpeg`} />
          </AvatarGroup>
          <Stack direction="column" spacing="7rem">
            <Box align="center" direction="column" mr="25" fontSize="xs" w="100%">
              {name}
              <Badge borderRadius="full" fontWeight="bold" px="5" mr="8" colorScheme="teal">
                {data.nivel} nivel
              </Badge>
            </Box>
          </Stack>

          <Box textColor="#0EAA8B" align="center" w="100%">
            <chakra.p fontSize="sm">Total de indicações</chakra.p>
            <Text> {data.qtd}</Text>
          </Box>
        </HStack>
      </VStack>
    </>
  );
}

export default info;
