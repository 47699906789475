import { Box, Heading, Image, chakra, Img, Text, VStack, Button, Switch, ButtonGroup, InputGroup, HStack, InputLeftElement } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Contact from '../../../../../../images/ClinicoIcon.svg';
import BackButton from '../../../../../../images/assets/telemedicina/backbutton.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectField from 'components/SelectField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import TextField from 'components/TextField';
import IconMedico from '../../../../../../images/iconeMedico.svg';

export default function Step2() {
  const { state } = useLocation();
  console.log('state ', state);
  const [medidor, setMedidor] = useState(false);
  const [medtemp, setMedtemp] = useState(false);
  const navigate = useNavigate();
  let initialValues = {
    pulso: '',
    pd: '',
    ps: '',
    temperatura: '',
  };
  let validationSchema = {
    pulso: !medidor
      ? Yup.string()
          .required('É necessário informar o pulso')
          .matches(/^[0-9\s]*$/, 'Somente números') 
      : '',
    pd: !medidor
      ? Yup.string()
          .required('É obrigatorio informar o pd')
          .matches(/^[0-9\s]*$/, 'Somente números')
      : '',
    ps: !medidor
      ? Yup.string()
          .required('É obrigatorio informar o ps')
          .matches(/^[0-9\s]*$/, 'Somente números') 
      : '',
    temperatura: !medtemp
      ? Yup.string()
          .required('É necessário informar a temperatura')
          .matches(/^[0-9]*\.?[0-9]+$/, 'Temperatura deve conter apenas números e ponto decimal')
      : '',
  };

  const Formulario = ({ formikProps }) => {
    const { setFieldValue, values } = formikProps;

    const formatarTemperatura = (peso) => {
      const apenasNumeros = peso.replace(/[^\d]/g, '');
      if (apenasNumeros.length > 2) {
        const parteInteira = apenasNumeros.slice(0, -1);
        const parteDecimal = apenasNumeros.slice(-1);
        return `${parteInteira}.${parteDecimal}`;
      }
      return apenasNumeros;
    };

    const handleTemperaturaChange = (e) => {
      const peso = e.target.value;
      const pesoFormatado = formatarTemperatura(peso);
      setFieldValue('temperatura', pesoFormatado);
    };
    return (
      <>
        {' '}
        <VStack as={Form} align="start" p="2em" w="100%">
          <Text mt="10%" size="xs" fontWeight="bold" color="#585858">
            Vamos aferir sua pressão
          </Text>
          <Text w="100%" textAlign="left">
            <Switch
              id="medidor"
              name="medidor"
              isChecked={medidor}
              colorScheme="orange"
              onChange={(e) => {
                setMedidor(e.target.checked);
                if (e.target.checked === true) {
                  setFieldValue('pulso', '');
                  setFieldValue('pd', '');
                  setFieldValue('ps', '');
                } else {
                  setFieldValue('pulso', '');
                  setFieldValue('pd', '');
                  setFieldValue('ps', '');
                }
              }}
            />{' '}
            Não possuo Medidor de Pressão (Esfigmomanômetro)
          </Text>
          {!medidor && (
            <>
              <Text textAlign="left">
                Pulso<chakra.span color="red">*</chakra.span>
              </Text>
              <InputGroup>
                <TextField name="pulso" placeholder="Bpm" type="tel" maxLength={3} />
                <Text color="gray.500" fontSize={'14px'} ml={'-13.6em'} mt={'1.2em'}>
                  {values.pulso ? 'bpm' : ''}
                </Text>
              </InputGroup>
              <HStack spacing={6} align="start">
                <VStack spacing={1} align="start">
                  <Text textAlign="left">
                    P.D(max)<chakra.span color="red">*</chakra.span>
                    <TextField name="pd" placeholder="00" type="tel" maxLength={3} />
                  </Text>
                </VStack>
                <VStack spacing={1} align="start">
                  <Text textAlign="left">
                    P.S(min)<chakra.span color="red">*</chakra.span>
                  </Text>
                  <TextField name="ps" placeholder="00" type="tel" maxLength={3} />
                </VStack>
              </HStack>
            </>
          )}
          <Text mt="10%" size="xs" fontWeight="bold" color="#585858">
            Vamos medir sua temperatura
          </Text>
          <Text w="100%" textAlign="left">
            <Switch
              id="medtemp"
              name="medtemp"
              isChecked={medtemp}
              colorScheme="orange"
              onChange={(e) => {
                setMedtemp(e.target.checked);
                if (e.target.checked === true) {
                  setFieldValue('temperatura', '');
                } else {
                  setFieldValue('temperatura', '');
                }
              }}
            />
            Não possuo Medidor de Temperatura (Termômetro)
          </Text>
          {!medtemp && (
            <>
              <Text textAlign="left">
                Temperatura<chakra.span color="red">*</chakra.span>
              </Text>
              <InputGroup>
                {values.temperatura ? (
                  <>
                    <InputLeftElement pointerEvents="none">
                      <Text color="gray.500" fontSize="16px" ml={'8em'} mt={'1.2em'}>
                        °C
                      </Text>
                    </InputLeftElement>
                  </>
                ) : (
                  ''
                )}

                <TextField name="temperatura" placeholder="°C" type="tel" maxLength={6} onChange={handleTemperaturaChange} />
              </InputGroup>
            </>
          )}
          <Button w="100%" background="#EB8B2A" textColor="white" type="submit">
            Continuar
          </Button>
        </VStack>
      </>
    );
  };
  return (
    <VStack align="center" wrap="no-wrap" minH="100vh" spacing={0} pt="6em" pb="6em" background="#EB8B2A" color="#585858" overflow={'scroll'}>
      <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
      <Box
        id=""
        as="card"
        width="95%"
        minHeight="75vh"
        borderRadius="18px"
        fontSize="xs"
        size="10"
        backgroundColor="white"
        maxInlineSize="80"
        textAlign="center"
      >
        <Img ml="40%" mt="15%" src={IconMedico} w="15%" />
        <Heading m="2%" size="xs">
          Falta pouco!
        </Heading>
        <Heading m="2%" size="xs">
          Preencha os campos abaixo para que a gente possa te atender melhor!
        </Heading>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchema)}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            navigate('/medico-agora-atualiza-telefone', {
              state: {
                ...state,
                ...values,
              },
            });
          }}
        >
          {(props) => <Formulario formikProps={props} />}
        </Formik>
      </Box>
    </VStack>
  );
}
