import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  chakra,
  Box,
  Text,
  VStack,
  Image,
  Spinner,
  useDisclosure,
  useMediaQuery,
  Button,
  Flex,
  HStack,
  Stack,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import SelectField from 'components/SelectField';
import { Form, Formik } from 'formik';
import PopupDependenteSemCpf from '../../Atendimento/PopupDependenteSemCpf';
import AvisoAtendimento from './Modal/AvisoAtendimento';
import Voltar from '../../../../../images/Voltar.svg';
import Contact from '../../../../../images/ClinicoIcon.svg';
import Familia from '../../../../../images/familia.svg';
import ModalAvisoPsicologo from './Modal/ModalAvisoPsicologo';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main() {
  const { user } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState('');
  const [value, setValue] = useState(null);
  const [menorIdade, setMenorIdade] = useState(false);
  const [botaoSeguir, setBotaoSeguir] = useState(false);
  const [dependentes, setDependentes] = useState(false);
  const modalPsicologo = useDisclosure();
  const arrayNomeDependentes = dependentes
    ? dependentes.map((dependente) => {
        const nomePartes = dependente.nome.split(' ');
        const primeiroNome = nomePartes[0];
        const sobrenome = nomePartes.length > 1 ? nomePartes[1] : '';
        return `${primeiroNome} ${sobrenome}`;
      })
    : [];

  const [client, setClient] = useState();
  const navigate = useNavigate();
  const [accept, setAccepted] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const disclosure = useDisclosure();
  const { state } = useLocation();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const disclosureAviso = useDisclosure();
  const [modalFechado, setModalFechado] = useState(false);
  const [idClientClinica, setIdClientCinica] = useState(0);

  const getClinicasId = async (cpf) => {
    const url = ENDPOINT + '/clients/getClientByCpfClinica/' + cpf;
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    if (postsData.length > 0) {
      setIdClientCinica(postsData[0].paciente_id);
    } else {
      setIdClientCinica(null);
    }
  };

  useEffect(() => {
    getClinicasId(user.username);
  }, [user.username]);

  const handleModalFechado = () => {
    if (user.titular === true) {
      if (dependentes && value) {
        geturl();
        setError(null);
        setShowText(true);
        setDisabled(true);
      }
    } else {
      geturl();
      setError(null);
      setShowText(true);
      setDisabled(true);
    }
  };

  const registerAppointment = async () => {
    const resposta = await fetch(
      `${ENDPOINT}/rapidoc/appointment-insert/${state && state.especialista ? 'especialista' : 'clinico'}/${dependentes[value].cpf}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    resposta.json().then((res) => {
      if (res.success) {
        console.log('sucesso');
      }
    });
  };

  async function geturl() {
    setLoading(true);

    registerAppointment(onlyNumbers(dependentes[value].cpf));
    navigate('/medico-agora/atendimento', { state: { patient: dependentes[value].cpf } });
  }

  const fetchDependentes = async () => {
    const url2 = ENDPOINT + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    if (resp.data[0] !== null) {
      if (state?.pediatra) {
        const filteredDependentes = resp.data.filter((dependente) => {
          const birthDate = new Date(dependente.nascimento);
          const today = new Date();
          const age =
            today.getFullYear() -
            birthDate.getFullYear() -
            (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()) ? 1 : 0);
          return age <= 14;
        });
        setDependentes(filteredDependentes);
      } else {
        setDependentes(resp.data);
      }
    } else {
      if (state?.pediatra) {
        setDependentes([]);
      } else {
        setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
        setValue(0);
        console.log(dependentes);
      }
    }
  };

  useEffect(() => {
    fetchDependentes();
  }, []);
  const [isLargeWidth] = useMediaQuery('(min-width: 668px)');
  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');
  async function calcularIdade(nascimento) {
    const birthDate = new Date(nascimento);
    const today = new Date();
    const age =
      today.getFullYear() -
      birthDate.getFullYear() -
      (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()) ? 1 : 0);
    return await age;
  }
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  const toast = useToast();
  useEffect(() => {
    async function validarDependente() {
      if (usuarioAssinante && user.titular === false) {
        const idade = await calcularIdade(user.data_aniv);
        if (idade < 18) {
          setMenorIdade(true);
          setBotaoSeguir(false)
        }else{
          setBotaoSeguir(true)
        }
        /*else{
          setBotaoSeguir(false);
          toast({
            title: 'Importante',
            description: 'Apenas maiores de idade podem agendar especialistas.',
            status: 'error',
            duration: 8000,
            isClosable: true,
            position: 'bottom',
            colorScheme: 'red',
            containerStyle: {
              paddingTop: '50px',
            },
          });
        } */
      }
    }
    validarDependente();
  }, []);

  async function validaMenorPsicologo(paciente_id) {
    try {
      const postsData = await fetch(`${ENDPOINT}/clients/aceiteMenorIdade`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paciente_id: paciente_id, tipo: 'menor_idade_acompanhado_psicologo' }),
      })
        .then(async (resposta) => {
          return await resposta.json();
        })
        .then(async (response) => {
          if (!response.modal) {
            navigate('/medico-agora/especialista/lista', {
              state: {
                pacienteEscolhido: dependentes[value].cpf,
                nascimento: usuarioAssinante
                  ? user.titular
                    ? dependentes[value].nascimento
                    : dependentes[value].data_aniv
                  : dependentes[value].data_aniv,
                typeList: 'Especialista',
                especialidade: 54,
                idClientClinica,
              },
            });
            return;
          }
          modalPsicologo.onOpen();
        });
    } catch (error) {
      console.log(error);
    }
  }

  return dependentes ? (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="#EB8B2A"
      >
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <AvisoAtendimento disclousure={disclosureAviso} onModalClose={handleModalFechado} />
        <PopupDependenteSemCpf disclosure={disclosure} datas={dependentes[value]} />
        <ModalAvisoPsicologo
          disclosure={modalPsicologo}
          pacienteEscolhido={dependentes[value]?.cpf}
          nascimento={usuarioAssinante ? (user?.titular ? dependentes[value]?.nascimento : dependentes[value]?.data_aniv) : dependentes[value]?.data_aniv}
          typeList={'Especialista'}
          especialidade={54}
          idClientClinica
          pacienteId={dependentes[value]?.paciente_id}
        />
        {/*<Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={Voltar} onClick={() => navigate(-1)} /> */}
        <Box
          as="card"
          width="328px"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
          height={'55%'}
        >
          <VStack m={{ sm: '10%' }} textColor="#585858">
            <VStack m="5%" textColor="#585858">
              <Image top={{ lg: '5.5em', sm: '5em' }} src={Contact} alt="teste" height={'4.5em'} />
              <Text fontWeight="bold" fontSize="1.5em" color={'#585858'}>
                Qual paciente <br /> será atendido?
              </Text>
              <Text fontSize="16px" color="#585858">
                Selecione o paciente abaixo
              </Text>

              {showText && !error ? (
                <Text fontSize="xs" color="green">
                  Isso pode demorar alguns segundos por favor aguarde. Você será redirecionado ao atendimento com o médico em breve.
                </Text>
              ) : (
                <Text color="red">{error}</Text>
              )}

              <Formik initialValues={{ dependente: user.titular === true ? null : 0 }} validateOnChange={false} validateOnBlur={false}>
                <VStack as={Form} w={{ base: '100%', md: '500px' }} m="auto" justify="top" overflow="hidden" align="start" mt={'-2em'}>
                  <SelectField
                    name="dependente"
                    placeholder="Selecione"
                    w={isLargeWidth ? '28vh' : isLargeHeight ? '40vh' : '32vh'}
                    variant="outline"
                    borderRadius={'0.8em'}
                    textAlign="left"
                    fontWeight="normal"
                    backgroundColor={'#EEEEEE'}
                    onChange={async (e) => {
                      if (usuarioAssinante) {
                        if (!dependentes[e].nascimento) {
                          setValue(e);
                          disclosure.onOpen();
                          return;
                        }
                        let idade = await calcularIdade(dependentes[e].nascimento);
                        if (dependentes[e].cpf) {
                          setError(null);
                          setShowText(false);
                          setValue(e);
                          if (idade < 18) {
                            setMenorIdade(true);
                            setBotaoSeguir(false);
                          } else {
                            setMenorIdade(false);
                            setBotaoSeguir(true);
                          }
                        } else {
                          setValue(e);
                          disclosure.onOpen();
                        }
                      } else {
                        let idade = await calcularIdade(user.data_aniv);
                        if (user.cpf) {
                          setError(null);
                          setShowText(false);
                          setValue(e);
                          if (idade < 18) {
                            setMenorIdade(true);
                            setBotaoSeguir(false);
                          } else {
                            setMenorIdade(false);
                            setBotaoSeguir(true);
                          }
                        } else {
                          setValue(e);
                          disclosure.onOpen();
                        }
                        setMenorIdade(false);
                        setBotaoSeguir(true);
                      }
                    }}
                    options={arrayNomeDependentes}
                    mb={'5%'}
                  />
                </VStack>
              </Formik>
              {menorIdade ? (
                <>
                  <Flex bg="#D60000" p="10px" borderRadius="10px" w={isLargeWidth ? '50%' : '100%'}>
                    <VStack w="100%" spacing="5px">
                      <HStack borderRadius="0.8em" w="100%" alignItems="left">
                        <Image src={Familia} />
                        <Text fontSize="19px" color="white" fontWeight="bold">
                          Paciente menor{' '}
                        </Text>
                      </HStack>
                      <Text fontSize="16px" color="white" textAlign={'left'}>
                        É necessário acompanhamento de um responsável maior para realizar seu atendimento.
                      </Text>
                      <Checkbox
                        color="white"
                        fontSize="16px"
                        alignSelf="flex-start"
                        onChange={() => {
                          if (botaoSeguir) {
                            setBotaoSeguir(false);
                            return;
                          }
                          setBotaoSeguir(true);
                        }}
                      >
                        Entendi
                      </Checkbox>
                    </VStack>
                  </Flex>
                </>
              ) : (
                <></>
              )}

              {dependentes.length === 0 ? (
                <>
                  <Flex bg="#D60000" p="10px" borderRadius="10px" w={isLargeWidth ? '50%' : '100%'}>
                    <VStack w="100%" spacing="5px">
                      <HStack borderRadius="0.8em" w="100%" alignItems="left">
                        <Image src={Familia} />
                        <Text fontSize="19px" color="white" fontWeight="bold">
                          Paciente menor{' '}
                        </Text>
                      </HStack>
                      <Text fontSize="16px" color="white" textAlign={'left'}>
                        É necessário que você possua pelo menos um dependente menor idade para ser atendido.
                      </Text>
                    </VStack>
                  </Flex>
                </>
              ) : (
                <></>
              )}
              {!loading && botaoSeguir ? (
                <Button
                  textAlign="center"
                  id="callBtn"
                  color={'white'}
                  fontWeight="bold"
                  w={isLargeWidth ? '60%' : '100%'}
                  fontSize="1.5em"
                  backgroundColor={'#EB8B2A'}
                  onClick={() => {
                    if (state?.especialista === true) {
                      if (state?.especialidade == 54) {
                        if (menorIdade) {
                          validaMenorPsicologo(dependentes[value].paciente_id);
                        } else {
                          navigate('/medico-agora/especialista/lista', {
                            state: {
                              pacienteEscolhido: dependentes[value].cpf,
                              nascimento: usuarioAssinante
                                ? user.titular
                                  ? dependentes[value].nascimento
                                  : dependentes[value].data_aniv
                                : dependentes[value].data_aniv,
                              typeList: 'Especialista',
                              especialidade: 54,
                              idClientClinica,
                            },
                          });
                        }
                      } else {
                        navigate('/medico-agora/especialista', {
                          state: {
                            dependentes,
                            value,
                            patient: dependentes[value].cpf,
                            nascimento: usuarioAssinante
                              ? user.titular
                                ? dependentes[value].nascimento
                                : dependentes[value].data_aniv
                              : dependentes[value].data_aniv,
                            idClientClinica,
                            sexo: dependentes[value].sexo
                          },
                        });
                      }
                    } else {
                      /*navigate('/medico-agora-atualiza-telefone', {
                        state: {
                          dependentes,
                          value,
                          patient: dependentes[value].cpf,
                        },
                      });*/
                      navigate('/medico-agora/triagem', {
                        state: {
                          dependentes,
                          value,
                          patient: dependentes[value].cpf,
                          idClientClinica,
                          nascimento: usuarioAssinante
                            ? user.titular
                              ? dependentes[value].nascimento
                              : dependentes[value].data_aniv
                            : dependentes[value].data_aniv,
                            sexo: dependentes[value].sexo
                        },
                      });
                    }
                  }}
                >
                  Continuar
                </Button>
              ) : loading ? (
                <Text textAlign="center" fontSize="1.5em" mt="0.5em">
                  carregando <Spinner size="sm" />
                </Text>
              ) : (
                <></>
              )}
            </VStack>
          </VStack>
        </Box>
      </VStack>
      <BottomNavBar />
    </>
  ) : (
    <>
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="#EB8B2A"
      >
        <Box
          mt="1"
          id=""
          as="card"
          width="328px"
          minH="238px"
          borderRadius="18px"
          fontSize="xs"
          size="10"
          backgroundColor="white"
          maxInlineSize="80"
          textAlign="center"
        ></Box>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
