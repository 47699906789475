import { VStack, Button, Tabs, TabList, Tab, TabPanel, TabPanels, ButtonGroup, chakra } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React, { useState, useContext } from 'react';
import TextField from '../../TextField';
import { AccountContext } from '../../AccountContext';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import './css/dadostabs.css';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavBar from '../../BottomNavBar';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function DadosPessoais() {
  const { user, setUser } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const { setPage } = useContext(AccountContext);
  const navigate = useNavigate();
  let data_aniv = new Date(user.data_aniv);
  const { state } = useLocation();

  const defaultIndex = state && state.defaultIndex ? state.defaultIndex : 0;
  setPage('Dados Pessoais');

  const yupSchema = Yup.object({
    telefone: Yup.string()
      .trim()
      .required('Número para contato é obrigatório')
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido'),
    whatsapp: Yup.string()
      .trim()
      .required('Número de Whatsapp é obrigatório')
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido'),
    email: Yup.string().trim().email('Email inválido'),
  });

  const initialValues = {
    nome: user.name,
    cpf: user.cpf,
    data_aniv: data_aniv.toLocaleDateString('pt-br', { dateStyle: 'short' }),
    telefone: user.phone2,
    whatsapp: user.phone1,
    email: user.email ? user.email.toLowerCase() : '',
  };

  function handleSubmit(values, actions) {
    let dateArray = values.data_aniv.split('/');
    data_aniv = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]).toISOString();
    const vals = { ...values, data_aniv: data_aniv, id_cliente_deovita: user.id_cliente_deovita };
    fetch(`${ENDPOINT}/client/update_user_info`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(vals),
    })
      .catch()
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError('Erro na Rede');
          return res.json();
        }
        if (res.status === 200) {
          setError('');
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.success === false) {
          setError('Falha na atualização');
          return;
        }
        if (data.success === true) {
          setUser((prevState) => ({
            ...prevState,
            phone1: vals.whatsapp,
            phone2: vals.telefone,
            email: vals.email,
            name: vals.nome,
            cpf: vals.cpf,
            data_aniv: data_aniv,
          }));
          setError('');
          navigate('/alerts/sucesso/atualiza_dados');
        }
      });
  }

  return (
    <>
      <ChildHeader />
      <chakra.p color="red">{error}</chakra.p>
      <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validationSchema={yupSchema} onSubmit={handleSubmit}>
        <Tabs as={Form} isFitted variant="enclosed" color="black" paddingBottom="20%" defaultIndex={defaultIndex} colorScheme={user.id_contrato ? 'orange': 'teal'}>
          <TabList m="1rem">
            <Tab>Dados Pessoais</Tab>
            <Tab>Dados para Contato</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" spacing="1rem" align="start">
                <TextField name="nome" label="Nome Completo:" isDisabled />
                <TextField name="cpf" label="CPF:" type="tel" mask="999.999.999-99" isDisabled />
                <TextField name="data_aniv" label="Data de Nascimento:" type="tel" mask="99/99/9999" isDisabled />
                <ButtonGroup pt="1rem" sx={{ width: '100%' }}>
                  <Button
                    backgroundColor={user.id_contrato ? '#EB8B2A': '#529C94'}
                    color='#FFF'
                    type="submit"
                    w={{
                      base: '100%',
                    }}
                  >
                    Atualizar
                  </Button>
                </ButtonGroup>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" spacing="1rem" align="start">
                <TextField name="telefone" type="tel" label="Número para Contato:" mask="(99) 99999-9999" />
                <TextField name="whatsapp" type="tel" label="Whatsapp:" mask="(99) 99999-9999" />
                <TextField type="email" name="email" label="Email:" />
                <ButtonGroup pt="1rem" sx={{ width: '100%' }}>
                  <Button backgroundColor={user.id_contrato ? '#EB8B2A': '#529C94'} color='#FFF' type="submit" w={{ base: '100%' }}>
                    Atualizar
                  </Button>
                </ButtonGroup>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default DadosPessoais;
