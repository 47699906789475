import { StarIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, chakra, ButtonGroup, Flex, HStack, VStack, WrapItem, Text, Image, Img, Heading } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import Loading from 'components/Loading';
import { AccountContext } from '../../../../AccountContext';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import Contact from '../../../../../images/ClinicoIcon.svg';
import BottomNavBar from 'components/BottomNavBar';

import SelectField from '../SelectField'

function EscolhaProcedimento() {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const { medico, paciente } = state;
  const dadosMedico = medico.dados;
  const horarios = medico.horarios;
  const [agendas, setAgendas] = useState([]);
  const [procedimento, setProcedimento] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const avaliacao = Math.round(dadosMedico.avaliacao_media);
  const profilePic = `${process.env.REACT_APP_URL_PROFILEPICS_OPERADOR}/${dadosMedico.medico}/foto_profissional.webp`;

  let namesArray = typeof dadosMedico.nome === 'string' ? dadosMedico?.nome?.split(' ') : [''];
  namesArray = namesArray.filter((name) => !['da', 'das', 'do', 'dos', 'de', 'des', 'e'].includes(name.toLowerCase()));
  const nomeMedico = namesArray.length < 2 ? namesArray[0] : `${namesArray[0]} ${namesArray[1]}`;

  let titulo = 'Dr(a).';
  if (dadosMedico.sexo === 'M') {
    titulo = 'Dr.';
  } else if (dadosMedico.sexo === 'F') {
    titulo = 'Dra.';
  }

  let procedimentos = [];
  let nomesProcedimentos = [];
  horarios.forEach((horario) => {
    horario.procedimentos.forEach((procedimento) => {
      if (nomesProcedimentos.includes(procedimento.nome) === false) {
        procedimentos.push(procedimento);
        nomesProcedimentos.push(procedimento.nome);
      }
    });
  });

  const valorProcedimento = procedimento !== null ? procedimentos[procedimento]?.valor : 0;

  setPage('Escolha o procedimento');

  function Profile() {
    return (
      <Flex w="100%" bg="" overflow="hidden">
        <Box w={1 / 3} bgSize="cover" align="center">
          <WrapItem ml="5px" mt="0.8em">
            <Avatar id={dadosMedico.medico_id} size="xl" name={dadosMedico.nome} src={profilePic} />
          </WrapItem>
          <chakra.a
            color="rgba(14, 170, 139, 1)"
            fontWeight="bold"
            fontSize="sm"
            onClick={() => navigate('/empresa/medico/curriculo', { state: { medico } })}
          >
            ver curriculo
          </chakra.a>
        </Box>
        <Box w={2 / 3} p={{ base: 4, md: 4 }} marginBottom="5%">
          <chakra.h2 fontSize="xl" fontWeight="bold">
            <Text casing="capitalize">{`${titulo} ${nomeMedico.toLowerCase()}`}</Text>
          </chakra.h2>
          <chakra.p fontSize="md">{dadosMedico.especialidade}</chakra.p>
          <chakra.p fontSize="sm">{dadosMedico.conselho}</chakra.p>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      <Formik initialValues={{ empresa: 0, dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
        <VStack
          align="center"
          justify={{
            base: 'center',
            md: 'space-around',
            xl: 'space-between',
          }}
          direction={{ base: 'column-reverse' }}
          wrap="no-wrap"
          minH="100vh"
          spacing={0}
          background="#EB8B2A"
          color="#585858"
        >
          <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
          <Box
            mt="1"
            id=""
            as="card"
            width="95%"
            minHeight="75vh"
            maxHeight="75vh"
            borderRadius="18px"
            fontSize="xs"
            size="10"
            backgroundColor="white"
            maxInlineSize="80"
            textAlign="center"
          >
            <Img ml="40%" mt="15%" src={Contact} w="15%" />
            <Heading m="2%" size="xs">
              Profissionais de Saúde
            </Heading>
            <Heading m="2%" size="xs">
              com agenda disponivel
            </Heading>

            <VStack w="90%" align="center" m="1.5em">
              <Profile />
              <Text fontSize="14px" mt="1em" color="#585858">
                Escolha o procedimento
              </Text>
              <SelectField
                name="procedimento"
                w="95%"
                h={50}
                variant="outline"
                borderWidth={2}
                options={nomesProcedimentos}
                onChange={(value) => {
                  setProcedimento(parseInt(value));
                  setDisabled(false);
                }}
              />

              <ButtonGroup pt="1rem" pb="25%" width="100%">
                <Button
                  w="100%"
                  background="#EB8B2A"
                  textColor="white"
                  onClick={() =>
                    navigate('/medico-agora/especialista/agendamento', {
                      state: {
                        ...state,
                        procedimentoId: procedimentos[procedimento].id,
                        procedimento: procedimentos[procedimento].nome,
                        price: valorProcedimento,
                        procedimentoParticular:
                          procedimentos &&
                          procedimentos.length > 0 &&
                          procedimentos[procedimento].procedimento_particular &&
                          procedimentos[procedimento].procedimento_particular.length > 0
                            ? procedimentos[procedimento].procedimento_particular[0].particular_id
                            : null,
                        valorParticular:
                          procedimentos &&
                          procedimentos.length > 0 &&
                          procedimentos[procedimento].procedimento_particular &&
                          procedimentos[procedimento].procedimento_particular.length > 0
                            ? procedimentos[procedimento].procedimento_particular[0].particular_valor
                            : null,
                      },
                    })
                  }
                  disabled={disabled}
                >
                  Continuar
                </Button>
              </ButtonGroup>
            </VStack>
          </Box>
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default EscolhaProcedimento;
