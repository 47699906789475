import React, { useContext, useState } from 'react';
import {
  chakra,
  Box,
  Img,
  LinkBox,
  Text,
  VStack,
  Checkbox,
  Image,
  RadioGroup,
  Stack,
  Radio,
  Spinner,
  Heading,
  useDisclosure,
  Textarea,
  Center,
  Highlight,
  Container,
  Badge,
  Flex,
  HStack,
  useMediaQuery,
  Button,
  useToast,
} from '@chakra-ui/react';
import BottomNavBar from 'components/BottomNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Voltar from '../../../../images/Voltar.svg';
import Contact from '../../../../images/ClinicoIcon.svg';
import BackButton from '../../../../images/assets/telemedicina/backbutton.svg';
import { AccountContext } from 'components/AccountContext';
function TermosDeUso() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');
  const drawerHeight = isLargeHeight ? '50vh' : '40vh';
  const [botaoSeguir, setBotaoSeguir] = useState(false);
  const [isLargeWidth] = useMediaQuery('(min-width: 668px)');
  const { user, toastInadimplente, setUser, updateData, loadedMedicina } = useContext(AccountContext);
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
  const toast = useToast();
  async function salvarAceiteContrato(paciente_id) {
    try {
      const postsData = await fetch(`${ENDPOINT}/clients/salvarAceiteContrato`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paciente_id: paciente_id, tipo: 'termo_de_uso_telemedicina' }),
      });
      if (postsData.status === 200) {
        navigate(`${state?.tela}`, { state: { pediatra: state?.pediatra } });
      } else {
        toast({
          title: 'Importante',
          description: 'Não foi possível concluir a solicitação. Tente novamente mais tarde.',
          status: 'warning',
          duration: 4000,
          isClosable: true,
          position: 'bottom',
          colorScheme: 'red',
          containerStyle: {
            paddingTop: '50px',
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [isMinHeigth] = useMediaQuery('(max-height: 592px)');
  return (
    <>
      <VStack align="center" wrap="no-wrap" minH="70vh" spacing={0} pt="6em" pb={isMinHeigth ? '1.5em' : "6em"} background="#EB8B2A" color="#585858" overflow={'scroll'}>
      <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
      <Box
        id=""
        as="card"
        width="95%"
        minHeight="75vh"
        borderRadius="18px"
        fontSize="xs"
        size="10"
        backgroundColor="white"
        maxInlineSize="80"
        textAlign="center"
          style={{
            maxHeight: botaoSeguir ? '95vh' : '80vh',
            overflowY: 'auto',
          }}
        >
          <VStack m={{ sm: '5%' }} textColor="#585858">
              <HStack backgroundColor="#E5E5E5" borderRadius="15px" mb={'2em'} >
              <Text p={'1em'}>
                <Text fontSize="18px" align="left" color="#585858" fontWeight={'bold'}>
                  Termo de Aceite para Utilização de Serviços de Telemedicina
                </Text>
                <Text fontSize="14px" align="left" color="#585858" fontWeight={'bold'} mt={'4%'}>
                  Este Termo de Aceite estabelece as condições de uso dos serviços de telemedicina oferecidos pelo Deovita Saúde Inteligente através
                  do aplicativo Deovita. Ao utilizar os serviços de telemedicina, você concorda com os termos e condições descritos abaixo.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  1. Aceitação dos Termos
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  Ao acessar ou utilizar os serviços de telemedicina oferecidos através do nosso aplicativo, você concorda em estar vinculado por este
                  Termo de Aceite. Caso não concorde com qualquer parte deste termo, não utilize os serviços de telemedicina.
                </Text>

                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  2. Definição de Serviços
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  Os serviços de telemedicina consistem em consultas médicas e atendimentos realizados por profissionais de saúde através de meios
                  eletrônicos, como vídeo chamadas, chats e mensagens eletrônicas.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  3. Elegibilidade
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  Para utilizar os serviços de telemedicina, você deve ser maior de 18 anos ou, se menor, estar acompanhado por um responsável legal
                  durante a consulta.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  4. Uso Adequado dos Serviços
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  4.1. Os serviços de telemedicina devem ser utilizados para fins legítimos e de acordo com a legislação vigente. 4.2. Você concorda
                  em fornecer informações precisas e completas durante as consultas, entendendo que a omissão de informações relevantes pode
                  comprometer a qualidade do atendimento.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  5. Privacidade e Confidencialidade
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  5.1. A Deovita Saúde Inteligente se compromete a proteger a privacidade e confidencialidade das suas informações pessoais e médicas,
                  em conformidade com a legislação de proteção de dados. 5.2. Todas as informações fornecidas durante as consultas serão tratadas como
                  confidenciais e não serão compartilhadas com terceiros sem o seu consentimento, exceto conforme exigido por lei.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  6. Pagamento pelos Serviços
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  6.1. Os serviços de telemedicina podem ser gratuitos ou pagos, dependendo do seu plano de assinatura. 6.2. Caso o serviço seja pago,
                  você será informado sobre os custos antes da realização da consulta, e o pagamento deverá ser realizado através dos meios
                  disponíveis no aplicativo.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  7. Limitação de Responsabilidade
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  7.1. Os profissionais de saúde que realizam atendimentos através do aplicativo são devidamente qualificados e licenciados para
                  exercer a profissão. 7.2. A Deovita Saúde Inteligente não se responsabiliza por diagnósticos ou tratamentos realizados com base em
                  informações incompletas ou imprecisas fornecidas pelo usuário. 7.3. O usuário compreende que os serviços de telemedicina possuem
                  limitações e que, em casos de emergência, deve-se buscar atendimento médico presencial imediatamente. 7.4. A Deovita Saúde
                  Inteligente isenta-se de qualquer responsabilidade por erros cometidos pelos profissionais de saúde durante as consultas ou por
                  erros resultantes de informações fornecidas pelo usuário. Qualquer ação ou omissão por parte dos profissionais de saúde é de
                  responsabilidade exclusiva deles.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  8. Alterações nos Termos
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  A Deovita Saúde Inteligente reserva-se o direito de modificar este Termo de Aceite a qualquer momento. Quaisquer alterações serão
                  notificadas aos usuários através do aplicativo, e o uso continuado dos serviços constituirá aceitação das mudanças.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  9. Cancelamento dos Serviços
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  Você pode cancelar o uso dos serviços de telemedicina a qualquer momento. No caso de cancelamento de um serviço pago, a política de
                  reembolso, se aplicável, será informada previamente.
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'} fontWeight={'bold'}>
                  10. Contato
                </Text>
                <Text fontSize="14px" align="left" color="#585858" mt={'4%'}>
                  Se você tiver qualquer dúvida sobre este Termo de Aceite ou sobre os serviços de telemedicina, entre em contato conosco através do
                  08002802133. Ao clicar em "Aceito", você confirma que leu, entendeu e concorda com os termos e condições aqui estabelecidos.
                </Text>
              </Text>
            </HStack>
            <Checkbox
              color="#585858"
              fontSize="14px"
              alignSelf="flex-start"
              onChange={() => {
                if (botaoSeguir) {
                  setBotaoSeguir(false);
                  return;
                }
                setBotaoSeguir(true);
              }}
            >
              <Text fontSize="14px" align="left" color="#585858">
                Li e concordo com os termos de uso
              </Text>
            </Checkbox>
            {botaoSeguir ? (
              <Button
                textAlign="center"
                id="callBtn"
                color={'white'}
                fontWeight="bold"
                w={isLargeWidth ? '60%' : '100%'}
                fontSize="1.5em"
                mt={'2em'}
                backgroundColor={'#EB8B2A'}
                onClick={() => {
                  salvarAceiteContrato(user.id_cliente_deovita);
                }}
              >
                Continuar
              </Button>
            ) : (
              <></>
            )}
          </VStack>
        </Box>
      </VStack>
    </>
  );
}

export default TermosDeUso;
