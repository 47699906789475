import { React, useContext, useEffect, useState } from 'react';
import {
  chakra,
  Button,
  Heading,
  VStack,
  Text,
  Center,
  Stack,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Image,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import Warning from '../../../images/cartaoAtualizacao.svg';
import { useNavigate } from 'react-router-dom';
function ModalCartaoV5Pagarme(props) {
  const disclosure = props.disclosure;
  const { user, setUser } = useContext(AccountContext);
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  const navigate = useNavigate();
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;

  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered size={miniLargeHeight ? 'sm' : 'xs'}>
        <ModalOverlay />
        <ModalContent borderRadius="0.8em">
          <ModalBody>
            <VStack align="left" mt={'1em'}>
              <Image src={Warning} h={'3em'} />
              <chakra.span fontSize="18px" fontWeight={'bold'} textAlign={'center'}>
                Não fique sem acessar os benefícios de sua assinatura!
              </chakra.span>
              <Text fontSize="16px" color="#585858" textAlign={'center'}>
                Atualize sua forma de pagamento para continuar utilizando a assinatura DeoVita.
              </Text>
            </VStack>
            <HStack spacing={3} align="start" mt={'2em'} mb={'2em'}>
              <Button
                isFullWidth
                borderRadius={'0.8em'}
                color={'#CCCCCC'}
                fontWeight="bold"
                border={'1px solid #CCCCCC'}
                bg={'white'}
                fontSize={'14px'}
                _focus={false}
                onClick={() => {
                  disclosure.onClose()
                }}
              >
                Lembrar depois
              </Button>
              <Button
                bg={'orange'}
                borderColor=""
                borderRadius={'0.8em'}
                color={'white'}
                isFullWidth
                fontWeight="bold"
                fontSize={'14px'}
                onClick={() => {
                  navigate('/perfil/dados-bancarios');
                }}
              >
                Atualizar agora
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalCartaoV5Pagarme;
