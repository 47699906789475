import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Stack,
  Container,
  Text,
  DrawerOverlay,
  Flex,
  AlertIcon,
  Alert,
  Image,
  useDisclosure,
  VStack,
  Center,
  Heading,
  Button,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { MdOutlineWarningAmber } from 'react-icons/md';

import warning from '../../../images/assets/icons/warning-icon.svg';
import React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner1 from '../../../images/banner1.png';
function DrawerServico(props) {
  const disclosure = props.disclosure;
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  return user.titular === true || user.loggedIn === false ? (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} mb="-4" width="100%" h="70%">
          <Center>
            {' '}
            <Heading mt="5" textColor="#5AB2AD" textAlign="center" textTransform="inherit" paddingTop={20}>
              Para escolher essa opção é necessário ser assinante DeoVita.
            </Heading>
          </Center>

          <DrawerBody>
            <Stack direction="column" spacing={4} paddingTop={30} align="center">
              <Button
                bgColor="#5AB2AD"
                color="white"
                _hover="none"
                w="100%"
                h="3em"
                size="md"
                borderRadius="0.6em"
                onClick={() => {
                  navigate(user.loggedIn === false ? '/cadastro' : '/cadastro/plano', {
                    state: {
                      client_id: user.id_cliente_deovita,
                      name: user.name,
                      cpf: user.cpf,
                    },
                  });
                }}
              >
                Assinar o plano
              </Button>
              {user.loggedIn === true && user.contrato_id === 0 ? (
                <Button
                  bgColor="#FF9A00"
                  mt="5%"
                  _hover="none"
                  color="white"
                  w="100%"
                  h="3em"
                  size="lg"
                  fontSize="md"
                  borderRadius="0.6em"
                  onClick={() => {
                    disclosure.onClose();
                  }}
                >
                  Seguir com valor particular
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  ) : (
    <>
      {' '}
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />

        <DrawerContent borderRadius={20} mb="-4" width="100%" h="90%">
          <DrawerBody>
            <VStack align="center" direction={{ base: 'column-reverse' }} spacing={4} height="full" px={2} py={1}>
              <Container maxW={'1xl'}>
                <Stack textAlign={'center'} align={'center'} py={{ base: 10, md: 18 }}>
                  <Heading fontWeight={600} mt="1em" fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }} lineHeight={'100%'}>
                    Somente o titular do plano pode utilizar
                    <Text as={'span'} textAlign={'center'} isTruncated textColor="#ee984e">
                      {''} este serviço.
                    </Text>
                  </Heading>
                  <Center>
                    {' '}
                    <Flex w={'full'}>
                      <Image src={warning} mt="6em" size="10em" />
                    </Flex>
                  </Center>
                  <Stack direction="column" align="center"></Stack>

                  <Text textAlign="center" fontSize="0.9em" fontWeight="bold">
                    Problemas ao utilizar o serviço?
                  </Text>
                  <Button 
                    colorScheme={'teal'} 
                    px={10} 
                    variant="link" 
                    onClick={() => {
                      window?.ReactNativeWebView?.postMessage(
                        JSON.stringify({
                          service: true,
                          openInBrowser: true,
                          url: 'https://wa.me/5508002802133',
                        })
                      ); 
                    }}>
                    Falar com Suporte
                  </Button>
                </Stack>
              </Container>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerServico;
