import { StarIcon } from '@chakra-ui/icons';
import { Img, Text, VStack, chakra, Tabs, Box, TabList, Tab, TabPanels, TabPanel, HStack, Badge, WrapItem } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Back from '../Medico/Imagens/back.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function CurriculoMedicoLivre() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { medico, pic } = state;
  const img = process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic;
  const options = { month: 'short', day: 'numeric' };
  const [exp, setExp] = useState([]);
  const [cv, setCv] = useState([]);
  const event = new Date(medico.horarios[0].AVA_DATE.split('/').reverse().join('-') + 'T08:00:00');
  const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

  const url = `${ENDPOINT}/schedule/cv/${medico.dados.medico}`;

  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      });
      let data = await response.json();
      setExp(data.experiencia);
      setCv(data.formacao);
      return data;
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    fetchData(url);
  }, []);

  const useEsp = exp.map((item, index) => {
    return <chakra.p>{item.experiencia}</chakra.p>;
  });

  const useExp = exp.map((item, index) => {
    return <chakra.p>{item.tempo_experiencia}</chakra.p>;
  });

  const Formacoes = cv.map((item) => (
    <>
      <chakra.p marginTop="5%" fontWeight="bold">
        {item.nivel_formacao}
      </chakra.p>
      <chakra.p>{item.formacao_academica + ' - ' + item.data_formacao}</chakra.p>
    </>
  ));

  const Horarios = (props) => (
    <Badge
      direction="column"
      minWidth="320px"
      height="90px"
      left="0px"
      borderRadius="1em"
      margin="1em"
      ml="2"
      padding="0.5em"
      fontSize="sm"
      colorScheme={props.rank === 0 ? 'rgba(90, 178, 173, 0.25);' : 'orange'}
      onClick={() => {
        navigate('/agendamentolivre', {
          state: {
            medico,
            startNum: 0,
            st: 0,
            defaultDateTime: props.horario.AVA_DATE + ' ' + props.horario.AVA_START_TIME,
          },
        });
      }}
    >
      <Box
        backgroundColor={props.rank === 0 ? 'rgba(90, 178, 173, 0.3)' : 'rgba(239, 167, 63, 0.35);'}
        borderRadius="5px"
        margin="8px"
        color={props.rank === 0 ? 'rgba(62, 100, 255, 1)' : 'rgba(62, 100, 255, 1)'}
        padding="1px"
        width="60px"
      >
        <Text fontWeight="bold" fontSize="xl" ml="4" color={props.rank === 0 ? '' : '#FE904B'}>
          {dataIcon[0]}
        </Text>
        <Text fontWeight="bold" fontSize="lg" ml="2.5" color={props.rank === 0 ? '' : '#FE904B'}>
          {dataIcon[1]}
        </Text>
      </Box>
      <chakra.h1 ml="20" color="black" fontSize="sm" mt="-14">
        {props.horario.ACTIVITY_NAME ? props.horario.ACTIVITY_NAME : ''}
      </chakra.h1>
      <Text ml="20" color="black">
        {event.toLocaleString('pt-br', { weekday: 'long' })}. {props.horario.AVA_START_TIME} - {props.horario.AVA_END_TIME}{' '}
      </Text>
    </Badge>
  );

  return (
    <>
      <Img
        src={Back}
        boxSize="2em"
        onClick={() => navigate(-1)}
        color="white"
        marginTop="2.5em"
        position="absolute"
        left="5"
        zIndex="100"
        borderRadius="15px"
        backgroundColor="black"
      />
      <Img src={img} zIndex="-1" w="full" position="relative" />
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        marginTop="-8"
        shadow="md"
        backgroundColor="white"
        minHeight="100px"
        borderTopRadius="30px"
        zIndex="100"
      >
        <chakra.h1 fontWeight="bold" align="center" fontSize="xl">
          {medico.dados.nome}
        </chakra.h1>
        <chakra.h2 marginTop="1em" paddingBottom="0.5em" align="center">
          {' '}
          5 <StarIcon color="gray.500" /> | {medico.dados.conselho} {medico.dados.rqe}
        </chakra.h2>
      </chakra.header>
      <Tabs variant="soft-rounded" w="full" px={{ base: 2, sm: 4 }} py={8}>
        <TabList borderBottom="1px solid gray" paddingBottom="1em">
          <Tab _selected={{ color: 'white', bg: 'black' }}>Experiencia</Tab>
          <Tab _selected={{ color: 'white', bg: 'black' }}>Formação</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500">Especialidade</chakra.p>
                {exp && useEsp}
              </VStack>
              <VStack>
                <chakra.p color="gray.500">Experiencia</chakra.p>
                {exp && useExp}
              </VStack>
            </HStack>
            <chakra.p marginTop="5%" fontWeight="bold">
              Sobre mim
            </chakra.p>
            <chakra.p>{medico.dados.descricaoPessoal}</chakra.p>
          </TabPanel>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p>{medico.dados.especializacaoacademica}</chakra.p>
              </VStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p>{medico.dados.experiencia_tempo}</chakra.p>
              </VStack>
            </HStack>
            {cv && Formacoes}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <WrapItem mt="35" />
    </>
  );
}

export default CurriculoMedicoLivre;
