import {
  Button,
  Center,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import BottomNavBar from 'components/BottomNavBar';
import React, {  } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AvisoNaoCadastrouDadosBancarios() {
  const navigate = useNavigate();

  const onClickCadastrarDadosBancarios = () => navigate('/empreendedor/dados-bancarios');

  return (
    <>
      <VStack
        align="row"
        mt="3"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'row' }}
        wrap="no-wrap"
        minH="10vh"
        px={5}
        spacing={2}
      >
        <Tabs isFitted>
          <TabList>
            <Tab>Revendedor</Tab>
            <Tab isDisabled></Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Heading size="md" paddingInlineStart={6} mt="4">
                Você ainda não cadastrou seus dados bancários. Para gerar o código de indicação, cadastre seus dados bancários.
              </Heading>
              <Center>
                <Button
                  mt="4em"
                  w="90%"
                  colorScheme="teal"
                  onClick={onClickCadastrarDadosBancarios}
                >
                  Cadastrar Dados Bancários
                </Button>
              </Center>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <BottomNavBar />
      </VStack>
    </>
  )
}