import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Avatar,
  Badge,
  Box,
  chakra,
  ChakraProvider,
  HStack,
  Text,
  VStack,
  Img,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import { useContext, useEffect } from 'react';
import ChildHeader from '../../ChildHeader';
import Resumo from './Resumo';
import { AccountContext } from '../../../../AccountContext';
import { MdOutlineOpenInBrowser } from 'react-icons/md';
import BottomNavBar from '../../../../BottomNavBar';
import SearchBar from './SearchBar';
import { useLocation, useNavigate } from 'react-router-dom';

function Main() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, setPage } = useContext(AccountContext);
  const { empresas } = state;
  let empresasCont = 0;
  setPage('Estabelecimentos');

  const empresasList = empresas.map((empresa) => {
    console.log(empresa);
    const unidade = empresa;
    return (
      <>
        <>
          <HStack align="center" w="100%" margin="3%">
            <Box>
              <Image
                w="3em"
                name={empresa.NAME}
                src={`${process.env.REACT_APP_URL_LOGOMARCA}/` + empresa.LOCATION_LID + '/logomarca.png'}
                fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
              />
            </Box>
            <Box align="center" w="40%">
              {empresa.NAME}
            </Box>
            <chakra.p fontSize="1em" color="#0EAA8B" onClick={() => navigate('/empresa/detalhes', { state: { unidade } })}>
              ver detalhes
            </chakra.p>
          </HStack>
        </>
      </>
    );
  });

  return (
    <>
      <ChildHeader />
      <VStack margin="0 5%" align="start">
        <chakra.h1 marginTop="10%" fontWeight="bold">
          Gerenciamento
        </chakra.h1>
        <Resumo data={empresas} />

        <Tabs width="100%">
          <TabList></TabList>
          <TabPanels>
            <Box marginBottom="5%"></Box>
            <SearchBar />
            <Box marginTop="5%">{empresas && empresasList}</Box>
          </TabPanels>
        </Tabs>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
