import React, {  } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import IconHandCoin from '../../../images/MDV/GastosTotais/icon-hand-coin.svg';

const Valores = ({ aReceber = 0, ganhosTotais = 0 }) => (
  <Box display={'flex'} flexDirection={'row'} padding={'16px'} bg={'#f5f5f5'} borderRadius={10} gap={'10px'}>
    <Box display={'flex'} padding={'16px'} justifyContent={'center'} alignItems={'center'} bgColor={'#EB8B2A'} borderRadius={'8px'}>
      <Image src={IconHandCoin} alt="Hand Money" w={12} h={'auto'} />
    </Box>
    <Box w={'100%'} justifyContent={'center'} alignItems={'center'} padding={'8px'} bgColor={'#FFF'} borderRadius={'4px'}>
      <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'} alignSelf={'stretch'}>
        <Text fontSize="sm" fontWeight={700} color={'#616161'}>
          A receber (R$):
        </Text>
        <Text fontSize="xl" fontWeight={800} color={'#C96F1B'}>
          R${aReceber}
        </Text>
      </Box>
      {/* --- Descomente para exibir os gastos totais --- */}
      {/* <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'} alignSelf={'stretch'}>
        <Text fontSize="sm" fontWeight={700} color={'#616161'}>
          Ganhos totais (R$):
        </Text>
        <Text fontSize="xl" fontWeight={800} color={'#5727B0'}>
          {ganhosTotais}
        </Text>
      </Box> */}
    </Box>
  </Box>
);

export default Valores;