import { Box, Button, ButtonGroup, Flex, Heading, Image, VStack, chakra, useLatestRef } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AvisoPendencias from '../../../images/AvisoPendencias.svg';
function CancelamentoFinal() {
  const { setPage, user, cancelaContrato } = useContext(AccountContext);
  setPage('Cancelamento do contrato');
  const navigate = useNavigate();
  const state = useLocation();

  return (
    <>
      <ChildHeader />
      <Box>
        <Flex minW="210px" maxW="340px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
          <VStack w={'100%'} mt={'2em'}>
            <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} p={'1em'} w={'100%'}>
              <Image boxSize="30px" objectFit="cover" src={AvisoPendencias} mr={'15em'}></Image>
              <VStack w={'100%'}>
                <Heading fontSize={'19px'} color={'#585858'} fontWeight={'bold'} ml={'-2em'}>
                  Você possue <b style={{ color: '#D60000' }}>pendências</b>
                </Heading>
              </VStack>

              <chakra.span color={'#585858'} fontSize="16" textAlign="left">
                Para finalizar o cancelamento do seu contrato é necessário regularizar as pendências da sua conta.
              </chakra.span>
              <chakra.span color={'#585858'} fontSize="16" textAlign="left">
                O não pagamento da multa contratual poderá ocasionar na inscrição do seu CPF em serviços de proteção de crédito (SPC/SERASA)
              </chakra.span>
            </VStack>

            <Box position="fixed" bottom="1rem" left="50%" transform="translateX(-50%)">
              <ButtonGroup pt="1rem" sx={{ width: '100%' }} w={'100%'}>
                <Button
                  name="btn"
                  color="white"
                  bg={'#EB8B2A'}
                  type="submit"
                  w={'20em'}
                  h={'2.5em'}
                  onClick={() => {
                    navigate('/pagamentoparcelas', { state: { status: 'cancelamento_contrato', motivo: state?.state?.motivo } })}}
                >
                  Regularizar pendências
                </Button>
              </ButtonGroup>
              <ButtonGroup
                pt="1rem"
                pb="15%"
                sx={{ width: '100%' }}
                w={'100%'}
                onClick={async () => {
                  const resposta = await cancelaContrato(user, state.state.pago, state.state.motivo);
                  if (!resposta.success) {
                    return;
                  }
                  navigate('/perfil/assinaturas/contrato-cancelado-sucesso');
                }}
              >
                <Button name="btn" color="#909090" bg={'#F3F3F3'} type="submit" w={'20em'} h={'2.5em'}>
                  Seguir com o cancelamento
                </Button>
              </ButtonGroup>
            </Box>
          </VStack>
        </Flex>
      </Box>
    </>
  );
}

export default CancelamentoFinal;
