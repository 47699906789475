import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  Tabs,
  Tab,
  Text,
  Image,
  TabList,
  TabPanel,
  chakra,
  useDisclosure,
  Box,
  Center,
  TabPanels,
  Button,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import Banner1 from '../../images/banner_1_small.png';
import Banner2 from '../../images/banner_2_small.png';
import Banner3 from '../../images/banner_3_small.png';
import Banner4 from '../../images/banner_4.png';
import Banner5 from '../../images/banner_5.png';
import Banner6 from '../../images/banner_6.png';
import mapa from '../../images/Basemap.jpg';
import { PhoneIcon } from '@chakra-ui/icons';
import localicon from '../../images/assets/icons/local.png';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function TelaDadosBanner() {
  const { setPage } = useContext(AccountContext);
  const { user, setUser } = useContext(AccountContext);
  const { id_contrato, titular, loggedIn } = user;
  const { state } = useLocation();
  const [banner, setBanner] = useState(null);
  const [titleText, setTitleText] = useState('');
  const [infoText, setInfoText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [footerText, setFooterText] = useState('');
  const navigate = useNavigate();
  const [medico, setMedico] = useState(false);
  const [tipo, setTipo] = useState('');
  const [credenciados, setCredenciados] = useState([]);
  const disclosure = useDisclosure();
  const toast = useToast();

  console.log(user);

  const fetchRdy = async () => {
    try {
      const response = await fetch(ENDPOINT + `/pet/medico_online`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      setMedico(postsData.medico_online);
    } catch (error) {
      console.log(error);
    }
  };
  fetchRdy();

  useEffect(() => {
    switch (state.banner) {
      case 1:
        setBanner(Banner1);
        setPage('Teleconsulta Pet');
        setTitleText('Tenha um veterinário online para tirar dúvidas sobre a saúde do seu Pet');
        setBodyText(
          'Chega de gastar dinheiro com idas casuais ao veterinário. Com o Telepet DeoVita você tem um profissional disponível para tirar suas dúvidas e obter orientações sobre a saúde do seu amigo de quatro patas.'
        );
        setInfoText('Tudo isso sem custo adicional');
        setFooterText('Para ter acesso a esse serviço, é necessário ser um assinante DeoVita.');
        break;
      case 2:
        setBanner(Banner2);
        setPage('Desconto em exames');
        setTitleText('Prevenção é o primeiro passo para manter a saúde em dia');
        setBodyText(
          'Não deixe de realizar seus exames por conta dos altos valores cobrados por aí. Com o DeoVita você tem até 80% de desconto nos exames.'
        );
        setInfoText('Não espere mais exames no posto!');
        setFooterText('Para ter acesso a esse serviço, é necessário ser um assinante DeoVita.');
        break;
      case 3:
        setBanner(Banner3);
        setPage('Economia');
        setTitleText('As maiores redes de farmácia do Brasil estão no DeoVita! ');
        setBodyText(
          'Tenha descontos nas compras de medicamentos no site ou balcão.<br><br>Os descontos de genéricos começam a partir de 37% na Drogasil!<br><br>Com apenas uma compra, você pode economizar o valor de meses de assinatura do DeoVita.'
        );
        setInfoText('Não pague caro por medicamentos!');
        setFooterText('Para usufruir é muito simples. Sendo um assinante DeoVita, basta fornecer seu CPF na hora da compra.');
        break;
      case 4:
        setBanner(Banner4);
        break;
      case 5:
        setBanner(Banner5);
        break;
      case 6:
        setBanner(Banner6);
        setPage('Credenciados');
        setTitleText('Veja abaixo a lista de credenciados que estão com o DeoVita!');
        setBodyText(
          `Para usufruir dos descontos em clínicas credenciadas você precisa ser um assinante DeoVita! ${
            user.loggedIn == false ? ' Confira as informações sobre os planos!' : ''
          }`
        );
        break;
      default:
        break;
    }
  }, []);

  function CardDadosPlano({ index }) {
    const currentColor = index === 1 ? '#529C94' : '#EB8B2A';

    const plano = <chakra.span color={currentColor}>plano</chakra.span>;

    const DataIndex1 = () => (
      <Box>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar quantos `}
          {<chakra.span color={currentColor}>dependentes</chakra.span>}
          {` quiser;`}
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar um `}
          {<chakra.span color={currentColor}>pet</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Usufruir de `}
          {<chakra.span color={currentColor}>consultas online</chakra.span>}
          {` gratuitamente`};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Tabela de `}
          {<chakra.span color={currentColor}>preços exclusivos</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {<chakra.span color={currentColor}>· Rede de atendimento</chakra.span>};
        </Text>
      </Box>
    );

    const DataIndex2 = () => (
      <Box>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar um `}
          {<chakra.span color={currentColor}>pet</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Usufruir de `}
          {<chakra.span color={currentColor}>consultas online</chakra.span>}
          {` gratuitamente`};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Tabela de `}
          {<chakra.span color={currentColor}>preços exclusivos</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {<chakra.span color={currentColor}>· Rede de atendimento</chakra.span>};
        </Text>
      </Box>
    );

    const BoxPreco = ({ preco }) => (
      <Box>
        <Text ml={10} color="#FFF" textAlign="start">
          Por apenas:
        </Text>
        <Text ml={10} fontSize={40} fontWeight="bold" color="#FFF" textAlign="start">
          R$ {preco}
        </Text>
      </Box>
    );

    const BoxPreçoInfo = () => (
      <Box mt={2} display="flex" justifyContent="center" backgroundColor={currentColor} borderRadius={20} w="90%" overflow="hidden">
        <Tabs w="100%" colorScheme="teal">
          <TabList w="100%">
            <Tab
              w="50%"
              h="10%"
              fontSize="14px"
              color={index === 1 ? '#529C94' : '#D4B799'}
              backgroundColor={index === 1 ? '#DDF9F7' : '#FFF0E1'}
              _selected={{ color: '#FFF', bg: currentColor }}
            >
              Mensal
            </Tab>
            <Tab
              w="50%"
              h="10%"
              fontSize="14px"
              color={index === 1 ? '#529C94' : '#D4B799'}
              backgroundColor={index === 1 ? '#DDF9F7' : '#FFF0E1'}
              _selected={{ color: '#FFF', bg: currentColor }}
            >
              Anual
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <BoxPreco preco={index === 1 ? '39,90' : '29,90'} />
            </TabPanel>
            <TabPanel>
              <BoxPreco preco={index === 1 ? '399,00' : '299,00'} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );

    return (
      <Box backgroundColor="#F1F1F1" w="315px" h="354px" borderRadius="20px" p={2} position="relative">
        <Text textAlign="center" pb={2}>
          {`Assinando nosso `}
          {plano}
          {` você pode:`}
        </Text>
        <Box pl={2} minH={100}>
          {index === 1 ? <DataIndex1 /> : <DataIndex2 />}
        </Box>
        <Center>
          <BoxPreçoInfo />
        </Center>

        <Box
          position="absolute"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          onClick={() => {
            if (user.id_contrato > 0) {
              if (user.contrato_pendente_pagamento === true) {
                toast({
                  title: 'Assinatura pendente',
                  description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                  status: 'warning',
                  duration: 4000,
                  isClosable: true,
                  position: 'bottom',
                  colorScheme: 'green',
                  containerStyle: {
                    paddingBottom: '5em',
                  },
                });
                return;
              } else {
                if (user.loggedIn === true && user.id_contrato > 0) {
                  if (medico === true) {
                    navigate('/telepet/termos');
                  } else {
                    toast({
                      title: 'Indisponível',
                      description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                  }
                } else {
                  setTipo('Veterinário');
                  disclosure.onOpen();
                }
              }
            } else if (user.loggedIn === false) {
              setUser((prevState) => {
                return {
                  ...prevState,
                  service_type: 'assinar',
                };
              });
              navigate('/cadastro');
            } else {
              setUser((prevState) => {
                return {
                  ...prevState,
                  service: 'telemedicine',
                  service_type: 'assinar',
                };
              });
              navigate('/cadastro/plano', {
                state: {
                  client_id: user.id_cliente_deovita,
                  name: user.name,
                  cpf: user.cpf,
                },
              });
            }
          }}
        >
          <Button bottom={0} color="#fff" backgroundColor={index === 1 ? '#529C94' : '#EB8B2A'} borderRadius={20}>
            {loggedIn === false ? 'Quero me cadastrar agora!' : 'Quero assinar agora!'}
          </Button>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    searchcredenciados();
  }, []);

  const searchcredenciados = async (text) => {
    try {
      const url = `${ENDPOINT}/schedule/searchCredenciados`;
      const responseData = await fetch(url);
      const response = await responseData.json();

      if (response) {
        const credenciadosData = response;
        setCredenciados(credenciadosData);
      } else {
        console.log('dsds');
        toast();
      }
    } catch (err) {
      console.log(err);
      toast();
    }
  };
  const isAndroid = user.device === 'android' ? true : false;

  var mapUriPrefix = 'https://maps.apple.com/?q=';
  if (isAndroid) mapUriPrefix = 'https://maps.google.com/?q=';

  function CardCredenciados() {
    return (
      <div>
        {credenciados.map((credenciado) => (
          <Box
            key={credenciado.id} // Adicione uma chave única para cada elemento do mapeamento
            backgroundColor="#F1F1F1"
            w="315px"
            borderRadius="20px"
            p={2}
            position="relative"
            mb={3}
          >
            <Center
              mt={2}
              textAlign="center"
              onClick={() => {
                window.open(
                  `${mapUriPrefix}${credenciado.logradouro}, ${credenciado.numero}, ${credenciado.bairro}, ${credenciado.cidade}, ${credenciado.uf}`
                );
              }}
            >
              <Image
                src={`https://backoffice.deovita.com.br/deovita//upload/fachada/` + credenciado.empresa_id + '/fachada.png'}
                fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
                backgroundColor="white"
                mt="0.5em"
                mb="0.5em"
                borderRadius="1.5em"
                name={credenciado.nome}
              />
            </Center>
            <Text pb={2} fontSize="22" fontWeight="bold" color="#585858">
              {credenciado.nome} {/* Use a propriedade apropriada do objeto credenciado */}
            </Text>
            <Text fontSize="14" color="#585858">
              <PhoneIcon boxSize={3} color="#5AB2AD" /> {credenciado.telefone}
            </Text>
            <Text fontSize="14" color="#585858">
              <Flex align="center">
                <Image boxSize="13px" objectFit="cover" src={localicon} alt="" mr={1} />
                {credenciado.logradouro}, {credenciado.cidade}({credenciado.uf})
              </Flex>

              <Flex justifyContent="space-between">
                <Box
                  textAlign="center"
                  justifyContent="center"
                  backgroundColor="#EB8B2A"
                  borderRadius="7px"
                  w="48%"
                  h="35px"
                  display="flex"
                  pl={2}
                  alignItems="center"
                  onClick={() => {
                    window.open(`https://api.whatsapp.com/send?phone=5508002802133&text=Olá, gostaria de tirar uma dúvida`);
                  }}
                >
                  <Text color="#fff" fontSize={12} fontWeight="bold">
                    Entrar em contato
                  </Text>
                </Box>

                <Box
                  textAlign="center"
                  justifyContent="center"
                  backgroundColor="#EB8B2A"
                  borderRadius="7px"
                  w="48%"
                  h="35px"
                  display="flex"
                  pl={2}
                  alignItems="center"
                  onClick={() => {
                    window.open(
                      `${mapUriPrefix}${credenciado.logradouro}, ${credenciado.numero}, ${credenciado.bairro}, ${credenciado.cidade}, ${credenciado.uf}`
                    );
                  }}
                >
                  <Text color="#fff" fontSize={12} fontWeight="bold">
                    Ver localização
                  </Text>
                </Box>
              </Flex>
            </Text>
          </Box>
        ))}
      </div>
    );
  }

  return (
    <>
      <NoUserHeader />
      <VStack pl="2em" pr="2em">
        <Center mt={2}>
          <Image src={banner} borderRadius={20} h="130" w="100vw"></Image>
        </Center>

        <Text pt={3} color="#529C94" fontWeight="bold" fontSize="20px" lineHeight="20px">
          {titleText}
        </Text>

        <Text pt={3} pb={3} lineHeight="16px" fontSize="14px" color="#818181">
          <div
            dangerouslySetInnerHTML={{
              __html: `${bodyText}`,
            }}
          />
        </Text>

        {infoText && infoText != '' ? (
          <Box backgroundColor="#EB8B2A" borderRadius="5px" w="100%" h="30px" display="flex" pl={2} alignItems="center" justifyContent={'center'}>
            <Text color="#fff" fontWeight="bold">
              {infoText}
            </Text>
          </Box>
        ) : null}

        {footerText && footerText != '' ? (
          <Text pt={3} lineHeight="16px" fontSize="14px" color="#818181">
            {footerText}
          </Text>
        ) : null}
      </VStack>

      {user.loggedIn === true && user.id_contrato > 0 ? (
        <VStack pl="2em" pr="2em" mt={7}>
          <Box w="100%">
            {state.banner !== 3 && state.banner !== 6 && (
              <Button
                bottom={0}
                lineHeight="16px"
                w="100%"
                color="white"
                backgroundColor={'#EB8B2A'}
                borderRadius={20}
                onClick={() => {
                  if (user.id_contrato > 0) {
                    if (user.contrato_pendente_pagamento === true) {
                      toast({
                        title: 'Assinatura pendente',
                        description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                        status: 'warning',
                        duration: 4000,
                        isClosable: true,
                        position: 'bottom',
                        colorScheme: 'green',
                        containerStyle: {
                          paddingBottom: '5em',
                        },
                      });
                      return;
                    } else {
                      if (state.banner == 1) {
                        if (user.loggedIn === true && user.id_contrato > 0) {
                          if (medico === true) {
                            navigate('/telepet/termos');
                          } else {
                            toast({
                              title: 'Indisponível',
                              description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                              status: 'warning',
                              duration: 4000,
                              isClosable: true,
                              position: 'bottom',
                              colorScheme: 'green',
                              containerStyle: {
                                paddingBottom: '5em',
                              },
                            });
                          }
                        } else {
                          setTipo('Veterinário');
                          disclosure.onOpen();
                        }
                      } else if (state.banner == 2) {
                        navigate('/exames/agendamento', {
                          state: { grupo_nome: 'Exame', grupo: 0 },
                        });
                      }
                    }
                  } else if (user.loggedIn === false) {
                    setUser((prevState) => {
                      return {
                        ...prevState,
                        service_type: 'assinar',
                      };
                    });
                    navigate('/cadastro');
                  } else {
                    setUser((prevState) => {
                      return {
                        ...prevState,
                        service: 'telemedicine',
                        service_type: 'assinar',
                      };
                    });
                    navigate('/cadastro/plano', {
                      state: {
                        client_id: user.id_cliente_deovita,
                        name: user.name,
                        cpf: user.cpf,
                      },
                    });
                  }
                }}
              >
                {state.banner == 1 ? 'Iniciar Atendimento' : 'Quero ver os exames disponíveis'}
              </Button>
            )}
          </Box>
        </VStack>
      ) : (
        <Tabs w="100%" colorScheme="teal" pt={5}>
          <TabList w="100%">
            <Tab w="50%" fontSize="14px">
              PLANO FAMÍLIA
            </Tab>
            <Tab w="50%" fontSize="14px">
              PLANO INDIVIDUAL
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Center p={5}>
                <CardDadosPlano index={1} />
              </Center>
            </TabPanel>
            <TabPanel>
              <Center p={5}>
                <CardDadosPlano index={2} />
              </Center>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {state.banner == 6 ? (
        <Center p={5}>
          <CardCredenciados />
        </Center>
      ) : null}
    </>
  );
}

export default TelaDadosBanner;
