import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import requestTimeout from "../../../Utils/Functions/requestTimeout";
import { AccountContext } from "components/AccountContext";

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ProcessarPagamento() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user, setUser, page, setPage } = useContext(AccountContext);
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;

  function handleError() {
    setLoading(false);
  }

  const data = { titular: user.titular, paciente_id: user.id_cliente_deovita, contrato_id: user.id_contrato };
    fetch(`${ENDPOINT}/client/getParcelasAbertas`, {
        signal: requestTimeout(timeOutInSeconds).signal,
        method: "POST",
        credentials: "include",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .catch((err) => {
          handleError();
          return;
        })
        .then((res) => {
          if (!res || !res.ok || res.status >= 400) {
            handleError();
            return;
          }
          return res.json();
        })
        .then((data) => {
          if (data && data > 0) { console.log(data)
            setUser((prevState) => ({ ...prevState, qntdeParcelas: data }));
          } else {
            handleError();
          }
        });
    navigate("/alerts/sucesso/pagamento_parcelas");
   
}

export default ProcessarPagamento;
