import React, { useContext, useState } from 'react';
import { Box, VStack, Button, Text, Img, Flex, Center, HStack, useDisclosure } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../../../images/Voltar.svg';
import AvisoAtendimento from './MedicoAgora/Modal/AvisoAtendimento';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function TesteConexao() {
  const navigate = useNavigate();
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('white');
  const [conexaoStatus, setConexaoStatus] = useState(null);
  const disclosureAviso = useDisclosure();
  const [error, setError] = useState(null);
  const [showText, setShowText] = useState('');
  const state = useLocation();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { user, setUser } = useContext(AccountContext);
  const [isDisabled, setDisabled] = useState(false);

  const getSignalBars = (status) => {
    switch (status) {
      case 'Sem Internet':
        return ['gray.200', 'gray.200', 'gray.200', 'gray.200', 'gray.200', 'gray.200'];
      case 'Conexão Fraca':
        return ['red.500', 'red.500', 'yellow', 'yellow', 'gray.200', 'gray.200'];
      case 'Boa conexão':
        return ['red.500', 'red.500', 'yellow', 'yellow', 'gray.200', 'gray.200'];
      case 'Excelente':
        return ['red.500', 'red.500', 'yellow', 'yellow', 'green.200', 'green.200'];
      default:
        return ['gray.200', 'gray.200', 'gray.200', 'gray.200', 'gray.200', 'gray.200'];
    }
  };

  const calculateSpeed = (startTime, endTime, imageSize) => {
    const timeDuration = (endTime - startTime) / 1000;
    const loadedBits = imageSize * 8;
    return (loadedBits / timeDuration / (1024 * 1024)).toFixed(2);
  };

  const fetchImageSize = async (url) => {
    const response = await fetch(url);
    return response.headers.get('content-length');
  };

  const runSpeedTest = async () => {
    setLoading(true);
    try {
      const imageApi =
        'https://images.unsplash.com/photo-1530281700549-e82e7bf110d6?q=80&w=1288&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
      const numTests = 5;
      let totalMbSpeed = 0;

      for (let i = 0; i < numTests; i++) {
        const startTime = new Date().getTime();
        const imageSize = await fetchImageSize(imageApi);

        await new Promise((resolve) => {
          const image = new Image();
          image.src = imageApi;
          image.onload = () => resolve();
        });

        const endTime = new Date().getTime();
        const speedInMbps = calculateSpeed(startTime, endTime, imageSize);
        totalMbSpeed += parseFloat(speedInMbps);
      }

      const averageSpeedInMbps = (totalMbSpeed / numTests).toFixed(2);
      setDownloadSpeed(averageSpeedInMbps);

      if (parseFloat(averageSpeedInMbps) < 0.1) {
        setConexaoStatus('Sem Internet');
      } else if (parseFloat(averageSpeedInMbps) < 5) {
        setConexaoStatus('Conexão Fraca');
      } else if (parseFloat(averageSpeedInMbps) < 25) {
        setConexaoStatus('Boa conexão');
      } else {
        setConexaoStatus('Excelente');
      }
    } catch {
      setConexaoStatus('Erro ao realizar o teste de velocidade');
      setColor('gray');
    } finally {
      setLoading(false);
    }
  };
  return (
    <VStack align="center" justify="center" direction="column" wrap="no-wrap" minH="100vh" spacing={4} background="#EB8B2A" position="relative">
      <Img top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />

      <Box
        as="card"
        width="auto"
        height="auto"
        borderRadius="18px"
        fontSize="xs"
        backgroundColor="white"
        maxInlineSize="80"
        textAlign="center"
        h={'70vh'}
        w={'55vh'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Flex align="center" mb={4}>
          {getSignalBars(conexaoStatus).map((color, index) => (
            <Box
              key={index}
              width="10px"
              height={index === 0 ? '50px' : index === 1 ? '60px' : index === 2 ? '70px' : index === 3 ? '80px' : index === 4 ? '90px' : '100px'}
              borderRadius="3px"
              backgroundColor={color}
              mx={1}
            />
          ))}
        </Flex>

        <VStack align="flex-start" borderRadius="0.8em" p={4} h="20em" w="20em" bg="white" boxShadow="md" spacing={4}>
          <Text fontSize="22px" fontWeight="bold">
            Status
          </Text>
          <Text fontSize="18px">{conexaoStatus ? conexaoStatus : 'Teste sua conexão'}</Text>
          <Text fontSize="22px" fontWeight="bold">
            Velocidade:
          </Text>
          <Text fontSize="18px">{downloadSpeed ? downloadSpeed : 0} Mbps</Text>
        </VStack>

        <Button
          colorScheme="orange"
          variant="solid"
          width="full"
          py={6}
          fontSize="lg"
          isLoading={loading}
          loadingText="Testando"
          onClick={() => runSpeedTest()}
          w={'90%'}
          mt={'2em'}
        >
          {loading ? 'Aguarde' : 'Testar conexão'}
        </Button>

        <>
          <Button
            colorScheme="green"
            variant="solid"
            width="full"
            py={6}
            fontSize="lg"
            onClick={() =>
              navigate('/medico-agora-atualiza-telefone', {
                state: {
                  ...state.state,
                },
              })
            }
            w={'90%'}
            mt={'2em'}
            isDisabled={parseFloat(downloadSpeed) > 5 ? false : true}
          >
            Continuar
          </Button>
        </>
      </Box>
    </VStack>
  );
}

export default TesteConexao;
