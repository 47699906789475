import React from 'react';
import {
  chakra,
  Box,
  Img,
  LinkBox,
  Text,
  VStack,
  Checkbox,
  Image,
  RadioGroup,
  Stack,
  Radio,
  Spinner,
  Heading,
  useDisclosure,
  Textarea,
  Center,
  Highlight,
  Container,
  Badge,
  Flex,
  HStack,
} from '@chakra-ui/react';
import BottomNavBar from 'components/BottomNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import Contact from '../../images/Vector.svg';
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';


function TermosTelemed() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const { atendimento } = state;
  return (
    <>
     
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="100vh"
        spacing={0}
        background="linear-gradient(180deg, #EB8B2A 0%, #EFA73F 28.65%, #5AB2AD 64.06%, #4E9E8F 100%);"
      >
        <Box as="card" width="328px" borderRadius="18px" fontSize="xs" size="10" backgroundColor="white" maxInlineSize="80" textAlign="center">
          <VStack m={{ sm: '10%' }} textColor="#585858">
            <Image top={{ lg: '5.5em', sm: '5em' }} src={Contact} alt="teste" height={'4.5em'} />

            <Text fontWeight="bold" fontSize="1.5em">
              Orientações da teleconsulta.
            </Text>

            <HStack backgroundColor="#E5E5E5" borderRadius="15px">
              <Text>
                <Flex ml="1em">
                  <Box backgroundColor="#EB8B2A" borderRadius="5px" margin="0.8em" color="rgba(62, 100, 255, 1)" padding="0 0.4em"></Box>
                  <Text margin="11% 0" fontSize="xs" align="center" color="#585858;">
                    <chakra.p fontWeight="bold">
                      Antes de iniciar a consulta, certifique-se de que sua <b>conexão</b> esteja estável;
                    </chakra.p>
                  </Text>
                </Flex>
                <Flex ml="1em">
                  <Box backgroundColor="#EB8B2A" borderRadius="5px" margin="0.8em" color="rgba(62, 100, 255, 1)" padding="0 0.4em"></Box>
                  <Text margin="11% 0" fontSize="xs" align="center" color="#585858;">
                    <chakra.p fontWeight="bold">
                      Confira também se seu <b>microfone</b> e <b>câmera</b> estão funcionando normalmente;
                    </chakra.p>
                  </Text>
                </Flex>
                <Flex ml="1em">
                  <Box backgroundColor="#EB8B2A" borderRadius="5px" margin="0.8em" color="rgba(62, 100, 255, 1)" padding="0 0.4em"></Box>
                  <Text margin="11% 0" fontSize="xs" align="center" color="#585858;">
                    <chakra.p fontWeight="bold">
                      Esteja em um <b>ambiente</b> silencioso de boa iluminação para ter melhor <b>experiência</b> em seu atendimento.
                    </chakra.p>
                  </Text>
                </Flex>
              </Text>
            </HStack>
            <VStack w="100%" >
              <BsFillArrowRightCircleFill size={'3em'} color="#EB8B2A" onClick={() => navigate('/termosemergencia',  { state: { atendimento: atendimento}})} />
            </VStack>
          </VStack>
        </Box>
      </VStack>

      <BottomNavBar />
    </>
  );
}

export default TermosTelemed;
