import React, { useState } from 'react';
import { VStack, Heading, Button, Text, InputGroup, InputRightElement, Progress } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '../../TextField';
import { ViewIcon, ViewOffIcon, WarningIcon } from '@chakra-ui/icons';
import NoUserHeader from '../../AreaLivre/NoUserHeader';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function RecuperaSenha() {
  const [show, setShow] = React.useState(false);
  const [error, setError] = React.useState(null);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const handleClick = () => setShow(!show);
  const [rule, setRule] = React.useState(/[0-9a-zA-Z!?$*&@#-]{8,}$/);
  const [visibility, setVisibility] = React.useState('');
  const navigate = useNavigate();
  const { state } = useLocation();
  const [progress, setProgress] = useState(0);
  const [barColor, setBarColor] = useState('red');
  const [strengthText, setStrengthText] = useState('');
  const [password, setPassword] = useState('');
  const passwordStrength = (string) => {
    let strength = 0;

    if (/[A-Z]/.test(string)) {
      strength += 20;
    }

    if (/[a-z]/.test(string)) {
      strength += 20;
    }

    if (/[0-9]/.test(string)) {
      strength += 20;
    }

    if (/[!?$*&@#-]/.test(string)) {
      strength += 20;
    }

    if (/[0-9a-zA-Z!?$*&@#-]{8,}$/.test(string)) {
      strength += 20;
    }
    setProgress(strength);
    if (strength === 100) {
      setBarColor('green');
      setStrengthText('Senha Forte');
    }
    if (strength <= 20 && strength >= 0) {
      setBarColor('red');
      setStrengthText('Senha Fraca');
    }
    if (strength <= 40 && strength > 20) {
      setBarColor('orange');
      setStrengthText('Senha Média');
    }
    if (strength < 100 && strength > 40) {
      setBarColor('blue');
      setStrengthText('Senha Razoavel');
    }
  };

  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={{ senha: '', senha2: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          senha: Yup.string().trim().required('Senha Obrigatória!').matches(rule, 'Senha fora de padrão'),
          senha2: Yup.string()
            .required('Confirmação da senha é obrigatória')
            .test('pass-match', 'Senhas precisam ser iguais', function (value) {
              return this.parent.senha === value;
            }),
        })}
        onSubmit={(values, actions) => {
          const vals = {
            password: values.senha,
            username: onlyNumbers(state.cpf),
          };
          fetch(`${ENDPOINT}/auth/update_password`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(vals),
          })
            .catch((err) => {
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                setError('Erro na Rede');
                return res.json();
              }
              if (res.status === 200) {
                setError('');
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              if (data.success === false) {
                setError('Falha na atualização da senha');
                return;
              }
              if (data.success === true) {
                setError('');
                navigate('/alerts/sucesso/recuperacao', { state: { nome: state.nome} });
              }
            });
        }}
      >
        {({ values, errors }) => (
          <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" marginBottom="5%" spacing="1rem" align="start">
            <Heading fontSize="1em">Escolha uma nova senha de no mínimo 8 caracteres.</Heading>
            <InputGroup size="md">
              <TextField
                pr="4.5rem"
                name="senha"
                id="senha"
                placeholder="Senha"
                type={show ? 'text' : 'password'}
                onKeyUp={(e) => {
                  e.preventDefault();
                  const filteredValue = e.target.value.replace(/[^0-9a-zA-Z!?$*&@#-]/g, '');
                  e.target.value = filteredValue;
                  values.senha = filteredValue;
                  passwordStrength(filteredValue);
                }}
              />
              <InputRightElement width="4rem" pt="1rem" size="md">
                <Button h="1.75rem" size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />}></Button>
              </InputRightElement>
            </InputGroup>
            <InputGroup size="md" id="senha2" pb="15%">
              <TextField pr="4.5rem" name="senha2" placeholder="Repita a senha" type={show ? 'text' : 'password'} />
              <InputRightElement width="4rem" pt="1rem" size="md">
                <Button h="1.75rem" size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />}></Button>
              </InputRightElement>
            </InputGroup>
            <Progress w="100%" value={progress} colorScheme={barColor} borderRadius="15px" />
            <Text color={barColor}>{strengthText ? strengthText + '!' : ''}</Text>
            <Button colorScheme="teal" w="100%" type="submit">
              Avançar
            </Button>
          </VStack>
        )}
      </Formik>
    </>
  );
}

export default RecuperaSenha;
