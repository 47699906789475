import {  Heading, HStack, Switch, VStack, chakra, ButtonGroup, Button, Text } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import TextField from '../TextField';
import SelectField from 'components/SelectField';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const Endereco = () => {
  const initialValues = {
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    localidadeIbge: '',
    cidade: '',
    estado: '',
    bairro: '',
    switchCep: false,
  };
  const validationSchema = Yup.object({
    cep: Yup.string().required('Cep é obrigatório').min(10, 'Cep inválido'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    numero: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Somente números e letras são permitidos').required('Campo obrigatório'),
    localidadeIbge: Yup.string().required('Cidade é brigatória'),
    estado: Yup.string().required('Estado é obrigatório'),
    bairro: Yup.string().required('Bairro é obrigatório'),
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setPage, user } = useContext(AccountContext);
  setPage('Cadastro');
  const [localidades, setLocalidades] = useState({ municipios: [], estados: [] });
  const [municipiosFiltradoPorEstado, setMunicipiosFiltradoPorEstado] = useState([]);
  const [error, setError] = useState(null);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const [desabilitaCampo, setDesabilitaCampo] = useState(true);
  const [desabilitaCadastro, setDesabilitaCadastro] = useState(false);


  function getCities() {
    fetch(`${ENDPOINT}/cities`, {
      method: 'GET',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .catch((error) => {})
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }
        return res.json();
      })
      .then((data) => {
        let estados = [];
        data.map((cidade) => (!estados.includes(cidade.estado) ? estados.push(cidade.estado) : ''));
        setLocalidades({ municipios: data, estados: estados });
      });
  }

  useEffect(() => getCities(), []);

  function handleSubmit(values) {
    const vals = {
      ...state,
      code_ibge: values.localidadeIbge,
      cep: onlyNumbers(values.cep),
      endereco: values.endereco,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      cidade: values.switchCep ? municipiosFiltradoPorEstado[values.campoCidade].nome : values.cidade,
      estado: values.estado,
    };
    navigate('/cadastro/confirmacao', { state: vals });
  }

  const AddressForm = (formProps) => {
    const { setFieldValue, values } = formProps;

    function BuscaCep(cep) {
      cep = onlyNumbers(cep);
      async function buscaCep() {
        try {
          const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_VIACEP}/${cep}/json/`);
          resposta.json().then((res) => {
           
            if (res.erro === 'true' || res.erro === true) {
              setError(`O CEP que você digitou não foi encontrado. Favor, preencha um CEP válido ou clique em 'Não sei o cep / Digitar dados'`);
              setDesabilitaCadastro(true)
              return;
            }
            if (res.logradouro === '' || res.logradouro === null) {
              setError(null);
              setFieldValue('endereco', res.logradouro);
              setFieldValue('bairro', res.bairro);
              setFieldValue('localidadeIbge', res.ibge);
              setFieldValue('cidade', res.localidade);
              setFieldValue('estado', res.uf);
              setDesabilitaCadastro(false)
              setDesabilitaCampo(false) //aqui eu libero o campo pra editar caso seja um cep 'universal'
              return
            }

            setDesabilitaCampo(true)
            setDesabilitaCadastro(false)
            setError(null);
            setFieldValue('endereco', res.logradouro);
            setFieldValue('bairro', res.bairro);
            setFieldValue('localidadeIbge', res.ibge);
            setFieldValue('cidade', res.localidade);
            setFieldValue('estado', res.uf);
          });
        } catch (error) {
          setError('Não foi possivel consultar seu cep, verifique o cep ou preencha as informações manualmente');
        }
      }
      buscaCep();
    }

    const campoCep = () => {
      return values.switchCep ? (
        ''
      ) : (
        <TextField
          name="cep"
          placeholder="CEP"
          mask="99.999-999"
          type="tel"
          maskChar={null}
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
            if (e.currentTarget.value.replace('_', '').length >= 10) BuscaCep(e.currentTarget.value);
          }}
        />
      );
    };

    const switchCep = () => {
      return (
        <Switch
          name="switchCep"
          color="gray"
          size="sm"
          fontSize="sm"
          colorScheme="teal"
          isChecked={values.switchCep}
          onChange={(e) => {
            function setEnd() {
              setFieldValue('cep', '00.000-000');
              setFieldValue('endereco', '');
              setFieldValue('bairro', '');
              setFieldValue('localidadeIbge', '');
              setFieldValue('cidade', '');
              setFieldValue('estado', '');
            }
            setDesabilitaCampo(false)
            setError(null)
            setDesabilitaCadastro(false)
            setFieldValue('switchCep', e.target.checked);
            e.target.checked ? setEnd() : setFieldValue('cep', '');
          }}
        >
          Não sei o cep / Digitar dados
        </Switch>
      );
    };

    const campoEstado = () => {
      return values.switchCep ? (
        <SelectField
          name="campoEstado"
          placeholder="Estado"
          w="100%"
          variant="outline"
          textAlign="left"
          fontWeight="normal"
          onChange={(e) => {
            setFieldValue('estado', localidades.estados[e]);
            setMunicipiosFiltradoPorEstado(localidades.municipios
              .filter((cidade) => cidade.estado === localidades.estados[e])
              .sort((a, b) => a.nome.localeCompare(b.nome))
            );
          }}
          options={localidades.estados}
        />
      ) : (
        <TextField name="cidade" placeholder="Cidade" isDisabled={!values.switchCep} />
      );
    };

    const campoCidade = () => {
      let arrayNomeMunicipios = municipiosFiltradoPorEstado.map((municipio) =>  municipio.nome);
      return values.switchCep ? (
        <SelectField
          name="campoCidade"
          placeholder="Cidade"
          w="100%"
          variant="outline"
          textAlign="left"
          fontWeight="normal"
          onChange={(e) => {
            let codIbge = municipiosFiltradoPorEstado[parseInt(e)].codigo_ibge;
            setFieldValue('localidadeIbge', codIbge);
          }}
          isDisabled={values.estado === ''}
          options={arrayNomeMunicipios}
        />
      ) : (
        <TextField name="estado" placeholder="Estado" isDisabled={!values.switchCep} />
      );
    };

    return (
      <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" spacing={3} align="start" paddingBottom="30%">
        <Heading size="sm">Complete seus dados de endereço. Já estamos quase terminando</Heading>
        <Text as="p" color="red.500">
          {error}
        </Text>
        {campoCep()}
        {switchCep()}
        <TextField name="endereco" placeholder="Endereço" isDisabled={desabilitaCampo} />
        <HStack spacing="0.3rem" justify="top" align="start">
          <TextField name="numero" placeholder="Número" w="35vw" />
          <TextField name="complemento" placeholder="Complemento (opcional)" w="54.5vw" />
        </HStack>
        <HStack justify="top" align="start" w="100%">
          {campoEstado()}
          {campoCidade()}
        </HStack>
        <TextField name="bairro" placeholder="Bairro" isDisabled={desabilitaCampo} />
        <ButtonGroup pt="1rem" pb="25%" sx={{ width: '100%' }}>
          <Button name="btn" backgroundColor={user.id_contrato ? '#EB8B2A': '#529C94'} color='#FFF' type="submit" w={{ base: '100%' }} isDisabled={desabilitaCadastro}>
            Avançar
          </Button>
        </ButtonGroup>
      </VStack>
    );
  };

  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {AddressForm}
      </Formik>
    </>
  );
};

export default Endereco;
