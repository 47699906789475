import {
  Image,
  Box,
  useDisclosure,
  useToast,
  AlertDescription,
  CloseButton,
  AlertTitle,
  AlertIcon,
  HStack,
  Alert,
  Heading,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';
import ProntoAtendimento from '../../../images/1.webp';

//Imagens
import Telemedicia from '../../../images/1.webp';
import Especialista from '../../../images/3.webp';
import Pediatra from '../../../images/2.webp';
import Telepet from '../../../images/4.webp';
import Psicologo from '../../../images/BannerPsicologo.webp';

import '../css/carouselBanner.css';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import AvisoTelefone from 'components/Dashboard/Main/DashboardAlerts/AvisoTelefone';
import AvisoDependente from 'components/Dashboard/Main/DashboardAlerts/AvisoDependente';
import ModalDivida from 'components/Dashboard/Main/Telemedicina/FluxoLivre/ModalDivida';
import DrawerAssinatura from 'components/DrawerAssinatura';
import LoadingGenerico from '../../AreaLivre/DashboardLivre/LoadingGenerico';
import Geovanna from '../../../images/geovanna.webp';
import Geovanna2 from '../../../images/geovanna2.webp';
import DemonstrativoTelmed from './DemonstrativoTelmed';
import DrawerCompraPlano from './DrawerCompraPlano';

//Banners Verdes
import GeovannaVerde from '../../../images/Deslogado/Banners/GeovannaVerde.webp';
import TelemedVerde from '../../../images/Deslogado/Banners/ProntoVerde.webp';
import TelepetVerde from '../../../images/Deslogado/Banners/PetVerde.webp';
import EspecialistaVerde from '../../../images/Deslogado/Banners/especialistaVerde.webp';
import PsicologoVerde from '../../../images/Deslogado/Banners/PsicologoVerde.webp';
import PediatraVerde from '../../../images/Deslogado/Banners/PediatraVerde.webp';

import DrawerConsulta from './Telemedicina/FluxoLivre/DrawerConsulta';
function BannerCarrosel() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user, toastInadimplente, setUser, updateData, loadedMedicina } = useContext(AccountContext);
  const { id_contrato } = user;
  const [tipo, setTipo] = useState([]);
  const disclosureTelefone = useDisclosure();
  const compraDisclosure = useDisclosure();
  const disclosure = useDisclosure();
  const modalDividaDisclousure = useDisclosure();
  const toast = useToast();
  const [medico, setMedico] = useState(false);
  const disclosureDemonstrativo = useDisclosure();
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

  useEffect(() => {
    if (user.loggedIn === true && (!user.phone1 || user.phone1 === undefined || user.phone1 === null || user.phone1 === '')) {
      disclosureTelefone.onOpen();
    }
  }, [user]);

  useEffect(() => {
    console.log(loadedMedicina);
  }, [loadedMedicina]);

  const fetchRdy = async () => {
    try {
      const response = await fetch(ENDPOINT + `/pet/medico_online`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      setMedico(postsData.medico_online);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  fetchRdy();

  const toastWrongVersion = () => {
    toast({
      position: 'bottom',

      duration: 30000,
      isClosable: true,
      render: () => (
        <Alert
          status="warning"
          variant="solid"
          flexDirection="column"
          onClick={() => {
            console.log('click');
          }}
          rounded="md"
        >
          <HStack w="100%">
            <AlertIcon />
            <AlertTitle w="80%" align="center">
              Atualização Necessária
            </AlertTitle>
            <CloseButton alignSelf="flex-end" position="relative" right={-1} top={-1} onClick={() => toast.closeAll()} />
          </HStack>
          <AlertDescription
            maxWidth="sm"
            textAlign="justify"
            onClick={() => {
              toast.closeAll();
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  service: true,
                  openInBrowser: true,
                  url: `https://onelink.to/deovit`,
                })
              );
            }}
          >
            Você está utilizando uma versão desatualizada do aplicativo DeoVita. Para utilizar a Telemedicina é necessário atualizar para a última
            versão disponível!
          </AlertDescription>
        </Alert>
      ),
      containerStyle: {
        paddingBottom: '100px',
      },
    });
  };

  const [focusedIndex, setFocusedIndex] = useState();
  const handleFocusChange = (index) => {
    setFocusedIndex(index);
  };

  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');

  const drawerHeight = isLargeHeight ? '50vh' : '40vh';

  const StyledImage = styled.img`
    border-radius: 20px;
    width: ${(props) => (props.isFocused ? '500px' : '100%')};
    max-width: ${isLargeHeight ? '42vh' : '35vh'};
    transition: width 0.5s ease;
  `;
  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <>
      <AvisoTelefone disclosure={disclosureTelefone} />
      <AvisoDependente />
      <DemonstrativoTelmed disclosure={disclosureDemonstrativo} />
      <ModalDivida disclosure={modalDividaDisclousure} />
      <DrawerAssinatura disclosure={disclosure} />
      <DrawerCompraPlano disclosure={compraDisclosure} />
      <LoadingGenerico />

      <Box w="100vw" mb={'2em'}>
        <Box w={drawerHeight} ml={'5%'} mt={'2em'}>
          <Text color={'#585858'} fontSize={'18px'} fontWeight={'bold'} mb={'1em'}>
            Telemedicina grátis para assinantes
          </Text>
        </Box>
        <Carousel itemsToShow={1.2} showArrows={false} focusOnSelect={true} pagination={false} itemPadding={[0, 10]}>
          <Image
            src={usuarioAssinante ? Geovanna : GeovannaVerde}
            borderRadius={20}
            w="19em"
            onClick={async () => {
              if ((user.loggedIn === true && user.id_contrato > 0) || (user.loggedIn === true && user.titular === false)) {
                if (user.build) {
                  const resposta = await updateData(user);
                  if (!resposta.success) {
                    return;
                  }
                  if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
                    toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                  } else {
                    //navigate('/aviso-pre-atendimento/');

                    if (user.telemedicina === true) {
                      navigate('/aviso-pre-atendimento/', { state: { titulo: 'Pronto Atendimento', tela: '/medico-agora/', pediatra: false } });
                      //navigate('/medico-agora/');
                    } else {
                      navigate('/medico-agora-rapidoc/');
                    }
                  }
                } else {
                  toastWrongVersion();
                  return;
                }
              } else {
                disclosureDemonstrativo.onOpen();
              }
            }}
          />
          <Image
            src={usuarioAssinante ? Telemedicia : TelemedVerde}
            borderRadius={20}
            w="19em"
            onClick={async () => {
              try {
                if (user.contrato_pendente_pagamento === true) {
                  toast({
                    title: 'Assinatura pendente',
                    description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                    position: 'bottom',
                    colorScheme: 'green',
                    containerStyle: {
                      paddingBottom: '5em',
                    },
                  });
                  return;
                } else {
                  if ((user.loggedIn === true && user.id_contrato > 0) || (user.loggedIn === true && user.titular === false)) {
                    if (user.build) {
                      const resposta = await updateData(user);
                      if (!resposta.success) {
                        return;
                      }
                      if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
                        toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                      } else {
                        //navigate('/aviso-pre-atendimento/');

                        if (user.telemedicina === true) {
                          navigate('/aviso-pre-atendimento/', { state: { titulo: 'Pronto Atendimento', tela: '/medico-agora/', pediatra: false } });
                          //navigate('/medico-agora/');
                        } else {
                          navigate('/medico-agora-rapidoc/');
                        }
                      }
                    } else {
                      toastWrongVersion();
                      return;
                    }
                  } else {
                    disclosure.onOpen();
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }}
          />
          <Image
            src={usuarioAssinante ? Pediatra : PediatraVerde}
            borderBottomRightRadius={20}
            w="19em"
            onClick={async () => {
              try {
                if (user.contrato_pendente_pagamento === true) {
                  toast({
                    title: 'Assinatura pendente',
                    description: 'O pagamento da sua assinatura está em análise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                    position: 'bottom',
                    colorScheme: 'green',
                    containerStyle: {
                      paddingBottom: '5em',
                    },
                  });
                  return;
                } else {
                  if ((user.loggedIn === true && user.id_contrato > 0) || (user.loggedIn === true && user.titular === false)) {
                    if (user.build) {
                      const resposta = await updateData(user);
                      if (!resposta.success) {
                        return;
                      }
                      if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
                        toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                      } else {
                        if (user.telemedicina === true) {
                          //navigate('/medico-agora/', { state: { pediatra: true } });
                          navigate('/aviso-pre-atendimento/', {
                            state: {
                              titulo: 'Pronto Atendimento Pediátrico',
                              tela: '/medico-agora/',
                              pediatra: true,
                            },
                          });
                        } else {
                          navigate('/medico-agora-rapidoc/', {
                            state: { pediatra: true },
                          });
                        }
                      }
                    } else {
                      toastWrongVersion();
                      return;
                    }
                  } else {
                    setTipo('Clínico Geral');
                    disclosure.onOpen();
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }}
          />

          <Image
            src={usuarioAssinante ? Especialista : EspecialistaVerde}
            borderRadius={20}
            w="19em"
            onClick={async () => {
              try {
                if (user.build) {
                  if (user.loggedIn === true) {
                    const resposta = await updateData(user);
                    if (!resposta.success) {
                      return;
                    }
                    if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
                      toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                    } else {
                      if (user.telemedicina === true) {
                        navigate('/medico-agora/', {
                          state: { especialista: true },
                        });
                      } else {
                        navigate('/medico-agora-rapidoc/', {
                          state: { especialista: true },
                        });
                      }
                    }
                  } else {
                    compraDisclosure.onOpen();
                  }
                } else {
                  toastWrongVersion();
                  return;
                }
              } catch (error) {
                console.log(error);
              }
            }}
          />

          <Image
            src={usuarioAssinante ? Psicologo : PsicologoVerde}
            borderRadius={20}
            w="19em"
            onClick={async () => {
              try {
                if (user.build) {
                  if (user.loggedIn === true) {
                    const resposta = await updateData(user);
                    if (!resposta.success) {
                      return;
                    }
                    if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
                      toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                    } else {
                      navigate('/medico-agora', {
                        state: {
                          especialista: true,
                          typeList: 'Especialista',
                          especialidade: 54,
                        },
                      });
                    }
                  } else {
                    compraDisclosure.onOpen();
                  }
                } else {
                  toastWrongVersion();
                  return;
                }
              } catch (error) {
                console.log(error);
              }
            }}
          />

          <Image
            src={usuarioAssinante ? Telepet : TelepetVerde}
            borderRadius={20}
            w="19em"
            onClick={async () => {
              try {
                if (user.build) {
                  if (user.telepet === false || !user.telepet) {
                    toast({
                      title: 'Importante',
                      description: 'Serviço indisponível no momento.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                    return;
                  }

                  if (user.contrato_pendente_pagamento === true) {
                    toast({
                      title: 'Assinatura pendente',
                      description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                    return;
                  } else {
                    if ((user.titular === true && user.id_contrato > 0) || (user.titular === false && user.id_contrato === 0)) {
                      const resposta = await updateData(user);
                      if (!resposta.success) {
                        return;
                      }
                      if (medico === true) {
                        if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0) {
                          toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                        } else {
                          navigate('/aviso-pre-atendimento/', { state: { titulo: 'Telemedicina Pet', tela: '/telepet/termos/', pediatra: false } });
                        }
                      } else {
                        toast({
                          title: 'Indisponível',
                          description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                          status: 'warning',
                          duration: 4000,
                          isClosable: true,
                          position: 'bottom',
                          colorScheme: 'green',
                          containerStyle: {
                            paddingBottom: '5em',
                          },
                        });
                      }
                    } else {
                      disclosure.onOpen();
                    }
                  }
                } else {
                  toastWrongVersion();
                  return;
                }
              } catch (error) {
                console.error(error);
              }
            }}
          />
        </Carousel>
      </Box>
    </>
  );
}

export default BannerCarrosel;
