import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Button,
  Center,
  useDisclosure,
  ModalCloseButton,
  List,
  ListItem,
  Image,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import especialistas from '../../../../images/doctor-badge.svg';
import ampulheta from '../../../../images/hourglass.svg';
import info from '../../../../images/info.svg';

export default function AvisoAtendimento(props) {
  const disclosure = props.disclosure;
  const { webviewParams } = useContext(AccountContext);
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

  const [loading, setLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);

  async function handleCheckResult(result) {
    setLoading(false);
    if (result.canceled) {
      setCanceled(true);
    } else {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          service: true,
          openInBrowser: false,
          //url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`,
          url: `https://atendimento.deovita.com.br:5000/atendimento?p=${webviewParams?.paciente}&m=${webviewParams?.medico}&a=${webviewParams?.agenda_exames_id}&u=paciente`,
        })
      );
      disclosure.onClose();
    }
  }

  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered size="full">
        <ModalOverlay />
        <ModalContent backgroundColor={'#EB8B2A'} p={8}>
          <ModalBody
            backgroundColor={'#EEEEEE'}
            borderRadius={20}
            position={'relative'}
            display={'flex'}
            flexDir={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <VStack gap={4} mt={20}>
              <Image src={especialistas} h="5em" w="5em" />

              <Text fontWeight={'bold'} fontSize={24} textAlign={'center'} color={'#EB8B2A'}>
                {canceled ? 'Seu tempo acabou!' : 'Prontinho!'}
              </Text>

              <Text fontWeight={'bold'} fontSize={18} textAlign={'center'} m={4} color={'#EB8B2A'}>
                {canceled ? 'Solicite um novo atendimento' : 'O Profissional de saúde está esperando por você'}
              </Text>
            </VStack>

            {!canceled && (
              <VStack gap={4}>
                <Image src={ampulheta} h="3em" w="3em" />

                <Button
                  bg={'#EB8B2A'}
                  textColor={'#FFF'}
                  paddingX={10}
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    if (canceled) {
                      disclosure.onClose();
                    } else {
                      fetch(`${ENDPOINT}/schedule/checkCanceledSchedule`, {
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        body: JSON.stringify({ agendaExamesId: webviewParams?.agenda_exames_id }),
                      }).then(async (data) => {
                        const result = await data.json();
                        handleCheckResult(result);
                      });
                    }
                  }}
                >
                  Entrar agora!
                </Button>
              </VStack>
            )}

            <VStack mb={4} width={'100%'}>
              {!canceled && (
                <VStack alignItems={'flex-start'} width={'100%'} backgroundColor={'#D60000'} color={'white'} p={4} borderRadius={8}>
                  <Image src={info} />

                  <Text>Você tem 1 minuto para entrar no atendimento</Text>
                  <Text fontSize={12}>Caso não entre neste tempo, seu lugar na fila será passado para outro paciente em espera.</Text>
                </VStack>
              )}
              <Button
                isFullWidth
                disabled={loading}
                border={'1px solid #7F7F7F'}
                color={'#7F7F7F'}
                fontWeight={'hairline'}
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                {canceled ? 'Continuar' : 'Desistir do atendimento'}
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
