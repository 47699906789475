import React from 'react'
import { CheckIcon } from "@chakra-ui/icons";
import { Heading, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, VStack, Button, Box, Center, HStack, Flex, ModalFooter } from "@chakra-ui/react";
import AvisoPendencias from '../../../../../../images/AvisoPendencias.svg'
import { AiFillAlert, AiFillMedicineBox } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import { IoWarning } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';



function AvisoAtendimento(props) {
    const disclousure = props.disclousure
    const navigate = useNavigate()
    const closeModal = () => {
        props.onModalClose();
        disclousure.onClose()
    };

    return (
        <>
            <Modal isOpen={disclousure.isOpen} onClose={disclousure.onClose} size='xs' isCentered>
                <ModalOverlay />
                <ModalContent borderRadius='15px' bg="white" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)">
                    <ModalHeader mt='1' borderBottomWidth="1px">
                        <Flex justifyContent="center" alignItems="center">
                            <IoWarning size="4em" color={'red'} alt="" />
                        </Flex>
                    </ModalHeader>
                    <ModalBody mb='3'>
                        <VStack spacing={3} align='left' >
                            <Text fontSize="lg" fontWeight="bold" textAlign="center">
                                Atenção!
                            </Text>
                            <Text fontSize={'16px'} textAlign="justify" >
                                Para assegurar sua posição na fila, permaneça nessa tela.
                            </Text>
                            <Text fontSize={'16px'} textAlign="justify" >
                                Não minimize o aplicativo!
                            </Text>
                            <Text fontSize={'16px'} textAlign="justify" >
                                Em caso de falha na conexão, clique no botão Reconectar e continue aguardando.
                            </Text>
                        </VStack>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        <Button bg="#529C94" color={'white'} onClick={() =>  closeModal()}>Continuar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


        </>
    )
}

export default AvisoAtendimento
