import React from "react";
import {
  VStack,
  Button,
  Heading,
  Text,
  List,
  ListIcon,
  Divider,
  ListItem,
  Center,
} from "@chakra-ui/react";

import { CheckIcon } from "@chakra-ui/icons";
import NoUserHeader from "../NoUserHeader";
import { useNavigate } from "react-router-dom";

function Orientações() {
  const navigate = useNavigate();
  return (
    <>
      <useContext>
        <NoUserHeader />
        <VStack
          w={{ base: "90%" }}
          m="auto"
          h="100%"
          spacing="0.8rem"
          marginTop="2"
        >
          <Heading mt="5" fontSize="xl">
            Acompanhe o seu agendamento através das seguintes opções:
          </Heading>
          <List mt="5" spacing={3}>
            <Heading mt="5" fontSize="lg">
              Whatsapp
            </Heading>
            <ListItem noOfLines={3}>
              <Text textAlign="start" paddingStart={3}>
                {" "}
                <ListIcon as={CheckIcon} wordBreak={5} color="green.500" />
                Fale conosco Através do Número (85) 98800-0001
              </Text>
            </ListItem>
            <Divider />
            <ListItem>
              <Text textAlign="start" paddingStart={3}>
                <ListIcon as={CheckIcon} color="green.500" />
                Atendimento das 08:00 as 17:00hrs.
              </Text>
            </ListItem>
            <Divider />
            <ListItem>
              <Text textAlign="start" paddingStart={3}>
                <ListIcon as={CheckIcon} color="green.500" />
                Obtenha todas as informações do seu agendamento na palma de sua
                mão.
              </Text>
            </ListItem>

            <Divider />
          </List>

          <List mt="5" spacing={3}>
            <Heading mt="4" fontSize="lg">
              Email
            </Heading>
            <ListItem>
              <Text textAlign="start" paddingStart={3}>
                <ListIcon as={CheckIcon} color="green.500" />
                Fale conosco através do email deovita.atendimento@gmail.com
              </Text>
            </ListItem>

            <Divider />
            <ListItem>
              <Text textAlign="start" paddingStart={3}>
                <ListIcon as={CheckIcon} color="green.500" />
                Atendimento das 08:00 ás 17:00hrs.
              </Text>
            </ListItem>
            <Divider />
            <ListItem>
              <Text textAlign="start" paddingStart={3}>
                <ListIcon as={CheckIcon} color="green.500" />
                Nos Envie um email com o seu CPF e nome completo, para ter
                acesso as informações de procedimentos e consultas agendadas.
              </Text>
            </ListItem>
          </List>
          <Center>
            <Button
              size="md"
              mt="8"
              height="52px"
              width="350px"
              borderRadius={10}
              colorScheme="teal"
              onClick={() => navigate("/mainlivre")}
            >
              Fechar
            </Button>
          </Center>
        </VStack>
      </useContext>
    </>
  );
}

export default Orientações;
