import React from 'react';
import { Center, Text, Stack, DrawerFooter, DrawerBody, DrawerHeader } from '@chakra-ui/react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';

function Pdf(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  return (
    <>
      <DrawerBody paddingTop={9}>
        {' '}
        <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
          <Center>
            <Page pageNumber={pageNumber} width="400" />
          </Center>
        </Document>{' '}
      </DrawerBody>
      <Center>
        {' '}
        <DrawerFooter>
          {' '}
          <Stack fontWeight="bold" direction="row">
            <Button colorScheme="teal" onClick={goToPrevPage}>
              Anterior
            </Button>
            <Button colorScheme="teal" onClick={goToNextPage}>
              Próximo
            </Button>
            <Center>
              {' '}
              <Text>
                {' '}
                Pagina {pageNumber} de {numPages}
              </Text>
            </Center>
          </Stack>
        </DrawerFooter>
      </Center>
    </>
  );
}

export default Pdf;
