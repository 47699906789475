import { HStack, VStack, Heading, Text, Badge, List, ListItem, Image, Box, chakra, Flex, useDisclosure, Img, Stack } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import Loading from 'components/Loading';
import { MdOutlineLocationOn } from 'react-icons/md';
import _ from 'lodash';
import DrawerProcedimento from './DrawerProcedimentos';
import SadFace from '../../../../images/assets/icons/sad_face.svg';
import DrawerCompraPlano from '../DrawerCompraPlano';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function EscolheEmpresa() {
  const navigate = useNavigate();
  const { user, setUser, setPage, toastInadimplente } = useContext(AccountContext);
  const drawerProcedure = useDisclosure();
  const { state } = useLocation();
  const [unidades, setUnidades] = useState([]);
  const [error, setError] = useState(null);
  const numberOfProcedures = user.cart ? user.cart.itens.length : 0;
  const [todos, setTodos] = useState(false);
  const [parciais, setParciais] = useState(false);
  const [pick, setPick] = useState();
  const drawerCompraPlano = useDisclosure();
  const [orcamento, setOrcamento] = useState(false);

  useEffect(() => {
    if (!user.cart) {
      navigate('/dashboard');
    } else {
      const fetchPosts = async () => {
        const url = ENDPOINT + '/schedule/getCompaniesByCart/';
        const payload = {
          pacienteId: user.id_cliente_deovita,
          cart: user.cart,
          lat: user.coords && user.coords.latitudeAtual ? user.coords.latitudeAtual : null,
          lng: user.coords && user.coords.longitudeAtual ? user.coords.longitudeAtual : null,
          city: user.cidade,
        };
        const response = await fetch(url, {
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        setUnidades(postsData);
        if (postsData.length === 0) setError('Não há empresas com horarios disponíveis que realizem os procedimentos selecionados.');
      };
      fetchPosts();
    }
    toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
  }, [user.cart]);

  const unidadesAgg = _.groupBy(unidades, 'empresa_id');

  const distanciaArray = [];
  for (const unidadeId in unidadesAgg) {
    const distancia = unidadesAgg[unidadeId][0].distancia;
    distanciaArray.push({ id: unidadeId, distancia: distancia });
  }

  // Sort the new object by distancia values
  const sortedDistanciaArray = distanciaArray.sort((a, b) => a.distancia - b.distancia);

  const clearCart = () => {
    const keysToKeep = [
      'convenio_id',
      'descricao',
      'dia_previsao',
      'grupo',
      'grupo_id',
      'materiais',
      'medico',
      'nome',
      'posicao',
      'procedimento_tuss_id',
      'subgrupo_id',
      'subgrupo_nome',
      'tuss_id',
    ];
    const filterKeys = (item) => {
      const filteredItem = {};
      keysToKeep.forEach((key) => {
        if (item[key] !== undefined) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem;
    };

    const updateCart = () => {
      const updatedCart = { ...user.cart, itens: user.cart.itens.map(filterKeys) };
      setUser({ ...user, cart: updatedCart });
    };
    updateCart();
  };
  const useUnidades = (sortedDistanciaArray ? sortedDistanciaArray : distanciaArray).map((unidade, index) => {
    let total_deovita = 0;
    let total_particular = 0;
    unidadesAgg[unidade.id].forEach((procedure) => {
      total_deovita += parseFloat(procedure.valor_deovita);
      total_particular += parseFloat(procedure.valor_particular);
    });

    const empresa = unidadesAgg[unidade.id][0];

    let distancia =
      user.coords && empresa.latitude && empresa.longitude ? (' ' + empresa && empresa.distancia ? empresa.distancia.toFixed(2) + ' km' : '') : '';

    if (unidadesAgg[unidade.id].length === numberOfProcedures && todos === false) {
      setTodos(true);
    }

    return unidadesAgg[unidade.id].length === numberOfProcedures ? (
      <ListItem
        fontWeight="bold"
        bgColor="rgba(90, 178, 173, 0.3)"
        borderRadius="20px"
        shadow="md"
        mt="0.8em"
        width="100%"
        backgroundColor="gray.100"
        minH="3em"
        pl="5em"
        m="2%"
        onClick={() => {
          if (user.loggedIn === true) {
            if (state.orcamento_id) {
              setOrcamento(state.orcamento_id);
            }
            clearCart();
            setPick(unidadesAgg[unidade.id]);
            drawerProcedure.onOpen();
          } else {
            drawerCompraPlano.onOpen();
          }
        }}
      >
        <Flex w="100%" minH="40%" maxH="50%">
          <Image
            src={`https://backoffice.deovita.com.br/deovita//upload/fachada/` + empresa.empresa_id + '/fachada.png'}
            fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
            backgroundColor="white"
            border="1px"
            h="5em"
            w="5em"
            ml="-6em"
            mr="5%"
            mt="0.5em"
            mb="0.5em"
            borderRadius="1.5em"
            name={empresa.NAME}
          />

          <VStack>
            <VStack align="right" h="100%" spacing={0} minW="50%" maxH="100%">
              <Text fontSize="xs" mt="2" textColor="gray" textAlign="start" maxW="80" lineheight="24.38px" noOfLines={2}>
                {empresa.empresa}
              </Text>

              <Flex>
                <Box>
                  {' '}
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" noOfLines={1}>
                    {empresa.endereco}, {empresa.numero},
                  </Text>
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" isTruncated>
                    - {empresa.municipio} / {empresa.estado}
                  </Text>
                </Box>
              </Flex>
            </VStack>
            <Flex alignSelf="start">
              <Badge backgroundColor="orange" color="white" pr="10%" borderLeftRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Particular</chakra.p>
                  <chakra.p fontSize="0.8em">{total_particular.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
              <Badge backgroundColor="green" color="white" ml="-1" pr="10%" borderRightRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Assinante</chakra.p>
                  <chakra.p fontSize="0.8em">{total_deovita.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
            </Flex>
            <HStack w="100%">
              <Text fontSize="9px" color="#808080">
                Realiza:{' '}
                <chakra.span color="#50A193" textDecoration="underline">
                  {' '}
                  {unidadesAgg[unidade.id].length} de {numberOfProcedures} | VER
                </chakra.span>
              </Text>
              <Flex>
                <MdOutlineLocationOn class="googlemap" color={user.coords && user.coords.longitudeAtual ? 'green' : '#E2E8F0'} />
                <chakra.span color="orange" fontWeight={5} fontSize="9px">
                  {distancia}
                </chakra.span>
              </Flex>{' '}
            </HStack>
          </VStack>
        </Flex>
      </ListItem>
    ) : (
      <></>
    );
  });

  const useUnidadesParciais = (sortedDistanciaArray ? sortedDistanciaArray : distanciaArray).map((unidade, index) => {
    const empresa = unidadesAgg[unidade.id][0];
    let distancia =
      user.coords && empresa.latitude && empresa.longitude ? (' ' + empresa && empresa.distancia ? empresa.distancia.toFixed(2) + ' km' : '') : '';
    if (unidadesAgg[unidade.id].length < numberOfProcedures && parciais === false) {
      setParciais(true);
    }
    let total_deovita = 0;
    let total_particular = 0;
    unidadesAgg[unidade.id].forEach((procedure) => {
      total_deovita += parseFloat(procedure.valor_deovita);
      total_particular += parseFloat(procedure.valor_particular);
    });
    return unidadesAgg[unidade.id].length < numberOfProcedures ? (
      <ListItem
        fontWeight="bold"
        bgColor="rgba(90, 178, 173, 0.3)"
        borderRadius="20px"
        shadow="md"
        mt="0.8em"
        width="100%"
        backgroundColor="gray.100"
        minH="3em"
        pl="5em"
        m="2%"
        onClick={() => {
          if (state.orcamento_id) {
            setOrcamento(state.orcamento_id);
          }
          if (user.loggedIn === true) {
            clearCart();
            setPick(unidadesAgg[unidade.id]);
            drawerProcedure.onOpen();
          } else {
            drawerCompraPlano.onOpen();
          }
        }}
      >
        <Flex w="100%" minH="40%" maxH="50%">
          <Image
            src={`https://backoffice.deovita.com.br/deovita//upload/fachada/` + empresa.empresa_id + '/fachada.png'}
            fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
            backgroundColor="white"
            border="1px"
            h="5em"
            w="5em"
            ml="-6em"
            mr="5%"
            mt="0.5em"
            mb="0.5em"
            borderRadius="1.5em"
            name={empresa.NAME}
          />

          <VStack>
            <VStack align="right" h="100%" spacing={0} minW="50%" maxH="100%">
              <Text fontSize="xs" mt="2" textColor="gray" textAlign="start" maxW="80" noOfLines={2}>
                {empresa.empresa}
              </Text>

              <Flex>
                <Box>
                  {' '}
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" noOfLines={1}>
                    {empresa.endereco}, {empresa.numero},
                  </Text>
                  <Text fontSize="9px" fontWeight={5} textAlign="left" textColor="gray.400" isTruncated>
                    - {empresa.municipio} / {empresa.estado}
                  </Text>
                </Box>
              </Flex>
            </VStack>
            <Flex alignSelf="start">
              <Badge backgroundColor="orange" color="white" pr="10%" borderLeftRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Particular</chakra.p>
                  <chakra.p fontSize="0.8em">{total_particular.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
              <Badge backgroundColor="green" color="white" ml="-1" pr="10%" borderRightRadius="5px">
                <Stack spacing={-1}>
                  <chakra.p fontSize="0.5em">Assinante</chakra.p>
                  <chakra.p fontSize="0.8em">{total_deovita.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
                </Stack>
              </Badge>
            </Flex>
            <HStack w="100%">
              <Text fontSize="9px" color="#808080">
                Realiza:{' '}
                <chakra.span color="#50A193" textDecoration="underline">
                  {' '}
                  {unidadesAgg[unidade.id].length} de {numberOfProcedures} | VER
                </chakra.span>
              </Text>
              <Flex>
                {distancia && (
                  <>
                    <MdOutlineLocationOn class="googlemap" color="green" />

                    <chakra.span color="orange" fontWeight={5} fontSize="9px">
                      {distancia}
                    </chakra.span>
                  </>
                )}
              </Flex>{' '}
            </HStack>
          </VStack>
        </Flex>
      </ListItem>
    ) : (
      <></>
    );
  });

  return unidades.length > 0 ? (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack m="5%" pb="25%" spacing={3} align="center">
        {pick && <DrawerProcedimento disclosure={drawerProcedure} data={pick} setPick={setPick} />}
        <DrawerCompraPlano disclosure={drawerCompraPlano} />
        {todos === true ? (
          <Heading fontSize="xs" fontWeight="bold" color="#585858">
            Locais que realizam todos os procedimentos
          </Heading>
        ) : (
          <>
            <VStack w="70%">
              <Img src={SadFace} />
              <Text textAlign="center">Não encontramos uma unidade que realize todos os procedimentos.</Text>
            </VStack>
            <VStack w="100%">
              <Text textAlign="center">Mas você pode selecionar abaixo unidades que fazem separadamente.</Text>
            </VStack>
          </>
        )}
        <List pl="1em" mt="3" w="100%" spacing={4}>
          {unidades && useUnidades}
        </List>
        {parciais === true ? (
          <>
            <Heading fontSize="xs" fontWeight="bold" color="#585858">
              Locais que realizam parte dos procedimentos
            </Heading>
            <List pl="1em" mt="3" w="100%" spacing={4}>
              {unidades && useUnidadesParciais}
            </List>
          </>
        ) : (
          <></>
        )}
      </VStack>
    </>
  ) : (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack m="5%" pb="25%" spacing={3} align="start">
        <Heading fontSize="lg">Escolha uma empresa</Heading>
        <Box height="10%" w="100%">
          {error === null ? <Loading /> : <Text>{error}</Text>}
        </Box>
      </VStack>
    </>
  );
}

export default EscolheEmpresa;
