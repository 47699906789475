import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Image,
  ModalCloseButton,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import MoneyFace from '../../../../images/moneyFace.svg';
import { useLocation, useNavigate } from 'react-router-dom';
function DrawerFaturaCancelamento(props) {
  const disclosure = props.disclosure;
  const navigate = useNavigate();
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const [isInRange] = useMediaQuery('(min-height: 840px) and (max-height: 1000px)');
  const state = useLocation();
  return (
    <>
      <Drawer isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent width="100%" h={isInRange ? '70%': "80%"} borderTopRadius={'2em'}>
          <DrawerBody p="5%">
            <VStack alignItems="left" spacing={2.5} p={'1em'}>
              <ModalCloseButton _focus={false}/>
              <Center>
                <Heading fontSize="16" fontWeight={'bold'} color={'#585858'}>
                  Cancelar Contrato
                </Heading>
              </Center>
              <Center>
                <Image boxSize="66" objectFit="cover" src={MoneyFace} alt="" mt={'1em'} />
              </Center>
              <Center>
                <Text fontSize="18p´x" fontWeight={'bold'} color={'#EB8B2A'} textAlign={'center'} mt={'1em'}>
                  Sabia que sua assinatura pode sair de graça e você ainda pode faturar?
                </Text>
              </Center>
              <Center>
                <Text fontSize="16px" color={'#585858'} textAlign={'center'}>
                  Indicando o DeoVita para amigos e <br /> parentes você pode ganhar até R$ <br /> 20,00 por indicação 💵
                </Text>
              </Center>
              <Center>
                <Text fontSize="16px" color={'#585858'} textAlign={'center'}>
                  Que tal manter seu plano ativo e <br /> aproveitar essa oportunidade de <br /> renda extra?
                </Text>
              </Center>
            </VStack>
            <Box minW={isMinHeight ? '380px' : '400px'} overflow="hidden" ml={'0.8em'} >
              <ButtonGroup sx={{ width: '85%' }} mb="1em" mt={'1em'} onClick={() => navigate('/perfil/assinaturas/continua-contrato')}>
                <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
                  Quero faturar com o DeoVita 💵
                </Button>
              </ButtonGroup>
              <ButtonGroup sx={{ width: '85%' }} mb="1em" onClick={() => navigate('/perfil/assinaturas/cancelar-contrato-musica-saudade')}>
                <Button name="btn" color="#909090" bg={'#F3F3F3'} type="submit" w={{ base: '100%' }} h={'2em'}>
                  Cancelar Contrato
                </Button>
              </ButtonGroup>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerFaturaCancelamento;
