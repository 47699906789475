import { Box, Button, ButtonGroup, Flex, Heading, Image, VStack, chakra, useLatestRef } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader'
import React, { useContext } from 'react'
import AvisoPendencias from '../../../images/AvisoPendencias.svg'
import { useLocation, useNavigate } from 'react-router-dom';
function DividaPendente() {
    const { setPage, user } = useContext(AccountContext);
    setPage('Upgrade Contrato')
    const navigate = useNavigate();
    const state = useLocation()
    const parcelas = state?.state?.parcelas
    return (
        <>
            <ChildHeader />
            <Box>
                <Flex
                    minW="210px"
                    maxW="340px"
                    mx="auto"
                    overflow="hidden"
                    padding="1em"
                    px={5}
                    pt={5}

                >
                    <VStack w={'100%'}>
                        <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} p={'1em'} w={'100%'}>
                            <Image boxSize="15px" objectFit="cover" src={AvisoPendencias} mr={'15em'}></Image>
                            <Heading fontSize={'19'} color={'#585858'} fontWeight={'bold'}>Você possue <b style={{ color: '#D60000' }}>pendências</b></Heading>
                            <chakra.span color={'#585858'} fontSize="16" textAlign="left">
                                A parcela referente a este mês ainda não foi paga. Para realizar o upgrade do seu plano é necessário realizar o pagamento da parcela.
                            </chakra.span>
                        </VStack>

                        <Box position="fixed" bottom="1rem" left="50%" transform="translateX(-50%)" onClick={() => navigate('/pagamentoparcelas', {  state: { parcelas: parcelas }  })}>
                            <ButtonGroup pt="1rem" pb="15%" sx={{ width: '100%' }} w={'100%'}>
                                <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={'20em'} h={'2.5em'}  >
                                    Regularizar pendências
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </VStack>
                </Flex>
            </Box>

        </>
    )
}

export default DividaPendente
