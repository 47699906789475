/* eslint-disable import/no-unresolved */
import { Heading, HStack, Switch, VStack, chakra, ButtonGroup, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import TextField from 'components/TextField';
import SelectField from 'components/SelectField';
import BottomNavBar from 'components/BottomNavBar';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Endereco() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [localidades, setLocalidades] = useState({ municipios: [], estados: [] });
  const [municipiosFiltradoPorEstado, setMunicipiosFiltradoPorEstado] = useState([]);
  const [error, setError] = useState(null);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  const initialValues = {
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    localidadeIbge: '',
    cidade: '',
    estado: '',
    bairro: '',
    switchCep: false,
  };

  const validationSchema = Yup.object({
    cep: Yup.string().required('Cep é obrigatório').min(10, 'Cep inválido'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    numero: Yup.number('Somente número').required('Número é obrigatório'),
    localidadeIbge: Yup.string().required('Cidade é brigatória'),
    estado: Yup.string().required('Estado é obrigatório'),
    bairro: Yup.string().required('Bairro é obrigatório'),
  });

  function getCities() {
    fetch(`${ENDPOINT}/cities/clinicas`, {
      method: 'GET',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .catch(() => {})
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return null;
        }
        return res.json();
      })
      .then((data) => {
        const estados = [];
        data.map((cidade) => (!estados.includes(cidade.estado) ? estados.push(cidade.estado) : ''));
        setLocalidades({ municipios: data, estados });
      });
  }

  function handleSubmit(values) {
    console.log(values);
    const vals = {
      ...state,
      cod_ibge: values.localidadeIbge,
      cep: onlyNumbers(values.cep),
      endereco: values.endereco,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      cidade: values.switchCep ? municipiosFiltradoPorEstado[values.campoCidade].nome : values.cidade,
      estado: values.estado,
    };
    console.log(vals);
    navigate(state.nextPage, { state: vals });
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function AddressForm(formProps) {
    const { setFieldValue, values, getFieldMeta } = formProps;

    function BuscaCep(cep) {
      const cep2 = onlyNumbers(cep);
      async function buscaCep() {
        try {
          const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_VIACEP}/${cep2}/json/`);
          resposta.json().then((res) => {
            if (res.erro === 'true') {
              setError('Não foi possivel consultar seu cep, verifique o cep ou preencha as informações manualmente');
              return;
            }
            setError(null);
            setFieldValue('endereco', res.logradouro);
            setFieldValue('bairro', res.bairro);
            setFieldValue('localidadeIbge', res.ibge);
            setFieldValue('cidade', res.localidade);
            setFieldValue('estado', res.uf);
          });
        } catch (e) {
          setError('Não foi possivel consultar seu cep, verifique o cep ou preencha as informações manualmente');
        }
      }
      buscaCep();
    }

    function campoCep() {
      return values.switchCep ? (
        ''
      ) : (
        <TextField
          name="cep"
          placeholder="CEP"
          mask="99.999-999"
          type="tel"
          maskChar="_"
          borderRadius={8}
          onChange={(e) => {
            setFieldValue(e.target.name, e.target.value);
            if (e.currentTarget.value.replace('_', '').length >= 10) BuscaCep(e.currentTarget.value);
          }}
        />
      );
    }

    function switchCep() {
      return (
        <Switch
          name="switchCep"
          color="gray"
          size="sm"
          fontSize="sm"
          colorScheme="teal"
          isChecked={values.switchCep}
          onChange={(e) => {
            function setEnd() {
              setFieldValue('cep', '00.000-000');
              setFieldValue('endereco', '');
              setFieldValue('bairro', '');
              setFieldValue('localidadeIbge', '');
              setFieldValue('cidade', '');
              setFieldValue('estado', '');
            }
            setFieldValue('switchCep', e.target.checked);
            // eslint-disable-next-line no-unused-expressions
            e.target.checked ? setEnd() : setFieldValue('cep', '');
          }}
        >
          Não sei o cep / digitar dados
        </Switch>
      );
    }

    const campoEstado = () => {
      return values.switchCep ? (
        <SelectField
          name="campoEstado"
          placeholder="Estado"
          w="100%"
          variant="outline"
          textAlign="left"
          fontWeight="normal"
          onChange={(e) => {
            setFieldValue('estado', localidades.estados[e]);
            setMunicipiosFiltradoPorEstado(localidades.municipios.filter((cidade) => cidade.estado === localidades.estados[e]));
          }}
          options={localidades.estados}
        />
      ) : (
        <TextField name="cidade" placeholder="Cidade" borderRadius={8} isDisabled={!values.switchCep} />
      );
    };

    const campoCidade = () => {
      let arrayNomeMunicipios = municipiosFiltradoPorEstado.map((municipio) => municipio.nome);
      return values.switchCep ? (
        <SelectField
          name="campoCidade"
          placeholder="Cidade"
          w="100%"
          variant="outline"
          textAlign="left"
          fontWeight="normal"
          onChange={(e) => {
            let codIbge = municipiosFiltradoPorEstado[parseInt(e)].codigo_ibge;
            setFieldValue('localidadeIbge', codIbge);
          }}
          isDisabled={values.estado === ''}
          options={arrayNomeMunicipios}
        />
      ) : (
        <TextField name="estado" placeholder="Estado" borderRadius={8} isDisabled={!values.switchCep} />
      );
    };

    return (
      <VStack align="start" mx="auto" mt="5" mb="20" spacing={5} as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top">
        <chakra.p color="red">{error}</chakra.p>
        <Heading size="sm">Complete com os dados de endereço</Heading>
        {campoCep()}
        {switchCep()}
        <TextField name="endereco" placeholder="Endereço" borderRadius={8} isDisabled={!values.switchCep} />
        <HStack spacing="0.3rem" justify="top" align="start">
          <TextField name="numero" borderRadius={8} placeholder="Número" w="35vw" />
          <TextField name="complemento" borderRadius={8} placeholder="Complemento (opcional)" w="54.5vw" />
        </HStack>
        <HStack justify="top" align="start" w="100%">
          {campoEstado()}
          {campoCidade()}
        </HStack>
        <TextField name="bairro" borderRadius={8} placeholder="Bairro" isDisabled={!values.switchCep} />
        <ButtonGroup pt="1rem" pb="25%" sx={{ width: '100%' }}>
          <Button colorScheme="teal" borderRadius={8} onClick={() => navigate(-1)} w={{ base: '100%' }}>
            Voltar
          </Button>
          <Button name="btn" colorScheme="teal" borderRadius={8} type="submit" w={{ base: '100%' }}>
            Avançar
          </Button>
        </ButtonGroup>
      </VStack>
    );
  }

  useEffect(() => getCities(), []);

  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {AddressForm}
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default Endereco;
