import React, { useContext } from 'react';
import {
  Text,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Button,
  UnorderedList,
  ListItem,
  Avatar,
  useMediaQuery,
} from '@chakra-ui/react';
import MoneyMouth from '../images/emoji-money-mouth.svg';
import SadFace from '../images/emoji-sad-face.svg';
import { AccountContext } from './AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';

function DrawerAssinatura(props) {
  const [lg] = useMediaQuery('(min-width: 425px)');
  const { disclosure } = props;
  const { user, setUser } = useContext(AccountContext);
  const state = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer isOpen={disclosure.isOpen} placement="bottom" onClose={disclosure.onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius="20px">
        <DrawerCloseButton />
        <DrawerBody p={10}>
          <Text fontWeight={'semibold'} fontSize={20} color={'#EB8B2A'} lineHeight={1.2} mb={8}>
            Espere... <br />
            Você ainda não é assinante Deovita?
          </Text>

          <Box bgColor={'#F1F1F1'} p={lg ? 8 : 6} borderRadius={20} mb={4}>
            <Text textAlign={'center'} lineHeight={1.1} mb={4}>
              Com o <span style={{ fontWeight: 'bold', color: '#EB8B2A' }}>Plano Família</span>,{' '}
              <span style={{ fontWeight: 'bold', color: '#FF8000' }}>Plano Família Plus</span> ou{' '}
              <span style={{ fontWeight: 'bold', color: '#529C94' }}>Plano Individual</span>, sua consulta online tem desconto!
            </Text>

            <Text textAlign={'center'} color={'#808080'} fontWeight={'bold'} mb={2}>
              E tem mais! Aqui você pode:
            </Text>

            <Text color={'#808080'} lineHeight={1.2} fontSize={12} p={2} mb={8}>
              <UnorderedList fontSize="xs">
                <ListItem>Falar com especialistas com desconto de 80%;</ListItem>
                <ListItem>Ter Teleconsulta e Pronto Atendimento sem custo adicional;</ListItem>
                <ListItem>Consultas presenciais a partir de R$ 25,00;</ListItem>
                <ListItem>Exames com até 80% de desconto.</ListItem>
              </UnorderedList>
            </Text>

            <Button
              isFullWidth
              bgColor={'#EB8B2A'}
              color={'white'}
              fontSize={lg ? 'sm' : 'xs'}
              display={'flex'}
              gap={4}
              onClick={() => {
                if (user.loggedIn === false) {
                  setUser((prevState) => {
                    return {
                      ...prevState,
                      service_type: 'assinar',
                    };
                  });
                  navigate('/cadastro');
                } else {
                  setUser((prevState) => {
                    return {
                      ...prevState,
                      service: 'telemedicine',
                      service_type: 'assinar',
                    };
                  });
                  navigate('/cadastro/plano', {
                    state: {
                      client_id: user.id_cliente_deovita,
                      name: user.name,
                      cpf: user.cpf,
                    },
                  });
                }
              }}
            >
              <Text>
                Assine e  {!lg && <br />}
                economize agora!
              </Text>
              <Avatar src={MoneyMouth} height={6} width={6}></Avatar>
            </Button>
          </Box>

          <Box bgColor={'#F1F1F1'} p={lg ? 8 : 6} borderRadius={20}>
            <Box color={'#838383'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={4} fontSize={lg ? 'md' : 'xs'}>
              <Text fontWeight={'bold'} maxWidth={'40%'}>
                Continuar sem assinar
              </Text>
              <Box>
                <Text fontSize={lg ? 12 : 10}>
                  Valor para <br /> não assinantes:
                </Text>
                <Text fontWeight={'bold'} color={'#D60000'} fontSize={lg ? 20 : 14}>
                  R$ 150,00
                </Text>
              </Box>
            </Box>

            <Button
              isFullWidth
              bgColor={'#999999'}
              color={'white'}
              display={'flex'}
              gap={4}
              fontSize={lg ? 'sm' : 'xs'}
              onClick={() => {
                if (user.loggedIn === false) {
                  navigate('/cadastro', {
                    state: {
                      service: 'telemedicine',
                      valor: '150.00',
                    },
                  });
                } else {
                  navigate('/pagamentos', {
                    state: {
                      name: user.name,
                      service: 'telemedicine',
                      valor: '150.00',
                      navig_to: '/alerts/sucesso/telemedicina',
                      parcelsActive: true,
                      item: {
                        client_id: user.id_cliente_deovita,
                        product_name: '',
                        nome: user.name,
                        fone: user.phone1 ? user.phone1 : user.phone2 ? user.phone2 : '',
                        pet: state.pet,
                      },
                      expiration_pix_in_min: 5,
                    },
                  });
                }
              }}
            >
              Não quero assinar <Avatar src={SadFace} height={6} width={6}></Avatar>
            </Button>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerAssinatura;
