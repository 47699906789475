import React from 'react';
import {
  chakra,
  Box,
  Img,
  LinkBox,
  Text,
  VStack,
  Checkbox,
  Image,
  RadioGroup,
  Stack,
  Radio,
  Spinner,
  Heading,
  useDisclosure,
  Textarea,
  Center,
  Highlight,
  Container,
  Badge,
  Flex,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react';
import BottomNavBar from 'components/BottomNavBar';
import { useLocation, useNavigate } from 'react-router-dom';

import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Voltar from '../../../../../images/Voltar.svg';
import Contact from '../../../../../images/ClinicoIcon.svg';
import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
function AvisoPreAtendimento() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');

  const drawerHeight = isLargeHeight ? '50vh' : '40vh';

  return (
    <>
      <VStack align="center" wrap="no-wrap" minH="100vh" spacing={0} pt="6em" pb="6em" background="#EB8B2A" color="#585858" overflow="hidden">
        <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
        <Box as="card" borderRadius="18px" fontSize="xs" size="10" backgroundColor="white" maxInlineSize="80" textAlign="center">
          <VStack m={{ sm: '10%' }} textColor="#585858">
            <Image top={{ lg: '5.5em', sm: '5em' }} src={Contact} alt="teste" height={'4.5em'} />
            <Text fontWeight="bold" fontSize="1.5em">
              Orientações do {state.titulo ? state.titulo : 'Pronto Atendimento'}
            </Text>

            <HStack backgroundColor="#E5E5E5" borderRadius="15px">
              <Text>
                <Flex ml="1em">
                  <Box backgroundColor="#EB8B2A" borderRadius="5px" margin="0.8em" color="rgba(62, 100, 255, 1)" padding="0 0.4em"></Box>
                  <Text p={'5'} fontSize="xs" align="left" color="#585858;">
                    <chakra.p>
                      Antes de iniciar a consulta, certifique-se de que sua <b>conexão</b> esteja estável;
                    </chakra.p>
                  </Text>
                </Flex>
                <Flex ml="1em">
                  <Box backgroundColor="#EB8B2A" borderRadius="5px" margin="0.8em" color="rgba(62, 100, 255, 1)" padding="0 0.4em"></Box>
                  <Text p={'5'} fontSize="xs" align="left" color="#585858;">
                    <chakra.p>
                      Confira também se seu <b>microfone</b> e <b>câmera</b> estão funcionando normalmente;
                    </chakra.p>
                  </Text>
                </Flex>
                <Flex ml="1em">
                  <Box backgroundColor="#EB8B2A" borderRadius="5px" margin="0.8em" color="rgba(62, 100, 255, 1)" padding="0 0.4em"></Box>
                  <Text p={'5'} fontSize="xs" align="left" color="#585858;">
                    <chakra.p>
                      Esteja em um <b>ambiente</b> silencioso de boa iluminação para ter melhor <b>experiência</b> em seu atendimento.
                    </chakra.p>
                  </Text>
                </Flex>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: 'orange',
                    display: 'inline-block',
                  }}
                ></div>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#D9D9D9',
                    display: 'inline-block',
                  }}
                ></div>
                <VStack w="100%" p={'1em'} ml={'40%'}>
                  <BsFillArrowRightCircleFill size={'2em'} color="#EB8B2A" onClick={() => navigate('/aviso-pre-atendimento2/', { state: state })} />
                </VStack>
              </Text>
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </>
  );
}

export default AvisoPreAtendimento;
