import {
  chakra,
  Box,
  Text,
  VStack,
  Image,
  Button,
  useDisclosure,
  Grid,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useMediaQuery,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import BackButton from '../../../../../images/assets/telemedicina/backbutton.svg';
import especialistas from '../../../../../images/doctor-badge.svg';
import { ReactComponent as InfoCircle } from '../../../../../images/info-circle.svg';
import ChildHeader from '../../ChildHeader';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

function DesistenciaModal({ state, agenda, setPausedFetchRdy }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const desistirAtendimento = useCallback(async () => {
    setPausedFetchRdy(true);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(ENDPOINT + `/schedule/updateTelemedAtendimento/${agenda}/${onlyNumbers(state.patient)}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      console.log(postsData);

      navigate('/dashboard', { replace: true });
    } catch (error) {
      console.log(error);
      setError('Ocorreu um erro durante a solicitação de desistência');
    } finally {
      setPausedFetchRdy(false);
      setIsLoading(false);
    }
  }, [agenda, navigate, setPausedFetchRdy, state.patient]);
  return (
    <>
      <Button
        isFullWidth
        size="sm"
        colorScheme="gray"
        variant="outline"
        color="#585858"
        mt={4}
        onClick={() => {
          onOpen();
        }}
      >
        Desistir do atendimento
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
        <ModalOverlay />
        <ModalContent textAlign={'center'} borderRadius={20} m={8}>
          <ModalHeader fontSize={'md'}>Você tem certeza disso?</ModalHeader>
          <ModalBody fontSize={'xs'}>Se desistir do atendimento, você perderá sua posição na fila.</ModalBody>

          <ModalFooter>
            <VStack w={'100%'}>
              {error && (
                <Text fontSize={'sm'} fontWeight={'bold'} bgColor={'#CB0000'} color={'white'} p={2} rounded={10}>
                  {error}
                </Text>
              )}

              <Button
                size="sm"
                colorScheme="teal"
                onClick={() => {
                  desistirAtendimento();
                }}
                isFullWidth
                isLoading={isLoading}
              >
                Confirmar desistência
              </Button>
              <Button size="sm" disabled={isLoading} colorScheme="gray" onClick={onClose} variant="ghost" isFullWidth>
                Cancelar
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function AtendimentoTelmedicina() {
  const [md] = useMediaQuery('(min-width: 375px)');
  const { setPage } = useContext(AccountContext);
  setPage('Telemedicina');
  const { state } = useLocation();
  const { user } = useContext(AccountContext);
  const [reconnect, setReconnect] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [medico, setMedico] = useState('');
  const [paciente, setPaciente] = useState('');
  const [agenda, setAgenda] = useState('');
  const [queued, setQueued] = useState(false);
  const [position, setPosition] = useState(false);
  const [tempo, setTempo] = useState(false);
  const [pausedFetchRdy, setPausedFetchRdy] = useState(false);
  const intervalRef = useRef();

  const today = new Date().toISOString().substring(0, 10);
  const url = ENDPOINT + '/schedule/appointment_telemed';
  const payload = {
    cpf: onlyNumbers(state.patient),

    cart: {
      convenio_id: 252,
      dia_previsao: 0,
      grupo: 'CONSULTA TELEMEDICINA',
      materiais: '',
      nome: 'CONSULTA TELEMEDICINA',
      procedimento_convenio_id: 6178,
      procedimento_tuss_id: 173059,
      valor: '0,00',
    },
    turno: 'manhã',
    valor: 0,
    empresa: {
      ativo: true,
      bairro: 'Centro',
      cep: '63500-065',
      descricao:
        'Iniciamos nossas atividades em 2006, tendo como base fundamental a garantia de um bom atendimento e qualidade. Com união entre profissionais qualificados e tecnologia avançada, conquistamos a confiança de nossos clientes, trazendo resultados com agilidade e precisão.     Além da sede em Iguatu/CE, hoje estamos presentes em Fortaleza, região metropolitana, e em diversas cidades do estado do Ceará.',
      empresa: 'ADOLFO LUTZ',
      empresa_id: 1,
      endereco: 'Rua Deputado Adail Barreto',
      estado: 'CEARA',
      grupo: 'ENDOSCOPIA',
      horario_sab_fim: '13:00',
      horario_sab_inicio: '06:00',
      horario_seg_sex_fim: '18:00',
      horario_seg_sex_inicio: '06:00',
      municipio: 'IGUATU',
      numero: '54',
      ordem: '1',
      telefone_01: '0800 280 2133',
      telefone_02: '88 981189898',
    },
    triagem: state?.triagem,
    data: today,
  };

  const fetchPosts = async () => {
    try {
      if (queued === false) {
        setQueued(true);

        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        });
        const postsData = await response.json();
        setSuccess(postsData.success);
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (success === false) {
    fetchPosts();
  }

  const fetchRdy = async () => {
    try {
      const response = await fetch(ENDPOINT + `/schedule/scheduletelemed/${state.patient}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const postsData = await response.json();
      console.log(postsData);
      setPosition(postsData.position);
      setTempo(postsData.avg_wait_time);
      setAgenda(postsData.agenda_exames_id);
      setError(false);
      setReconnect(false);
      console.log(position);
      if (postsData.medico_ready === true) {
        setMedico(postsData.medico_id);
        setAgenda(postsData.agenda_exames_id);
        setPaciente(postsData.paciente_id);

        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            service: true,
            openInBrowser: false,
            url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`,
          })
        );
        // navigate('/dashboard');

        /* const isAndroid = user.device === 'android' ? true : false;

        if(isAndroid) {
          window.location = `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`
        }




        else {
          setLoading(false)
          /* window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              service: true,
              url: `https://atendimento.deovita.com.br:5000/atendimento?p=${postsData.paciente_id}&m=${postsData.medico_id}&a=${postsData.agenda_exames_id}&u=paciente`
            })
          );



        }

*/

        //setLoading(false);
        const highestId = window.setTimeout(() => {
          for (let i = highestId; i >= 0; i--) {
            window.clearInterval(i);
          }
        }, 0);
      }
    } catch (error) {
      setError('Problema na rede, verifique se seu dispositivo está conectado a internet');
      console.log(error);
    }
  };

  useEffect(() => {
    if (success === true && !pausedFetchRdy) {
      intervalRef.current = setInterval(fetchRdy, 10000);
    }

    return () => clearInterval(intervalRef.current);
  }, [success, pausedFetchRdy]);

  return isLoading ? (
    <>
      {/* <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} /> */}
      <Center flexDirection={'column'} minH="100vh" background="#EB8B2A" p={md ? 8 : 4} pb={20}>
        <Box borderRadius={20} backgroundColor="white" textAlign="center" p={md ? 8 : 4} fontSize="xs">
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Image src={especialistas} h={md ? '5em' : '3em'} w={md ? '5em' : '3em'} />
            <Text color="#585858" fontWeight="bold" fontSize={md ? 'lg' : 'sm'} mt={4}>
              Agora é só aguardar!
            </Text>
            <Text>
              {error !== false && error !== undefined ? (
                'Falha na conexão'
              ) : position !== null ? (
                position === false || position === undefined ? (
                  'conectando...'
                ) : (
                  <Box borderBottom={'2px solid #DFDFDF'} p={4}>
                    <Text>Você é o </Text>
                    <Text fontSize={'md'} fontWeight="bold" color={'#EB8B2A'}>{`${position}º da fila`}</Text>
                  </Box>
                )
              ) : (
                'iniciando atendimento...'
              )}
            </Text>

            <Text mt={4}>
              {tempo && (tempo === false || tempo === undefined) ? (
                ''
              ) : tempo > 0 ? (
                <Box>
                  <Text>Tempo estimado de espera</Text>
                  <Text fontSize={'md'} fontWeight="bold" color={'#EB8B2A'}>
                    {Math.round(tempo / 60) > 0 ? `${Math.round(tempo / 60)} minutos` : 'menos de 1 minuto'}
                  </Text>
                </Box>
              ) : (
                ''
              )}
            </Text>

            {/* <Accordion allowToggle mt={4} w={'100%'} bgColor={'#DFDFDF'} color={'#585858'} borderRadius={10}>
              <AccordionItem>
                <AccordionButton display={'flex'} justifyContent={'space-between'} p={4}>
                  <InfoCircle />
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel px={4} textAlign={'left'}>
                  É importante lembrar que o profissional de saúde pode demorar um pouco mais durante o atendimento. Ou seja, seu lugar na fila e o
                  tempo estimado pode aumentar ou diminuir.
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}

            <Box bgColor={'#DFDFDF'} color={'#585858'} p={4} textAlign={'left'} borderRadius={10} mt={md ? 10 : 4}>
              <InfoCircle style={{ height: '1rem' }} />
              <Text mt={4}>
                É importante lembrar que o profissional de saúde pode demorar um pouco mais durante o atendimento. Ou seja, seu lugar na fila e o
                tempo estimado pode aumentar ou diminuir.
              </Text>
            </Box>

            {position !== false && position?.length > 0 && (
              <DesistenciaModal state={state} agenda={agenda} setPausedFetchRdy={setPausedFetchRdy}></DesistenciaModal>
            )}

            {/* <Text mt="1em" color="#585858" fontSize="sm" mb="0.5em">
                Para assegurar a sua posição na fila permaneça nessa tela, evite minimizar o aplicativo, nossa equipe de médico irá te atender em
                poucos instantes. Em caso de falha na sua conexão, não se preocupe, entraremos em contato para reestabelecer. Após o atendimento,
                aparecerá uma tela de feedback
              </Text> */}

            {/* <Box w="25em" h="3em" top={error !== false && error !== undefined ? '23em' : '25em'} borderRadius="10">
                {error !== false && error !== undefined ? (
                  <>
                    <Text mt="1.5em" color="red" fontWeight={'bold'} fontSize="sm" mb="1em">
                      {error}
                    </Text>
                  </>
                ) : position !== null ? (
                  position === false || position === undefined ? (
                    <></>
                  ) : (
                    <>
                      <Button position="absolute" bottom="10" ml="11em" isLoading colorScheme="whiteAlpha" variant="ghost"></Button>
                    </>
                  )
                ) : (
                  <></>
                )}
              </Box> */}
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} fontSize={md ? 'lg' : 'xs'} mt={4}>
          {reconnect === true ? (
            <Text color="white" fontWeight="bold">
              Reconectando...
            </Text>
          ) : (
            <>
              <Text color="white" fontWeight="bold">
                Problemas na conexão?
              </Text>
              <Button
                isFullWidth
                variant="ghost"
                color="white"
                fontSize={md ? 'lg' : 'xs'}
                onClick={() => {
                  setReconnect(true);
                  fetchRdy();
                }}
              >
                Clique aqui para reconectar
              </Button>
            </>
          )}
        </Box>
      </Center>

      <BottomNavBar />
    </>
  ) : (
    <>
      <ChildHeader />
      <VStack>
        <chakra.iframe
          width="95%"
          paddingTop="0.5em"
          height="600px"
          src={`https://atendimento.deovita.com.br:5000/atendimento?p=${paciente ? paciente : ''}&m=${medico ? medico : ''}&a=${
            agenda ? agenda : ''
          }&u=paciente`}
          allow="camera *;microphone *"
        ></chakra.iframe>
      </VStack>

      <BottomNavBar />
    </>
  );
}

export default AtendimentoTelmedicina;
