import { SmallCloseIcon, EditIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Table, TableContainer, Tbody, Thead, Tr, Td, ButtonGroup, Button, Text } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ListarPets() {
  const navigate = useNavigate();
  const { user } = useContext(AccountContext);
  const [dependentes, setDependentes] = useState([]);
  const [error, setError] = useState(null);
  const { id_contrato } = user;
  const [loading, setLoading] = useState(false);

  const url = `${ENDPOINT}/client/pets`;
  const payload = {
    id_contrato: user.id_contrato,
  };
  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json(); 
      setLoading(true);
      setDependentes(postsData.data);
    };
    fetchPosts();
  }, []);

  const useDependentes = dependentes.map((dependente, index) => {
    const arrName = dependente.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nome = Array.isArray(arrName) ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name) === false).join(' ') : '';
    return (
      <>
        <Tr key={index}>
          <Td>
            <WarningTwoIcon color="white" />
            {' ' + nome.toUpperCase()}
          </Td>
          <Td>
            <SmallCloseIcon color="white" />
          </Td>
          <Td>
            <SmallCloseIcon
              onClick={() => navigate('/perfil/dependentes/aviso-exclusao', { state: { id: dependente.paciente_contrato_dependente_id } })}
              color="red"
              boxSize="2em"
            />
          </Td>
        </Tr>
      </>
    );
  });
 
  return (
    <>
      <TableContainer minWidth="100%">
        <Table>
          <Thead>
            <Tr></Tr>
          </Thead>
          <Tbody>{dependentes && useDependentes}</Tbody>
        </Table>
      </TableContainer>
      <Text color="red">{error}</Text>

      <ButtonGroup pb="40%" sx={{ width: '100%' }}>
        <Button
          isDisabled={loading ? false : true}
          name="btn"
          colorScheme={id_contrato ? 'orange' : 'teal'}
          variant="outline"
          onClick={() => {
            let limit = user.qtde_max_dependentes;
            if (dependentes.length >= 1) {
              setError(`Você já atingiu o limite de 1 pet gratuito incluso no seu plano`);
            } else {
              navigate('/perfil/dependentes/cadastro-pet', {
                state: { id_cliente_deovita: user.id_cliente_deovita, id_contrato: user.id_contrato },
              });
            }
          }}
          w={{
            base: '100%',
          }}
        >
          Adicionar Pet
        </Button>
      </ButtonGroup>
    </>
  );
}

export default ListarPets;
