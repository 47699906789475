import React from 'react';
import { Box, Center, chakra, HStack, VStack } from '@chakra-ui/react';
import { MdOutlineHowToReg, MdStoreMallDirectory, MdOutlineQueryStats, MdOutlineMonetizationOn } from 'react-icons/md';

function Resumo(data, total) {
  console.log(data.total);
  console.log(total);
  const cont = data.data;
  console.log(cont.length);
  return (
    <VStack spacing={8} paddingBottom="10%" width="100%">
      <HStack spacing={0} width="100%">
        <Box width="50%" height="100px">
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="50%" height="80%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(78, 158, 143, 0.1)">
              <Center height="100%">
                <MdOutlineHowToReg size="3em" color="rgba(78, 158, 143, 1)" />
              </Center>
            </Box>
            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" fontWeight="bold" mt="15%" color="#6F767E">
                Mês
              </chakra.p>
              <chakra.p fontSize="sm" fontWeight="bold" color="#6F767E">
                Atual
              </chakra.p>
              <chakra.p fontSize="1.5sem" fontWeight="bold">
                {cont ? cont.length : '-'}
              </chakra.p>
            </VStack>
          </HStack>
        </Box>
        <Box width="50%" height="100px">
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="50%" height="80%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(235, 139, 42, 0.1)">
              <Center height="100%">
                <MdStoreMallDirectory size="3em" color="rgba(235, 139, 42, 1)" />
              </Center>
            </Box>
            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" textAlign="justify" mt="15%" fontWeight="bold" color="#6F767E">
                Total
              </chakra.p>
              <chakra.p fontSize="sm" textAlign="justify" fontWeight="bold" color="#6F767E">
                Geral
              </chakra.p>
              <chakra.p fontSize="1.2sem">{cont ? cont.length : '-'} </chakra.p>
            </VStack>
          </HStack>
        </Box>
      </HStack>
    </VStack>
  );
}

export default Resumo;
