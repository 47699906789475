import {
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Table,
  TableContainer,
  Td,
  Text,
  Tr,
  VStack,
  chakra,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import React, { useContext, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import '../css/carouselBanner.css';
import Medicos from '../../../images/Presencial/Medicos.svg';
import Dentistas from '../../../images/Presencial/Dentistas.svg';
import Exames from '../../../images/Presencial/Exames.svg';
import Procedimentos from '../../../images/Presencial/Procedimentos.svg';
import Teste from '../../../images/Presencial/1.svg';
import { useNavigate } from 'react-router-dom';
import outrosMedicos from '../../../images/outrosMedicos.svg';
import ProcOdonto from '../../../images/ProcOdonto.svg'

//Icones Verdes
import MedicosVerdes from '../../../images/Deslogado/Icons/MedicosVerde.svg'
import ExamesVerde from '../../../images/Deslogado/Icons/ExamesVerde.svg'
import Dentista from '../../../images/Deslogado/Icons/Dentista.svg'
import ProcOdontoVerde from '../../../images/Deslogado/Icons/ProcOdonto.svg'
import OutrosMedicos from '../../../images/Deslogado/Icons/OutrosMedicosVerde.svg'


function AtendimentoPresencial() {
  const { user, toastInadimplente, setUser, updateData, loadedMedicina } = useContext(AccountContext);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const disclosureListaMedicos = useDisclosure();

  const handleFocusChange = (currentItemIndex) => {
    setFocusedIndex(currentItemIndex);
  };

  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');
  const [isIpad] = useMediaQuery('(max-width: 1180px)');
  const [isMotolora] = useMediaQuery('(max-height: 640px)');
  const [isIphoneLarge] = useMediaQuery('(max-height: 6896px)');
  const drawerHeight = isLargeHeight ? '50vh' : '40vh';

  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;

  function CardPresencial({ descricao, icone, legenda, color, onClick }) {
    return (
      <>
        <Td borderRadius="15px" p="0.5em" fontSize="xl" textAlign="center" className="margin-td">
          <Flex backgroundColor="ffff" shadow="base" borderRadius="15px" height="5em" w="100%" onClick={onClick} align="flex-start" ml="5%">
            <Box w="100%" borderRadius="5px">
              <Center w="100%" p="0.5em" borderRadius="5px" ml={'-1em'}>
                <Image borderRadius="5px" src={icone} />
              </Center>
              {descricao === 'Proc. Odonto' ? (
                <>
                  <Text fontSize="16px" textAlign="center" color="#808080">
                    Proc.
                  </Text>
                  <Text fontSize="16px" textAlign="center" color="#808080">
                    Odonto
                  </Text>
                </>
              ) : descricao === 'Outros Médicos' ? (
                <>
                  <Text fontSize="16px" textAlign="center" color="#808080">
                    Outros
                  </Text>
                  <Text fontSize="16px" textAlign="center" color="#808080">
                    Médicos
                  </Text>
                </>
              ) : (
                <Text fontSize="16px" textAlign="center" color="#808080" mt={'1.5em'}>
                  {descricao}
                </Text>
              )}
            </Box>
          </Flex>
        </Td>
      </>
    );
  }

  return (
    <>
      <Box ml={'5%'} mt={'2em'} w="100vw">
        <Text color={'#585858'} fontSize={'18px'} ml={isLargeHeight ? '5%' : '5%'} fontWeight={'bold'} mb={'1em'}>
          Atendimento Presencial
        </Text>

        <VStack spacing={3} align="start" mb={'30%'}>
          <TableContainer minWidth="100%">
            <Table variant="simple" size="lg" class="table-agendamento-exame">
              <Tr>
                <CardPresencial
                  descricao={'Médicos'}
                  icone={usuarioAssinante ? Medicos : MedicosVerdes}
                  legenda={'Seja atendido(a) agora!'}
                  onClick={() => {
                    navigate('/medicosGeral', {
                      state: {
                        typeList: 'Medicos',
                      },
                    });
                  }}
                />
                <CardPresencial
                  descricao={'Exames'}
                  icone={usuarioAssinante ? Exames : ExamesVerde}
                  legenda={'Seja atendido(a) agora!'}
                  onClick={() => {
                    navigate('/exames/agendamento', {
                      state: { grupo_nome: 'Exames', grupo: 0 },
                    });
                  }}
                />
                <CardPresencial
                  descricao={'Dentistas'}
                  icone={usuarioAssinante ? Dentistas : Dentista}
                  legenda={'Seja atendido(a) agora!'}
                  onClick={() => {
                    navigate('/medicosGeral', {
                      state: {
                        typeList: 'Dentistas',
                      },
                    });
                  }}
                />
                <CardPresencial
                  descricao={'Proc. Odonto'}
                  icone={usuarioAssinante ? ProcOdonto : ProcOdontoVerde}
                  legenda={'Seja atendido(a) agora!'}
                  onClick={() => {
                    navigate('/exames/agendamento', {
                      state: { grupo_nome: 'ODONTOLOGIA', grupo: 211 },
                    });
                  }}
                />
                <CardPresencial
                  descricao={'Outros Médicos'}
                  icone={usuarioAssinante ? outrosMedicos : OutrosMedicos}
                  legenda={'Seja atendido(a) agora!'}
                  onClick={() => {
                    navigate('/medicosGeral', {
                      state: {
                        typeList: 'Outros',
                      },
                    });
                  }}
                />
              </Tr>
            </Table>
          </TableContainer>
        </VStack>
      </Box>
    </>
  );
}

export default AtendimentoPresencial;
