import { Image, Box, HStack, chakra, useMediaQuery, Text, Flex, Center, toast, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AccountContext } from 'components/AccountContext';
import PopupDependenteSemCpf from './Atendimento/PopupDependenteSemCpf';
import DrawerServico from './DrawerServico';
import '../../Dashboard/Main/carroselEspecialidades.css';

//Bannersnovos
const banners = [
  require('../../../images/Deslogado/Especialidades/1.webp'),
  require('../../../images/Deslogado/Especialidades/2.webp'),
  require('../../../images/Deslogado/Especialidades/3.webp'),
  require('../../../images/Deslogado/Especialidades/4.webp'),
  require('../../../images/Deslogado/Especialidades/5.webp'),
  require('../../../images/Deslogado/Especialidades/6.webp'),
  require('../../../images/Deslogado/Especialidades/7.webp'),
  require('../../../images/Deslogado/Especialidades/8.webp'),
  require('../../../images/Deslogado/Especialidades/9.webp'),
  require('../../../images/Deslogado/Especialidades/10.webp'),
  require('../../../images/Deslogado/Especialidades/11.webp'),
  require('../../../images/Deslogado/Especialidades/12.webp'),
  require('../../../images/Deslogado/Especialidades/13.webp'),
  require('../../../images/Deslogado/Especialidades/14.webp'),
  require('../../../images/Deslogado/Especialidades/15.webp'),
];

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function BannerEspecialidades() {
  const { user, toastInadimplente } = useContext(AccountContext);
  
  let usuario = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuario = true;

  const [isLargeHeight] = useMediaQuery('(max-height: 667px)');
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  const navigate = useNavigate();

  return (
    <>
      <Box w="100vw" mt={'2em'}>
        <HStack alignItems="center" justify="space-between" mb={'1em'} mr="0.7em">
          <Text color={'#585858'} fontSize={'18px'} ml={'5%'} fontWeight={'bold'}>
            Especialidades grátis para assinantes
          </Text>
        </HStack>
        <Carousel
          itemsToShow={1.1}
          showArrows={false}
          focusOnSelect={true}
          pagination={true}
          itemPadding={[0, 10]}
          renderPagination={({ pages, activePage, onClick }) => (
            <div className={usuario ? 'carousel-pagination' : 'carousel-pagination-deslogado'}>
              {pages.map((page) => (
                <span
                  key={page}
                  className={`carousel-pagination-item ${activePage === page ? 'active' : usuario ? '' : 'desactive'}`}
                  onClick={() => onClick(page)}
                />
              ))}
            </div>
          )}
        >
          {banners.map((banner, index) => (
            <HStack key={index}>
              <HStack
                id={``}
                backgroundColor="white"
                height={miniLargeHeight ? '5em' : '6em'}
                minWidth={miniLargeHeight ? '16em' : '14em'}
                mt="1em"
                mb="2em"
                onClick={() => navigate('/especialidades-deslogado/')}
              >
                <Box w="100%" p={'0.3125em'} shadow="md" borderRadius="1em" onClick={() => {}}>
                  <Center w="100%">
                    <Image
                      borderRadius="1em"
                      bgColor="white"
                      height={miniLargeHeight ? '7em' : '8em'}
                      minWidth={'16em'}
                      objectFit={'cover'}
                      src={banner}
                      fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
                      alt="banner"
                    />
                  </Center>
                </Box>
              </HStack>
            </HStack>
          ))}
        </Carousel>
      </Box>
    </>
  );
}

export default BannerEspecialidades;
