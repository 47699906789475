export default function soundex (inputStr) {
    // Check if the input string is empty and set a default value
    if (!inputStr) {
        inputStr = " ";
    }

    inputStr = inputStr.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^\sa-zA-Z]/g, " ");

    // Convert the input string to uppercase
    inputStr = inputStr.toUpperCase();

    const prepositions = [
        'DE', 'DA', 'DO', 'DOS',
        'DAS', 'EM', 'NO', 'NA',
        'NOS', 'NAS', 'AO', 'AOS',
        'À', 'ÀS', 'POR', 'PARA',
        'COM', 'E', '1', '2', '3',
        '4', '5', '6', '7', '8', '9'
    ];

    // Define the Soundex conversion table
    const soundexTable = {
        A: '', B: '1', C: '2', D: '3', E: '', F: '1', G: '2', H: '', I: '',
        J: '2', K: '2', L: '4', M: '5', N: '5', O: '', P: '1', Q: '2', R: '6',
        S: '2', T: '3', U: '', V: '1', W: '', X: '2', Y: '', Z: '2'
    };

    // Split the input string into words
    let words = inputStr.split(/[\s()]+/).filter(word => word);


    words = words.filter(word => !prepositions.includes(word));

    // Function to compute the Soundex code for a single word
    const computeSoundex = (str) => {
        const firstLetter = str[0];
        let encoded = firstLetter;
        let previousCode = soundexTable[firstLetter];

        for (let i = 1; i < str.length; i++) {
            const char = str[i];
            const code = soundexTable[char];

            // Ignore vowels and duplicate codes
            if (code !== previousCode && code !== '') {
                encoded += code;
            }

            previousCode = code;
        }

        // Return the encoded string without padding or truncating
        return encoded;
    };

    // Apply the Soundex function to each word and join the results
    const result = words.map(word => computeSoundex(word)).join(' ');

    return result;
};



