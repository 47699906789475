import { useState, React } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Formik, Form } from 'formik';
import { Button, chakra, Heading, VStack } from '@chakra-ui/react';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import validaCPF from '../../Utils/Functions/validaCPF';
import NoUserHeader from '../../AreaLivre/NoUserHeader';
import TextField from '../../TextField';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function SecurityQuestions() {
  const [error, setError] = useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formasPagamento, setFormasPagamento] = useState([]); //['Boleto', 'Enel', 'Cartão de Crédito']
  const [formaPag, setFormaPag] = useState('');
  const arrayNomesFormaPagamento = formasPagamento.map((item) => item.nome);
  console.log(state.cpf)
  const initialValues = { nome: state.name, cpf: state.cpf, nascimento: '' };
  const validationSchema = Yup.object({
    cpf: Yup.string()
      .required('Cpf Obrigatório!')
      .trim()
      .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf)),
    nascimento: Yup.date()
      .transform((value, originalValue) => parse(originalValue, 'dd/MM/yyyy', new Date()))
      .required('Data de nascimento é obrigatória!')
      .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),
  });

  
  function HandleSubmit(values) {
    setError(null);
    var vals = {
      nome: state.name ? state.name : values.nome,
      cpf: onlyNumbers(state.cpf ? state.cpf : values.cpf),
      nascimento: values.nascimento,
    };
    fetch(`${ENDPOINT}/auth/recovery2`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(vals),
    })
      .catch(function (err) {
        setError('Não foi possível completar a consulta nesse momento');
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError('Não foi possível completar a consulta nesse momento');
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.success) {
          navigate('/recuperacao/atualizar-fone', {
            state: { cpf: onlyNumbers(vals.cpf), name: state.name, paciente_id: state.paciente_id },
          });
        } else {
          setError('Dados incorretos');
        }
      });

  }


  return (
    <>
      <NoUserHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => HandleSubmit(values)}
      >
        <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" spacing="1rem" align="start">
          <Heading fontSize="1em">Precisaremos confirmar alguns dados pessoais</Heading>
          <chakra.p color="red">{error}</chakra.p>
          <TextField name="nome" placeholder="Digite seu nome" isDisabled />
          <TextField name="cpf" type="tel" placeholder="CPF" mask="999.999.999-99" maskChar={null} isDisabled />
          <TextField name="nascimento" type="tel" placeholder="Data de nascimento" mask="99/99/9999" maskChar={null} />

          <Button colorScheme="teal" type="submit" isFullWidth>
            Confirmar
          </Button>
        </VStack>
      </Formik>
    </>
  );
}

export default SecurityQuestions;
