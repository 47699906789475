import { Button, ButtonGroup, chakra, HStack, VStack, Heading, Switch, useDisclosure, Checkbox, Text } from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'date-fns';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../../../Dashboard/Main/ChildHeader';
import TextField from '../../../TextField';
import SelectField from 'components/SelectField';
import validaCPF from '../../../Utils/Functions/validaCPF';
import { AccountContext } from 'components/AccountContext';
import ListaParcelas from '../../Dependentes/ListaParcelas';
import PagamentoLocalizado from 'components/Pagamento/PagamentoLocalizado';
import Loading from 'components/Loading';

function PagamentoParcelas() {
  const { user } = useContext(AccountContext);
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
  const url = `${ENDPOINT}/client/parcelas`;

  const [loading, setLoading] = useState(true);
  const [parcelas, setParcelas] = useState([]);
  const disclosureParcelas = useDisclosure();
  const state = useLocation();
  const parcelas_assinatura = state?.state?.parcelas;
  const status = state?.state?.status;
  const motivo = state?.state?.motivo;

  const payload = {
    id_contrato: user.id_contrato,
    titular: user.titular,
    id_contrato_dependente: user.id_contrato_dependent,
  };

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();
      setParcelas(postsData);
      setLoading(false);

      if (postsData?.length === 0) {
        disclosureParcelas.onOpen();
      }
    };
    fetchPosts();
  }, [user]);

  return !loading && parcelas.length > 0 ? (
    <>
      <ChildHeader />
      <VStack w={'100%'}>
      
        <ListaParcelas parcelas={parcelas_assinatura ? parcelas_assinatura : parcelas} status={status} motivo={motivo}/>
      </VStack>
      <BottomNavBar />
    </>
  ) : !loading && parcelas.length === 0 ? (
    <>
      <ChildHeader />
      <PagamentoLocalizado disclosure={disclosureParcelas} />
    </>
  ) : (
    <>
      <ChildHeader />
      <Loading />
    </>
  );
}

export default PagamentoParcelas;
