import React, { useEffect } from 'react';
import {
  VStack,
  Box,
  Text,
  Stack,
  Flex,
  Button,
  Input,
  TabList,
  useToast,
  HStack,
  Tabs,
  TabPanels,
  Link,
  Tab,
  Center,
  useClipboard,
  TabPanel,
  Badge,
  Image,
  Heading,
  WrapItem,
  Spacer,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { CopyIcon, CheckIcon, ExternalLinkIcon } from '@chakra-ui/icons';

import BottomNavBar from '../../../../BottomNavBar';
import MainChildHeader from '../../MainChildHeader';
import { AccountContext } from '../../../../AccountContext';
import CardEmpreendedor from './CardEmpreendedor';
import CardAmigos from './CardAmigos';
import CardYoutube from './CardYoutube';
import CardInstagram from './CardInstagram';
import { useContext } from 'react';
import { useState } from 'react';
import { AiFillFilePdf } from 'react-icons/ai';
import ChildHeader from '../../ChildHeader';
import Whatsapp from '../../../../../images/whatsapp.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon, FacebookMessengerIcon, TelegramIcon, InstapaperIcon } from 'react-share';
import { MdOutlineIosShare } from 'react-icons/md';

function Main() {
  const { setPage, user } = useContext(AccountContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const shareDrawer = useDisclosure();
  const [onDrawer, setOndrawer] = useState(false);
  const [copy, setCopy] = useState(false);

  setPage('Material de Apoio');

  console.log(navigator);

  const objeto = {
    title: 'DeoVita Saúde',
    text: `'👋 Olá, você já conhece o Deovita? 🤔
    Um APLICATIVO DE SAÚDE 📱 que está revolucionando a saúde do Brasil!  🥳
    
    Assinantes tem direito:
    - Clínico Geral médico 👨‍⚕️ Telemedicina 24h  GRÁTIS E ILIMITADO 
    - Especialista Telemedicina agendado em até 15 dias! GRÁTIS E ILIMITADO
        🧑‍⚕️Cardiologia
        🧑‍⚕️Dermatologia
        🧑‍⚕️Endocrinologia
        🧑‍⚕️Geriatria
        🧑‍⚕️Ginecologia
        🧑‍⚕️Neurologia
        🧑‍⚕️Pediatria
        🧑‍⚕️Psiquiatria
        🧑‍⚕️Traumatologia
        🧑‍⚕️Otorrinolaringologia
        🧑‍⚕️Urologia
        🧑‍⚕️Nutrição
    - Consultas presenciais com todas especiliadidades a partir de R$ 25,00
    - Até 80% de DESCONTOS em Exames: 
        🧪 Laboratoriais 
        🧪 Ultrassom 
        🧪Tomografia 
        🧪 Raio X
        🧪 Ressonância 
        🧪 Muitos outros…
    
    - Até 50% de DESCONTOS em Odontológia 🦷
    - Desconto  em inúmeras empresas parceiras, como gás de cozinha e super mercados.  🧑‍🍳
    - Desconto nos seus medicamentos nas farmácias Pague Menos. 
    - Até 10 dependentes👨‍👩‍👧‍👦
    - Uso imediato 
    
    Gostou? Pois ainda tem mais! Sabe seu animalzinho de estimação? Temos telemedicina veterinária grátis para assinantes!! 
    
    *Assine agora!!!! *😍
    
    Plano individual ( vc + 1 PET):  R$ 29,90 
    Plano familiar ( 5 pessoas + 1 PET) R$ 39,90
    
    O que está esperando!? Baixe o app e Assine agora mesmo o DeoVita! 
    📱 IOS:  https://apps.apple.com/app/id1545985928
    📱 Android:  https://play.google.com/store/apps/details?id=com.deovita.app',`,

    url: 'https://apps.apple.com/app/id1545985928',
  };

  const [value, setValue] = useState(objeto.text);
  const { hasCopied, onCopy } = useClipboard(objeto.text);
  const toast = useToast();

  function shareContent(objeto) {
    if (navigator.share) {
      navigator
        .share(objeto)
        .then(() => {
          console.log('Compartilhado com sucesso!');
        })
        .catch(console.error);
    } else {
      window.alert('Não Suportado');
      setOndrawer(true);
    }
  }

  function Copiar() {
    return (
      <>
        <CopyIcon
          color="white"
          pt="0.9em"
          mt="0.1em"
          boxSize={9}
          onClick={() => {
            onCopy();
            return toast({
              position: 'bottom-left',
              render: () => (
                <Box color="white" borderRadius={10} mb="5" p={4} bg="green.500">
                  Copiado! <CheckIcon />
                </Box>
              ),
            });
          }}
        >
          {hasCopied ? <CheckIcon /> : <CheckIcon />}
        </CopyIcon>
      </>
    );
  }

  const ShareAndroid = () => {
    const btnRef = React.useRef();

    return (
      <>
        <Drawer isOpen={shareDrawer.isOpen} placement="bottom" onClose={shareDrawer.onClose} borderRadius={20}>
          <DrawerOverlay />
          <DrawerContent borderTopRadius={20}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center">Compartilhar</DrawerHeader>

            <DrawerBody w="100%" alignContent="center">
              <Center>
                <HStack align="center">
                  <WhatsappShareButton url={objeto.text}>
                    <WhatsappIcon size={42} round />
                  </WhatsappShareButton>
                  <FacebookMessengerShareButton url={objeto.text}>
                    <FacebookMessengerIcon size={42} round />
                  </FacebookMessengerShareButton>
                  <TwitterShareButton url={objeto.text}>
                    <TwitterIcon size={42} round />
                  </TwitterShareButton>

                  <TelegramShareButton url={objeto.text}>
                    <TelegramIcon size={42} round />
                  </TelegramShareButton>
                </HStack>
              </Center>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    );
  };

  return (
    <>
      <ChildHeader />
      <ShareAndroid />
      <VStack w={{ base: '100%' }} m="auto" h="90vh" spacing="2rem" marginTop="2">
        <Heading mt="5" textColor="#5AB2AD" textAlign="center" fontSize="2em" paddingTop={10}>
          SEJA UM REVENDEDOR DEOVITA DE SUCESSO.
        </Heading>
        <Text color="#616161" w="90%">
          Baixe o material de apoio e entenda tudo que você precisa para realizar as melhores vendas.
        </Text>
        <Center w="90%">
          <ButtonGroup ml="1.5em" w="90%">
            {' '}
            <Button
              w="90%"
              colorScheme="teal"
              textColor="white"
              onClick={() => window.open('https://deovita.com.br/wp-content/uploads/2024/06/B2C3.pdf', '_blank')}
            >
              Baixar o Material
            </Button>
          </ButtonGroup>
        </Center>

        <Center mb="25%">
          <HStack mt="10%" spacing="4%">
            <Box>
              <CardInstagram />
            </Box>
            <Box>
              <CardYoutube />
            </Box>
          </HStack>
        </Center>
        <VStack pt="2%" pb="25%">
          <Image src={Whatsapp} />
          <HStack w="100%" pt="2%">
            <Button
              w="100%"
              borderRadius="2em"
              bgColor="#57AB7A"
              color="white"
              onClick={() => {
                if (user.device && user.device === 'ios') {
                  shareContent(objeto);
                } else {
                  shareDrawer.onOpen();
                }
              }}
            >
              <MdOutlineIosShare />
              Compartilhar
            </Button>
            <Button
              w="50%"
              borderRadius="2em"
              bgColor="#57AB7A"
              color="white"
              onClick={() => {
                onCopy();
                return toast({
                  position: 'bottom-left',
                  render: () => (
                    <Box color="white" borderRadius={10} mb="5" p={4} bg="green.500">
                      Copiado! <CheckIcon />
                    </Box>
                  ),
                });
              }}
            >
              <CopyIcon />
              Copiar
            </Button>
          </HStack>
        </VStack>

        <BottomNavBar />
      </VStack>
    </>
  );
}

export default Main;
