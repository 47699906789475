import { Button, chakra, VStack, ButtonGroup, Container, Box, Text, Radio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import _ from 'lodash';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const REACT_APP_DEOVITA_RECIPIENT_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_DEOVITA_RECIPIENT_ID_PROD
    : process.env.REACT_APP_DEOVITA_RECIPIENT_ID_TEST;

function ConferePedido() {
  const navigate = useNavigate();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { user, setUser, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const { id_contrato, titular } = user;
  const [splitRulesData, setSplitRulesData] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [preparos, setPreparos] = useState([]);
  setPage('Pedido');
  console.log(state)
  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
        ? '#EB8B2A'
        : titular === true && id_contrato > 0
          ? '#EB8B2A'
          : '#529C94';

  // useEffect para pegar dados de split
  useEffect(() => {
    let split_rules_info = {
      procedimento_tuss_id: state.item.map((e) => e.procedimento_tuss_id),
      medico_selecionado: state.item.filter((objeto) => objeto.medico_selecionado !== null).map((objeto) => objeto.medico_selecionado)[0],
      id_empresa_recebe: state.empresa.empresa_id,
      convenio: state.convenio,
    };
    setSplitRulesData(split_rules_info);

    let currData = getMinorDateFromItemCart(state.item)
    const [ano, mes, dia] = currData.split("-").map(Number);
    const data = new Date(ano, mes - 1, dia); // O mês é indexado em zero (janeiro é 0), então subtraímos 1
    setCurrentDate(data);
    let procedimentos = []


    user.cart.itens.forEach((procedimento) => {
      procedimentos.push(procedimento.procedimento_tuss_id);
    });

    const payload = {
      procedimentos: procedimentos,
    }

    async function getPreparos() {
      const url = `${ENDPOINT}/schedule/searchHierar`;
      const response = await fetch(url, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();
      setPreparos(postsData?.data);
    }
    getPreparos()

  }, []);

  const confirmEstimate = (id) => {

    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/confirm`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(user.solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
        }
      });
    }
  };

  const useItem = state.item.map((procedimento) => {
    return (
      <>
        <chakra.p>{procedimento.nome}</chakra.p>
      </>
    );
  });

  const useListProcedures = user.cart.itens.map((procedimento) => {
    return (
      <>
        <Text mt="1%" mb="1%" fontWeight="bold">
          {procedimento.nome}:
        </Text>
        <Text mt="1%" mb="1%">
          - Sem informações.
        </Text>
      </>
    );
  });

  const procedimentosUnicos = Array.from(new Set(preparos.map(procedure => procedure.descricao)));

  const descriccoes = new Map();

  procedimentosUnicos.forEach((descricao, index) => {
    const preparosParaDescricao = preparos.filter(proc => proc.descricao === descricao);
    const firstProcedure = preparosParaDescricao[0];
    descriccoes.set(descricao, firstProcedure);
  });


  const useListProceduresHierar = preparos.map((procedure, index) => {
    const descricao_maiscula = procedure.descricao_pai.charAt(0).toUpperCase() + procedure.descricao_pai.slice(1);
    return (
      <div key={index}>
        <chakra.ul>
          <Text mt={'0.5em'}>{descricao_maiscula + ' ' + procedure.descricao}</Text>
        </chakra.ul>
      </div>
    );
  });


  const firstDate = new Date(new Date(state?.item[0]?.dia_selecionado).setHours(new Date(state?.item[0]?.dia_selecionado).getHours() + 3));
  const procedimentos_pagos = state.item.map((procedimento) => procedimento?.tuss_id);

  const usePreparo = user.cart.itens
    .filter((procedimento) => procedimento && procedimento.tuss_id && procedimentos_pagos.includes(procedimento?.tuss_id))
    .map((procedimento) => (
      <>
        <Text mt="1%" mb="1%" fontWeight="bold">
          {procedimento?.nome}:
        </Text>
        {procedimento && procedimento?.preparo_descricao ?
          <Text mt="1%" mb="1%" fontWeight="bold">{procedimento?.preparo_descricao}</Text> :
          <>
            <Text mt="1%" mb="1%">
              - Sem informações.
            </Text>
          </>}
      </>
    ));

  function getMinorDateFromItemCart(array) {
    if (array.length === 0) return null;
    return array.reduce((menorData, item) => {
      const dataAtual = new Date(item.dia_selecionado);
      const dataMenor = menorData ? new Date(menorData) : null;
      if (!dataMenor || dataAtual < dataMenor) {
        return item.dia_selecionado;
      } else {
        return menorData;
      }
    }, null);
  }


  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack
        w={{
          base: '90%',
          md: '500px',
        }}
        m="auto"
        alignItems="left"
        spacing={1}
        marginBottom="10%"
      >
        <chakra.h1 fontWeight="bold" margin="5% 0" fontSize="xl">
          Informações do Pedido:
        </chakra.h1>
        <chakra.p fontWeight="bold"></chakra.p>
        <chakra.p color="gray.500">Paciente:</chakra.p>
        <chakra.p fontWeight="bold">{state.paciente.nome.toUpperCase()}</chakra.p>
        <chakra.p color="gray.500">Procedimentos:</chakra.p>
        <chakra.p fontWeight="bold">{useItem}</chakra.p>
        <chakra.p color="gray.500">Data:</chakra.p>
        <chakra.p fontWeight="bold">
          {currentDate && currentDate.toLocaleDateString('pt-br', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}{' '}
          {state.isByOrder ? <></> : state.horario ? ' às ' + state.horario : ''}

        </chakra.p>
        {state.isByOrder ? <chakra.p fontWeight="bold">(atendimento por ordem de chegada)</chakra.p> : <></>}
        <chakra.p color="gray.500">Local:</chakra.p>
        <chakra.p fontWeight="bold">{state.empresa.empresa_nome}</chakra.p>
        <chakra.p color="black.500" fontSize="xs">
          {' '}
          {state.empresa.endereco}
        </chakra.p>

        {(user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
          (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0') ? (
          <>
            <chakra.p color="gray.500">Valor particular:</chakra.p>
            <chakra.p fontWeight="bold">
              <chakra.del>{(state.valor + state.economia).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.del>
            </chakra.p>
            <chakra.p color="gray.500">Valor Deovita:</chakra.p>
            <chakra.p fontWeight="bold" color={backgroundColor}>
              {state.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </chakra.p>
            <chakra.p fontWeight="bold" color={backgroundColor}>
              Economia de {state.economia.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} reais neste agendamento.
            </chakra.p>
          </>
        ) : (
          <>
            <chakra.p color="gray.500">Valor:</chakra.p>
            <chakra.p fontWeight="bold">{state.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</chakra.p>
          </>
        )}
        <Container maxW="2xl" leftContent>
          <Box padding="1" borderRadius="md" borderWidth="1px" margin="5% 0" bcolor="black" maxW="sm">
            <chakra.p color="black">Orientações:</chakra.p>
            <chakra.p color="red.500" margin="5% 0" fontSize="sm">
              {preparos && preparos?.length > 0 ? useListProceduresHierar : '- Sem informações.'}
            </chakra.p>
          </Box>
        </Container>

        <ButtonGroup pt="1rem" pb="15%" width="100%">
          <Button
            backgroundColor={backgroundColor}
            color="#FFF"
            width="100%"
            onClick={() => {
              confirmEstimate(state.orcamento_id);
              if (!state.valor || state.valor === 0) {
                setUser((prevState) => ({ ...prevState, cart_temp: { itens: state.item } }));
                navigate('/exames/loading/', {
                  state: {
                    ...state,
                    valor: 0,
                    item: state.item,
                    paciente: state.paciente,
                    empresa: state.empresa,
                    horario: state.horario,
                    convenio: state.convenio,
                    data: currentDate,
                    confim: true,
                    transaction_id: 9999999,
                    isByOrder: state.isByOrder,
                  },
                });
              } else {
                setUser((prevState) => ({ ...prevState, cart_temp: { itens: state.item } }));
                console.log('data', {
                  ...state,
                  parcelsActive: true,
                  valor: state.valor,
                  expiration_pix_in_min: 5,
                  service: 'schedule_procedure',
                  navig_to: '/alerts/sucesso/agendamento-exame/',
                  split: splitRulesData,
                  item: {
                    valor: state.valor,
                    cpf: onlyNumbers(state.paciente.cpf),
                    nome: state.paciente.nome,
                    fone: state.paciente.celular,
                    email: state.paciente.email,
                    horario: state.horario,
                    empresa: state.empresa,
                    convenio: state.convenio,
                    data: state.data,
                    cart: state.item,
                    confim: true,
                    isByOrder: state.isByOrder,
                    solicitacao: state.solicitacao,
                    orcamentoId: state.orcamento_id,
                  },
                });
                navigate('/pagamentos', {
                  state: {
                    ...state,
                    parcelsActive: true,
                    valor: state.valor,
                    expiration_pix_in_min: 5,
                    service: 'schedule_procedure',
                    navig_to: '/alerts/sucesso/agendamento-exame/',
                    split: splitRulesData,
                    item: {
                      valor: state.valor,
                      cpf: onlyNumbers(state.paciente.cpf),
                      nome: state.paciente.nome,
                      fone: state.paciente.celular,
                      email: state.paciente.email,
                      horario: state.horario,
                      empresa: state.empresa,
                      convenio: state.convenio,
                      data: currentDate,
                      cart: state.item,
                      confim: true,
                      isByOrder: state.isByOrder,
                      solicitacao: state.solicitacao,
                      orcamentoId: state.orcamento_id,
                      id_recipient_solicitante: state.id_recipient_solicitante,
                      id_empresa_solicitante: state.id_empresa_solicitante,
                    },
                  },
                });
              }
            }}
          >
            Ir para o pagamento
          </Button>
        </ButtonGroup>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default ConferePedido;
