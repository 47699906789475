import { Search2Icon } from "@chakra-ui/icons";
import {
	Input,
	InputGroup,
	InputRightElement,
	HStack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { SearchContext } from "./SearchContext";
import { AccountContext } from "components/AccountContext";

function Searchbox() {
	const { fetchSearch, lastSearch } = useContext(SearchContext);
	const [textSearch, setTextSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const { user } = useContext(AccountContext);
  	const {id_contrato} = user;

	const search = async () => {
		setLoading(true);
		if (textSearch !== lastSearch) fetchSearch(textSearch, 1, 10) // text, page, limit
		setLoading(false);
	};

	return (
		<HStack>
			<InputGroup>
				<Input
					placeholder="Buscar Profissionais"
					focusBorderColor="teal"
					bg={'#F0F0F0'}
					// shadow="lg"
					borderRadius="30px"
					value={textSearch}
					onChange={(event) => setTextSearch(event.target.value)}
					onKeyPress={(event) => {
						if (event.key === "Enter") {
							search();
						}
					}}
					
				/>

				<InputRightElement width="15%">
					
					<Search2Icon 
						color={id_contrato ? "#EB8B2A" : "#529C94"}
						onClick={search}
					/>
				</InputRightElement>
			</InputGroup>
		</HStack>
	);
}

export default Searchbox;
