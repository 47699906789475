import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Divider,
  ModalHeader,
  ModalFooter,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function ModalLogin() {
  function BasicUsage() {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const navigate = useNavigate();
    return (
      <>
        <Modal isOpen={isOpen} isCentered onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW="60%" borderRadius="2EM">
            <ModalCloseButton />
            <ModalBody>
              <Center>
                <Heading mt="5" size="md">
                  Bem Vindo!{' '}
                </Heading>
              </Center>
            </ModalBody>
            <Center>
              <Stack direction="column" spacing={4} align="center">
                <Button
                  id='id_ml_cadastro'
                  bgColor="#FF9A00"
                  mt="5%"
                  _hover="none"
                  color="white"
                  w="88%"
                  size="lg"
                  borderRadius="0.6em"
                  autofocus="true"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Já sou cadastrado
                </Button>
                <Button
                id='id_ml_cadastrar'
                  bgColor="#5AB2AD"
                  color="white"
                  _hover="none"
                  w="88%"
                  size="lg"
                  borderRadius="0.6em"
                  onClick={() => {
                    navigate('/cadastro');
                  }}
                >
                  Quero me cadastrar
                </Button>
              </Stack>
            </Center>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  return (
    <>
      <BasicUsage />
    </>
  );
}

export default ModalLogin;
