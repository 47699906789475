import React from 'react';
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  Radio,
  RadioGroup,
  useDisclosure,
  VStack,
  chakra,
  Button,
} from '@chakra-ui/react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { useField } from 'formik';
import { ChevronDownIcon } from '@chakra-ui/icons';

const SelectField = ({ label, ...props }) => {

  const { options, placeholder, onChange } = props;
  const [field, meta, helpers] = useField(props);
  const { value } = meta;

  const { setValue } = helpers;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const colorErro = meta.error ? { colorScheme: 'red' } : {};
  let textButton;
  if (typeof value === 'number') {
    if (typeof options[value] === 'string') {
      textButton = options[value].substring(0, 25) + (options[value].length > 25 ? '...' : '');
    } else {
      textButton = '';
    }
  } else if (placeholder) {
    textButton = placeholder;
  } else {
    textButton = 'Selecionar';
  }

  //resolver aviso de erro lançado quando clicar no select

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const truncatedText = truncateText(textButton, 20);

  return (
    <>
      <FormControl isInvalid={meta.error}>
        <FormLabel>{label}</FormLabel>
        <Menu>
          <Center>
            <MenuButton as={Button} onClick={onOpen} rightIcon={<ChevronDownIcon />} {...field} {...props} {...colorErro}>
              {truncatedText}
            </MenuButton>
          </Center>
        </Menu>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
      <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} mb="-4" width="90" maxH="50vh">
          <DrawerHeader mt="5">Selecione</DrawerHeader>
          <DrawerBody>
            <RadioGroup
              name="select"
              w="100%"
              value={value}
              pb={5}
              onChange={(e) => {
                setValue(parseInt(e));
                onChange(e);
                onClose();
              }}
            >
              <VStack align="start" w="100" spacing={1}>
                {options.map((option, index) => {
                  return (
                    <Radio id={index} value={index} padding="1em">
                      <chakra.p fontWeight="bold" fontSize="sm">
                        {option}
                      </chakra.p>
                    </Radio>
                  );
                })}
              </VStack>
            </RadioGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SelectField;
