import { useState, useEffect } from 'react';

const useApi = (base_url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (url, method = 'GET', body = null) => {
    setLoading(true);

    try {
      const response = await fetch(`${base_url}/${url}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          // Adicione headers adicionais, se necessário
        },
        body: body ? JSON.stringify(body) : null,
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Erro na requisição');
      }

      setData(result);
      setError(null);
    } catch (error) {
      setError(error.message || 'Erro na requisição');
    } finally {
      setLoading(false);
    }
  };

  
  const get = async (url) => {
    await fetchData(url, 'GET');
    return { data, error };
  };

  const post = async (url, body) => {
    await fetchData(url, 'POST', body);
    return { data, error };
  };

  const put = async (url, body) => {
    await fetchData(url, 'PUT', body);
    return { data, error };
  };

  const del = async (url) => {
    await fetchData(url, 'DELETE');
    return { data, error };
  };

  useEffect(() => {
    // Exemplo: Carregar dados iniciais ao montar o componente
    // get(''); // Substitua pela rota desejada
  }, []);

  return {
    loading,
    get,
    post,
    put,
    del,
  }
};

export default useApi;
