import { Box, Button, ButtonGroup, HStack, Img, Input, Radio, RadioGroup, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useState, useEffect, useContext } from 'react';
import GatoImg from '../../../../images/assets/telemedicina/gato.png';
import CachorroImg from '../../../../images/assets/telemedicina/cachorro.png';
import SelectField from 'components/SelectField';
import SelectFieldSearch from 'components/SelectFieldSearch';
import { Form, Formik } from 'formik';
import TextField from 'components/TextField';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function CadastroPet() {
  const { user, setPage } = useContext(AccountContext);
  const state = useLocation()
  const [animal, setAnimal] = useState(null);
  const [races, setRaces] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const racesMap = Object.keys(races).map((key) => [races[key].nome]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const initialValues = {
    tipo: '',
    nome: '',
    data_aniv: '',
    sexo: '',
    petRace: '',
  };

  const validationSchema = Yup.object({
    nome: Yup.string().required('Nome é obrigatório'),
    data_aniv: Yup.date()
      .max(new Date(), 'Futuro não é permitido')
      .transform((value, originalValue) => {
        return parse(originalValue, 'dd/MM/yyyy', new Date());
      })
      .required('Data de nascimento é obrigatória!')
      .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),
    sexo: Yup.string().required('Sexo é obrigatório').label('sexo'),
    petRace: Yup.number().required('Raça é obrigatório'),
  });

  function callToast(title, descricao, status) {
    toast({
      title: title,
      description: descricao,
      status: status ? status : 'warning',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function handleSubmit(values) {
    const vals = {
      tipo: animal,
      nome: values.nome.toUpperCase(),
      nascimento: values.data_aniv.replace(/([0-9]{2})[/]([0-9]{2})[/]([0-9]{4})/g, '$3-$2-$1'),
      sexo: values.sexo === 0 ? 'M' : 'F',
      petRace: races[values.petRace].especie_id,
      contrato_id: user.id_contrato,
    };
    console.log(typeof vals.tipo);
    console.log(typeof vals.nome);
    console.log(typeof vals.data_aniv);
    console.log(typeof vals.sexo);
    console.log(typeof vals.petRace);
    setLoading(true);
    fetch(`${ENDPOINT}/pet/add_pet`, {
      method: 'POST',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError('Erro na Rede');
          return res.json();
        }
        if (res.status === 200) {
          setError('');
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.success === false) {
          if (data.data.error === 'cliente já existe') {
            setLoading(false);
            setError('Falha no cadastro do Dependente. Cliente já existe');
          } else {
            setLoading(false);
            setError('Falha no Cadastro do Dependente');
          }
          return;
        }
        if (data.success === true) {
          setError('');
          navigate('/perfil/assinaturas/sucesso-cadastro-pet')
          //callToast('Sucesso', 'Sucesso ao cadastrar o pet', 'success')
        }
      });
  }
  setPage('Cadastro PET');
  useEffect(() => {
    if (animal) {
      fetch(`${ENDPOINT}/pet/races/${animal}`, {
        method: 'GET',
        credentials: 'include',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      })
        .catch((error) => {
          console.log(error);
        })
        .then((res) => {
          if (!res || !res.ok || res.status >= 400) {
            return;
          }
          return res.json();
        })
        .then((data) => {
          setRaces(data);
          console.log(races);
        });
    }
  }, [animal]);
  const FormularioCadastro = ({ formikProps }) => {
    const { setFieldValue, values } = formikProps;
    return (
      <>
        <Box align="center" marginTop="5%" w="100%" color="#585858">
          <Text textAlign="center">Selecione a opção que</Text>
          <Text textAlign="center">corresponde ao seu pet.</Text>
        </Box>

        <VStack
          as={Form}
          w={{ base: '90%', md: '500px' }}
          m="auto"
          justify="top"
          marginTop="5%"
          spacing="0.3rem"
          align="start"
          ml="10%"
          paddingBottom="30%"
          color="#585858"
        >
          <RadioGroup name="tipo" onChange={setAnimal} value={animal}>
            <Stack direction="column">
              <HStack>
                <Radio value="2">Cão</Radio>
                <Img src={CachorroImg} />
              </HStack>
              <HStack>
                <Radio value="1">Gato</Radio>
                <Img src={GatoImg} w="20%" />
              </HStack>
            </Stack>
          </RadioGroup>
          <VStack w="100%" pt="3%" align="start">
            <Text>Escreva abaixo o nome do seu pet</Text>
            <TextField name="nome" type="text" w="90%" autocomplete="off" />
            <Text>Data de Nascimento</Text>
            <TextField name="data_aniv" type="tel" placeholder="Nascimento" mask="99/99/9999" maskChar={null} w="90%" />
            <SelectField
              name="sexo"
              placeholder="Sexo"
              w="90%"
              ml="-10%"
              variant="outline"
              fontWeight="normal"
              onChange={(e) => setFieldValue(e)}
              options={['Macho', 'Fêmea']}
            />
            <SelectFieldSearch
              name="petRace"
              placeholder={values.petRace ? races[values.petRace].nome : 'raça'}
              w="90%"
              ml="-10%"
              variant="outline"
              fontWeight="normal"
              onChange={(e) => {
                setFieldValue('petRace', e);
              }}
              options={racesMap}
            />
          </VStack>
          <ButtonGroup w="100%" pt="10%" pb="25%">
            <Button w="90%" type="submit" colorScheme="teal" borderRadius="20px" isLoading={loading} loadingText="Carregando">
              Enviar
            </Button>
          </ButtonGroup>
        </VStack>
      </>
    );
  };

  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(props) => <FormularioCadastro formikProps={props} />}
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default CadastroPet;
