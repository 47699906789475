import BottomNavBar from '../../../BottomNavBar';
import { useEffect, useState } from 'react';
import MainChildHeader from '../MainChildHeader';
import { AccountContext } from '../../../AccountContext';
import { useContext } from 'react';
import SelectField from 'components/SelectField';
import {
  chakra,
  VStack,
  Flex,
  Text,
  Box,
  Button,
  Img,
  Stack,
  toast,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  HStack,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Carrinhoimg from '../../../../images/carrinho.svg';
import _ from 'lodash';
import Loading from 'components/Loading';
import requestTimeout from 'components/Utils/Functions/requestTimeout';
import { Form, Formik } from 'formik';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Main() {
  const navigate = useNavigate();
  const { user, setPage, setUser } = useContext(AccountContext);
  setPage('Carrinho de Compras');
  const [updated, setUpdated] = useState(false);
  const [isShareButtonLoading, setIsShareButtonLoading] = useState(false);
  const toast = useToast();
  const [historico, setHistorico] = useState([]);
  const [solicitacoes_consulta, setSolicitacoesConsultas] = useState(user.solicitacoes_consulta);
  const [solicitacoes, setSolicitacoesDep] = useState(user.solicitacoes);
  const [cart, setCartDep] = useState(user.cart);
  const [cpfUser, setCpfUser] = useState(user.username);
  const [userName, setUserName] = useState(user.name);
  const [dependentes, setDependentes] = useState([]);
  const { itens, valor } = cart || {};
  const [currentIndex, setCurrentIndex] = useState();

  const refuseEstimate = (id) => {
    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/refuse`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
          navigate('/alerts/sucesso/sucesso_solicitacoes');
        }
      });
    }
  };

  const refuseHistorico = (id, cpfUser) => {
    if (id > 0) {
      if (id > 0) {
        const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/refuse`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((res) => {
          if (res.status === 200) {
            getOrcamentosNaoRecusados(cpfUser);
          }
          navigate('/alerts/sucesso/sucesso_solicitacoes');
        });
      }
    }
  };

  const refuseEstimateSolicitacao = (id, cpf) => {
    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/refuse`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(solicitacoes_consulta, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes_consulta: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes_consulta: { ...newCartItems } }));
          }
          getOrcamentosNaoRecusados(cpfUser);
          navigate('/alerts/sucesso/sucesso_solicitacoes');
        }
      });
    }
  };

  const confirmEstimate = (id) => {
    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/confirm`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
        }
      });
    }
  };
  const updateCart = async () => {
    await fetch(`${ENDPOINT}/cart/updateCart/${cpfUser}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if(user.titular){
          setUser((prevState) => ({
            ...prevState,
            carrinho: { ...data.cart },
            solicitacoes: { ...data.solicitacoes },
            solicitacoes_consulta: { ...data.solicitacoes_consulta },
          }));
        }
        getCartDependentes(cpfUser)
        setUpdated(true);
      });
  };

  async function requestPdf(content) {
    setIsShareButtonLoading(true);

    const payload = {
      content: content,
      user: userName,
    };


    const response = await fetch(`${ENDPOINT}/pdf/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      //signal: requestTimeout(25000).signal
    });

    const data = await response.json();

    if (response.status !== 404) {
      window.open(data.fileUrl, '_blank');
    }
    setIsShareButtonLoading(false);
  }

  const getOrcamentosNaoRecusados = async (cpf) => {
    const url_ = ENDPOINT + '/clients/fetchOrcamentosRecusados';
    const response = await fetch(url_, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ cpf: cpf }),
    });
    const resp = await response.json();
    setHistorico(resp);
  };

  const fetchSolicitacoesConsultasDependentes = async (cpf) => {
    const url_ = ENDPOINT + '/clients/fetchSolicitacoesConsultasDependentes';
    const response = await fetch(url_, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ cpf: cpf }),
    });
    const resp = await response.json();
    setSolicitacoesConsultas(resp);
  };

  const getSolicitacoesDependentes = async (cpf) => {
    const url_ = ENDPOINT + '/clients/fetchSolicitacoesDependentes';
    const response = await fetch(url_, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ cpf: cpf }),
    });
    const resp = await response.json();
    setSolicitacoesDep(resp);
  };

  const getCartDependentes = async (cpf) => {
    const url_ = ENDPOINT + '/clients/getCartDependentes';
    const response = await fetch(url_, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ cpf: cpf }),
    });
    const resp = await response.json();
    setCartDep(resp);
  };

  const fetchDependentes = async () => {
    const url = ENDPOINT + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    setDependentes(resp.data);
  };

  const atualizarCarrinho = async (cpf) => { 
    const payload = {
      cart: null,
      cpf: cpf,
    };

    const response = await fetch(`${ENDPOINT}/cart/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    if(data.message === 'cart operation received'){
      getCartDependentes(cpfUser)
    }
  }

  useEffect(() => {
    updateCart();
    getOrcamentosNaoRecusados(cpfUser);
    fetchDependentes();
    fetchSolicitacoesConsultasDependentes(cpfUser)
  }, []);

  const useProcedimentos = cart?.itens?.map((item, index) => {
    return (
      <>
        <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
          <Flex mt="1em">
            <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
              {item.nome}
            </Text>
            <DeleteIcon
              color="#EB8B2A"
              onClick={() => {
                const updatedItens = cart.itens.filter((cartItem) => cartItem !== item);
                if (updatedItens.length === 0) {
                  updateCart()
                  setUser((prevState) => ({ ...prevState, cart: null }));
                } else {
                  updateCart()
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, itens: updatedItens } }));
                }
              }}
            />
          </Flex>
        </chakra.tr>
      </>
    );
  });

  function getFormattedDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Mês começa do zero, por isso soma-se 1
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function generateHtmlText(names) {
    const htmlHeader = `<tr><td class="Dadospaciente bordasimples" colspan="2"><span>Deovita</span></td><td class="Dadospaciente bordasimples" colspan="2"><span></span></td></tr><tr><td class="Dadospaciente" colspan="2"><span>Av. Santos Dumont 2811 - Aldeota</span></td><td class="Dadospaciente" colspan="2"><span><b class="backgroundsilver">Emissão: ${getFormattedDate()}</b></span></td></tr><tr><td class="Dadospaciente" colspan="2"><span>Fone: 8599 107 8367 / 85 991078367</span></td></tr>`;
    const htmlDadosPaciente = `<tr><td class="Dadospaciente bordasimples" style="padding: 0;" colspan="2"><span><b class="backgroundsilver">Paciente: ${userName}</b></span></td><td class="Dadospaciente bordasimples" colspan="2"><span></span></td></tr><br>`;
    const htmlTableHeader = `<thead><tr><td class="negrito tamanhopadrao headtable" colspan="2">Descrição dos Serviços</td><td class="negrito tamanhopadrao headtable" colspan="2">Qtde</td></tr></thead><tbody>`;
    let htmlTableData = null;
    htmlTableData = names
      .map((item) => `<tr><td class="tamanhopadrao" colspan="2">${item}</td><td class="tamanhopadrao" colspan="2">1</td></tr>`)
      .join('');
    const htmlFooter = `</tbody>`;
    return htmlHeader + htmlDadosPaciente + htmlTableHeader + htmlTableData + htmlFooter;
  }

  const useSolicitacoesConsultas = Object.keys(solicitacoes_consulta)?.map((index) => {
    const useProcedimentos = solicitacoes_consulta[index].itens?.map((item, index) => {
      return (
        <>
          <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
            <Flex mt="1em">
              <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
                {item.nome}
              </Text>
            </Flex>
          </chakra.tr>
        </>
      );
    });

    return solicitacoes_consulta[index] ? (
      <>
        <Box
          w="95%"
          mx="auto"
          alignItems="center"
          overflow="hidden"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="10px"
          padding="1em"
          marginTop={5}
          px={5}
          pt={5}
        >
          {console.log('solicitacoes_consulta', user?.solicitacoes_consulta)}
          <Text fontSize="0.9em" mb="2" fontWeight="bold" color="#000" w="100%" textAlign="center">
            Solicitaçao de Consulta
          </Text>
          <Flex w="100%">
            <Stack fontSize="sm" w="100%">
              <Text fontSize="0.7em" color="#585858">
                solicitante:
              </Text>
              <Text fontSize="0.8em" fontWeight="bold">
                {solicitacoes_consulta[index].solicitante}
              </Text>
            </Stack>
            <Stack fontSize="sm">
              <Text fontSize="0.7em" color="#585858">
                Data da Solicitação:
              </Text>
              <Text fontSize="0.8em" fontWeight="bold">
                {new Date(solicitacoes_consulta[index].data_cadastro)?.toLocaleDateString('pt-br', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Text>
            </Stack>
          </Flex>
          <chakra.table w="100%">{useProcedimentos}</chakra.table>

          {/* Fechar pedido */}
          <Button
            mt="6%"
            width="100%"
            colorScheme="teal"
            onClick={() => {
              const { metadata: medico } = solicitacoes_consulta[index];
              if (!medico.horarios[0].procedimentos) {
                navigate('/atendimento/agendamento', {
                  state: {
                    medico,
                    startNum: 0,
                    st: 0,
                    defaultDateTime: null,
                    procedimentoId: medico.horarios[0].ACTIVITY_LID,
                    procedimento: medico.horarios[0].ACTIVITY_NAME,
                    price: medico.horarios[0].PRICE,
                    orcamento_id: user?.solicitacoes_consulta[index].ambulatorio_orcamento_id,
                  },
                });
              } else {
                if (medico.horarios[0].procedimentos.length === 1) {
                  navigate('/atendimento/agendamento', {
                    state: {
                      medico,
                      startNum: 0,
                      st: 0,
                      defaultDateTime: null,
                      procedimentoId: medico.horarios[0].procedimentos[0].id,
                      procedimento: medico.horarios[0].procedimentos[0].nome,
                      price: medico.horarios[0].procedimentos[0].valor,
                      procedimentoParticular:
                        medico.horarios &&
                        medico.horarios.length > 0 &&
                        medico.horarios[0].procedimentos &&
                        medico.horarios[0].procedimentos.length > 0 &&
                        medico.horarios[0].procedimentos[0].procedimento_particular &&
                        medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                          ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_id
                          : null,
                      valorParticular:
                        medico.horarios &&
                        medico.horarios.length > 0 &&
                        medico.horarios[0].procedimentos &&
                        medico.horarios[0].procedimentos.length > 0 &&
                        medico.horarios[0].procedimentos[0].procedimento_particular &&
                        medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                          ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_valor
                          : null,
                      orcamento_id: solicitacoes_consulta[index].ambulatorio_orcamento_id,
                    },
                  });
                } else {
                  navigate('/atendimento/escolha-procedimento', {
                    state: {
                      medico,
                      startNum: 0,
                      st: 0,
                      defaultDateTime: null,
                      orcamento_id: solicitacoes_consulta[index].ambulatorio_orcamento_id,
                    },
                  });
                }
              }
            }}
          >
            Fechar Pedido
          </Button>

          {/* Recusar solicitação */}
          <Button
            mt="6%"
            width="100%"
            colorScheme="gray"
            onClick={() => {
              refuseEstimateSolicitacao(solicitacoes_consulta[index].ambulatorio_orcamento_id, cpfUser);
            }}
          >
            Recusar Solicitação
          </Button>
        </Box>
      </>
    ) : (
      <></>
    );
  });

  const useSolicitacoes = Object.keys(solicitacoes)?.map((index) => {
    const useProcedimentos = solicitacoes[index].itens?.map((item, index) => {
      return (
        <>
          <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
            <Flex mt="1em">
              <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
                {item.nome}
              </Text>
            </Flex>
          </chakra.tr>
        </>
      );
    });

    return solicitacoes[index] && solicitacoes[index].itens ? (
      <>
        <Box
          w="98%"
          mx="auto"
          alignItems="center"
          overflow="hidden"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="10px"
          padding="1em"
          marginTop={5}
          px={5}
          pt={5}
        >
          <Flex w="100%">
            <Stack fontSize="sm" w="100%">
              <Text fontSize="0.7em" color="#585858">
                solicitante:
              </Text>
              <Text fontSize="0.8em" fontWeight="bold">
                {solicitacoes[index].solicitante}
              </Text>
            </Stack>
            <Stack fontSize="sm">
              <Text fontSize="0.7em" color="#585858">
                Data da Solicitação:
              </Text>
              <Text fontSize="0.8em" fontWeight="bold">
                {new Date(solicitacoes[index].data_cadastro)?.toLocaleDateString('pt-br', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Text>
            </Stack>
          </Flex>
          <chakra.table w="100%">{useProcedimentos}</chakra.table>

          {/* Compartilhar */}
          
          {solicitacoes[index].procedimentos_sem_empresa === 0 || solicitacoes[index].procedimentos_sem_empresa === '0' ? (
            <></>
          ) : ( 
            <>
              <Button
                isLoading={isShareButtonLoading}
                loadingText="Carregando"
                mt="10%"
                width="100%"
                colorScheme="teal"
                onClick={() => {
                  const data = solicitacoes[index].itens.map((e) => e.procedimento_tuss_id);
                  requestPdf(data);
                }}
              >
                Compartilhar
              </Button>
            </>
          )}

          {/* Fechar pedido */}
          <Button
            mt="6%"
            width="100%"
            colorScheme="teal"
            onClick={() => {
              const destinationPage = '/exames/empresa';
              setUser((prevState) => ({
                ...prevState,
                cart: {
                  itens: solicitacoes[index].itens,
                  solicitacao: true,
                  id_recipient_solicitante: solicitacoes[index].id_recipient,
                  orcamento_id: solicitacoes[index].ambulatorio_orcamento_id,
                  empresa_id_solicitante: solicitacoes[index].empresa_id,
                },
              }));
              confirmEstimate(solicitacoes[index].ambulatorio_orcamento_id);
              navigate(destinationPage, {
                state: {
                  solicitacao: true,
                  id_recipient_solicitante: solicitacoes[index].id_recipient,
                  cart: solicitacoes[index].itens,
                  orcamento_id: solicitacoes[index].ambulatorio_orcamento_id,
                  empresa_id_solicitante: solicitacoes[index].empresa_id,
                },
              });
            }}
          >
            Fechar Pedido
          </Button>

          {/* Recusar solicitação */}
          <Button
            mt="6%"
            width="100%"
            colorScheme="gray"
            onClick={() => {
              refuseEstimate(solicitacoes[index].ambulatorio_orcamento_id);
            }}
          >
            Recusar Solicitação
          </Button>
        </Box>
      </>
    ) : (
      <></>
    );
  });

  const Historico = () => {
    return (
      <>
        <VStack pb="25%">
          {historico?.length > 0 ? (
            <>
              <chakra.h1 fontWeight="bold" paddingTop="10%" mb={'1em'}>
                Histórico
              </chakra.h1>
            </>
          ) : (
            <></>
          )}
          {historico?.length === 0 ? (
            <>
              <Img boxSize="5em" src={Carrinhoimg} mt={'2em'} />
              <chakra.h1 fontWeight="bold">Carrinho vazio!</chakra.h1>
              <chakra.h2 fontColor="#585858" w="55%" align="center">
                {<>Você não possui histórico de solicitações.</>}
              </chakra.h2>
            </>
          ) : (
            <>
              {historico.map((solicitacao) => (
                <>
                  <Box
                    w="95%"
                    mx="auto"
                    alignItems="center"
                    overflow="hidden"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="10px"
                    padding="1em"
                    marginTop={5}
                    px={5}
                    pt={5}
                  >
                    <VStack>
                      <Flex w="100%">
                        <Stack fontSize="sm" w="100%">
                          <Text fontSize="0.7em" color="#585858">
                            solicitante:
                          </Text>
                          <Text fontSize="0.8em" fontWeight="bold">
                            {solicitacao.solicitante}
                          </Text>
                        </Stack>
                        <Stack fontSize="sm">
                          <Text fontSize="0.7em" color="#585858">
                            Data da Solicitação:
                          </Text>
                          <Text fontSize="0.8em" fontWeight="bold">
                            {new Date(solicitacao?.data_cadastro)?.toLocaleDateString('pt-br', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </Text>
                        </Stack>
                      </Flex>
                      <chakra.table w="100%">
                        {solicitacao.itens.map((item) => {
                          return (
                            <>
                              <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
                                <Flex mt="1em">
                                  <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
                                    {item.nome}
                                  </Text>
                                </Flex>
                              </chakra.tr>
                            </>
                          );
                        })}
                      </chakra.table>
                      {solicitacao.procedimentos_sem_empresa === 0 || solicitacao.procedimentos_sem_empresa === '0' ? (
                        <></>
                      ) : (
                        <>
                          <Button
                            isLoading={isShareButtonLoading}
                            loadingText="Carregando"
                            mt="10%"
                            width="100%"
                            colorScheme="teal"
                            onClick={() => {
                              const data = solicitacao.itens.map((e) => e.procedimento_tuss_id);
                              /* const names = solicitacoes[index].itens.map(e => e)
                  const content = generateHtmlText(names); */
                              requestPdf(data);
                            }}
                          >
                            Compartilhar
                          </Button>
                        </>
                      )}

                      {/* Fechar pedido */}
                        <>
                          <Button
                            mt="6%"
                            width="100%"
                            colorScheme="teal"
                            onClick={() => {
                              const destinationPage = '/exames/empresa';
                              setUser((prevState) => ({
                                ...prevState,
                                cart: {
                                  itens: solicitacao.itens,
                                  solicitacao: true,
                                  id_recipient_solicitante: solicitacao.id_recipient,
                                  orcamento_id: solicitacao.ambulatorio_orcamento_id,
                                  empresa_id_solicitante: solicitacao.empresa_id,
                                },
                              }));
                              confirmEstimate(solicitacao.ambulatorio_orcamento_id);
                              navigate(destinationPage, {
                                state: {
                                  solicitacao: true,
                                  id_recipient_solicitante: solicitacao.id_recipient,
                                  cart: solicitacao.itens,
                                  orcamento_id: solicitacao.ambulatorio_orcamento_id,
                                  empresa_id_solicitante: solicitacao.empresa_id,
                                },
                              });
                            }}
                          >
                            Fechar Pedido
                          </Button>

                          {/* Recusar solicitação */}
                          <Button
                            mt="6%"
                            width="100%"
                            colorScheme="gray"
                            onClick={() => {
                              refuseHistorico(solicitacao.ambulatorio_orcamento_id, cpfUser);
                            }}
                          >
                            Recusar Solicitação
                          </Button>
                        </>
   
                    </VStack>
                  </Box>
                </>
              ))}
            </>
          )}
        </VStack>
      </>
    );
  };
  var larguraMaxima = window.innerWidth;

  const Carrinho = () => {
    return (
      <>
        <VStack mb="5em">
        {cart !== undefined && cart !== null ? (
          <>
          <chakra.h1 fontWeight="bold" mt={'1em'}>
            Último carrinho de compras
          </chakra.h1>
          </>
        ) : (<></>)}
          {cart !== undefined && cart !== null ? (
            <>
              <Box
                w="90%"
                mx="auto"
                alignItems="center"
                overflow="hidden"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="10px"
                padding="1em"
                marginTop={5}
                px={5}
                pt={5}
              >
                <chakra.h2 marginTop="5%" fontWeight="bold">
                  PROCEDIMENTOS
                </chakra.h2>
                <chakra.table w="100%">{useProcedimentos}</chakra.table>
                <Button
                  mt="10%"
                  width="100%"
                  backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
                  color="#FFF"
                  onClick={() => {
                    const destinationPage = '/exames/empresa';
                    /* console.log({
                    cart: user.cart.itens,
                    solicitacao: user?.cart?.solicitacao ? user.cart.solicitacao : undefined,
                    id_recipient_solicitante: user?.cart?.id_recipient ? user?.cart?.id_recipient : undefined,
                    orcamento_id: user?.cart?.orcamento_id ? user?.cart?.orcamento_id : undefined,
                    empresa_id_solicitante: user?.cart?.empresa_id_solicitante ? user?.cart?.empresa_id_solicitante : undefined,
                  }); */
                    navigate(destinationPage, {
                      state: {
                        cart: cart.itens,
                        solicitacao: cart?.solicitacao ? cart.solicitacao : undefined,
                        id_recipient_solicitante: cart?.id_recipient_solicitante ? cart?.id_recipient_solicitante : undefined,
                        orcamento_id: cart?.orcamento_id ? cart?.orcamento_id : undefined,
                        empresa_id_solicitante: cart?.empresa_id_solicitante ? cart?.empresa_id_solicitante : undefined,
                      },
                    });
                  }}
                >
                  Fechar Pedido
                </Button>
                <Button
                  mt={'2em'}
                  width="100%"
                  colorScheme="gray"
                  onClick={() => {
                    if(user.titular){
                      setUser((prevState) => ({ ...prevState, cart: null }));
                    }
                    atualizarCarrinho(cpfUser)
                    navigate('/carrinho');
                  }}
                >
                  Esvaziar Carrinho
                </Button>
              </Box>
            </>
          ) : (
              <>
                <Img boxSize="5em" src={Carrinhoimg} mt={'2em'} />
                <chakra.h1 fontWeight="bold">Carrinho vazio!</chakra.h1>
                <chakra.h2 fontColor="#585858" w="55%" align="center">
                  {<>Você não possui nenhum carrinho pendente.</>}
                </chakra.h2>
              </>
          )}
        </VStack>
      </>
    );
  };

  const Prescricoes = () => {
    return (
      <>
        <VStack pb="25%">
          {Object.keys(solicitacoes) && Object.keys(solicitacoes).length > 0 ? (
            <>
              <chakra.h1 fontWeight="bold" paddingTop="10%">
                {Object.keys(solicitacoes) && Object.keys(solicitacoes).length > 0 && <>Solicitações</>}
              </chakra.h1>
              <VStack>
                {useSolicitacoes} {useSolicitacoesConsultas}
              </VStack>
            </>
          ) : (
            <>
              <>
                <Img boxSize="5em" src={Carrinhoimg} mt={'2em'} />
                <chakra.h1 fontWeight="bold">Carrinho vazio!</chakra.h1>
                <chakra.h2 fontColor="#585858" w="55%" align="center">
                  {<>Você não possui nenhuma solicitação ativa.</>}
                </chakra.h2>
              </>
            </>
          )}
        </VStack>
      </>
    );
  };

  const SelectClient = () => {
    const arrayNomeDendente = dependentes.map((dependente) => dependente.nome);
    return (
      <div>
      <label
        htmlFor="clients"
        style={{ marginLeft: larguraMaxima <= 320 ? '1em' : '1em' }}
      >
        Cliente:
      </label>
      <SelectField
        name="clients"
        placeholder="Cliente"
        w={larguraMaxima <= 320 ? "90%" : "95%"}
        variant="outline"
        textAlign="left"
        borderColor="gray.300"
        borderRadius="10px"
        fontWeight="bold"
        options={arrayNomeDendente}
        onChange={(e) => {
          getOrcamentosNaoRecusados(dependentes[e].cpf);
          getSolicitacoesDependentes(dependentes[e].cpf);
          getCartDependentes(dependentes[e].cpf);
          fetchSolicitacoesConsultasDependentes(dependentes[e].cpf);
          setCpfUser(dependentes[e].cpf);
          setUserName(dependentes[e].cpf);
        }}
      />
    </div>
    );
  };

  return updated ? (
    <>
      <MainChildHeader />
      <Formik initialValues={{ clients: 0 }} validateOnChange={false} validateOnBlur={false}>
        <VStack mt="5%" as={Form}>
          <Tabs>
            {user.titular === true && user.id_contrato > 0 && <SelectClient pb="5" ml={larguraMaxima  <= 320 ? '2em' : ''}/>}
            <TabList>
              <Tab>Carrinho</Tab>
              <Tab>Solicitações ativas</Tab>
              <Tab>Histórico solicitações</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Carrinho />
              </TabPanel>
              <TabPanel>
                <Prescricoes />
              </TabPanel>
              <TabPanel>
                <Historico />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Formik>
      <BottomNavBar name="carrinho" />
    </>
  ) : (
    <>
      <MainChildHeader />
      <Loading />
      <BottomNavBar name="carrinho" />
    </>
  );
}

export default Main;
