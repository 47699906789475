import React  from 'react';
import { Box, Button, Image, Text } from '@chakra-ui/react';
import IconHandMoney from '../../../images/MDV/GastosTotais/icon-hand-money.svg';


const DisponivelParaSaque = ({ valor = 0, onSacarPress=()=>{} }) => (
  <Box display={'flex'} flex={1} flexDirection={'row'} padding={'16px'} bg={'#f5f5f5'} borderRadius={10} gap={'10px'}>
    <Box>
      <Text fontSize="sm" fontWeight={700} color={'#616161'}>
        Disponível para saque:
      </Text>
      <Text fontSize="2xl" fontWeight={800} color={'#18A403'}>
        R${valor}
      </Text>
    </Box>
    <Box display={'flex'} flex={1} justifyContent={'flex-end'}>
      <Button onClick={onSacarPress} colorScheme="teal" size="lg" height={'100%'} bgColor={'#18A403'} borderRadius={16} fontWeight={'600px'} disabled={Number(valor) <= 0}>
        Sacar
        <Image src={IconHandMoney} alt="Icon Alert" w={5} h={'auto'} ml={4} />
      </Button>
    </Box>
  </Box>
);

export default DisponivelParaSaque;