import { Box, Button, Flex, HStack, Heading, Image, Text, VStack, useClipboard, useToast } from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useState } from 'react';
import NetshoesLogo from '../../../../images/carrosel/descontos/Banners/Netshoes.svg';
import { Link } from 'react-router-dom';
import { CheckIcon } from '@chakra-ui/icons';
import { AccountContext } from 'components/AccountContext';

function Netshoes() {
  const [value, setValue] = useState('URBIS');
  const { hasCopied, onCopy } = useClipboard(value);
  const toast = useToast();
  const { setPage, setUser, user } = useContext(AccountContext);
  setPage('Clube de Vantagens');
  return (
    <>
      <ChildHeader />
      <Flex minW="210px" maxW="500px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5} justifyContent="center">
        <VStack alignItems="center" spacing={2.5} w={'100%'} mt={'2em'}>
          <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} h={'auto'} alignItems="center" w={'100%'} p={5}>
            <Image boxSize="180px" src={NetshoesLogo} alt="" color={'#585858'} />
            <Heading fontSize="20px" color={'#8E8E8E'} padding={'1em'} textAlign={'left'}>
              Cupom de 15% OFF
            </Heading>
            <Heading w={'100%'} fontSize="20px" bg={'#ffff'} color={'#8E8E8E'} padding={'1em'} borderRadius={'0.8em'} textAlign={'center'}>
              <Box mx="auto">URBIS</Box>
            </Heading>
            <VStack w={'100%'} mt={'1em'}>
              <Button
                bg="#EB8B2A"
                color="#FFFFFF"
                w="100%"
                borderRadius={'0.8em'}
                _hover={{ bg: '#FFA95D' }}
                onClick={() => {
                  onCopy();
                  return toast({
                    position: 'bottom',
                    render: () => (
                      <Box color="white" borderRadius={10} mb="5" p={4} bg="green.500">
                        Cupom copiado! <CheckIcon />
                      </Box>
                    ),
                  });
                }}
              >
                Copiar cupom
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </Flex>

      <VStack alignItems="left" spacing={2.5} w={'100%'} p={'2em'}>
        <Text textAlign={'justify'} fontSize={'16px'} color={'#474747'} fontWeight={'bold'}>
          Para utilizar este cupom, vá ao site ou aplicativo da NetShoes, realize sua compra e ao fechar o pedido informe o código do seu cupom.
        </Text>
        <Text textAlign={'justify'} fontSize={'16px'} color={'#474747'} fontWeight={'bold'}>
          Este cupom só é válido para pedidos online.
        </Text>
      </VStack>
    </>
  );
}

export default Netshoes;
