import { Button, ButtonGroup, Center, Heading, Image, Modal, ModalBody, chakra, ModalCloseButton, Stack, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import Bills from '../../../../../images/bills.png';

function ModalDivida(prop) {
  const navigate = useNavigate()
  const disclosure = prop.disclosure;
  const { user, setPage, setUser } = useContext(AccountContext);
  const valor = user && user.qntdeParcelas && user.qntdeParcelas.valorParcelas ? user.qntdeParcelas.valorParcelas : null
  const qntdeAberto = user && user.qntdeParcelas && user.qntdeParcelas.countParcelas ? user.qntdeParcelas.countParcelas : null
  const [parcelas, setParcelas] = useState([]);
  const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
  const url = `${ENDPOINT}/client/parcelas`;

  function continuar() {
    navigate('/pagamentoparcelas')
  }

  function validaPagamento() {
    const payload = {
      id_contrato: user.id_contrato,
      titular: user.titular,
      id_contrato_dependente: user.id_contrato_dependent
    };


    const fetchPosts = async () => {
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();

      if (postsData?.length === 0) {
        setUser((prevState) => ({ ...prevState, qntdeParcelas: null }));
      }
      disclosure.onClose()
    };
    fetchPosts();
  }


  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered onClose={() => { validaPagamento() }}>
        <ModalOverlay onClick={() => validaPagamento()} />
        <ModalContent maxW="85%" h="30%" borderRadius="0.8em">
          <ModalCloseButton onClick={() => validaPagamento()} />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="#808080" mt="5" size="md">
                Olá, {user && user.name ? user.name.split(' ')[0] : ""}!
              </Heading>
            </Center>
            <chakra.h4 mt="0.8em" color="#808080" textAlign="center" fontSize="0.8em">

              {user.titular ?
                `Vimos que você possui um total de ${qntdeAberto} parcelas em aberto, totalizando um valor de R$ ${valor}. Que tal realizar o pagamento e voltar a usar seus benefícios?`
                :
                `Vimos que o titular do seu plano possui um total de ${qntdeAberto} parcelas em aberto, totalizando um valor de R$ ${valor}. Que tal realizar o pagamento e voltar a usar seus benefícios?`
              }
            </chakra.h4>
          </ModalBody>
          <Center>
            <Stack direction="column" spacing={4} align="center">
              <Button
                backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
                mt="5%"
                _hover="none"
                color="white"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                autofocus="true"
                onClick={() => {
                  continuar()
                }}
              >
                Realizar Pagamento
              </Button>
            </Stack>
          </Center>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalDivida;
