import { InputGroup, Button, useToast, ButtonGroup, Heading, InputRightElement, Text, VStack, chakra, Image } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import TextField from '../TextFieldAuth';
import Logo from '../../images/Logomarca-Deovita.svg';
import * as Yup from 'yup';
import { React, useContext, useState } from 'react';
import { useNavigate, searchParams } from 'react-router';
import { AccountContext } from '../AccountContext';
import Background from '../../images/Background.png';
import BackButton from '../../images/assets/telemedicina/backbutton.svg';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useLocation, useSearchParams } from 'react-router-dom';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const Login = () => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const handleClick = () => setIsPasswordHidden(!isPasswordHidden);
  const { setUser } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  return (
    <chakra.div backgroundImage={Background} backgroundSize="cover">
       <Image top="2.5em" left="1.3em" zIndex="9999" position="absolute" src={BackButton} onClick={() => navigate(-1)} />
      <Formik
        initialValues={{ username: '', password: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          username: Yup.string()
            .required('CPF obrigatório!')
            .trim()
            .matches('([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})', 'Cpf inválido'),
          password: Yup.string().required('Senha Obrigatória!').min(6, 'Senha inválida!'),
        })}
        onSubmit={(values, actions) => {
          const vals = {
            username: onlyNumbers(values.username),
            password: values.password,
          };
          actions.resetForm();
          fetch(`${ENDPOINT}/auth/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(vals),
          })
            .catch((err) => {
              showToast();
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                showToast();
                return;
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              setUser((prevState) => ({ ...prevState,...data, device: prevState.device, coords: prevState.coords ? prevState.coords : null, build: prevState.build ? prevState.build : null }));
              if (data.status) {
                setError(data.status);
              } else if (data.loggedIn) {
                if (data.id_contrato === 0 && data.titular === true) {
                  if (data.contrato_pendente_pagamento === true) {
                    navigate('/cadastro/analise', {
                      state: {
                        client_id: data.id_cliente_deovita,
                        name: data.name,
                        cpf: data.cpf,
                      },
                    });
                  } else {
                    navigate('/dashboard');
                  }
                } else {
                  navigate('/dashboard');
                }
              }
            });
        }}
      >
        <VStack
          as={Form}
          w={{
            base: '90%',
            md: '500px',
          }}
          m="auto"
          justify="center"
          h="100vh"
          spacing="1rem"
        >
          <Heading>
            <img src={Logo} alt="logo deovita" />
          </Heading>
          <Text as="p" color="#FFFFFF">
            {error}
          </Text>
          <TextField
            name="username"
            placeholder="Entre com seu CPF"
            mask="999.999.999-99"
            maskChar={null}
            type="tel"
            borderRadius="10em"
            height="4em"
            backgroundColor="rgba(255, 255, 255, 0.35)"
            _placeholder={{ textAlign: 'center', color: 'white' }}
            textAlign={'center'}
            color={'white'}
            onFocus={() => {
              setError('');
            }}
          />
          <InputGroup>
            <TextField
              name="password"
              type={isPasswordHidden ? 'password' : 'text'}
              placeholder="Senha"
              borderRadius="10em"
              height="4em"
              backgroundColor="rgba(255, 255, 255, 0.35)"
              _placeholder={{ textAlign: 'center', color: 'white' }}
              textAlign={'center'}
              color={'white'}
              onFocus={() => {
                setError('');
              }}
            />
            <InputRightElement width="2rem" height="4rem" pt="1rem" pr="2rem" size="4rem">
              <Button
                borderRadius="50%"
                colorScheme="twitter"
                variant="outline"
                pr="1em"
                size="lg"
                onClick={handleClick}
                leftIcon={isPasswordHidden ? <ViewOffIcon /> : <ViewIcon />}
              ></Button>
            </InputRightElement>
          </InputGroup>
          <ButtonGroup sx={{ width: '100%' }}>
            <Button
              aria-label="ok"
              colorScheme="teal"
              type="submit"
              w={{
                base: '100%',
              }}
              height="4em"
              borderRadius="10em"
            >
              Entrar
            </Button>
          </ButtonGroup>
          <chakra.p
            color="white"
            paddingBottom="2.5em"
            onClick={() => {
              navigate('/recuperacao/cpf');
            }}
          >
            Esqueci minha senha
          </chakra.p>
          <ButtonGroup pt="0.5rem" sx={{ width: '100%' }}>
            <Button
              onClick={() => navigate('/cadastro')}
              w={{
                base: '100%',
              }}
              height="4em"
              backgroundColor="white"
              borderRadius="10em"
            >
              Cadastre-se aqui!
            </Button>
          </ButtonGroup>
          <chakra.p color="white" paddingBottom="2.5em" textAlign="center" onClick={() => (window.open('https://wa.me/5508002802133'))}>
            Fale com um de nossos atendentes.
          </chakra.p>
        </VStack>
      </Formik>
    </chakra.div>
  );
};

export default Login;
