import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import { useContext } from 'react';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function FimCadastroEstabelecimento() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setUser } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  async function postOperator(payload) {
    const url = `${ENDPOINT}/licensedEstablishment/operator`;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    try {
      const operatorData = await fetch(url, options);
      const operator = await operatorData.json();
      if (operator.success) {
        setUser((currentUser) => ({ ...currentUser, empresasEmpreendedor: null }));
        setLoading(false);
      } else {
        setError('erro');
        setLoading(false);
      }
    } catch (err) {
      setError('erro');
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    postOperator(state);
  }, []);

  function LoadingIcon() {
    return (
      <>
        <ChildHeader />
        <Loading />
      </>
    );
  }
  return loading ? <LoadingIcon /> : error ? navigate('/alerts/error/default') : navigate('/alerts/sucesso/cadastro_estabelecimento');
}

export default FimCadastroEstabelecimento;
