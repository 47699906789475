import { chakra, Box, Flex, Text, Image,  Button } from '@chakra-ui/react';
import React, { useContext } from 'react';
import LogoDashboard from '../../images/logo_deovita_blank.svg';
import NotificationIcon from '../../images/notification_icon_1.svg';
import '../AreaLivre/PerfilEmpresa/css/main.css';
import { AccountContext } from '../AccountContext';
import { useNavigate } from 'react-router-dom';

function UserHeaderLivre() {
  const { user, webviewParams } = useContext(AccountContext);
  
  const navigate = useNavigate();

  const {loggedIn, id_contrato, titular} = user;
  
  const backgroundColor = (titular === true  && id_contrato === 0) ? "#529C94" :
                          (titular === false && id_contrato === 0) ? "#EB8B2A" :
                          (titular === true && id_contrato > 0) ? "#EB8B2A" : "#529C94";

  let message = webviewParams?.subscriber || 'Seja um assinante!';
  if (user.situacao_contrato === 'P') message = "Contrato em análise";
  return (
    <>
      <chakra.header
        w="full"
        py={0}
        shadow="md"
        backgroundColor={backgroundColor}
        minHeight="136px"
        borderBottomRadius="30px"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto" ml={{sm: "40px", base: "10px"}}>
          <Flex direction="column">
            <chakra.h2  fontSize="xl" fontWeight="bold" mt="43px" color="white">
              Olá!
              <br />
            </chakra.h2>
            <Button 
              fontSize="12px" 
              w="136px" 
              h="20px" 
              color="#5AB2AD" 
              borderRadius={20}
              onClick={() => { 
                if(message === "Seja um assinante!" && !id_contrato ) {
                  navigate('/login')
                }
              }}
              >
                {message}
              </Button>
          </Flex>
            <Box display={{ base: 'inline-flex' }}>
              <Image w="70px" h="55px" me={{sm: "30px", base: "10px"}} position="absolute" right="0.02rem" top="43px" src={LogoDashboard} alt="teste" />
            </Box>
        </Flex>

        <Text
          position="absolute"
          fontSize="xl"
          fontWeight="bold"
          pt="1.1em"
          textColor="white"
          textShadow="2px 3px 5px #808080"
          textAlign="center"
          width="90%"
          zIndex="1"
        ></Text>
        <Flex justifyContent="flex-end">
         { loggedIn && <Image 
            w="20px"
            h="20px"
            mt={4}
            position="absolute"
            mr={7}
            src={NotificationIcon} 
            alt="notification"
            onClick={() => {alert('show notification')}}
          />}
        </Flex>
        
      </chakra.header>
    </>
  );
}

export default UserHeaderLivre;
