import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Grafico(contractData) {
  const contratosArr = contractData.contractData;
  const sortedObj = {};
  const last3Months = [];
  const last3MonthsValue = [];

  contratosArr.forEach((e) => {
    const k = e.data_cadastro.slice(0, 7); // key in YYYY-MM (e.g. 2019-10)
    const fk = `${k}-02`; // key with appended '-01'
    sortedObj[fk] = sortedObj[fk] || []; // create new entry if no value for key exists
    sortedObj[fk].push(e); // add key to existing list
  });

  const sortedArr = Object.entries(sortedObj).sort((objA, objB) => Number(new Date(objA[0])) - Number(new Date(objB[0])));

  const getMonths = Object.entries(sortedArr).map((key) => {
    let date = new Date(key[1][0]);
    const getMonth = date.toLocaleString('pt-br', { month: 'short' });
    last3Months.push(getMonth);
  });

  console.log(sortedArr);
  const valuesByMonth = Object.entries(sortedArr).map((key) => {
    const contract = key[1][1];
    const sum = contract.reduce((a, b) => {
      const qtdExists = b.qtd ? true : false;
      const multiplier = qtdExists ? b.qtd : 1;
      return a + (b.valor_pago ? (parseFloat(b.valor_pago.replace(',', '.')).toFixed(2) / 100) * 17 * multiplier : 0);
    }, 0);
    last3MonthsValue.push(sum);
  });

  let valorTotal = 0;
  let valorInicial = 0;
  const useValor = contratosArr.map((contract) => {
    const qtdExists = contract.qtd ? true : false;
    const multiplier = qtdExists ? contract.qtd : 1;
    valorTotal += contract.valor_pago ? (parseFloat(contract.valor_pago.replace(',', '.')).toFixed(2) / 100) * 17 * multiplier : 0;
  });

  const options = {
    barThickness: 30,
    responsive: true,
    scales: { y: { min: 0, max: valorTotal + 10 } },
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Ganhos',
      },
    },
  };
  const labels = last3Months;
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'ganhos',
        data: [
          last3MonthsValue[0] ? last3MonthsValue[0] : 0,
          last3MonthsValue[1] ? last3MonthsValue[1] : 0,
          last3MonthsValue[2] ? last3MonthsValue[2] : 0,
        ],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: ['rgba(53, 162, 235, 0.5)', 'rgba(86, 218, 145, 0.8)', 'rgba(86, 39, 185, 0.46)'],
      },
    ],
  };
  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
}
export default Grafico;
