import { Button, ButtonGroup, Center, Heading, Image, Modal, ModalBody, chakra, ModalCloseButton, Stack, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import { CheckCircleIcon } from '@chakra-ui/icons';

function PagamentoLocalizado(prop) {
    const navigate = useNavigate()
    const disclosure = prop.disclosure;
    const { user, setPage, setUser } = useContext(AccountContext);

    function atualizaPagamento(){
        setUser((prevState) => ({ ...prevState, qntdeParcelas: null }));

        disclosure.onClose()

        navigate('/dashboard')
    }

    return (
    <>
        <Modal isOpen={disclosure.isOpen} isCentered >
            <ModalOverlay />
            <ModalContent maxW="85%" borderRadius="2em">
                <ModalHeader>
                    <VStack w="100%">
                        <CheckCircleIcon color="green.500" boxSize={20} />
                    </VStack>
                </ModalHeader>
                <ModalBody>
                <Center>
                    <Heading textAlign="center" color="gray.600" size="md" >
                    Opa! Localizamos seu pagamento em nosso sistema.
                    </Heading>
                </Center>
                <Text mt="0.5em" color="gray.600" textAlign="justify" fontSize="sm">
                    Volte a usar os benefícios para sua saúde na DeoVita!
                </Text>
                </ModalBody>
                <ModalFooter justifyContent="center">
                <Button bg="green.400" color={'white'} onClick={() => {atualizaPagamento()}}>
                    Continuar
                </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    
    </>
    )
}

export default PagamentoLocalizado
