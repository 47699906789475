import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent, Stack,
  DrawerOverlay, Button, Heading, Flex,
  Text,
  HStack, Radio,
  TableContainer,
  Table,
  Tr,
  LinkBox,
  Td,
  Badge
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectField from 'components/SelectField';
import { AccountContext } from 'components/AccountContext';
import { Formik } from 'formik';
import Loading from 'components/Loading';

  function DrawerHorario(props) {
    const disclosure = props.disclosure;
    const especialidadeEscolha = props.especialidadeId;
    console.log(props.escolha);
    const [escolha, setEscolha] = useState(null);
    const { user, setUser } = useContext(AccountContext);
    const [dependentes, setDependentes] = useState([]);
    const [agendas, setAgendas] = useState([]);
    let defaultDateTime = null;
    const [pac, setPac] = useState(0);
    const [pag, setPag] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];

    const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

    const postAppoint = async function () {
      const url = ENDPOINT + '/schedule/appointmentTelemed';
      const payload = {
        especialidade: especialidadeEscolha,
        cpf: dependentes[pac].cpf,
        clientId: dependentes[pac].paciente_id,
        procedimento_convenio_id: 80168,
        forma_pagamento: 'DINHEIRO',
        horario: escolha.agenda.AVA_START_TIME,
        data: escolha.agenda.AVA_DATE,
        paid: true,
      };
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const status = await response.json();
      if (status.success) {
        navigate('/alerts/sucesso/default');
      } else {
        navigate('/alerts/erro/default');
      }
    };
    useEffect(() => {
      const fetchPosts = async () => {
        const url = ENDPOINT + '/schedule/getAvailabilityByEspecialidade/' + especialidadeEscolha;
        const response = await fetch(url, {
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          method: 'GET',
        });
        const postsData = await response.json();
        setAgendas(postsData);
      };
      const fetchDependentes = async () => {
        const url2 = ENDPOINT + '/client/dependents2';
        const titularId = {
          id_contrato: user.id_contrato,
        };
        const response = await fetch(url2, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(titularId),
        });
        const resp = await response.json();
        if (resp.data[0] !== null) {
          setDependentes(resp.data);
        } else {
          setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
          console.log(dependentes);
        }
        console.log(resp.data);
      };
      fetchDependentes();
      fetchPosts();
    }, []);
    let uniqueDates = [];

    const useAgenda = agendas.map((agenda, index) => {
      if (!uniqueDates.includes(agenda.AVA_DATE)) {
        uniqueDates.push(agenda.AVA_DATE);
      }
    });

    const filteredData = agendas.filter((item, index, self) => index === self.findIndex((i) => i.AVA_START_TIME === item.AVA_START_TIME));

    const filteredAgendas = filteredData.filter((item) => uniqueDates[pag].includes(item.AVA_DATE));

    const mapDates = uniqueDates.map((uniqueDate, index) => {
      const options = { month: 'short', day: 'numeric' };
      const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
      const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

      const useFilteredAgendas = filteredAgendas.map((filteredAgenda, index) => {
        if (!escolha) {
          var dateTime = `${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}`;
          if (dateTime === defaultDateTime) {
            setEscolha({
              valor: 0,
              agenda: filteredAgenda,
              paciente: dependentes[pac],
            });

            setDisabled(true);
          }
        }

        return (
          <>
            <Td>
              <Badge
                id={'agenda' + index}
                borderRadius="0.5em"
                margin="0 0.5em"
                padding="0.5em 1.4em"
                size="lg"
                fontSize="md"
                style={`${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}` === defaultDateTime ? { border: '1px solid orange' } : {}}
                backgroundColor="rgba(90, 178, 173, 0.3)"
                color="rgba(62, 100, 255, 1)"
                onClick={() => {
                  document.getElementById('agenda' + index).scrollIntoView({ behavior: 'smooth' });
                  document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                    item.style.border = 'none';
                  });
                  document.getElementById('agenda' + index).scrollIntoView({ inline: 'center', behavior: 'smooth' });
                  document.getElementById('agenda' + index).style.border = '1px solid orange';

                  setEscolha({
                    valor: 0,
                    agenda: filteredAgenda,
                    paciente: dependentes[pac],
                  });
                  if (escolha) {
                    setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
                  }
                  console.log(user);
                  dependentes[pac].cpf && setDisabled(true);
                }}
              >
                {filteredAgenda.AVA_START_TIME}
              </Badge>
            </Td>
          </>
        );
      });
      return (
        <>
          <Td
            id={`td${index}`}
            borderRadius="15px"
            p="0.5em"
            fontSize="xl"
            textAlign="center"
            class="margin-td"
            onClick={() => {
              document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
              document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                item.style.border = 'none';
              });
              document.getElementById('date' + index).style.border = '1px solid orange';
              setPag(index);
              setDisabled(false);
              setEscolha(null);
            }}
          >
            <HStack
              id={`date${index}`}
              backgroundColor="rgba(90, 178, 173, 0.25)"
              borderRadius="15px"
              style={pag === index ? { border: '1px solid orange' } : {}}
            >
              <Text width="60%">
                <Flex ml="1em">
                  <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="1em" color="rgba(62, 100, 255, 1)" padding="0 1em">
                    <Text fontWeight="bold" fontSize="xl">
                      {dataIcon[0]}
                    </Text>
                    <Text fontWeight="bold" fontSize="sm" mt="-1">
                      {dataIcon[1].toUpperCase()}
                    </Text>
                  </Box>
                  <Text margin="11% 0" fontSize="xs" align="left">
                    {event.toLocaleString('pt-br', { weekday: 'long' })}
                  </Text>
                </Flex>
              </Text>
            </HStack>
          </Td>
        </>
      );
    });
    const useFilteredAgendas = filteredAgendas.map((filteredAgenda, index) => {
      if (!escolha) {
        var dateTime = `${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}`;
        if (dateTime === defaultDateTime) {
          setEscolha({
            valor: 0,
            agenda: filteredAgenda,
            paciente: dependentes[pac],
          });

          setDisabled(true);
        }
      }

      return (
        <>
          <Td>
            <Badge
              id={'agenda' + index}
              borderRadius="0.5em"
              margin="0 0.5em"
              padding="0.5em 1.4em"
              size="lg"
              fontSize="md"
              style={`${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}` === defaultDateTime ? { border: '1px solid orange' } : {}}
              backgroundColor="rgba(90, 178, 173, 0.3)"
              color="rgba(62, 100, 255, 1)"
              onClick={() => {
                document.getElementById('agenda' + index).scrollIntoView({ behavior: 'smooth' });
                document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
                  item.style.border = 'none';
                });
                document.getElementById('agenda' + index).scrollIntoView({ inline: 'center', behavior: 'smooth' });
                document.getElementById('agenda' + index).style.border = '1px solid orange';

                setEscolha({
                  valor: 0,
                  agenda: filteredAgenda,
                  paciente: dependentes[pac],
                });
                if (escolha) {
                  setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
                }
                console.log(user);
                dependentes[pac].cpf && setDisabled(true);
              }}
            >
              {filteredAgenda.AVA_START_TIME}
            </Badge>
          </Td>
        </>
      );
    });
    return (
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderTopRadius={20} width="100%" h="80%">
          <DrawerBody pb="10%">
            <Stack direction="column" spacing={4} paddingTop={30} align="center">
              {dependentes.length > 0 ? (
                <>
                  <Radio w="3em" autoFocus hidden></Radio>
                  <Text w="95%" size="sm" color="#585858" fontWeight="bold">
                    Selecione o paciente:
                  </Text>
                  <LinkBox id="" as="card" p="3" borderWidth="2px" borderColor="green" borderRadius="15px" fontSize="xs" width="100%">
                    <Formik initialValues={{ dependente: 0 }}>
                      <Heading size="sm" my="2">
                        <SelectField
                          name="dependente"
                          colorScheme="teal"
                          border="green"
                          color="black"
                          borderRadius="15px"
                          borderWidth="2px"
                          variant="ghost"
                          fontWeight="bold"
                          onChange={setPac}
                          options={arrayNomeDependentes}
                        />
                      </Heading>
                    </Formik>
                  </LinkBox>
                  <Text w="95%" size="sm" color="#585858" fontWeight="bold">
                    Selecione o dia e o horário
                  </Text>
                  {!agendas.length || agendas.length <= 0 ? (
                    <>
                      <Text align="center" fontSize="0.8em" pb="20%">
                        Sem horarios disponíveis para essa especialidade
                      </Text>
                    </>
                  ) : (
                    <>
                      <TableContainer minWidth="100%">
                        <Table variant="simple" size="lg" class="table-agendamento">
                          <Tr>{mapDates}</Tr>
                        </Table>
                      </TableContainer>
                      <TableContainer minWidth="100%">
                        <Table variant="simple">
                          <Tr>{useFilteredAgendas}</Tr>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  <Button
                    colorScheme="teal"
                    w="100%"
                    borderRadius="15px"
                    disabled={escolha ? false : true}
                    onClick={() => {
                      postAppoint();
                    }}
                  >
                    Continuar
                  </Button>
                  <Button colorScheme="teal" w="100%" borderRadius="15px" variant="ghost" onClick={disclosure.onClose}>
                    Voltar
                  </Button>
                </>
              ) : (
                <>
                  <Loading />
                </>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  export default DrawerHorario;
