import React, { useContext, useEffect, useState } from 'react';
import MainHeader from './MainHeader';
import UserHeaderLivre from 'components/AreaLivre/UserHeaderLivre';
import { AccountContext } from 'components/AccountContext';
import ChildHeader from './ChildHeader';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import {
  Box,
  Center,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  VStack,
  chakra,
  useToast,
  useDisclosure,
  Button,
  HStack,
  Skeleton,
  Progress,
} from '@chakra-ui/react';
import EmojiCoracoes from '../../../images/EmojiCoracoes.svg';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Heart } from '../../../images/heart.svg';
import { ReactComponent as FamiliaPlus } from '../../../images/familia-plus.svg';
import { ReactComponent as Familia } from '../../../images/plano-familia.svg';
import { ReactComponent as Individual } from '../../../images/individual.svg';
import { loadPlans } from 'api/plans';

function getPlanColor(planID) {
  switch (planID) {
    case 29: // FAMILIA
      return '#EB8B2A';
    case 55: //INDIVIDUAL
      return '#529C94';

    default:
      return '#FF8000';
  }
}

function getPlanIcon(planID) {
  switch (planID) {
    case 29: // FAMILIA
      return <Familia height={20}></Familia>;
    case 55: //INDIVIDUAL
      return <Individual height={20}></Individual>;
    case 137:
      return <FamiliaPlus height={20}></FamiliaPlus>;

    default:
      return <Individual height={20}></Individual>;
  }
}

function CardDadosPlano({ plans }) {
  const { setPage, setUser, user } = useContext(AccountContext);
  const [medico, setMedico] = useState(false);
  const [tipo, setTipo] = useState('');
  const [lg] = useMediaQuery('(min-width: 425px)');
  const toast = useToast();
  const disclosure = useDisclosure();
  const navigate = useNavigate();

  return plans?.map((item, index) => {
    const idPlano = item.id;
    const menorValor = item?.parcelas && item.parcelas.length > 0 ? Math.min(...item.parcelas.map((parcela) => parcela.min_valor)) : null;
    return (
      <Box
        key={item.id}
        plan={item}
        p={8}
        borderRadius={20}
        width={'100%'}
        flexShrink={0}
        scrollSnapAlign={'center'}
        overflow={'visible'}
        position={'relative'}
        fontSize={lg ? 'md' : 'xs'}
        border={`1px solid ${getPlanColor(item.id)}`}
        bg={'linear-gradient(180deg, #F1F1F1 0%, #F7F7F7 100%)'}
      >
        <Center bg={getPlanColor(item.id)} color={'white'} gap={2} p={2} borderRadius={10} textAlign={'center'}>
          {getPlanIcon(item.id)} Plano {item.nome}
        </Center>

        <Box mb={2} bg={getPlanColor(item.id)} mt={4} p={4} borderRadius={20} color={'white'}>
          <Center flexDirection={'column'}>
            <Text fontSize={lg ? 'xl' : 'md'} alignSelf={'flex-start'}>
              A partir de
            </Text>
            <Text fontSize={lg ? '5xl' : '3xl'} fontWeight={'bold'} lineHeight={1} mb={4} alignSelf={'flex-start'}>
              {Number(menorValor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </Text>
            <Text fontSize={'xs'}>* O valor do plano será ajustado de acordo com a forma de pagamento escolhida.</Text>
          </Center>
        </Box>

        <Center>
          <Button
            bottom={0}
            color="#fff"
            backgroundColor={`${getPlanColor(item.id)}`}
            borderRadius={20}
            mt={'1em'}
            onClick={() => {
              if (user.id_contrato > 0) {
                if (user.contrato_pendente_pagamento === true) {
                  toast({
                    title: 'Assinatura pendente',
                    description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                    position: 'bottom',
                    colorScheme: 'green',
                    containerStyle: {
                      paddingBottom: '5em',
                    },
                  });
                  return;
                } else {
                  if (user.loggedIn === true && user.id_contrato > 0) {
                    if (medico === true) {
                      navigate('/telepet/termos');
                    } else {
                      toast({
                        title: 'Indisponível',
                        description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                        status: 'warning',
                        duration: 4000,
                        isClosable: true,
                        position: 'bottom',
                        colorScheme: 'green',
                        containerStyle: {
                          paddingBottom: '5em',
                        },
                      });
                    }
                  } else {
                    setTipo('Veterinário');
                    disclosure.onOpen();
                  }
                }
              } else if (user.loggedIn === false) {
                setUser((prevState) => {
                  return {
                    ...prevState,
                    service_type: 'assinar',
                  };
                });
                navigate('/cadastro');
              } else {
                setUser((prevState) => {
                  return {
                    ...prevState,
                    service: 'telemedicine',
                    service_type: 'assinar',
                  };
                });
                navigate('/cadastro/plano', {
                  state: {
                    client_id: user.id_cliente_deovita,
                    name: user.name,
                    cpf: user.cpf,
                  },
                });
              }
            }}
          >
            {user.loggedIn === false ? <Text>Quero me {!lg && <br />} cadastrar agora!</Text> : 'Quero assinar agora!'}
          </Button>
        </Center>
        <Box pt={4} borderTop={'2px solid #D0D0D0'} mt={6} color={'#585858'}>
          <Text fontWeight={'bold'} fontSize={'md'} textAlign={'center'} mb={4}>
            Com o <span style={{ color: getPlanColor(item.id) }}>Plano {item.nome}</span>, você pode:
          </Text>
          {item?.beneficios &&
            item?.beneficios?.map((beneficio) => (
              <HStack mt={1}>
                <chakra.span fontSize="16px">
                  <Heart style={{ flexShrink: 0, marginTop: 4 }} color={getPlanColor(idPlano)}></Heart>
                </chakra.span>
                {
                  <chakra.span color={'#585858'} fontSize="16px">
                    {beneficio.descricao}
                  </chakra.span>
                }
              </HStack>
            ))}
        </Box>
      </Box>
    );
  });
}

function EspecialidadesDeslogado() {
  const { setPage } = useContext(AccountContext);
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const [plans, setPlans] = useState([]);
  setPage('');

  useEffect(() => {
    (async () => {
      try {
        const data = await loadPlans();
        setPlans(data);
      } catch (error) {
        //setError(true);
      } finally {
        //setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <NoUserHeader />
      <VStack w={'100%'} mt={'1.5em'}>
        <Image w="20%" h={'10%'} objectFit="cover" src={EmojiCoracoes} ml={'-50%'} />
        <VStack w={'100%'}>
          <Box minW="210px" maxW="390px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5}>
            <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'} mb={2}>
              No <b>DeoVita </b> você tem acesso a <b> Pronto Atendimento 24h</b> e <b>Pediatria Online 24h +13 especialidades</b>.
            </Text>
            <Text color={'#585858'} fontSize={'16px'} ml={isMinHeight ? '1.5em' : '2.2em'}>
              Além de um <b>veterinário online</b> para tirar dúvidas sobre a saúde do seu amigo de quatro patas.
            </Text>
          </Box>
        </VStack>
      </VStack>

      <Box
        display={'flex'}
        gap={4}
        px={10}
        py={8}
        overflow={'visible'}
        overflowY={'auto'}
        overscrollBehaviorX={'contain'}
        scrollSnapType={'x mandatory'}
      >
        {!plans.length && (
          <>
            <Skeleton p={8} borderRadius={20} height={'75vh'} width={'100%'} flexShrink={0} scrollSnapAlign={'center'}></Skeleton>
            <Skeleton p={8} borderRadius={20} height={'75vh'} width={'100%'} flexShrink={0} scrollSnapAlign={'center'}></Skeleton>
          </>
        )}
        <CardDadosPlano plans={plans} />
      </Box>
    </>
  );
}

export default EspecialidadesDeslogado;
