import React, { useState } from "react";
import { StarIcon } from "@chakra-ui/icons";
import { Radio, HStack, Box } from "@chakra-ui/react";

export default function StarRating({ rating, setRating, count, size }) {
    const [hover, setHover] = useState(null);
    return (
        <HStack spacing={"2px"}>
            {[...Array(count || 5)].map((star, index) => {
                const ratingValue = index + 1;
                return (
                    <Box
                        as="label"
                        key={index}
                        color={ratingValue <= (hover || rating) ? "#FF9A00" : "#e4e5e9"}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(null)}
                    >
                        <Radio
                            name="rating"
                            onChange={() => setRating(ratingValue)}
                            value={ratingValue}
                            d="none"
                        ></Radio>
                        <StarIcon boxSize={30} transition="color 200ms" />
                    </Box>
                );
            })}
        </HStack>
    );
}
