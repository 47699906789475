import React, { useContext } from 'react';
import { AccountContext } from '../../AccountContext';
import { HStack, VStack, Avatar, chakra, Box, Flex, Wrap, Image, Text, Img } from '@chakra-ui/react';
import ListaDependentes from './ListaDependentes';
import BottomNavBar from '../../BottomNavBar';
import Back from '../../../images/back.svg';
import LogoDashboard from '../../../images/logo_dashboard.svg';
import { useNavigate } from 'react-router-dom';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import ListarPets from './ListarPets';
function Main() {
  const { setPage, user } = useContext(AccountContext);
  setPage('Dependentes');
  let firstName = user.name.split(' ');
  const formatCpf = (str) => str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return (
    <>
      <ChildHeader />
      <VStack
        w={{ base: '90%', md: '500px' }}
        m="auto"
        justify="top"
        marginTop="5%"
        spacing="1rem"
        align="start"
        overflow="hidden"
        marginBottom="15%%"
      >
        <chakra.h1 fontWeight="bold" fontSize="lg">
          Gerenciamento Dependentes
        </chakra.h1>
        <HStack width="100%" spacing={6} borderBottom="0.5px solid #D6D9D8">
          <VStack alignItems="left">
            <chakra.p fontSize="sm" color="gray.500">
              Titular
            </chakra.p>
            <chakra.p fontSize="md" fontWeight="bold">
              {firstName[0]} {firstName[1]}
            </chakra.p>
          </VStack>
          <VStack alignItems="left">
            <chakra.p fontSize="sm" color="gray.500">
              CPF
            </chakra.p>
            <chakra.p fontSize="md" fontWeight="bold">
              {formatCpf(user.cpf)}
            </chakra.p>
          </VStack>
        </HStack>
        <chakra.h1 fontWeight="bold">Dependentes no Plano</chakra.h1>
        <ListaDependentes />
        <chakra.h1 fontWeight="bold">Pets no Plano</chakra.h1>
        <ListarPets />
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default Main;
