import { Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure, ModalCloseButton, Box, ModalHeader } from '@chakra-ui/react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { AccountContext } from '../../../AccountContext';
import { ReactComponent as CheckCircle } from '../../../../images/check-circle.svg';

export default function AvisoAnaliseContrato(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useContext(AccountContext);

  useEffect(() => {
    if (user.situacao_contrato === 'P') {
      onOpen();
    }
  }, [onOpen, user.situacao_contrato]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={20} m={8}>
          <ModalCloseButton />
          <ModalBody p={10}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={4} textAlign={'center'}>
              <CheckCircle height={36}></CheckCircle>
              <Text fontWeight={'bold'}>Cadastro em análise</Text>
              <Text>Seu cadastro foi recebido e encaminhado para nosso setor de análise. Retornaremos em até 48h com o resultado da validação</Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
