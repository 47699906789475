import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Text,
  DrawerOverlay,
  Center,
  Heading,
  Button,
  HStack,
  ButtonGroup,
  CloseButton,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import TextField from 'components/TextField';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function DrawerIndicacao(props) {
  const disclosure = props.disclosure;
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user, setUser } = useContext(AccountContext);
  var { state } = useLocation();

  if (user.loggedIn) {
    state = user;
  }

  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  function handleError() {
    setError(true);
    setLoading(false);
  }

  const sessionLogin = () => {
    fetch(`${ENDPOINT}/auth/login`, {
      credentials: 'include',
    })
      .catch((err) => {
        setUser((user) => {
          return { loggedIn: false, device: user.device };
        });
        return;
      })
      .then((r) => {
        if (!r || !r.ok || r.status >= 400) {
          setUser((user) => {
            return { loggedIn: false, device: user.device };
          });
          return;
        }
        return r.json();
      })
      .then((data) => {
        if (!data) {
          setUser((user) => {
            return { loggedIn: false, device: user.device };
          });
          return;
        }
        setUser({ ...data, device: user.device });
        if (data.id_contrato === 0 && data.titular === true) {
          if (data.contrato_pendente_pagamento === true) {
            navigate('/cadastro/analise', {
              state: {
                client_id: data.id_cliente_deovita,
                name: data.name,
                cpf: data.cpf,
              },
            });
          } else {
            navigate('/dashboard');
          }
        } else {
          navigate('/dashboard');
        }
      });
  };
  const userLogin = () => {
    const vals = {
      username: onlyNumbers(state.cpf),
      password: state.senha,
    };
    fetch(`${ENDPOINT}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        handleError();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          handleError();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setUser((prevState) => ({ ...prevState, ...data }));
        if (data.status) {
          handleError();
        } else if (data.loggedIn) {
          const payload = {
            cpf: data.cpf,
            numero: data.phone1,
            nome: data.name,
          };
          navigate('/dashboard');
        }
      });
  };
  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} borderBottomRadius={0} p={8}>
          <DrawerCloseButton />
          <DrawerBody>
          <Heading textColor="#5AB2AD" textAlign="center" textTransform="inherit" mb={8}>
            Recebeu um voucher? insira o código abaixo!
          </Heading>
            <Formik
              initialValues={{ cod_indicacao: '' }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, actions) => {
                const vals = { ...values };
                if (vals.cod_indicacao.toUpperCase().includes('COR')) {
                  fetch(`${ENDPOINT}/voucher/${vals.cod_indicacao}/${String(state.name).replace(' ', '%20')}/${state.cpf}/${props.clientId}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                  }).then((res) => {
                    setErrorText('');
                    if (!res || !res.ok || res.status >= 400) {
                      setErrorText('falha na verificação do código de indicação');
                      return;
                    }
                    res.json().then((data) => {
                      if (!data || data.success === false) {
                        setErrorText('Codigo de indicação invalido');
                        return;
                      }
                      if (data.success === true && data.message) {
                        setUser((prevState) => ({
                          ...prevState,
                          voucher: data && data.message && data.message.codigo,
                        }));
                        if (user.loggedIn === true) {
                          sessionLogin();
                        } else {
                          userLogin();
                        }
                      }
                    });
                  });
                } else {
                  fetch(`${ENDPOINT}/entrepreneur/voucher`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(vals),
                  }).then((res) => {
                    setErrorText('');
                    if (!res || !res.ok || res.status >= 400) {
                      setErrorText('falha na verificação do código de indicação');
                      return;
                    }
                    res.json().then((data) => {
                      if (!data || data.success === false) {
                        setErrorText('Codigo de indicação invalido');
                        return;
                      }
                      if (data.success === true && data.data && data.data.length > 0) {
                        setUser((prevState) => ({
                          ...prevState,
                          voucher: data.data[0],
                        }));

                        setDisabled(true);
                        disclosure.onClose();
                      }
                    });
                  });
                }
              }}
            >
              <HStack as={Form}>
                <TextField placeholder="Codigo de Indicação" name="cod_indicacao" disabled={user.voucher ? false : disabled} />
                <ButtonGroup pt="0.5em">
                  <Button colorScheme="teal" type="submit" disabled={user.voucher ? false : disabled}>
                    {!disabled ? 'Aplicar' : 'aplicado'}
                  </Button>
                </ButtonGroup>
              </HStack>
            </Formik>
            <Center>
              <Text color="red">{errorText}</Text>
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerIndicacao;
