import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Button,
  Center,
  useDisclosure,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

export default function AvisoDependente() {
  const disclosure = useDisclosure();
  const { user, setUser } = useContext(AccountContext);
  const [dependentes, setDependentes] = useState([]);
  const navigate = useNavigate();
  const url = `${ENDPOINT}/client/dependents-no-cpf`;
  const payload = {
      id_contrato: user.id_contrato,
  };
  const fetchPosts = async () => {
    const response = await fetch(url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
    });
    const postsData = await response.json().then(async function(postsData){
      setDependentes(postsData.data);
      if(postsData.data && postsData.data.length > 0){
        disclosure.onOpen();
      } 
    }); 
  };
  useEffect(() => {
    if(!user.aviso_dependentes && user.id_contrato && user.id_contrato !== 0){
      fetchPosts();
    }
  }, []);

  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose} size="xs">
        <ModalOverlay />
        <ModalContent boxShadow="4px 4px 7px rgba(0, 0, 0, 0.25)" borderRadius="15px">
          <ModalHeader mt="3">
            <VStack spacing={3}>
              <Heading size="md">
                <Center>
                  <Text fontSize="2em" color="#585858"> NÃO DEIXE NINGUEM DE FORA!</Text>
                </Center>
              </Heading>
            </VStack>
          </ModalHeader>
          <ModalBody mb="3">
            <VStack spacing={3} align="start">
              <Text color="#529C94" fontWeight="bold" fontSize="1.3em">Atualize agora o cadastro de seus dependentes.</Text>
              
              <Button
                colorScheme="teal"
                isFullWidth
                borderColor="blue.500"
                borderRadius="1.1em"
                fontWeight="bold"
                onClick={() => {
                  disclosure.onClose();
                  setUser((prevState) => ({
                    ...prevState, aviso_dependentes: true
                  }))
                  navigate('/perfil/dependentes/');
                  
                }}
              >
                Atualizar agora
              </Button>
              <Button
                colorScheme="teal"
                variant="link"
                fontWeight="bold"
                isFullWidth
                onClick={(e) => {
                  disclosure.onClose();
                  setUser((prevState) => ({
                    ...prevState, aviso_dependentes: true
                  }))
                }}
              >
                Atualizar depois
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
