import { VStack, Button, Heading, Text, List, ListItem, ListIcon, ButtonGroup, Select, FormLabel } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Form, Formik } from 'formik';
import { React, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';
import BottomNavBar from '../../../BottomNavBar';
import ProgressBar from './ProgressBar';
import SelectField from 'components/SelectField';
import { useEffect } from 'react';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const Fim = () => {
  const { user } = useContext(AccountContext);
  const { state } = useLocation();
  const [comorb, sintomas] = state;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState('');
  const [value, setValue] = useState(4);
  const [dependentes, setDependentes] = useState(false);
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];
  const [client, setClient] = useState();
  const navigate = useNavigate();
  async function geturl() {
    setLoading(true);
    const resposta = await fetch(`${ENDPOINT}/rapidoc/url/${client}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    });
    resposta.json().then((res) => {
      if (res.success) {
        //window.location = res.data;
        navigate('/pa/atendimento', { state: { rapidocUrl: res.data } });
      } else {
        setLoading(false);
        setError('Não foi possível realizar seu atendimento no momento, tente novamente mais tarde ou entre em contato com o suporte');
      }
    });
  }

  const fetchDependentes = async () => {
    const url2 = ENDPOINT + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url2, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    const dependentes = resp.data;
    setDependentes(dependentes);
    setClient(dependentes[0].paciente_id);
  };

  useEffect(() => {
    fetchDependentes();
  }, []);

  return dependentes ? (
    <>
      <ChildHeader />
      <ProgressBar value={value} />
      <Formik initialValues={{ dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
        <VStack as={Form} w={{ base: '100%', md: '500px' }} m="auto" padding="5%" justify="top" overflow="hidden" align="start">
          {showText && (
            <Text fontSize="xs" color="red">
              Isso pode demorar alguns segundos por favor aguarde. Você será redirecionado ao atendimento com o médico em breve.
            </Text>
          )}
          <Heading fontSize="1.2rem">Confirme suas informações</Heading>
          <SelectField
            name="dependente"
            placeholder="Paciente"
            label="Selecione o paciente:"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => {
              setError('');
              setShowText(false);
              setClient(dependentes[e].paciente_id);
            }}
            options={arrayNomeDependentes}
          />

          <Text as="p" color="red.500">
            {error}
          </Text>

          <Text fontSize="1rem" fontWeight="bold">
            Comorbidades
          </Text>
          <List>
            {comorb.map((item) => (
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                {item.nome}
              </ListItem>
            ))}
          </List>
          <Text fontSize="1rem" fontWeight="bold">
            Sintomas
          </Text>
          <List>
            {sintomas.map((item) => (
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                {item.nome}
              </ListItem>
            ))}
          </List>

          <Text as="p">Clique no botão abaixo para ser redirecionado para seu atendimento.</Text>

          <ButtonGroup width="100%" paddingBottom="5%">
            <Button
              colorScheme="teal"
              w="100%"
              isLoading={loading}
              loadingText="Carregando"
              onClick={() => {
                geturl();
                setValue(5);
                setShowText(!showText);
              }}
            >
              Continuar
            </Button>
          </ButtonGroup>
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  ) : (
    <>
      <ChildHeader />
      <ProgressBar value={value} />
    </>
  );
};

export default Fim;
