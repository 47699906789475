/**
 * Função para converter um valor em centavos para reais.
 * 
 * @param {number} valorEmCentavos - Valor numérico em centavos. Ex: 652425
 * @returns {number} - Valor em reais (decimal). Ex: 6524.25
 */
export function converterCentavosParaReais(valorEmCentavos) {
  return valorEmCentavos / 100;
}

/**
* Função para formatar um valor em reais no padrão monetário brasileiro (sem símbolo de moeda).
* 
* @param {number} valorEmReais - Valor numérico em reais. Ex: 6524.25
* @returns {string} - Valor formatado com separadores de milhar e decimais. Ex: 6.524,25
*/
export function formatarValorMonetario(valorEmReais) {
  return valorEmReais.toLocaleString('pt-BR', {
      minimumFractionDigits: 2, // Garantir sempre 2 casas decimais
      maximumFractionDigits: 2  // Não permitir mais que 2 casas decimais
  });
}

export function formataCentavosParaRealBrasileiro(valorEmCentavos) {
  const reais = converterCentavosParaReais(valorEmCentavos);
  return formatarValorMonetario(reais);
}