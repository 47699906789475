import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Stack,
  DrawerOverlay,
  Image,
  Button,
  useDisclosure,
  VStack,
  Center,
  Header,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Banner1 from '../../../images/banner1.png';
function DrawerCompraPlano(props) {
  const disclosure = props.disclosure;
  const navigate = useNavigate();
  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} mb="-10" width="100%" h="90%">
          <DrawerBody mt="10" paddingTop={10}>
            <Center>
              {' '}
              <Heading mt="5" textColor="#5AB2AD" textAlign="center" textTransform="full-size-kana" paddingTop={20}>
                Para continuar é necessário ser cadastrado.
              </Heading>
            </Center>
            <Stack direction="column" spacing={4} paddingTop={30} align="center">
              <Button
                id='id_dcp_cadastrar'
                bgColor="#5AB2AD"
                color="white"
                _hover="none"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                onClick={() => {
                  navigate('/cadastro');
                }}
              >
                Quero me cadastrar
              </Button>
              <Button
                id='id_dcp_cadastrado'
                bgColor="#FF9A00"
                mt="5%"
                _hover="none"
                color="white"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Já sou cadastrado
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerCompraPlano;
