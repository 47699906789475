import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Button,
  Center,
  useDisclosure,
  ModalCloseButton,
  List,
  ListItem,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';

export default function AvisoTelefone(props) {
  const disclosure = props.disclosure;
  const { user, setUser } = useContext(AccountContext);
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered size="xs">
        <ModalOverlay />
        <ModalContent boxShadow="4px 4px 7px rgba(0, 0, 0, 0.25)" borderRadius="15px">
          <ModalHeader mt="3">
            <VStack spacing={3}>
              <Heading size="md">
                <Center>
                  <Text fontSize="2em" color="#585858">
                    Atenção!
                  </Text>
                </Center>
              </Heading>
            </VStack>
          </ModalHeader>
          <ModalBody mb="3">
            <VStack spacing={3} align="start">
              <Text color="#529C94" fontWeight="bold" fontSize="1em" textAlign="left">
                Para seguir com o uso do app é necessário atualizar seu número de telefone.
              </Text>
              <Button
                colorScheme="teal"
                isFullWidth
                borderColor="blue.500"
                borderRadius="1.1em"
                fontWeight="bold"
                onClick={() => {
                  disclosure.onClose();
                  navigate('/perfil/dados-pessoais', { state: { defaultIndex: 1 } });
                }}
              >
                Atualizar agora
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
