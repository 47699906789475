import {
  VStack,
  Heading,
  Text,
  Stack,
  Center,
  Thead,
  Tr,
  Table,
  Td,
  Tbody,
  TableContainer,
  Box,
  Grid,
  HStack,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ChangeTitle from '../ChangeTitle';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import { AccountContext } from '../AccountContext';
import background from '../../images/sucesso-background-3.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const FimCadastroCliente = () => {
  ChangeTitle('Cadastro');
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const { setPage, setUser, user } = useContext(AccountContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  setPage('Sucesso');

  function Card() {
    return (
      <Center>
        <Box padding="2%" bgColor="white" borderWidth="1px" borderRadius="1em" width="110%">
          <Box>
            <Text fontSize="sm" textColor="teal.500" fontWeight="semibold" fontFamily="body" textAlign="center">
              Médicos e especialistas
            </Text>
            <Text textColor="teal.500" fontWeight="semibold" marginTop="-0.4em" fontFamily="body" fontSize="sm" textAlign="center">
              sem custo para assinantes:
            </Text>{' '}
            <HStack>
              <Box p={1}>
                <TableContainer w="100%">
                  <Table variant="none" size="5">
                    <Tbody>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Clinico Geral</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontSize="0.5em">Cardiologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Dermatologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Endocrinologia</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <TableContainer w="100%">
                  <Table variant="none" size="xs">
                    <Thead>
                      <Tr> </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Otorrinolaringologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Pediatria</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Psiquiatria</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Traumatologia</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <TableContainer w="100%">
                  <Table variant="none" size="xs">
                    <Thead>
                      <Tr> </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Urologia</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Nutrição</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Veterinário</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          {' '}
                          <Text fontSize="0.5em">Geriatria</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </HStack>
          </Box>
        </Box>
      </Center>
    );
  }

  const userLogin = useCallback(async () => {
    const res = await fetch(`${ENDPOINT}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: onlyNumbers(state.cpf),
        password: state.senha,
      }),
    });

    if (!res || !res.ok || res.status >= 400) {
      throw new Error('Status login inválido');
    }

    return res.json();
  }, [state.cpf, state.senha]);

  console.log(loading);

  useEffect(() => {
    if (!user.loggedIn) {
      (async () => {
        try {
          setLoading(true);
          const data = await userLogin();

          if (data && data.loggedIn) {
            setUser((prevState) => ({ ...prevState, ...data }));
          }
        } catch (error) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [setUser, user.loggedIn, userLogin]);

  return (
    <Box minHeight={'100vh'}>
      <NoUserHeader />

      <Formik w="100%">
        <Stack>
          <Center>
            <Stack direction="column">
              {' '}
              <Heading
                as="h1"
                mt="1"
                bgClip="text"
                bgGradient="linear-gradient(90deg, #529C94 0%, #5AB2AD 28.13%, #EFA73F 62.5%, #EB8B2A 100%);"
                fontSize="3em"
              >
                Parabéns!
              </Heading>
              <Center>
                <Text fontSize="0.8em" fontWeight="extrabold" color={'gray.600'}>
                  Cadastro finalizado com sucesso!
                </Text>
              </Center>
            </Stack>
          </Center>

          <VStack
            as={Form}
            backgroundImage={background}
            style={{
              backgroundSize: 'cover',
              paddingBottom: '7em',
            }}
            w="100%"
            mt="10%"
          >
            <Center>
              <VStack alignItems="center" paddingTop="12em">
                {' '}
                <Box>
                  <Grid mt="7em">
                    <Center>
                      {' '}
                      <Center>
                        {' '}
                        <VStack>
                          {' '}
                          {/*<Heading textColor="white" fontSize="1em" textAlign="center">
                            Comece a economizar agora!
                          </Heading>
                          <Text fontSize="8" textAlign="center" textColor="white" maxW="59%">
                            Seja um(a) assinante e pague apenas R$ {valor} em seus procedimentos agendados.
                          </Text>*/}
                        </VStack>
                      </Center>
                    </Center>{' '}
                  </Grid>
                </Box>
                <HStack spacing="24px">
                  <Center>
                    {' '}
                    {/*<Text textAlign="center" fontSize="sm" textColor="white" fontWeight="bold">
                      ECONOMIA DE R${valor}
                    </Text>*/}
                  </Center>
                </HStack>{' '}
                <Card />
                <Center>
                  {loading ? (
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={4} pt={4}>
                      <Spinner /> Carregando dados
                    </Box>
                  ) : user && user.service_type && user.service_type === 'assinar' ? (
                    <>
                      {' '}
                      <Stack direction="column" align="center" mt="1em" w="100%">
                        <Button
                          disabled={loading}
                          size="md"
                          colorScheme={'teal'}
                          variant="solid"
                          borderRadius="20em"
                          onClick={() => {
                            navigate('/cadastro/plano', { state: { ...state } });
                          }}
                        >
                          Continuar
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack direction="column" align="center" mt="1em">
                        <Button
                          disabled={loading && !error}
                          size="md"
                          colorScheme={'teal'}
                          variant="solid"
                          borderRadius="20em"
                          onClick={() => {
                            navigate('/cadastro/plano', { state: { ...state } });
                          }}
                        >
                          Quero ser assinante
                        </Button>
                        <Button
                          disabled={loading && !error}
                          size="sm"
                          variant="ghost"
                          borderRadius="20em"
                          onClick={() => {
                            if (!state.service) {
                              navigate('/dashboard');
                            } else {
                              if (state.service === 'telemedicine') {
                                navigate('/pagamentos', {
                                  state: {
                                    ...state,
                                    navig_to: 'alerts/sucesso/telemedicina',
                                    parcelsActive: true,
                                    item: { product_name: '', client_id: user.id_cliente_deovita, nome: user.name, fone: user.phone1 },
                                    expiration_pix_in_min: 5,
                                  },
                                });
                              }
                            }
                          }}
                        >
                          Continuar sem assinar
                        </Button>
                      </Stack>
                    </>
                  )}
                </Center>
                {error && (
                  <Text color={'red.800'} px={10} pt={2} textAlign={'center'}>
                    Ocorreu um erro: Não foi possível carregar os dados do usuário
                  </Text>
                )}
              </VStack>
            </Center>
          </VStack>
        </Stack>
      </Formik>
    </Box>
  );
};

export default FimCadastroCliente;
