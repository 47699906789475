import { React, useContext, useEffect, useState } from 'react';
import {
    chakra,
    Button,
    Heading,
    VStack,
    Text,
    Center,
    Stack,
    HStack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
} from '@chakra-ui/react';
import { FaCircleInfo } from 'react-icons/fa6';

function Pet(props) {
    const disclosure = props.disclosure;
    return (
        <>
            <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose} size="xs">
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <VStack mt={'1em'} align="left" >
                            <FaCircleInfo color="#585858" onClick={() => { }} />

                            <VStack align="left" mt={'1em'}>
                                <chakra.span fontSize="14" color={'#585858'} >1. Você pode adicionar até 4 dependentes de forma gratuita. Para adicionar um dependente extra, é necessário pagar uma taxa.</chakra.span>
                            </VStack>

                            <VStack align="left" mt={'1em'}>
                                <chakra.span fontSize="14" color={'#585858'} >2. Para remover um dependente, é necessário efetuar o pagamento de uma taxa de R$ 50,00. </chakra.span>
                            </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}

export default Pet
