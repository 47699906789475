import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  DrawerOverlay,
  Button,
  VStack,
  Center,
  Text,
  TableContainer,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import React, { useContext } from 'react';

const LinkButton = ({ link, type }) => {
  const { user } = useContext(AccountContext);
  const { id_contrato, descricao_plano, titular } = user;

  const backgroundColor =
    titular === true && id_contrato === 0
      ? '#529C94'
      : titular === false && id_contrato === 0
      ? '#EB8B2A'
      : titular === true && id_contrato > 0
      ? '#EB8B2A'
      : '#529C94';

  const fileName = String(link).split('/').pop().split('?')[0];

  return (
    <Button
      w={'100%'}
      backgroundColor={backgroundColor}
      color={'#FFF'}
      onClick={() => {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            service: true,
            openInBrowser: true,
            url: `${link}`,
          })
        );
      }}
    >
      {`${type} ${fileName}`}
    </Button>
  );
};

function DrawerDocumentos(props) {
  const disclosure = props.disclosure;
  var documentos = props.documentos;

  const ReceituariosSimplesAssinados = () => {
    if ('receituario_simples_assinado' in documentos && documentos.receituario_simples_assinado.length > 0) {
      return (
        <>
          {documentos.receituario_simples_assinado.map((e, id) => (
            <LinkButton link={e} type={'Receituário'} />
          ))}
        </>
      );
    } else {
      return (
        <Center>
          <Text>Não há receituários disponíveis</Text>
        </Center>
      );
    }
  };

  const AtestadosAssinados = () => {
    if ('Atestado_assinado' in documentos && documentos.Atestado_assinado.length > 0) {
      return (
        <>
          {documentos.Atestado_assinado.map((e, id) => (
            <LinkButton link={e} type={'Atestado'} />
          ))}
        </>
      );
    } else {
      return (
        <Center>
          <Text>Não há atestados disponíveis</Text>
        </Center>
      );
    }
  };

  const ReceituarioEspecialAssinado = () => {
    if ('receituario_especial_assinado' in documentos && documentos.receituario_especial_assinado.length > 0) {
      return (
        <>
          {documentos.receituario_especial_assinado.map((e, id) => (
            <LinkButton link={e} type={'Especial'} />
          ))}
        </>
      );
    } else {
      return (
        <Center>
          <Text>Não há Receituarios especiais disponíveis</Text>
        </Center>
      );
    }
  };

  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} mb="-10" width="100%" h="70%">
          <DrawerBody mt="0" p="5%">
            <Stack direction="column" spacing={3} align="center" w="100%">
              <VStack w="100%">
                <Tabs w="100%">
                  <TabList>
                    <Tab w="50%">Receituários</Tab>
                    <Tab w="50%">Atestados</Tab>
                  </TabList>
                  <TabPanels>
                    {/*primeira aba*/}
                    <TabPanel>
                      <TableContainer w="100%">
                        <VStack mt="5%">
                          <ReceituariosSimplesAssinados />
                          <ReceituarioEspecialAssinado />
                        </VStack>
                      </TableContainer>
                    </TabPanel>

                    {/*segunda aba*/}
                    <TabPanel>
                      <TableContainer w="100%">
                        <VStack mt="5%">
                          <AtestadosAssinados />
                        </VStack>
                      </TableContainer>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </VStack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerDocumentos;
