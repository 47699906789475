import { Search2Icon } from '@chakra-ui/icons';
import { Button, Input, InputGroup, InputRightElement, HStack } from '@chakra-ui/react';
import React from 'react';

function SearchBar() {
  const search = async () => {};

  return (
    <HStack width="100%">
      <InputGroup>
        <Input
          placeholder="Busque por nome"
          focusBorderColor="teal"
          backgroundColor="white"
          shadow="lg"
          borderRadius="50px"
          width="100%"
          value={''}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              search();
            }
          }}
        />

        <InputRightElement width="15%">
          <Button backgroundColor="#EB8B2A" borderRadius="50px" width="100%" children={<Search2Icon color="white" />} onClick={search}></Button>{' '}
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
}

export default SearchBar;
