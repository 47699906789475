import {
  HStack,
  VStack,
  Heading,
  Text,
  Badge,
  List,
  ListItem,
  Image,
  Box,
  chakra,
  Flex,
  useDisclosure,
  Img,
  Stack,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import ChildHeader from '../ChildHeader';
import NoUserHeader from '../../../AreaLivre/NoUserHeader';
import Loading from 'components/Loading';
import { MdOutlineLocationOn } from 'react-icons/md';
import _ from 'lodash';
import DrawerProcedimento from './DrawerProcedimentos';
import SadFace from '../../../../images/assets/icons/sad_face.svg';
import DrawerCompraPlano from '../DrawerCompraPlano';

const ENDPOINT = process.env.ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function EscolheMedicoProcedimento(props) {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const drawerProcedure = useDisclosure();
  const { state } = useLocation();
  const [unidades, setUnidades] = useState([]);
  const [error, setError] = useState(null);
  const numberOfProcedures = user.cart ? user.cart.itens.length : 0;
  const [todos, setTodos] = useState(false);
  const [parciais, setParciais] = useState(false);
  const [pick, setPick] = useState();
  const drawerCompraPlano = useDisclosure();
  const [orcamento, setOrcamento] = useState(false);
  
  useEffect(() => {

    if (!user.cart) {
      navigate('/dashboard');
    } else {
      console.log(state)
      // procurar uma agenda pra empresa que o cara escolheu



    }
  }, [user.cart]);

  setPage('Médicos')

  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack m="5%" pb="25%" spacing={3} align="center">
        <Text>Selecione o médico para cada procedimento:</Text>

      </VStack>
    </>  
  );
}

export default EscolheMedicoProcedimento;
