import React from 'react';
import OperationsInfo from './Operations/Info';

function ListaOperations(lista) {
  const operationsLista = Object.keys(lista).map((key) => {
    const useOperations = Object.keys(lista[key]).map((contrato) => {
      return (
        <>
          <OperationsInfo data={lista[key][contrato]} />
        </>
      );
    });
    return <>{useOperations}</>;
  });
  return <>{lista && operationsLista}</>;
}

export default ListaOperations;
