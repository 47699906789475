import { Box, Flex, HStack, Heading, Text, VStack, useMediaQuery, chakra, Spacer, ButtonGroup, Button, Image } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext } from 'react';
import EmojiCoracoes from '../../../images/EmojiCoracoes.svg';

function VirarTitular() {
  const { setPage, setUser, user } = useContext(AccountContext);
  setPage('Cancelamento do contrato');
  const [isMinHeight] = useMediaQuery('(max-height: 667px)');
  const [iphoneXr] = useMediaQuery('(max-height: 896px)');
  return (
    <>
      <ChildHeader />
      <VStack w={'100%'} overflowY="auto" maxHeight="calc(100vh - 200px)">
        <Box w={'74%'}>
          <HStack justify="space-between" w="100%" mt={'1em'}>
            <Heading fontSize="18px" color={'#EB8B2A'} fontWeight={'bold'}>
              <Image w="60px" h={'60px'} src={EmojiCoracoes}/>
            </Heading>
          </HStack>

          <HStack justify="space-between" w="100%" >
            <Heading fontSize="18px" color={'#EB8B2A'} fontWeight={'bold'}>
              Que ótimo! Mas antes, solicite ao titular que remova você da lista de dependentes dele
            </Heading>
          </HStack>
          <VStack align="left" mb={'1em'} mt={'1em'}>
            <chakra.span fontSize="16px" color={'#585858'}>
              Se preferir, entre em contato com uma consultora de vendas pelo nosso Call Center.
            </chakra.span>
          </VStack>
          <ButtonGroup
            pt="1rem"
            pb="25%"
            sx={{ width: '75%', position: 'fixed', bottom: 0 }}
            onClick={() => {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  service: true,
                  openInBrowser: true,
                  url: 'https://wa.me/5508002802133',
                })
              );}}
          >
            <Button name="btn" color="white" bg={'#EB8B2A'} type="submit" w={{ base: '100%' }} h={'2em'}>
              Seguir para o Call Center
            </Button>
          </ButtonGroup>
        </Box>
      </VStack>
    </>
  );
}

export default VirarTitular;
