/* eslint-disable import/no-unresolved */
import { VStack, Heading, Button, Text, InputGroup, InputRightElement, HStack, Switch, ButtonGroup } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon, WarningIcon } from '@chakra-ui/icons';
import TextField from 'components/TextField';
import ChangeTitle from 'components/ChangeTitle';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import BottomNavBar from 'components/BottomNavBar';

function SenhaClinica() {
  ChangeTitle('Cadastro');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [rule, setRule] = useState(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!?$*&@#-])[0-9a-zA-Z!?$*&@#-]{8,}$/);
  const [visibility, setVisibility] = useState('');
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();
  const { state } = useLocation();
  const isUpperCase = (string) => /[A-Z]/.test(string);
  const isUpperMinuscula = (string) => /[a-z]/.test(string);
  const isUpperNumeros = (string) => /[0-9]/.test(string);
  const isUpperCaracteresespeciais = (string) => /[!?$*&@#-]/.test(string);
  const isUpperLenght = (string) => /[0-9a-zA-Z!?$*&@#-]{8,}$/.test(string);
  console.log(state);
  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={{ usuario: state.cpf, senha: '', senha2: '' }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          usuario: Yup.string().required('Usuario obrigatório'),
          senha: Yup.string().trim().required('Senha Obrigatória!').matches(rule, 'Senha fora de padrão'),
          senha2: Yup.string()
            .required('Confirmação da senha é obrigatória')
            .test('pass-match', 'Senhas precisam ser iguais', function (value) {
              return this.parent.senha === value;
            }),
        })}
        onSubmit={(vals) => {
          const states = {
            ...state,
            usuario: vals.usuario,
            senha: vals.senha,
            //vendedor_id: user.id_vendedor,
            //empresa_id: 0,
          };
          navigate('/empreendedor/cadastro-estabelecimento/endereco', { state: states, ...states });
        }}
      >
        {({ values }) => (
          <VStack
            as={Form}
            w={{ base: '90%', md: '500px' }}
            m="auto"
            pb="20"
            justify="top"
            marginTop="5%"
            marginBottom="5%"
            spacing="1rem"
            align="start"
          >
            <Heading fontSize="1em">Escolha o usuário e senha.</Heading>
            <Text>{error ? 'Ocorreu um erro ao processar sua solicitação, tente novamente mais tarde ou contact o suporte' : null}</Text>
            <TextField pr="4.5rem" name="usuario" placeholder="Usuario" type="text" mask="999.999.999-99" disabled />
            <InputGroup size="md" id="senha">
              <TextField pr="4.5rem" name="senha" placeholder="Senha" type={show ? 'text' : 'password'} />
              <InputRightElement width="4rem" pt="1rem" size="md">
                <Button h="1.75rem" size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />} />
              </InputRightElement>
            </InputGroup>
            <InputGroup size="md" id="senha2">
              <TextField pr="4.5rem" name="senha2" placeholder="Repita a senha" type={show ? 'text' : 'password'} />
              <InputRightElement width="4rem" pt="1rem" size="md">
                <Button h="1.75rem" size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />} />
              </InputRightElement>
            </InputGroup>
            <Text as="p" fontSize="small">
              Sua senha precisa seguir os seguintes requisitos.
            </Text>
            <VStack alignItems="left" display={visibility}>
              <HStack>
                <WarningIcon color={isUpperCase(values.senha) ? 'green' : 'red'} />
                <Text as="p" fontSize="small">
                  Uma letra maiúscula.
                </Text>
              </HStack>
              <HStack>
                <WarningIcon color={isUpperMinuscula(values.senha) ? 'green' : 'red'} />
                <Text as="p" fontSize="small">
                  Uma letra minúscula.
                </Text>
              </HStack>
              <HStack>
                <WarningIcon color={isUpperNumeros(values.senha) ? 'green' : 'red'} />
                <Text as="p" fontSize="small">
                  Um número.
                </Text>
              </HStack>
              <HStack>
                <WarningIcon color={isUpperCaracteresespeciais(values.senha) ? 'green' : 'red'} />
                <Text as="p" fontSize="small">
                  Um caracter especial.
                </Text>
              </HStack>
              <HStack>
                <WarningIcon color={isUpperLenght(values.senha) ? 'green' : 'red'} />
                <Text as="p" fontSize="small">
                  Mínimo de 8 caracteres.
                </Text>
              </HStack>
            </VStack>
            <VStack alignItems="left" display={visibility === 'none' ? '' : 'none'}>
              <HStack>
                <WarningIcon color={isUpperLenght(values.senha) ? 'green' : 'red'} />
                <Text as="p" fontSize="small">
                  Mínimo de 8 caracteres.
                </Text>
              </HStack>
            </VStack>
            <HStack>
              <Switch
                size="sm"
                value={' '}
                onChange={(event) => {
                  if (event.target.checked === true) {
                    setRule(/[0-9a-zA-Z!?$*&@#-]{8,}$/);
                    setVisibility('none');
                  } else if (event.target.checked === false) {
                    setVisibility('');
                    setRule(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!?$*&@#-])[0-9a-zA-Z!?$*&@#-]{8,}$/);
                  }
                }}
              />
              <Text as="p" fontWeight="bold" fontSize="small">
                Prefiro uma senha fraca.
              </Text>
            </HStack>
            <ButtonGroup w="100%">
              <Button colorScheme="teal" w="100%" onClick={() => navigate(-1)}>
                Voltar
              </Button>
              <Button colorScheme="teal" w="100%" type="submit" isLoading={loading} loadingText="carregando, aguarde ...">
                Continuar
              </Button>
            </ButtonGroup>
          </VStack>
        )}
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default SenhaClinica;
