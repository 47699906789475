import { Box, Flex, HStack, Heading, Text, VStack, useMediaQuery, chakra, Spacer, ButtonGroup, Button, Image } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useEffect } from 'react';
import EmojiCoracoes from '../../../images/EmojiCoracoes.svg';
import Sucesso from '../../../images/SucessoLaranja.svg';
import { useNavigate } from 'react-router-dom';

function ContaExcluida() {
  const { setPage, setUser, user } = useContext(AccountContext);
  const navigate = useNavigate();
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

  const fetchExcluirConta = async () => {
    const response = await fetch(`${ENDPOINT}/clients/excluirConta`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ user: user }),
    });
    const handleLogout = async () => {
      try {
        await fetch(`${ENDPOINT}/auth/logout`, {
          method: 'GET',
          credentials: 'include',
        });
      } catch (error) {
        console.error(error.message);
      }
    };
    const postsData = await response.json().then(async (response) => {
      console.log('response ', response[0].paciente_id);
      setTimeout(() => {
        setUser({ loggedIn: false });
        handleLogout();
        navigate('/dashboard');
      }, 5000);
    });
  };

  useEffect(() => {
    fetchExcluirConta();
  }, [user]);

  return (
    <>
      <Box mt={'18em'}>
        <Flex minW="210px" maxW="340px" mx="auto" overflow="hidden" padding="1em" px={5} pt={5} justifyContent="center" alignItems="center">
          <VStack w={'100%'}>
            <VStack bg={'#F3F3F3'} borderRadius={'0.8em'} p={'1em'} w={'100%'}>
              <Image boxSize="30px" objectFit="cover" src={Sucesso} mr={'15em'} mb={'1em'}></Image>
              <VStack w={'100%'}>
                <Heading fontSize={'19px'} color={'#585858'} fontWeight={'bold'} ml={'-3em'}>
                  Sua conta foi excluída
                </Heading>
              </VStack>

              <chakra.span color={'#585858'} fontSize="16" textAlign="left">
                Você será direcionado para a tela de login do aplicativo.
              </chakra.span>
            </VStack>
          </VStack>
        </Flex>
      </Box>
    </>
  );
}

export default ContaExcluida;
