import {
  VStack,
  Button,
  Text,
  RadioGroup,
  Radio,
  chakra,
  Wrap,
  Flex,
  Heading,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
  Center,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import ChildHeader from '../Dashboard/Main/ChildHeader';
import { AccountContext } from '../AccountContext';
import Loading from '../LoadingWithHeader';
import PlanoImg from '../../images/deovita_plano.svg';
import Meses2white from '../../images/2-meses-white.svg';
import Meses2 from '../../images/2-meses.svg';

const PlanoModo = () => {
  const { user, setPage } = useContext(AccountContext);
  const colors = useColorModeValue(['#5AB2AD', '#EB8B2A', 'blue.50'], ['#5AB2AD', '#EB8B2A', 'blue.900']);
  const [tabIndex, setTabIndex] = useState(0);
  const bg = colors[tabIndex];
  const { state } = useLocation();
  console.log(state);
  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}

      <VStack w={{ base: '90%', md: '300px' }} m="auto" justify="top" marginBottom="5%" marginTop="5%" spacing="1rem" align="center">
        <Heading>
          <img src={PlanoImg} alt="logo deovita" />
        </Heading>
      </VStack>
    </>
  );
};

export default PlanoModo;
