import { Button, ButtonGroup, chakra, HStack, VStack, Heading, Switch, useDisclosure, useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'date-fns';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../../../Dashboard/Main/ChildHeader';
import TextField from '../../../TextField';
import SelectField from 'components/SelectField';
import validaCPF from '../../../Utils/Functions/validaCPF';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Cadastro() {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { state } = useLocation();
  const { user, setPage } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [municipiosFiltradoPorEstado, setMunicipiosFiltradoPorEstado] = useState([]);
  const [localidades, setLocalidades] = useState({ municipios: [], estados: [] });
  const navigate = useNavigate();
  const disclosure = useDisclosure();
  const toast = useToast();
  const [desabilitaCampo, setDesabilitaCampo] = useState(true);
  const [desabilitaCadastro, setDesabilitaCadastro] = useState(false);
  const initialValues = {
    nome: '',
    cpf: '',
    data_aniv: '',
    fone: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    localidadeIbge: 0,
    cidade: '',
    estado: '',
    bairro: '',
    sexo: '',
    switchCep: false,
    extra: state.extra,
  };
  setPage('Cadastro');
  function callToast(title, descricao) {
    toast({
      title: title,
      description: descricao,
      status: 'warning',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  const validationSchema = Yup.object({
    nome: Yup.string()
      .required('Nome é obrigatório')
      .matches(/^[A-Za-z]+\s[A-Za-z ]+$/m, 'Escreva o nome e sobrenome pelo menos e sem acentos ou caracteres especiais'),
    cpf: Yup.string().required('cpf é obrigatório').test('valida_cpf', 'Cpf inválido', validaCPF),
    data_aniv: Yup.date()
      .max(new Date(), 'Futuro não é permitido')
      .transform((value, originalValue) => {
        return parse(originalValue, 'dd/MM/yyyy', new Date());
      })
      .required('Data de nascimento é obrigatória!')
      .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),
    sexo: Yup.number().required('Sexo é obrigatório').label('sexo'),
    fone: Yup.string()
      .required('Telefone é obrigatório')
      .trim()
      .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido'),
    cep: Yup.string().required('Cep é obrigatório').min(10, 'Cep inválido'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    numero: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Somente números e letras são permitidos').required('Campo obrigatório'),
    localidadeIbge: Yup.string().required('Cidade é brigatória'),
    estado: Yup.string().required('Estado é obrigatório'),
    bairro: Yup.string().required('Bairro é obrigatório'),
  });

  useEffect(() => {
    fetch(`${ENDPOINT}/cities`, {
      method: 'GET',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .catch((error) => { })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }
        return res.json();
      })
      .then((data) => {
        let estados = [];
        data.map((cidade) => (!estados.includes(cidade.estado) ? estados.push(cidade.estado) : ''));
        setLocalidades({ municipios: data, estados: estados });
      });
  }, []);

  function handleSubmit(values) {
  
    const vals = {
      nome: values.nome.toUpperCase(),
      cpf: onlyNumbers(values.cpf),
      contrato_id: state.id_contrato,
      nascimento: values.data_aniv.replace(/([0-9]{2})[/]([0-9]{2})[/]([0-9]{4})/g, '$3-$2-$1'),
      cod_ibge: values.localidadeIbge,
      cep: onlyNumbers(values.cep),
      logradouro: values.endereco,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      sexo: values.sexo === 0 ? 'm' : 'f',
      celular: onlyNumbers(values.fone),
      email: '',
      extra: state.extra,
    };


    const whatsappVals = {
      nome: values.nome.toUpperCase(),
      numero: onlyNumbers(values.fone),
    };
    fetch(`${ENDPOINT}/client/add_dependent`, {
      method: 'POST',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setError('Erro na Rede');
          return res.json();
        }
        if (res.status === 200) {
          setError('');
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.success === false) {
          console.log(data.data)
          if (data.data.error === 'cliente já existe') {
            callToast('Importante', 'Não foi possível adicionar este dependente no momento, pois ele já pertence a outro contrato.');
          } else if (data.data.error === 'a quantidade de dependentes não pode ser superior a 5') {
            callToast('Importante', 'Não foi possível adicionar este dependente no momento, pois o limite de 5 dependentes foi alcançado.');
          } else {
            callToast('Importante', 'Não foi possível adicionar este dependente no momento, tente novamente mais tarde ou entre em contato com o suporte.');
          }
          return;
        }    
        if (data.success === true) {
          setError('');
          state?.navigate_to ? navigate(`${state?.navigate_to}`) : navigate('/alerts/sucesso/cadastrar_dependente');
          fetch(`${ENDPOINT}/whatsapp/confirmacao_dependente`, {
            method: 'POST',
            credentials: 'include',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(whatsappVals),
          });
        }
      });
  }

  const FormularioCadastro = ({ formikProps }) => {
    const { setFieldValue, values } = formikProps;

    function firstLetterToCapital(name) {
      let arr_names = name.split(' ');
      for (var i = 0; i < arr_names.length; i++) {
        arr_names[i] = arr_names[i].charAt(0).toUpperCase() + arr_names[i].slice(1);
      }
      let res = arr_names.join(' ');
      setFieldValue('nome', res);
    }

    function BuscaCep(cep) {
      cep = onlyNumbers(cep);
      async function buscaCep() {
        try {
          const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_VIACEP}/${cep}/json/`);
          console.log(resposta)
          resposta.json().then((res) => {
            console.log(res)
            if (res.erro === 'true' || res.erro === true) {
              setError(`O CEP que você digitou não foi encontrado. Favor, preencha um CEP válido ou clique em 'Não sei o cep / Digitar dados'`);
              setDesabilitaCadastro(true)
              setDesabilitaCampo(true)
              return;
            }

            if (res.logradouro === '' || res.logradouro === null) {
              setError(null);
              setFieldValue('endereco', res.logradouro);
              setFieldValue('bairro', res.bairro);
              setFieldValue('localidadeIbge', res.ibge);
              setFieldValue('cidade', res.localidade);
              setFieldValue('estado', res.uf);
              setDesabilitaCadastro(false)
              setDesabilitaCampo(false) //aqui eu libero o campo pra editar caso seja um cep 'universal'
              return
            }

            setDesabilitaCampo(true)
            setDesabilitaCadastro(false)
            setError(null);
            setFieldValue('endereco', res.logradouro);
            setFieldValue('bairro', res.bairro);
            setFieldValue('localidadeIbge', res.ibge);
            setFieldValue('cidade', res.localidade);
            setFieldValue('estado', res.uf);
          });
        } catch (error) {
          setError('Ocorreu um erro inesperado. Favor tente novamente ou se o erro persistir, entre em contato com o suporte');
        }
      }
      buscaCep();
    }

    const DadosPessoais = () => {
      return (
        <>
          <Heading size="sm">Dados pessoais:</Heading>
          <TextField name="nome" placeholder="Nome Completo" onChange={(e) => firstLetterToCapital(e.target.value)} />
          <TextField name="cpf" type="tel" placeholder="Digite seu CPF" mask="999.999.999-99" maskChar={null} />
          <SelectField
            name="sexo"
            placeholder="Sexo"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => setFieldValue(e)}
            options={['Masculino', 'Feminino']}
          />
          <HStack spacing="0.3rem" justify="top" align="start">
            <TextField name="data_aniv" type="tel" placeholder="Nascimento" mask="99/99/9999" maskChar={null} w="35vw" />
            <TextField name="fone" type="tel" placeholder="Celular com DDD" mask="(99) 99999-9999" maskChar={null} w="54.5vw" />
          </HStack>
        </>
      );
    };

    const Endereco = () => {
      const campoCep = () => {
        return values.switchCep ? (
          ''
        ) : (
          <TextField
            name="cep"
            placeholder="CEP"
            mask="99.999-999"
            type="tel"
            maskChar={'_'}
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
              if (e.currentTarget.value.replace('_', '').length >= 10) BuscaCep(e.currentTarget.value);
            }}
          />
        );
      };
      const switchCep = () => {
        return (
          <Switch
            name="switchCep"
            color="gray"
            size="sm"
            fontSize="sm"
            colorScheme="teal"
            isChecked={values.switchCep}
            onChange={(e) => {
              function setEnd() {
                console.log('e.target.checked ', e.target.checked)
                setFieldValue('cep', '00.000-000');
                setFieldValue('endereco', '');
                setFieldValue('bairro', '');
                setFieldValue('localidadeIbge', '');
                setFieldValue('cidade', '');
                setFieldValue('estado', '');
              }
              setDesabilitaCampo(false) //aqui eu libero o campo pra editar caso seja um cep 'universal'
              setFieldValue('switchCep', e.target.checked);
              e.target.checked ? setEnd() : setFieldValue('cep', '');
              console.log('e.target.checked ', e.target.checked)
              setDesabilitaCadastro(false)
            }}
          >
            Não sei o cep / Digitar dados
          </Switch>
        );
      };

      const campoEstado = () => {
        return values.switchCep ? (
          <SelectField
            name="campoEstado"
            placeholder="Estado"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => {
              setFieldValue('estado', localidades.estados[e]);
              setMunicipiosFiltradoPorEstado(localidades.municipios.filter((cidade) => cidade.estado === localidades.estados[e]));
            }}
            options={localidades.estados}
          />
        ) : (
          <TextField name="cidade" placeholder="Cidade" isDisabled={!values.switchCep} />
        );
      };

      const campoCidade = () => {
        let arrayNomeMunicipios = municipiosFiltradoPorEstado.map((municipio) => municipio.nome);
        arrayNomeMunicipios.sort((a, b) => {
          return a.localeCompare(b);
        });
        return values.switchCep ? (
          <SelectField
            name="campoCidade"
            placeholder="Cidade"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => {
              let codIbge = municipiosFiltradoPorEstado[parseInt(e)].codigo_ibge;
              setFieldValue('localidadeIbge', codIbge);
            }}
            isDisabled={values.estado === ''}
            options={arrayNomeMunicipios}
          />
        ) : (
          <TextField name="estado" placeholder="Estado" isDisabled={!values.switchCep} />
        );
      };

      return (
        <>
          <Heading size="sm">Endereço:</Heading>
          {campoCep()}
          {switchCep()}
          <TextField name="endereco" placeholder="Endereço" isDisabled={desabilitaCampo} />
          <HStack spacing="0.3rem" justify="top" align="start">
            <TextField name="numero" placeholder="Número" w="35vw" />
            <TextField name="complemento" placeholder="Complemento (opcional)" w="54.5vw" />
          </HStack>
          <HStack justify="top" align="start" w="100%">
            {campoEstado()}
            {campoCidade()}
          </HStack>
          <TextField name="bairro" placeholder="Bairro" isDisabled={desabilitaCampo} />
        </>
      );
    };

    return (
      <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" justify="top" marginTop="5%" spacing="0.3rem" align="start" paddingBottom="30%">
        <chakra.p color="red">{error}</chakra.p>
        {DadosPessoais()}
        <div></div>
        {Endereco()}
        <ButtonGroup pt="1rem" pb="25%" sx={{ width: '100%' }}>
          <Button name="btn" colorScheme="teal" type="submit" w={{ base: '100%' }} isDisabled={desabilitaCadastro}>
            Cadastrar
          </Button>
        </ButtonGroup>
      </VStack>
    );
  };

  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(props) => <FormularioCadastro formikProps={props} />}
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default Cadastro;
