import { Button, ButtonGroup, Heading, HStack, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect, useState, useContext } from 'react';
import * as Yup from 'yup';
import BottomNavBar from 'components/BottomNavBar';
import Select from 'components/SelectField';
import Input from 'components/TextField';
import validaCPF from 'components/Utils/Functions/validaCPF';
import ChildHeader from '../../ChildHeader';
import requestTimeout from 'components/Utils/Functions/requestTimeout';
import Loading from 'components/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;


const FormDadosBancariosClinica = () => {
  const { user } = useContext(AccountContext);
  const { state } = useLocation();
  console.log(state);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [documentType, setDocumentType] = useState('CPF');
  const documentMask = documentType === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99';

  const [banks, setBanks] = useState([{}]);
  const listbanks = banks.map((bank) => bank.nome_abreviado);

  const bankAccountTypesPagarme = ['conta_corrente', 'conta_poupanca', 'conta_corrente_conjunta', 'conta_poupanca_conjunta'];
  const bankAccountTypes = ['Conta corrente', 'Conta poupanca', 'Conta corrente conjunta', 'Conta poupanca conjunta'];

  const pixKeyTypes = ['CPF', 'E-mail', 'Telefone', 'Aleatória'];
  const [pixType, setPixType] = useState(null);

  const defaultArgs = { borderRadius: 8, w: '100%', maskChar: null };
  const defaultArgsSelect = { textAlign: 'left', fontWeight: 'normal', variant: 'outline', onChange: () => {} };
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;
  const navigate = useNavigate();

  let initialValues = { nome: '', documento: '', agencia: '', digitoAgencia: '', conta: '', digitoConta: '', chavePix: '' };

  const validationSchema = Yup.object({
    nome: Yup.string()
      .required('Campo obrigatório')
      .matches(/^[A-Za-z]+\s[A-Za-z ]+$/m, 'Escreva o nome e sobrenome pelo menos e sem acentos ou caracteres especiais'),
    documento:
      documentType === 'CPF'
        ? Yup.string()
            .required('Campo obrigatório')
            .trim()
            .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf))
        : Yup.string()
            .required('Campo obrigatório')
            .trim()
            .matches(/(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/, 'CNPJ inválido'),
    banco: Yup.number().required('Campo obrigatório'),
    agencia: Yup.string().required('Campo obrigatório'),
    digitoAgencia: Yup.string(),
    conta: Yup.string().required('Campo obrigatório'),
    digitoConta: Yup.string().required('Campo obrigatório'),
    tipoConta: Yup.number().required('Campo obrigatório'),
    tipoPix: Yup.number().required('Campo obrigatório'),
    chavePix: validaChavePix(),
  });

  let pixInputType;
  let pixInputMask;
  switch (pixType) {
    case 'CPF':
      pixInputType = 'tel';
      pixInputMask = '999.999.999-99';
      break;
    case 'E-mail':
      pixInputType = 'email';
      pixInputMask = '';
      break;
    case 'Telefone':
      pixInputType = 'tel';
      pixInputMask = '(99) 99999-9999';
      break;
    default:
      pixInputType = 'text';
      pixInputMask = '';
      break;
  }

  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  async function postEstablishment(payload) {
    setError(false);
    setLoading(true);
    const url = `${ENDPOINT}/licensedEstablishment/`;
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    try {
      const operatorData = await fetch(url, options);
      const operator = await operatorData.json();
      if (operator.success) {
        navigate('/empreendedor/cadastro-estabelecimento/dados-responsavel', {
          state: { empresa_id: operator.data.empresa_id, ...state },
        });
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  async function onSubmit(values) {
    const cpf = state.tipoempresa === 1 ? state.documento : '';
    const cnpj = state.tipoempresa === 0 ? state.documento : '';
    setLoading(true);
    const payload = {
      tipoempresa: state.tipoempresa,
      nome: state.nome,
      cod_ibge: state.cod_ibge,
      razao_social: state.razaoSocial,
      cpf,
      cnpj,
      email: state.email,
      telefone: state.fone,
      celular: state.celular,
      whatsapp: state.whatsapp,
      cep: state.cep,
      logradouro: state.endereco,
      numero: state.numero,
      bairro: state.bairro,
      complemento: state.complemento,
      descricao: state.detalhes,
      horario_seg_sex_inicio: state.inicioSegunda,
      horario_seg_sex_fim: state.fimSegunda,
      horario_sab_inicio: state.inicioSabado,
      horario_sab_fim: state.fimSabado,
      horario_dom_inicio: state.inicioDomingo,
      horario_dom_fim: state.fimDomingo,
      qtdmedico: state.qtdeMedicos,
      id_vendedor_indicacao: user.id_vendedor,
      id_bank: banks[values.banco].banco_id,
      tipo_chave_pix: pixKeyTypes[values.tipoPix],
      chave_pix: values.chavePix,
      bank_account: {
        bank_code: banks[values.banco].codigo,
        agencia: values.agencia,
        //agencia_dv: values.digitoAgencia,
        conta: values.conta,
        conta_dv: values.digitoConta,
        type: bankAccountTypesPagarme[values.tipoConta],
        document_type: documentType.toLowerCase(),
        document_number: onlyNumbers(values.documento),
        legal_name: values.nome,
      },
    };
    postEstablishment(payload);
  }

  function validaChavePix() {
    switch (pixType) {
      case 'CPF':
        return Yup.string()
          .required('Campo obrigatório')
          .trim()
          .test('valida_cpf', 'Cpf inválido', (cpf) => validaCPF(cpf));
      case 'E-mail':
        return Yup.string().trim().email('O email digitado é inválido').required('Campo obrigatório');
      case 'Telefone':
        return Yup.string()
          .required('Campo obrigatório')
          .trim()
          .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Telefone Inválido');
      default:
        return Yup.string().required('Campo obrigatório');
    }
  }

  async function fetchDatas(url, verb, payload) {
    let options;
    if (verb === 'get') {
      options = {
        signal: requestTimeout(timeOutInSeconds).signal,
        method: 'GET',
      };
    } else if (verb === 'post') {
      options = {
        signal: requestTimeout(timeOutInSeconds).signal,
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };
    }
    try {
      const respondeData = await fetch(url, options);
      return await respondeData.json();
    } catch (error) {
      setError(true);
      setLoading(false);
      console.log(error);
    }
  }

  async function getDatas() {
    const urlBanks = ENDPOINT + '/banks/';
    const requestBanks = await fetchDatas(urlBanks);
    if (requestBanks.success) {
      setBanks(requestBanks.data);
    } else {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    getDatas();
  }, []);

  return loading ? (
    <Loading />
  ) : error ? (
    navigate('/alerts/erro/default')
  ) : (
    <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validationSchema={validationSchema} onSubmit={onSubmit}>
      <VStack as={Form} w={{ base: '90%', md: '500px' }} align="start" mx="auto" mt="5" mb="20" spacing={5}>
        <Heading size="sm">Informe os dados bancários para o recebimento dos serviços</Heading>
        <Input
          {...defaultArgs}
          name="nome"
          placeholder={documentType === 'CPF' ? 'Nome completo' : 'Razão Social'}
          type="text"
          mask=""
          style={{ textTransform: 'capitalize' }}
        />
        <ButtonGroup w="100%">
          <Button {...defaultArgs} colorScheme={documentType === 'CPF' ? 'teal' : null} onClick={() => setDocumentType('CPF')}>
            CPF
          </Button>
          <Button {...defaultArgs} colorScheme={documentType === 'CNPJ' ? 'teal' : null} onClick={() => setDocumentType('CNPJ')}>
            CNPJ
          </Button>
        </ButtonGroup>
        <Input {...defaultArgs} name="documento" placeholder={documentType} type="tel" mask={documentMask} />
        <Select {...defaultArgs} {...defaultArgsSelect} name="banco" placeholder="Selecione o banco" options={listbanks} />
        <HStack w="100%" align="start">
          <Input {...defaultArgs} w="50%" name="agencia" placeholder="Agência" type="tel" mask="9999" />
          {/*<Input {...defaultArgs} name="digitoAgencia" placeholder="Dígito" type="tel" mask="9" />*/}
        </HStack>
        <HStack w="100%" align="start">
          <Input {...defaultArgs} name="conta" placeholder="Conta" type="tel" mask="9999999" />
          <Input {...defaultArgs} name="digitoConta" placeholder="Dígito" type="tel" mask="9" />
        </HStack>
        <Select {...defaultArgs} {...defaultArgsSelect} name="tipoConta" placeholder="Selecione o tipo de conta" options={bankAccountTypes} />
        <Select
          {...defaultArgs}
          {...defaultArgsSelect}
          name="tipoPix"
          placeholder="Selecione o tipo de chave pix"
          options={pixKeyTypes}
          onChange={(index) => setPixType(pixKeyTypes[index])}
        />
        <Input {...defaultArgs} name="chavePix" placeholder="Chave pix" type={pixInputType} mask={pixInputMask} />
        <ButtonGroup w="100%">
          <Button {...defaultArgs} colorScheme="teal" onClick={() => navigate(-1)}>
            Voltar
          </Button>
          <Button {...defaultArgs} colorScheme="teal" type="submit">
            Salvar
          </Button>
        </ButtonGroup>
      </VStack>
    </Formik>
  );
};

const DadosBancariosClinica = () => {
  return (
    <>
      <ChildHeader />
      <FormDadosBancariosClinica />
      <BottomNavBar />
    </>
  );
};

export default DadosBancariosClinica;
