import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Avatar,
  DrawerOverlay,
  DrawerContent,
  VStack,
  Center,
  Image,
  Flex,
  useColorModeValue,
  Box,
  Heading,
  Text,
  Wrap,
  WrapItem,
  chakra,
  Stack,
  Alert,
  AlertIcon,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Input,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { AccountContext } from 'components/AccountContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillDownCircle } from 'react-icons/ai';
import verifyPeriodOfTheDay from '../../Utils/Functions/verifyPeriodOfTheDay'

function DrawerPedidoPendente() {
  const { user, setUser } = useContext(AccountContext);
  const disclosure = useDisclosure();
  const navigate = useNavigate();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const data = user.pedido_pendente.agenda.data_inicio;
  const formData = new Date(data ? data.replace(' ', 'T') : '');
  const appTime = user.pedido_pendente.agenda.AVA_START_TIME;

  useEffect(() => {
    if (user.pedido_pendente.agenda !== null) {
      disclosure.onOpen();
    }
  }, [user.pedido_pendente.agenda]);

  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }

  return user.pedido_pendente ? (
    <>
      <Drawer isOpen={disclosure.onOpen} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderTopRadius="1em">
          <DrawerBody>
            <VStack
              align="center"
              justify={{
                base: 'center',
                md: 'space-around',
                xl: 'space-between',
              }}
              direction={{ base: 'column-reverse' }}
              wrap="no-wrap"
              spacing={6}
              px={5}
              py={6}
            >
              <Stack direction="row">
                <Heading fontSize="lg" align="center">
                  Volte e finalize seu agendamento pendente!
                </Heading>
              </Stack>

              <Center>
                <Stack
                  // borderColor={'teal'}
                  minW="100%"
                  maxW="500px"
                  direction={{ base: 'column', md: 'center' }}
                  bg={'white'}
                  padding={4}
                >
                  <VStack alignItems="right" spacing={4} width="100%">
                    <Center py={6}>
                      <Avatar
                        id={user.pedido_pendente.agenda.RESOURCE_LID}
                        size="xl"
                        name={user.pedido_pendente.medico.nome}
                        src={
                          process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + user.pedido_pendente.agenda.RESOURCE_LID + '/foto_profissional.webp?'
                        }
                      />
                    </Center>

                    <Heading fontSize="xs">
                      {user.pedido_pendente.agenda.ACTIVITY_NAME
                        ? user.pedido_pendente.agenda.ACTIVITY_NAME
                        : 'CONSULTA COM ' + user.pedido_pendente.especialidade.toUpperCase()}
                    </Heading>
                    <Text ml="-5" fontSize="0.7em" casing="capitalize">
                      {user.pedido_pendente.medico.dados.nome === 'Sem Solicitante'
                        ? ''
                        : 'Dr. ' + myToLowerCase(user.pedido_pendente.medico.dados.nome)}
                    </Text>
                    <chakra.p fontSize="0.7em">
                      <chakra.span fontWeight="bold"> Modalidade: </chakra.span>
                      {user.pedido_pendente.agenda.TELEMEDICINA === true ? 'Telemedicina' : 'Presencial'}
                    </chakra.p>
                    <chakra.p fontSize="0.7em">
                      <chakra.span fontWeight="bold"> Data da consulta: </chakra.span>
                      {user.pedido_pendente.agenda.RESOURCE_NAME === 'Sem Solicitante'
                        ? //formData.toLocaleDateString('pt-br', options) +
                        ' no turno da ' + 
                        
                                                  verifyPeriodOfTheDay(appTime) +
                        
                                                  ' (Atendimento por ordem de chegada)'
                        : formData.toLocaleDateString('pt-br', options)}
                    </chakra.p>
                    <chakra.p fontSize="0.7em">
                      <chakra.span fontWeight="bold"> Horário: </chakra.span>
                      {user.pedido_pendente.agenda.RESOURCE_NAME === 'Sem Solicitante'
                        ? //formData.toLocaleDateString('pt-br', options) +
                        ' no turno da ' + 
                        
                                                  verifyPeriodOfTheDay(appTime) +
                        
                                                  ' (Atendimento por ordem de chegada)': appTime
                          ? appTime.split(':')[0] + ':' + appTime.split(':')[1]
                          : ''}
                    </chakra.p>
                  </VStack>
                </Stack>
              </Center>
              <Center>
                <Button
                  colorScheme="teal"
                  borderRadius="10"
                  onClick={() => {
                    navigate('/atendimento/pedido', {
                      state: { ...user.pedido_pendente, paciente: { nome: user.name, ...user } },
                    });
                  }}
                >
                  Seguir Para o Agendamento
                </Button>
              </Center>
            </VStack>

            <VStack mb="15%">
              <Stack direction="column" spacing={2}>
                <Button
                  colorScheme="teal"
                  borderRadius={8}
                  height="40px"
                  onClick={() => {
                    setUser((prevState) => ({ ...prevState, pedido_pendente: null }));
                    disclosure.onClose();
                  }}
                  variant="ghost"
                >
                  Seguir sem agendar
                </Button>
              </Stack>
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  ) : (
    <></>
  );
}

export default DrawerPedidoPendente;
