import { Box, Center, chakra, HStack, VStack } from '@chakra-ui/react';
import { MdOutlineHowToReg, MdStoreMallDirectory, MdOutlineQueryStats, MdOutlineMonetizationOn } from 'react-icons/md';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Resumo(props) {
  const { contratos, empresas, vendedores } = props;
  const totalContratos = contratos ? Object.keys(contratos).length : '-';
  const totalCredenciados = empresas ? Object.keys(empresas).length : '-';
  const totalVendedores = vendedores ? Object.keys(vendedores).length : '-';
  const navigate = useNavigate();
  let totalGanho = 0;

  const contratosList =
    contratos !== null || contratos !== undefined || contratos !== [] || contratos !== {}
      ? contratos.map((contrato) => {
          if (contrato.plano_id === 55) {
            if (contrato.valor_pago === '99.00') {
              totalGanho += contrato.valor_pago ? (parseFloat(contrato.valor_pago.replace(',', '.')) / 100) * 7.21 : 0;
            }
            if (contrato.valor_pago === '9.90') {
              totalGanho += contrato.valor_pago ? (parseFloat(contrato.valor_pago.replace(',', '.')) / 100) * 66.66 : 0;
            }
            if (contrato.valor_pago === '19.90') {
              totalGanho += contrato.valor_pago ? (parseFloat(contrato.valor_pago.replace(',', '.')) / 100) * 33.16 : 0;
            }
          }
          if (contrato.plano_id === 29) {
            if (contrato.valor_pago === '299.00') {
              totalGanho += contrato.valor_pago ? (parseFloat(contrato.valor_pago.replace(',', '.')) / 100) * 7.33 : 0;
            }
            if (contrato.valor_pago === '29.90') {
              totalGanho += contrato.valor_pago ? (parseFloat(contrato.valor_pago.replace(',', '.')) / 100) * 66.88 : 0;
            }
            if (contrato.valor_pago === '39.90') {
              totalGanho += contrato.valor_pago ? (parseFloat(contrato.valor_pago.replace(',', '.')) / 100) * 50.12 : 0;
            }
          }
        })
      : [];

  return (
    <VStack spacing={8} paddingTop="10%" paddingBottom="10%" width="100%">
      <HStack spacing={0} width="100%">
        <Box width="100%" height="100px" onClick={() => navigate('/empreendedor/contratos', { state: { contratos: contratos } })}>
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="25%" height="80%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(78, 158, 143, 0.1)">
              <Center height="100%">
                <MdOutlineHowToReg size="3em" color="rgba(78, 158, 143, 1)" />
              </Center>
            </Box>
            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" fontWeight="bold" mt="15%" color="#6F767E">
                Total de
              </chakra.p>
              <chakra.p fontSize="sm" fontWeight="bold" color="#6F767E">
                Contratos
              </chakra.p>
              <chakra.p fontSize="1.5em">{totalContratos}</chakra.p>
            </VStack>
          </HStack>
        </Box>
        {/*<Box width="50%" height="100px" onClick={() => navigate('/empreendedor/estabelecimentos', { state: { empresas: empresas } })}>
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="50%" height="80%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(235, 139, 42, 0.1)">
              <Center height="100%">
                <MdStoreMallDirectory size="3em" color="rgba(235, 139, 42, 1)" />
              </Center>
            </Box>
            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" textAlign="justify" mt="15%" fontWeight="bold" color="#6F767E">
                Empresas
              </chakra.p>
              <chakra.p fontSize="sm" textAlign="justify" fontWeight="bold" color="#6F767E">
                Credenciadas
              </chakra.p>
              <chakra.p fontSize="1.5em">{totalCredenciados}</chakra.p>
            </VStack>
          </HStack>
  </Box>*/}
      </HStack>
      {/* <HStack spacing={0} width="100%"> */}
        {/*<Box width="50%" height="100px" onClick={() => navigate('/empreendedor/vendedores', { state: { vendedores: vendedores } })}>
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="50%" height="80%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(78, 158, 143, 0.1)">
              <Center height="100%">
                <MdOutlineQueryStats size="3em" color="rgba(78, 158, 143, 1)" />
              </Center>
            </Box>
            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" fontWeight="bold" mt="15%" color="#6F767E">
                Total de
              </chakra.p>
              <chakra.p fontSize="sm" fontWeight="bold" color="#6F767E">
                Vendedores
              </chakra.p>
              <chakra.p fontSize="1.5em">{totalVendedores}</chakra.p>
            </VStack>
          </HStack>
        </Box>*/}
        {/* <Box
          width="100%"
          height="100px"
          onClick={() => navigate('/mdv/ganhos-totais')}
        >
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="25%" height="80%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(235, 139, 42, 0.1)">
              <Center height="100%">
                <MdOutlineMonetizationOn size="3em" color="rgba(235, 139, 42, 1)" />
              </Center>
            </Box>
            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" textAlign="justify" mt="15%" fontWeight="bold" color="#6F767E">
                Total
              </chakra.p>
              <chakra.p fontSize="sm" textAlign="justify" fontWeight="bold" color="#6F767E">
                Ganho
              </chakra.p>
              <chakra.p fontSize="1.5em">
                {totalGanho !== 0
                  ? totalGanho.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : '-'}
              </chakra.p>
            </VStack>
          </HStack>
        </Box>
      </HStack> */}
    </VStack>
  );
}

export default Resumo;
