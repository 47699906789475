import {
  AspectRatio,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FileCircle from '../../images/file-circle.svg';
import CamIcon from '../../images/cam.svg';
import { ReactComponent as CheckCircle } from '../../images/check-circle.svg';
import { ReactComponent as SadCircle } from '../../images/sad-circle.svg';
import { useLocation, useNavigate } from 'react-router-dom';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function useCamera(videoRef, canvasRef, streamRef) {
  const [photo, setPhoto] = useState(null);

  const takePhoto = () => {
    const width = 640;
    const height = 480;

    let video = videoRef.current;
    let photo = canvasRef.current;

    photo.width = width;
    photo.height = height;

    let context = photo.getContext('2d');
    context.drawImage(video, 0, 0, width, height);

    setPhoto(photo.toDataURL('image/png'));
  };

  const reset = () => {
    let photo = canvasRef.current;
    let context = photo.getContext('2d');
    context.clearRect(0, 0, photo.width, photo.height);

    setPhoto(null);
  };

  const startStream = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        streamRef.current = stream;
        const video = videoRef.current;
        video.srcObject = stream;
        video.setAttribute('playsinline', 'true');
        video.setAttribute('webkit-playsinline', 'true'); // For Safari on iOS
        video.play();
      })
      .catch((err) => {
        console.error('error:', err);
      });
  }, [streamRef, videoRef]);

  const stopStream = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  }, [streamRef]);

  useEffect(() => {
    return () => {
      stopStream();
    };
  }, [stopStream]);

  return { photo, takePhoto, reset, startStream, stopStream };
}

export default function VerificacaoIdentidadeEnel() {
  const [md, lg] = useMediaQuery(['(min-width: 375px)', '(min-width: 425px)']);
  const { state } = useLocation();
  const { idContrato } = state;

  const navigate = useNavigate();

  const [successEnvio, setSuccessEnvio] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: openLoading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure();

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const firstUpdate = useRef(true);

  const {
    photo: fotoFatura,
    takePhoto: capturaFatura,
    reset: resetFatura,
    startStream,
    stopStream: stopStreamFatura,
  } = useCamera(videoRef, canvasRef, streamRef);
  const {
    photo: fotoDocumento,
    takePhoto: capturaDocumento,
    reset: resetDocumento,
    stopStream: stopStreamDocumento,
  } = useCamera(videoRef, canvasRef, streamRef);

  const enviarFotos = async () => {
    onClose();
    onOpenLoading();

    const { success } = await fetch(`${ENDPOINT}/enel/registrar/fotos`, {
      method: 'POST',
      body: JSON.stringify({
        contrato: idContrato,
        foto: {
          documento: fotoFatura,
          fatura: fotoDocumento,
        },
      }),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .catch((err) => {
        console.error(err);
        setSuccessEnvio(false);
      })
      .finally(() => {
        onCloseLoading();
      });

    setSuccessEnvio(success);
  };

  const showStream = useMemo(() => !isOpen && !openLoading && successEnvio === null && !firstUpdate.current, [isOpen, openLoading, successEnvio]);

  useEffect(() => {
    onOpen();
  }, [fotoFatura, fotoDocumento, onOpen]);

  useEffect(() => {
    if (showStream) {
      startStream();
    } else {
      stopStreamFatura();
      stopStreamDocumento();
    }
  }, [showStream, startStream, stopStreamDocumento, stopStreamFatura]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, []);

  return (
    <>
      <Box p={lg ? 14 : 10} display={'flex'} flexDirection={'column'} gap={4}>
        <Text color={'#529C94'} fontWeight={'bold'} fontSize={'xl'}>
          {fotoFatura ? 'Tire uma selfie segurando o seu documento (RG, CPF, CNH).' : 'Tire uma selfie segurando a fatura da sua conta de luz'}
        </Text>
        <Text>
          Certifique-se de que esteja em um local iluminado e que a imagem esteja nítida. Caso sua foto não fique boa, você poderá capturar uma nova
          foto.
        </Text>

        <Box>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          <AspectRatio maxW="100%" ratio={9 / 16}>
            <Box position={'relative'} borderRadius={20} overflow={'hidden'} border={'2px solid #529C94'} mt={4}>
              <video
                ref={videoRef}
                width={'100%'}
                style={{
                  display: showStream ? 'inline-block' : 'none',
                  aspectRatio: 9 / 16,
                  objectFit: 'cover',
                  transform: 'scaleX(-1)',
                }}
              ></video>
              <Button
                onClick={fotoFatura ? capturaDocumento : capturaFatura}
                position={'absolute'}
                bottom={4}
                left={md ? 16 : 10}
                right={md ? 16 : 10}
                colorScheme="teal"
                gap={4}
              >
                Capturar <Image src={CamIcon}></Image>
              </Button>
            </Box>
          </AspectRatio>
        </Box>
        <Button isFullWidth onClick={onOpen} disabled={!fotoFatura} colorScheme="teal" mt={4}>
          Pronto!
        </Button>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent m={8} rounded={20} fontSize={md ? 'base' : 'sm'}>
          <ModalHeader textAlign={'center'} fontSize={md ? 'base' : 'md'}>
            Tirar foto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={8}>
              <Image src={FileCircle} height={md ? 'unset' : 16}></Image>
              <Text fontWeight={'bold'}>
                Agora, precisamos que tire duas selfies. Uma segurando a fatura da sua conta de luz e uma com seu documento (RG, CPF, CNH)
              </Text>

              {(fotoDocumento || fotoFatura) && (
                <Grid templateColumns="repeat(2, 1fr)" alignItems={'end'} gap={4}>
                  {fotoFatura && (
                    <GridItem>
                      <Text fontSize={'xs'} textAlign={'center'} mb={4}>
                        Fatura da sua <br />
                        conta de luz
                      </Text>
                      <Box position={'relative'} borderRadius={20} overflow={'hidden'} border={'2px solid #529C94'}>
                        <Skeleton speed={'1s'} isLoaded={fotoFatura}>
                          <img
                            src={fotoFatura}
                            alt="captured"
                            width={'100%'}
                            style={{ aspectRatio: 9 / 16, objectFit: 'cover', transform: 'scaleX(-1)' }}
                          />
                        </Skeleton>
                        <Button
                          onClick={resetFatura}
                          position={'absolute'}
                          bottom={4}
                          left={md ? 2 : 0}
                          right={md ? 2 : 0}
                          size="xs"
                          colorScheme="teal"
                        >
                          Tentar de novo
                        </Button>
                      </Box>
                    </GridItem>
                  )}

                  {fotoDocumento && (
                    <GridItem>
                      <Text fontSize={'xs'} textAlign={'center'} mb={4}>
                        Documento
                      </Text>
                      <Box position={'relative'} borderRadius={20} overflow={'hidden'} border={'2px solid #529C94'}>
                        <img
                          src={fotoDocumento}
                          alt="captured"
                          width={'100%'}
                          style={{ aspectRatio: 9 / 16, objectFit: 'cover', transform: 'scaleX(-1)' }}
                        />
                        <Button
                          onClick={resetDocumento}
                          position={'absolute'}
                          bottom={4}
                          left={md ? 2 : 0}
                          right={md ? 2 : 0}
                          size="xs"
                          colorScheme="teal"
                        >
                          Tentar de novo
                        </Button>
                      </Box>
                    </GridItem>
                  )}
                </Grid>
              )}

              {!(fotoFatura && fotoDocumento) && (
                <Text>{fotoFatura ? 'Agora, tire uma selfie com seu documento' : 'Primeiro, tire uma selfie com a fatura da sua conta de luz'}</Text>
              )}
            </Box>
          </ModalBody>
          <ModalFooter pb={6}>
            {fotoFatura && fotoDocumento ? (
              <Button isFullWidth colorScheme="teal" onClick={enviarFotos} gap={4}>
                Finalizar!
              </Button>
            ) : (
              <Button isFullWidth colorScheme="teal" onClick={onClose} gap={4}>
                Tirar foto <Image src={CamIcon}></Image>
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={openLoading} isCentered>
        <ModalOverlay />
        <ModalContent m={8} bg="#F3F3F3" rounded={20}>
          <ModalBody p={8}>
            <Center gap={10} flexDirection={'column'}>
              <Spinner thickness="4px" emptyColor="gray.200" color="#5AB2AD" size="xl" speed="1s" />
              <Text>
                Estamos carregando as <br />
                informações, aguarde...
              </Text>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={successEnvio !== null} isCentered>
        <ModalOverlay />
        <ModalContent m={8} bg="#F3F3F3" rounded={10}>
          <ModalBody p={8} color={'#585858'} textAlign={'center'}>
            <Center gap={4} flexDirection={'column'}>
              {successEnvio === true ? (
                <>
                  <CheckCircle></CheckCircle>
                  <Text fontWeight={'bold'}>Fotos enviadas!</Text>
                  <Text>Suas fotos foram enviadas para nosso setor de análise. Iremos confirmar a validação dos seus dados em até 48h!</Text>
                </>
              ) : (
                <>
                  <SadCircle />
                  <Text fontWeight={'bold'}>
                    Não foi possível
                    <br /> enviar suas fotos...
                  </Text>
                  <Text>Parece que suas selfies não foram enviadas para nosso setor de análise. Por favor, tente novamente mais tarde.</Text>
                </>
              )}
            </Center>
          </ModalBody>
          <ModalFooter>
            {successEnvio === true ? (
              <Button isFullWidth colorScheme="teal" onClick={() => navigate('/')}>
                Entendi!
              </Button>
            ) : (
              <Button isFullWidth colorScheme="teal" onClick={() => setSuccessEnvio(null)}>
                Tentar novamente
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
