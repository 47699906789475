import { Box, Center, Image, chakra, HStack, VStack, Heading } from '@chakra-ui/react';
import { MdOutlineHowToReg, MdStoreMallDirectory, MdOutlineQueryStats, MdOutlineMonetizationOn } from 'react-icons/md';
import React from 'react';
import mesatualicon from '../../../../../images/assets/icons/iconesEmprendedor/Union.png';
import totalgeralicon from '../../../../../images/assets/icons/iconesEmprendedor/totalgeralicon.png';
import ListaNiveis from './ListaNiveis';
import Info from './Info';

function Resumo(props) {
  console.log(props);
  return (
    <VStack spacing={8} paddingTop="10%" paddingBottom="10%" width="100%" align="start">
      <Heading ml="3%" size="md" textAlign="initial">
        Gerenciamento
      </Heading>
      <HStack spacing={0} width="100%">
        <Box width="50%" height="100px">
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="50%" height="70%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(78, 158, 143, 0.1)">
              <Center height="100%">
                <Image src={mesatualicon} size="4em" color="rgba(78, 158, 143, 1)" />
              </Center>
            </Box>
            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" fontWeight="bold" mt="15%" color="#6F767E">
                Mês Atual
              </chakra.p>

              <chakra.p fontSize="2em">{props.totalGeral}</chakra.p>
            </VStack>
          </HStack>
        </Box>

        <Box width="50%" height="100px">
          <HStack w="100%" h="100%" spacing={5}>
            <Box width="50%" height="70%" marginLeft="5%" borderRadius="15px" backgroundColor="rgba(235, 139, 42, 0.1)">
              <Center height="100%">
                <Image src={totalgeralicon} size="3em" color="rgba(235, 139, 42, 1)" />
              </Center>
            </Box>

            <VStack width="60%" height="100%" spacing={-1} alignItems="left">
              <chakra.p fontSize="sm" textAlign="justify" mt="15%" fontWeight="bold" color="#6F767E">
                Total Geral
              </chakra.p>

              <chakra.p fontSize="2em">{props.totalGeral}</chakra.p>
              <ListaNiveis lista={''} />
            </VStack>
          </HStack>
        </Box>
      </HStack>
    </VStack>
  );
}

export default Resumo;
