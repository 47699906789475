import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  DrawerOverlay,
  Button,
  VStack,
  Center,
  HStack,
  Text,
  Divider,
  TableContainer,
  Table,
  Tr,
  Td,
  ButtonGroup,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Badge,
  Input,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  CheckboxGroup,
  Checkbox,
  chakra,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TrocarPlano from '../../../../images/TrocarPlanoIndividual.svg';
function DrawerTrocarPlano(props) {
  const { disclosure, atualizarUsuario } = props;
  const [termos, setTermos] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [maioridade, setMaioridade] = useState(false);
  const [valor, setValor] = useState(0);
  const { user, setUser, page, setPage } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [parcelas, setParcelas] = useState([]);
  const disclousureDivida = useDisclosure();
  const ENDPOINT =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
      : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
  const navigate = useNavigate();
  const toast = useToast();
  //PLANO FAMILIA
  function callToast(title, descricao, status) {
    toast({
      title: title,
      description: descricao,
      status: status,
      duration: 6000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  async function validaParcelas() {
    fetch(`${ENDPOINT}/clients/verificarParcelas`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ contrato_id: user.id_contrato, cpf: user.cpf }),
    })
      .catch((err) => {
        return err;
      })
      .then(async (res) => {
        if (!res || !res.ok || res.status >= 400) {
          callToast(
            'Importante',
            'Ocorreu um problema ao validar suas parcelas pendentes. Tente de novo ou entre em contato com o suporte',
            'warning'
          );
          return;
        }
        return res.json();
      })
      .then(async (resposta) => {
        setParcelas(resposta?.data);
      });
  }

  function trocarPlano() {
    setLoading(true);
    if(valor === 0){
      callToast('Importante', 'Ocorreu um problema ao mudar seu plano. Tente de novo ou entre em contato com o suporte', 'warning');
      return
    }

    fetch(`${ENDPOINT}/clients/trocarPlanoTitular`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ contrato_id: user.id_contrato, cpf: user.cpf, valor: valor }),
    })
      .catch((err) => {
        return err;
      })
      .then(async (res) => {
        if (!res || !res.ok || res.status >= 400) {
          setLoading(false);
          callToast('Importante', 'Ocorreu um problema ao mudar seu plano. Tente de novo ou entre em contato com o suporte', 'warning');
          return;
        }
        return res.json();
      })
      .then(async (resposta) => {
        setLoading(false);
        if (resposta?.data.contrato?.length > 0) {
          setUser((prevState) => ({
            ...prevState,
            id_plano: 29,
            qtde_max_dependentes: resposta?.data.contrato_familia?.qtde_max_dependentes,
            taxa_remocao_dependente: resposta?.data.contrato_familia?.taxa_remocao_dependente,
            qtde_max_dependentes_total: resposta?.data.contrato_familia?.qtde_max_dependentes_total,
            valoradcional: resposta?.data.contrato_familia?.valoradcional,
            descricao_plano: resposta?.data.contrato_familia?.nome,
          }));
          callToast(
            'Plano Família!',
            'Agora você é família! Com o Plano Família você pode adicionar dependentes para usufruir das vantagens do app DeoVita.',
            'success'
          );
          navigate('/perfil/assinaturas');
          atualizarUsuario({
            success: true,
            valor: valor,
          })
          disclosure.onClose();
          return;
        }
      });
  }

  const fetchPosts = async () => {
    setLoading(true)
    fetch(`${ENDPOINT}/clients/getValorPlanoFamilia`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    })
      .catch((err) => {
        return err;
      })
      .then(async (res) => {
        if (!res || !res.ok || res.status >= 400) {
          setLoading(false);
          callToast('Importante', 'Ocorreu um problema. Tente de novo ou entre em contato com o suporte', 'warning');
          return;
        }
        return res.json();
      })
      .then(async (resposta) => {
        setLoading(false)
        setValor(resposta?.data?.valor_adesao);
      });
  };

  useEffect(() => {
    validaParcelas();

    fetchPosts();
  }, [user]);

  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent  width="100%" h="80%" borderTopRadius={'2em'}>
          <DrawerBody p="5%">
            <VStack alignItems="left" spacing={2.5} p={'1em'}>
              <ModalCloseButton />
              <Center>
                <Heading fontSize="16" fontWeight={'bold'} color={'#585858'}>
                  Trocar Plano
                </Heading>
              </Center>
              <Center>
                <Image boxSize="66" objectFit="cover" src={TrocarPlano} alt="" mt={'1em'} />
              </Center>
              <Center>
                <Text fontSize="16" fontWeight={'bold'} color={'#585858'} fontStyle={'justify'} mt={'1em'}>
                  Quer trocar sua assinatura individual para o Plano Família?
                </Text>
              </Center>
              <Center>
                <Text fontSize="16" color={'#585858'} fontStyle={'center'}>
                  Com o Plano Família você poderá adicionar até 4 dependentes de forma gratuita, além das vantagens que seu plano atual já possui.
                </Text>
              </Center>
              <Text fontSize="1rem" color={'#585858'}>
                Confirmo que estou ciente que ao efetivar a troca do plano para Plano Familía, passarei a pagar o valor de R$ {valor}, que será
                lançado a partir da próxima mensalidade.
              </Text>
            </VStack>
            <VStack alignItems="left">
              <Button
                bg="#EB8B2A"
                color="#FFFFFF"
                w="100%"
                onClick={() => {
                  setTermos(false);
                  if (parcelas.length === '0' || parcelas.length === 0) {
                    trocarPlano();
                  } else {
                    navigate('/perfil/assinaturas/parcela-mes-pendente', { state: { parcelas: parcelas, cancelamento: false } });
                  }
                }}
                isLoading={isLoading}
              >
                Li e estou ciente das condições
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerTrocarPlano;
