import { VStack, Button, Text, ButtonGroup, Switch, Heading, HStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from '../../AccountContext';
import { useNavigate } from 'react-router-dom';
import TextField from '../../TextField';
import ChangeTitle from '../../ChangeTitle';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import BottomNavBar from '../../BottomNavBar';
import * as Yup from 'yup';
import SelectField from 'components/SelectField';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const Endereco = () => {
  ChangeTitle('Perfil');
  const { setPage } = useContext(AccountContext);
  setPage('Endereço');

  const { user, setUser } = useContext(AccountContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [desabilitaCadastro, setDesabilitaCadastro] = useState(false);
  const [desabilitaCampo, setDesabilitaCampo] = useState(true);
  const [municipiosFiltradoPorEstado, setMunicipiosFiltradoPorEstado] = useState([]);
  const [localidades, setLocalidades] = useState({ municipios: [], estados: [] });
  const [endereco, setEndereco] = useState({
    cep: user.cep,
    code_ibge: user.code_ibge,
    endereco: user.logradouro,
    nro: user.numero,
    complemento: user.complemento,
    cidade: user.cidade,
    estado: user.uf,
    bairro: user.bairro,
  });
  const initialValues = {
    cep: user.cep,
    endereco: user.logradouro,
    nro: user.numero,
    complemento: user.complemento,
    cidade: user.cidade,
    estado: user.uf,
    bairro: user.bairro,
    switchCep: false,
  }
  const validationSchema = Yup.object({
    cep: Yup.string().required('Cep é obrigatório').min(8, 'Cep inválido'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    nro: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Somente números e letras são permitidos').required('Campo obrigatório'),
    estado: Yup.string().required('Estado é obrigatório'),
    localidadeIbge: Yup.string().required('Cidade é obrigatória'),
    bairro: Yup.string().required('Bairro é obrigatório'),
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${ENDPOINT}/cities`, {
      method: 'GET',
      credentials: 'include',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })
      .catch((error) => { })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }
        return res.json();
      })
      .then((data) => {
        let estados = [];
        data.map((cidade) => (!estados.includes(cidade.estado) ? estados.push(cidade.estado) : ''));
        setLocalidades({ municipios: data, estados: estados });
      });
  }, []);

  const AddressForm = (formProps) => {
    console.log(formProps);
    const { setFieldValue, values } = formProps;

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
    };



    function BuscaCep(cep) {
      let cepNumber = onlyNumbers(cep);
      async function buscaCep() {
        try {
          const resposta = await fetch(`${process.env.REACT_APP_BASE_URL_API_VIACEP}/${cepNumber}/json/`);
          resposta.json().then((res) => {
            if (res.erro === 'true' || res.erro === true) {
              setError(`O CEP que você digitou não foi encontrado. Favor, preencha um CEP válido.`);
              setDesabilitaCadastro(true)
              return;
            }

            if (res.logradouro === '' || res.logradouro === null) {
              setError(null);
              setFieldValue('endereco', res.logradouro);
              setFieldValue('bairro', res.bairro);
              setFieldValue('localidadeIbge', res.ibge);
              setFieldValue('cidade', res.localidade);
              setFieldValue('estado', res.uf);
              setDesabilitaCadastro(false)
              setDesabilitaCampo(false)
              return
            }

            setDesabilitaCampo(true)
            setDesabilitaCadastro(false)
            setError(null);
            setFieldValue('endereco', res.logradouro);
            setFieldValue('bairro', res.bairro);
            setFieldValue('localidadeIbge', res.ibge);
            setFieldValue('cidade', res.localidade);
            setFieldValue('estado', res.uf);

            setError(null);
            setEndereco((prevState) => {
              return {
                ...prevState,
                cep: cep,
                code_ibge: res.ibge,
                endereco: res.logradouro,
                complemento: res.complemento,
                cidade: res.localidade,
                estado: res.uf,
                bairro: res.bairro,
              };
            });
          });
        } catch (error) {
          setError('Não foi possivel consultar seu cep, verifique o cep ou preencha as informações manualmente');
        }
      }
      buscaCep();
    }

    const Logradouro = () => {

      const campoCep = () => {
        return values.switchCep ? (
          ''
        ) : (
          <TextField
            name="cep"
            placeholder="CEP"
            mask="99.999-999"
            type="tel"
            maskChar={'_'}
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
              if (e.currentTarget.value.replace('_', '').length >= 10) BuscaCep(e.currentTarget.value);
            }}
          />
        );
      };

      const switchCep = () => {
        return (
          <Switch
            name="switchCep"
            color="gray"
            size="sm"
            fontSize="sm"
            colorScheme="teal"
            isChecked={values.switchCep}
            onChange={(e) => {
              function setEnd() {
                console.log('e.target.checked ', e.target.checked)
                setFieldValue('cep', '00.000-000');
                setFieldValue('endereco', '');
                setFieldValue('bairro', '');
                setFieldValue('localidadeIbge', '');
                setFieldValue('cidade', '');
                setFieldValue('estado', '');
              }
              setDesabilitaCampo(false)
              setFieldValue('switchCep', e.target.checked);
              e.target.checked ? setEnd() : setFieldValue('cep', '');
              console.log('e.target.checked ', e.target.checked)
              setDesabilitaCadastro(false)
            }}
          >
            Não sei o cep / Digitar dados
          </Switch>
        );
      };

      const campoEstado = () => {
        return values.switchCep ? (
          <SelectField
            name="campoEstado"
            placeholder="Estado"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => {
              setFieldValue('estado', localidades.estados[e]);
              setMunicipiosFiltradoPorEstado(localidades.municipios.filter((cidade) => cidade.estado === localidades.estados[e]));
            }}
            options={localidades.estados}
          />
        ) : (
          <TextField name="cidade" placeholder="Cidade" isDisabled={!values.switchCep} />
        );
      };

      const campoCidade = () => {
        let arrayNomeMunicipios = municipiosFiltradoPorEstado.map((municipio) => municipio.nome);
        arrayNomeMunicipios.sort((a, b) => {
          return a.localeCompare(b);
        });
        return values.switchCep ? (
          <SelectField
            name="campoCidade"
            placeholder="Cidade"
            w="100%"
            variant="outline"
            textAlign="left"
            fontWeight="normal"
            onChange={(e) => {
              let codIbge = municipiosFiltradoPorEstado[parseInt(e)].codigo_ibge;
              setFieldValue('localidadeIbge', codIbge);
            }}
            isDisabled={values.estado === ''}
            options={arrayNomeMunicipios}
          />
        ) : (
          <TextField name="estado" placeholder="Estado" isDisabled={!values.switchCep} />
        );
      };

      return (
        <>
          <Heading size="sm">Endereço:</Heading>
          {campoCep()}
          {switchCep()}
          <TextField name="endereco" placeholder="Endereço" isDisabled={desabilitaCampo} />
          <HStack spacing="0.3rem" justify="top" align="start">
            <TextField name="nro" placeholder="Número" w="35vw" />
            <TextField name="complemento" placeholder="Complemento (opcional)" w="54.5vw" />
          </HStack>
          <HStack justify="top" align="start" w="100%">
            {campoEstado()}
            {campoCidade()}
          </HStack>
          <TextField name="bairro" placeholder="Bairro" isDisabled={desabilitaCampo} />
        </>
      );
    };
    return (
      <VStack as={Form} w={{ base: '90%', md: '500px' }} m="auto" paddingBottom="5%" justify="top" marginTop="5%" spacing="1rem" align="start">
       {Logradouro()}
        <Text as="p" color="red.500">
          {error}
        </Text>
        <ButtonGroup width="100%" paddingBottom="20%">
          <Button backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'} color='#FFF' w="100%" type="submit"  loadingText="Atualizando" isDisabled={desabilitaCadastro}>
            Atualizar
          </Button>
        </ButtonGroup>
      </VStack>
    );
  };

  

  return (
    <>
      <ChildHeader />
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setError(null);
          setLoading(true);

          let payload = {
            cep: onlyNumbers(values.cep),
            code_ibge: endereco.code_ibge,
            localidadeIbge: values.localidadeIbge,
            endereco: values.endereco,
            nro: values.nro,
            complemento: values.complemento,
            cidade: values.cidade,
            estado: values.estado,
            bairro: values.bairro,
            id_cliente: user.id_cliente_deovita,
          };
          console.log(payload);
          const string_error =
            'Não foi possível completar a atualização dos seus dados no momento, tente novamente mais tarde ou entre em contato com o suporte';
          fetch(`${ENDPOINT}/client/update_adress`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setError(string_error);
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                console.log(res);
                setLoading(false);
                setError(string_error);
                return;
              }
              return res.json();
            })
            .then((data) => {
              setLoading(false);
              if (!data || !data.success) {
                console.log(data);
                setError(string_error);
                return;
              }
              setUser({
                ...user,
                cep: values.cep,
                code_ibge: values.localidadeIbge,
                logradouro: values.endereco,
                numero: values.nro,
                complemento: values.complemento,
                cidade: values.cidade,
                uf: values.estado,
                bairro: values.bairro,
              });
              navigate('/alerts/sucesso/update_adress');
            });
        }}
      >
        {AddressForm}
      </Formik>
      <BottomNavBar />
    </>
  );
};

export default Endereco;
