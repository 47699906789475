import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  useClipboard,
  useDisclosure,
  useToast,
  chakra,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useMediaQuery,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import UserHeaderLivre from 'components/AreaLivre/UserHeaderLivre';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ClubeVantagem() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [valor, setValor] = useState([]);
  const { setPage, setUser, user } = useContext(AccountContext);
  const { url_externa, url_interna, cupom, link_vantagem, descricao, valor_cupom, percentual, nome_site } = state;
  const { id_contrato, descricao_plano, titular } = user;
  const toast = useToast();
  const [value, setValue] = useState(cupom);
  const { hasCopied, onCopy } = useClipboard(value);
  setPage('Clube de Vantagens');
  const [tipo, setTipo] = useState('');
  const [medico, setMedico] = useState(false);
  const disclosure = useDisclosure();
  let usuarioAssinante = id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  const [miniLargeHeight] = useMediaQuery('(max-height: 592px)');
  function CardDadosPlano({ index }) {
    const currentColor = index === 1 ? '#EB8B2A' : '#529C94';

    const plano = <chakra.span color={currentColor}>{index === 1 ? 'Plano Família' : 'Plano Indivídual'}</chakra.span>;

    const DataIndex1 = () => (
      <Box>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar até 4 dependentes `}
          {<chakra.span color={currentColor}>gratuitamente</chakra.span>}
          {`;`}
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar 1 pet `}
          {<chakra.span color={currentColor}>gratuitamente</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Usufruir de `}
          {<chakra.span color={currentColor}>consultas online</chakra.span>}
          {` gratuitamente`};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Tabela de `}
          {<chakra.span color={currentColor}>preços exclusivos</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
        {`· `}
          {<chakra.span color={currentColor}> Rede de atendimento</chakra.span>};
        </Text>
      </Box>
    );

    const DataIndex2 = () => (
      <Box>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar um `}
          {<chakra.span color={currentColor}>pet</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Usufruir de `}
          {<chakra.span color={currentColor}>consultas online</chakra.span>}
          {` gratuitamente`};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Tabela de `}
          {<chakra.span color={currentColor}>preços exclusivos</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
        {`· `}
          {<chakra.span color={currentColor}>Rede de atendimento</chakra.span>};
        </Text>
      </Box>
    );

    const BoxPreco = ({ preco }) => (
      <Box ml={miniLargeHeight ? (preco.length === 5 ? '-0.5em' : (preco.length > 5 ? '-1.2em' : '0.5em')) : !miniLargeHeight ? (preco.length === 5 ? '' : (preco.length > 5 ? '-0.5em' : '0.5em')) : ''}>
        <Text ml={10} color="#FFF" textAlign="start">
          Por apenas:
        </Text>
        <Text ml={10} fontSize={40} fontWeight="bold" color="#FFF" textAlign="start">
          R$ {preco}
        </Text>
      </Box>
    );

    const BoxPreçoInfo = () => (
      <Box mt={2} display="flex" justifyContent="center" backgroundColor={currentColor} borderRadius={20} w="90%" overflow="hidden">
        <Tabs w="100%" colorScheme="teal">
          <TabList w="100%">
            <Tab
              w="50%"
              h="10%"
              fontSize="14px"
              color={index === 1 ? '#D4B799 ' : '#529C94'}
              backgroundColor={index === 1 ? '#FFF0E1 ' : '#DDF9F7'}
              _selected={{ color: '#FFF', bg: currentColor }}
            >
              Mensal
            </Tab>
            <Tab
              w="50%"
              h="10%"
              fontSize="14px"
              color={index === 1 ? '#D4B799' : '#529C94'}
              backgroundColor={index === 1 ? '#FFF0E1' : '#DDF9F7'}
              _selected={{ color: '#FFF', bg: currentColor }}
            >
              Anual
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <BoxPreco preco={index === 1 ? '39,90' : '29,90'} />
            </TabPanel>
            <TabPanel>
              <BoxPreco preco={index === 1 ? '399,00' : '299,00'} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
    return (
      <Box backgroundColor="#F1F1F1" w="315px" h="384px" borderRadius="20px" p={2} position="relative">
        <Text textAlign="center" pb={2}>
          {`Assinando nosso `}
          {plano}
          {` você pode:`}
        </Text>
        <Box pl={2} minH={100}>
          {index === 1 ? <DataIndex1 /> : <DataIndex2 />}
        </Box>
        <Center>
          <BoxPreçoInfo />
        </Center>

        <Box
          position="absolute"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          onClick={() => {
            if (user.id_contrato > 0) {
              if (user.contrato_pendente_pagamento === true) {
                toast({
                  title: 'Assinatura pendente',
                  description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                  status: 'warning',
                  duration: 4000,
                  isClosable: true,
                  position: 'bottom',
                  colorScheme: 'green',
                  containerStyle: {
                    paddingBottom: '5em',
                  },
                });
                return;
              } else {
                if (user.loggedIn === true && user.id_contrato > 0) {
                  if (medico === true) {
                    navigate('/telepet/termos');
                  } else {
                    toast({
                      title: 'Indisponível',
                      description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                  }
                } else {
                  setTipo('Veterinário');
                  disclosure.onOpen();
                }
              }
            } else if (user.loggedIn === false) {
              setUser((prevState) => {
                return {
                  ...prevState,
                  service_type: 'assinar',
                };
              });
              navigate('/cadastro');
            } else {
              setUser((prevState) => {
                return {
                  ...prevState,
                  service: 'telemedicine',
                  service_type: 'assinar',
                };
              });
              navigate('/cadastro/plano', {
                state: {
                  client_id: user.id_cliente_deovita,
                  name: user.name,
                  cpf: user.cpf,
                },
              });
            }
          }}
        >
          <Button bottom={0} color="#fff" backgroundColor={index === 1 ? '#EB8B2A ' : '#529C94'} borderRadius={20}>
            {user.loggedIn === false ? 'Quero me cadastrar agora!' : 'Quero assinar agora!'}
          </Button>
        </Box>
      </Box>
    );
  }
  return cupom === null || cupom.length <= 0 ? (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack alignItems="center" spacing={2.5} w={miniLargeHeight ? '55.2vh' : '50h' } p="3em" h="100%" ml={miniLargeHeight ? '-0.5em': '-1em'} mt={'-2em'}>
        <Box
          width="100%"
          maxW="800px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          borderRadius="2em"
          border="2px solid white"
          marginLeft={'0.5em'}
        >
          <Box width="100%" borderRadius="2em">
            <Image src={url_interna} width="100%" height="auto" style={{ objectFit: 'contain', borderRadius: '2em' }} />
          </Box>
        </Box>
      </VStack>

      <Text textAlign="left" fontSize="16px" color="#474747" p="3em" mt="-5em" whiteSpace="pre-wrap">
        {descricao}
      </Text>

      <Text textAlign="justify" fontSize="16px" color="#818181" p="3em" mt="-4em" whiteSpace="pre-wrap">
        {!usuarioAssinante ? 'Para usufruir dos descontos dos parceiros DeoVita você precisa ser um assinante!' : ''}
      </Text>
      {usuarioAssinante !== false ? (
        <>
          <Center w="100%">
            <Button
              bg="#EB8B2A"
              color="#FFFFFF"
              w={miniLargeHeight ? '90%' : "80%"}
              borderRadius={'0.8em'}
              mb="2em"
              onClick={() => {
                window.open(`${link_vantagem}`, '_blank');
              }}
            >
              {nome_site}
            </Button>
          </Center>
        </>
      ) : (
        <></>
      )}

      {usuarioAssinante === false ? (
        <>
          <Tabs w="100%" colorScheme="teal">
            <TabList w="100%">
              <Tab w="50%" fontSize="14px">
                PLANO FAMÍLIA
              </Tab>
              <Tab w="50%" fontSize="14px">
                PLANO INDIVIDUAL
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Center p={5}>
                  <CardDadosPlano index={1} />
                </Center>
              </TabPanel>
              <TabPanel>
                <Center p={5}>
                  <CardDadosPlano index={2} />
                </Center>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      {/* */}
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <VStack alignItems="center" spacing={2.5} w={miniLargeHeight ? '55.2vh' : '50h' } p="3em" h="100%" ml={miniLargeHeight ? '-0.5em': '-1em'} mt={'-2em'}>
        <Box
          width="100%"
          maxW="800px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          borderRadius="2em"
          border="2px solid white"
          marginLeft={'0.5em'}
        >
          <Box width="100%" borderRadius="2em">
            <Image src={url_interna} width="100%" height="auto" style={{ objectFit: 'contain', borderRadius: '2em' }} />
          </Box>
        </Box>
      </VStack>

      <Text textAlign="left" fontSize="16px" color="#474747" p="3em" mt="-5em" whiteSpace="pre-wrap">
        {descricao}
      </Text>

      <Text textAlign="justify" fontSize="16px" color="#818181" p="3em" mt="-4em" whiteSpace="pre-wrap">
        {!usuarioAssinante ? 'Para usufruir dos descontos dos parceiros DeoVita você precisa ser um assinante!' : ''}
      </Text>
      {usuarioAssinante !== false ? (
        <>
          <Center w="100%">
            <Button
              bg="#EB8B2A"
              color="#FFFFFF"
              w={miniLargeHeight ? '90%' : "80%"}
              borderRadius={'0.8em'}
              mb="2em"
              onClick={() => {
                window.open(`${link_vantagem}`, '_blank');
              }}
            >
              {nome_site}
            </Button>
          </Center>
        </>
      ) : (
        <></>
      )}
      {usuarioAssinante === false ? (
        <>
          <Tabs w="100%" colorScheme="teal">
            <TabList w="100%">
              <Tab w="50%" fontSize="14px">
                PLANO FAMÍLIA
              </Tab>
              <Tab w="50%" fontSize="14px">
                PLANO INDIVIDUAL
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Center p={5}>
                  <CardDadosPlano index={1} />
                </Center>
              </TabPanel>
              <TabPanel>
                <Center p={5}>
                  <CardDadosPlano index={2} />
                </Center>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ClubeVantagem;
