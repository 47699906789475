import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdShoppingCart, MdPerson, MdOutlineAttachMoney, MdHomeFilled, MdCalendarToday } from 'react-icons/md';
import { Image, useDisclosure, useToast, chakra, Flex } from '@chakra-ui/react';
import Notify from '../images/assets/icons/notify.svg';
import { AccountContext } from './AccountContext';
import DrawerServico from './Dashboard/Main/DrawerServico';
import PopupDependenteSemCpf from './Dashboard/Main/Atendimento/PopupDependenteSemCpf';

//cinzas
import Carrinho from '../images/BottomNavBar/Carrinho.svg';
import Perfil from '../images/BottomNavBar/Perfil.svg';
import Pronturario from '../images/BottomNavBar/Prontuario.svg';
import Revendedor from '../images/BottomNavBar/Revendedor.svg';
import Home from '../images/BottomNavBar/Home.svg';

//laranjas
import HomeLaranja from '../images/BottomNavBar/HomeLaranja.svg';
import RevendedorLaranja from '../images/BottomNavBar/RevendedorLaranja.svg';
import PerfilLaranja from '../images/BottomNavBar/PerfilLaranja.svg';
import CarrinhoLaranja from '../images/BottomNavBar/CarrinhoLaranja.svg';
import PronuarioLaranja from '../images/BottomNavBar/PronturarioLaranja.svg';

//laranjas
import HomeVerde from '../images/HomeVerde.svg';
import ProntuarioVerde from '../images/PronturarioVerde.svg';
import PerfilVerde from '../images/PerfilVerde.svg';
import CarrinhoVerde from '../images/CarrinhoVerde.svg';
import PronuarioVerde from '../images/PronturarioVerde.svg';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const BottomNavBar = (props) => {
  const navigate = useNavigate();
  const [activeTabs, setActiveTabs] = useState(props.name);
  const [notificacao, setNoficacao] = useState(false);
  const { setPage, setUser, user, toastInadimplente, toastSolicitacao } = useContext(AccountContext);
  const disclosure = useDisclosure();
  const disclosure_popup = useDisclosure();
  const toast = useToast();
  const { id_contrato, descricao_plano, titular } = user;

  let assinante = id_contrato && descricao_plano ? true : titular === false ? true : false;
  const activeBarSection = {
    color: assinante ? '#EB8B2A' : '#529C94',
  };
  const dataNascimento = new Date(user.data_aniv);
  const idade = user.data_aniv !== null ? new Date().getFullYear() - dataNascimento.getFullYear() : null;
  const [toastCalled, setToastCalled] = useState(false);

  function callToast(title, descricao) {
    toast({
      title: title,
      description: descricao,
      status: 'warning',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  const updateCart = async () => {
    await fetch(`${ENDPOINT}/cart/updateCart/${user.username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUser((prevState) => ({
          ...prevState,
          carrinho: { ...data.cart },
          solicitacoes: { ...data.solicitacoes },
          solicitacoes_consulta: { ...data.solicitacoes_consulta },
        }));
      });
  };

  useEffect(() => {
    switch (activeTabs) {
      case 'dashboard':
        navigate('/dashboard');
        break;
      case 'perfil':
        navigate('/perfil/main');
        break;
      case 'consultas':
        navigate('/consultas');
        break;
      case 'empreendedor':
        navigate('/empreendedor');
        // navigate('/mdv/ganhos-totais');
        break;
      case 'carrinho':
        navigate('/carrinho');
        break;
      default:
        break;
    }
  }, [activeTabs]);

  const dadosPac = {
    paciente_id: user.id_cliente_deovita,
    nome: user.name,
    cpf: user.cpf,
    nascimento: user.data_aniv,
    cep: user.cep,
    code_ibge: user.codigo_ibge,
    logradouro: user.logradouro,
    numero: user.numero,
    bairro: user.bairro,
    complemento: user.complemento,
    municipio: user.municipio,
    estado: user.uf,
    celular: user.phone1 ? user.phone1 : user.phone2,
    sexo: user.sexo,
    type: 'empreendedor',
  };

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }

  const dataAtual = new Date();

  return user.loggedIn === true ? (
    <>
      <DrawerServico disclosure={disclosure} />
      <PopupDependenteSemCpf disclosure={disclosure_popup} datas={dadosPac} />
      <div className="bottom-nav">
        <div
          id="id_btm_dashboard"
          className={`bn-tab ${activeTabs === 'dashboard' ? 'active-tab' : ''}`}
          style={{ ...(activeTabs === 'dashboard' ? activeBarSection : {}), marginRight: '10px' }}
        >
          <Flex align="center" flexDirection="column">
            {activeTabs === 'dashboard' ? (
              <Image
                src={assinante ? HomeLaranja : HomeVerde}
                size="35"
                color="#FFF"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  setActiveTabs('dashboard');
                }}
              />
            ) : (
              <Image
                src={Home}
                size="35"
                color="#585858"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  setActiveTabs('dashboard');
                }}
              />
            )}
            <chakra.p fontSize={'12px'}>Início</chakra.p>
          </Flex>
        </div>

        <div
          id="id_btm_consultas"
          className={`bn-tab ${activeTabs === 'consultas' ? 'active-tab' : ''}`}
          style={{ ...(activeTabs === 'consultas' ? activeBarSection : {}), marginRight: '10px' }}
        >
          <Flex align="center" flexDirection="column">
            {activeTabs === 'consultas' ? (
              <Image
                src={assinante ? PronuarioLaranja : PronuarioVerde}
                size="30"
                /*color="rgba(90, 178, 173, 0.7)"*/ color="#FFF"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  setActiveTabs('consultas');
                }}
              />
            ) : (
              <Image
                src={Pronturario}
                size="30"
                color="#585858"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  setActiveTabs('consultas');
                }}
              />
            )}
            {user.avaliacao_pendente === true ? <Image position="absolute" left="34%" top="10%" src={Notify} boxSize={4}></Image> : <></>}
            <chakra.p fontSize={'12px'}>Prontuário</chakra.p>
          </Flex>
        </div>

        <div
          id="id_btm_carrinho"
          className={`bn-tab ${activeTabs === 'carrinho' ? 'active-tab' : ''}`}
          style={{ ...(activeTabs === 'carrinho' ? activeBarSection : {}), marginRight: '10px' }}
        >
          <Flex align="center" flexDirection="column">
            {activeTabs === 'carrinho' ? (
              <Image
                src={assinante ? CarrinhoLaranja : CarrinhoVerde}
                size="30"
                /*color="rgba(90, 178, 173, 0.7)"*/ color="#FFF"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  setActiveTabs('carrinho');
                }}
              />
            ) : (
              <Image src={Carrinho} size="30" color="#585858" onClick={() => setActiveTabs('carrinho')} />
            )}
            {user.cart ||
            (user.solicitacoes && user?.solicitacoes && Object.keys(user.solicitacoes).length > 0) ||
            (user.solicitacoes_consulta && user?.solicitacoes_consulta && Object.keys(user.solicitacoes_consulta).length > 0) ? (
              <>
                <Image position="absolute" left="51%" top="10%" src={Notify} boxSize={4}></Image>
              </>
            ) : (
              <></>
            )}

            <chakra.p fontSize={'12px'}>Carrinho</chakra.p>
          </Flex>
        </div>

        <div
          id="id_btm_empreendedor"
          className={`bn-tab ${activeTabs === 'empreendedor' ? 'active-tab' : ''}`}
          style={{ ...(activeTabs === 'empreendedor' ? activeBarSection : {}), marginRight: '10px' }}
        >
          <Flex align="center" flexDirection="column">
            {activeTabs === 'empreendedor' ? (
              <Image
                src={RevendedorLaranja}
                size="35"
                /*color="rgba(90, 178, 173, 0.7)"*/ color="#FFF"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  if (user.id_contrato > 0) {
                    if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
                      toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                      return;
                    } else {
                      if (user.empreendedor_popup === 'ativo') {
                        setActiveTabs('empreendedor');
                      }
                    }
                  } else {
                    disclosure.onOpen();
                  }
                }}
              />
            ) : (
              <Image
                src={Revendedor}
                size="35"
                color="#585858"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  if (
                    ((user.titular === true && user.id_contrato > 0) || (user.titular === false && user.id_contrato === 0)) &&
                    idade !== null &&
                    idade >= 18
                  ) {
                    if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
                      toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                      return;
                    } else {
                      setActiveTabs('empreendedor');
                    }
                  } else if (user.data_aniv !== null && idade !== null && idade < 18) {
                    callToast('Importante', 'É necessário ser maior de idade para acessar a aba de empreendedor');
                  } else if (user.data_aniv === null && idade === null) {
                    disclosure_popup.onOpen();
                  } else {
                    callToast('Atenção', 'Você precisa ser assinante para acessar esta opção.');
                  }
                }}
              />
            )}
            <chakra.p fontSize={'12px'}>Revendedor</chakra.p>
          </Flex>
        </div>
        <div
          id="id_btm_perfil"
          className={`bn-tab ${activeTabs === 'perfil' ? 'active-tab' : ''}`}
          style={{ ...(activeTabs === 'perfil' ? activeBarSection : {}), marginRight: '10px' }}
        >
          <Flex align="center" flexDirection="column">
            {activeTabs === 'perfil' ? (
              <Image
                src={assinante ? PerfilLaranja : PerfilVerde}
                size="35"
                color="#FFF"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  setActiveTabs('perfil');
                }}
              />
            ) : (
              <Image
                src={Perfil}
                size="35"
                color="#585858"
                onClick={() => {
                  if (user.loggedIn) {
                    updateCart();
                  }
                  setActiveTabs('perfil');
                }}
              />
            )}
            <chakra.p fontSize={'12px'}>Perfil</chakra.p>
          </Flex>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default BottomNavBar;
