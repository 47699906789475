import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  List,
  ListItem,
  ListIcon,
  VStack,
  Button,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export default function PopupDependenteSemCpf(props) {
  const dependente = props.datas;
  const navigate = useNavigate();
  const disclosure = props.disclosure;
  const type = dependente?.type ? true : false
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} size="xs" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={20}>
        <ModalHeader mt="5" fontSize="lg">
          {type ? 'Seus dados estão desatualizados!' : 'Os dados do dependente estão desatualizados!'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack align="start" spacing={5}>
            <Text>{type ? 'Por favor, atualize seus dados para prosseguir.' : 'Por favor atualize os dados desse dependente para continuar o agendamento.'}</Text>
            <Text fontWeight="bold">{type ? 'Os campos abaixo são obrigatórios para prosseguir.' : 'Os campos abaixo são obrigatórios para agendar.'} </Text>
            <List>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Nome
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Cpf
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Data de nascimento
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Telefone
              </ListItem>
            </List>
            <Button
              isFullWidth
              colorScheme="teal"
              onClick={() =>
                navigate('/perfil/dependentes/editar', {
                  state: {
                    id: dependente.paciente_id,
                    dependente_id: dependente.paciente_contrato_dependente_id,
                    nome: dependente.nome,
                    cpf: dependente.cpf,
                    nascimento: dependente.nascimento,
                    cep: dependente.cep,
                    code_ibge: dependente.codigo_ibge,
                    logradouro: dependente.logradouro,
                    numero: dependente.numero,
                    bairro: dependente.bairro,
                    complemento: dependente.complemento,
                    municipio: dependente.municipio,
                    estado: dependente.estado,
                    fone: dependente.celular,
                    sexo: dependente.sexo,
                  },
                })
              }
            >
              {type ? 'Atualizar dados.' : 'Atualizar dependentes'}
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
