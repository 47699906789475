import { VStack,chakra } from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';

function Atendimento() {
    const {setPage} = useContext(AccountContext)
    const { state } = useLocation();
    console.log(state.rapidocUrl)
    setPage('Telemedicina')
    return (
    <>
        <ChildHeader />
        <VStack
          align="center"
          minHeight="100vh"
        >
          <chakra.iframe width="95%" paddingTop="0.5em" height="600px" src={state.rapidocUrl} name="result" allow="midi; geolocation 'src'; camera 'src';microphone 'src' display-capture; encrypted-media;" sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation allow-downloads" allowfullscreen="" allowpaymentrequest="" frameborder="0"></chakra.iframe>
          
        </VStack>
        <BottomNavBar />
    </>
  )
}

export default Atendimento