import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, VStack, HStack, Box, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { React, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../AccountContext';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import MainChildHeader from '../Dashboard/Main/MainChildHeader';
import _ from 'lodash';
export default function Sucesso() {
  const { rota } = useParams();
  const { user, setUser, page, setPage } = useContext(AccountContext);
  let { state } = useLocation();

  if (page === 'Titulo da Pagina' || page === '' || !page) {
    setPage('');
  }

  var msg = 'Operação realizada com sucesso';
  var ms2 = ''
  var navigateTo = '/dashboard';

  function encontrarChavePorId(objeto, valorProcurado) {
    for (const chave in objeto) {
      if (objeto[chave].ambulatorio_orcamento_id === valorProcurado) {
        return chave;
      }
    }
    return null;
  }

  const limparCarrinho = () => {
    if (user.cart && user.cart_temp && rota === 'agendamento-exame') {
      console.log('limparCarrinho');
      let newCartItems = _.filter(
        user.cart.itens,
        (item) => !_.includes(_.map(user.cart_temp.itens, 'procedimento_tuss_id'), item.procedimento_tuss_id)
      );

      if (newCartItems.length === 0) {
        setUser((prevState) => ({ ...prevState, cart: null }));
      } else {
        setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, itens: newCartItems } }));
      }
    }

    if (user?.solicitacoes_consulta && rota === 'agendamento' && state?.item?.orcamento_id) {
      let solicitacoes = user.solicitacoes_consulta;
      let index = encontrarChavePorId(solicitacoes, state.item.orcamento_id);
      delete solicitacoes[index];

      setUser((prevState) => ({
        ...prevState,
        solicitacoes_consulta: solicitacoes,
      }));
    }
  };

  useEffect(() => {
    limparCarrinho();
  }, [rota]);

  console.log(rota);
  switch (rota) {
    case 'excluir-agendamento':
      msg = 'Agendamento excluído com sucesso';
      navigateTo = '/consultas';
      break;
    case 'update_adress':
      msg = 'Endereço atualizado com sucesso';
      break;
    case 'atualiza_dados':
      msg = 'dados atualizados com sucesso';
      break;
    case 'agendamento':
      msg = 'Estamos processando seu pagamento. Você receberá uma notificação assim que o pagamento for confirmado.';
      navigateTo = '/consultas';

      break;
    case 'agendamento-exame':
      msg = 'Estamos processando seu pagamento. Você receberá uma notificação assim que o pagamento for confirmado.';

      break;
    case 'agendamentoAvulso':
      msg = 'Estamos processando seu pagamento. Você receberá uma notificação assim que o pagamento for confirmado.';
      navigateTo = '/arealivre/sucesso';
      break;
    case 'recuperacao':
      ms2 = `Olá, ${state.nome}`;
      msg = `Recuperação de senha realizada com sucesso`;
      break;
    case 'atualizar_dependente':
      msg = 'dependente atualizado com sucesso';
      navigateTo = '/perfil/assinaturas';
      break;
    case 'excluir_dependente':
      msg = 'dependente excluído com sucesso';
      navigateTo = '/perfil/dependentes';
      break;
    case 'excluir_dependente_assinatura':
      msg = 'Aguarde o pagamento ser processado para que o dependente seja excluído com sucesso';
      navigateTo = '/dashboard';
      break;
    case 'att_empreendedor':
      msg = 'Seus dados bancários foram atualizados com sucesso';
      navigateTo = '/perfil/dashboard';
      break;
    case 'atualiza_foto':
      msg = 'Foto atualizada com sucesso';
      navigateTo = '/perfil/main';
      break;
    case 'cadastrar_dependente':
      msg = 'dependente atualizado com sucesso';
      navigateTo = '/perfil/assinaturas';
      break;
    case 'ativa_empreendedor':
      msg = 'Adesão realizada com sucesso.';
      navigateTo = '/empreendedor';
      break;
    case 'dados_bancarios_empreendedor':
      msg = 'Dados bancarios cadastrados com sucesso.';
      navigateTo = '/empreendedor';
      break;
    case 'cadastro_estabelecimento':
      msg = 'Estabelecimento cadastrado com sucesso';
      navigateTo = '/empreendedor';
      break;
    case 'transferencia':
      msg =
        'Sua transferência será encaminhada ao banco até o final do dia, caso tenha sido feito até às 15h no horário de Brasília. Após isso, será encaminhado somente no próximo dia útil.';
      navigateTo = '/empreendedor';
      break;
    case 'sucesso_solicitacoes':
      msg = 'Solicitação recusada com sucesso';
      navigateTo = '/carrinho';
      break;
    case 'telemedicina':
      msg =
        'Estamos processando seu pagamento. Você receberá uma mensagem via whatsapp com o link para o atendimento assim que o pagamento for confirmado.';
      navigateTo = '/dashboard';
      break;
    case 'agendamento-gratis':
      msg = 'Agendamento realizado com sucesso. Certifique-se de entrar no app 15 minutos antes da sua consulta medica iniciar';
      navigateTo = '/consultas';
      break;
    case 'antecipacao':
      msg =
        'Antecipações para o mesmo dia útil precisam ser solicitadas antes das 10:59 da manhã. Esta antecipação será analisada e poderá ser aprovada ou rejeitada. Após a aprovação, o valor a ser antecipado é adicionado ao saldo a partir das 12 horas do dia selecionado.';
      navigateTo = '/empreendedor';
      break;
    case 'cadastro_contrato':
      msg = 'Já existe um contrato para este paciente. Faça já seu login no aplicativo utilizando seu cpf e a senha informada no cadastro.';
      navigateTo = '/login';
      break;
    case 'pagamento_parcelas':
      msg = 'Pagamento efetuado com sucesso. Porém, pode levar algum tempo até ser processado. Favor, desconsidere';
      navigateTo = '/perfil/dashboard';
      break;
    default:
  }

  function Feature({ title, desc, ...rest }) {
    return (
      <Box p={15} w="100%" shadow="xl" borderWidth="1px" borderRadius="15" {...rest}>
        <VStack w="100%">
          <CheckCircleIcon mt="5" color="green" boxSize={20} />
        </VStack>
        <Heading fontSize="xl" mt="5" textAlign="center">
          {title}{' '}
        </Heading>

        <Text mt={5} textAlign="center">
          {' '}
          {ms2 ? <> <p>{ms2}</p></> : ''}
          <p>{msg}</p>
          <Button
            mt="10"
            borderRadius="15"
            height="48px"
            width="200px"
            border="1px"
            alignItems="center"
            onClick={() => {
              setUser((prevState) => ({ ...prevState, pedido_pendente: null }));

              user.cart && user.cart_temp && rota === 'agendamento-exame' ? navigate('/carrinho') : navigate(navigateTo ? navigateTo : '/dashboard');

              /* if (!(user.cart && user.cart_temp && rota === 'agendamento-exame')) {
                navigate(navigateTo ? navigateTo : '/dashboard');
              } */
            }}
          >
            OK
          </Button>
        </Text>
      </Box>
    );
  }

  const navigate = useNavigate();
  return (
    <>
      {user.loggedIn ? <MainChildHeader /> : <NoUserHeader />}
      <VStack w="90vw" m="auto" justify="top" spacing="1rem" marginTop="20">
        <HStack>
          <Feature
            title="Sucesso"
            desc="You deserve good things. With a whooping 10-15% interest rate per annum, grow your savings on your own terms with our completely automated process"
          />
        </HStack>
      </VStack>
    </>
  );
}
