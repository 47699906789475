import React, { useEffect, useState } from "react";
import { ptBR } from 'date-fns/locale';
import { addDays, getHours, isSameDay, startOfDay } from 'date-fns'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Loading from "components/Loading";

const Calendario = (props) => {
    const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;
    const backgroundColor = props.backgroundColor;
    const [horariosEmpresa, setHorarioEmpresa] = useState([]);

    // CSS para estilo do calendário
    const css = `

        .rdp {
            background-color: #F3F3F3;
            padding: 10px;
            border-radius: 5px;
            --rdp-background-color: ${backgroundColor};
            --rdp-accent-color: #C2C6C5;
            color: black;
        }

        .rdp-caption {
            background-color: ${backgroundColor};
            border-radius: 5px;
        }

        .rdp-caption_label {
            color: #FFF
        }

        .rdp-nav_button  {
            rotate: 90deg;
        }

        .rdp svg {
            color: #FFF;
        }

        .rdp-day_selected {
            border-radius: 10px;
            color: black;
        }

    `;

    // Feriados mockados temporariamente. Devido ao UTC, tive que por 1 dia na frente.
    const currentYear = new Date().getFullYear();
    const feriadosMock = [
        new Date(`${currentYear}-01-02`), // Ano Novo (domingo) – Feriado nacional


        new Date(`${currentYear}-05-02`), // Dia do trabalhador – Feriado nacional
        new Date(`${currentYear}-05-31`), // Corpus Christi

        new Date(`${currentYear}-09-08`), // Independência do Brasil
        new Date(`${currentYear}-10-13`), // Nossa Sra Aparecida – Padroeira do Brasil
        new Date(`${currentYear}-11-04`), // Finados

        new Date(`${currentYear}-11-16`), // Proclamação da República
        new Date(`${currentYear}-11-21`), // Dia da Consciência Negra

        new Date(`${currentYear}-12-26`), // Natal
    ];


    // Datas disponíveis
    const datasDisponiveis = props.datasDisponiveis || [];

    const estaDataDisponivel = date => {

            const hoje = startOfDay(new Date());
            const horaAtual = new Date().getHours() * 60 + new Date().getMinutes();
            let horaInicio, horaFim;

            switch (date.getDay()) {
                case 0: // Domingo
                    horaInicio = horariosEmpresa?.horario_integral_dom_inicio;
                    horaFim = horariosEmpresa?.horario_integral_dom_fim;
                    break;
                case 6: // Sábado
                    horaInicio = horariosEmpresa?.horario_integral_sab_inicio;
                    horaFim = horariosEmpresa?.horario_integral_sab_fim;
                    break;
                default: // Dias da semana (segunda a sexta)
                    horaInicio = horariosEmpresa?.horario_integral_seg_sex_inicio;
                    horaFim = horariosEmpresa?.horario_integral_seg_sex_fim;
                    break;
            }

            if (horaInicio !== null && horaFim !== null && horaInicio !== undefined && horaFim !== undefined) {
                const [horaInicioHour, horaInicioMin] = horaInicio.split(':').map(Number);
                const [horaFimHour, horaFimMin] = horaFim.split(':').map(Number);

                const horaInicioTotalMin = horaInicioHour * 60 + horaInicioMin;
                const horaFimTotalMin = horaFimHour * 60 + horaFimMin;

                if (date.getDate() === hoje.getDate() && horaAtual >= horaFimTotalMin) {
                    // Se o horário de funcionamento já passou hoje, então o dia não está disponível
                    return false;
                }
            }

            if (!datasDisponiveis || datasDisponiveis.length === 0) {
                return date >= hoje;
            }

            return (
                datasDisponiveis.some(disponivel => isSameDay(disponivel, date)) &&
                date >= hoje &&
                !feriadosMock.some(feriado => isSameDay(feriado, date))
            );
    };


    const isFeriado = date => {
        return feriadosMock.some(feriado => isSameDay(feriado, date));
    };

    return  (
        <>
            <style dangerouslySetInnerHTML={{ __html: css }} />
            <DayPicker
                defaultMonth={props.date}
                mode="single"
                selected={props.date}
                locale={ptBR}
                onDayClick={day => {
                    if (estaDataDisponivel(day)) {
                        const date = new Date(day.toISOString().split("T")[0] + "T12:00:00")
                        props.setDate(date);
                        if (day.getDay() === 6) {
                            console.log("dia clicado sabado")
                            props.setIsSaturday(true)
                        }
                        else {
                            props.setIsSaturday(false)
                        }
                    }
                }}
                disabled={day => !estaDataDisponivel(day) || day.getDay() === 0 || isFeriado(day)}


            />
        </>
    )
};

export default Calendario;
