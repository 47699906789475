import { StarIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, chakra, ButtonGroup, Flex, HStack, VStack, WrapItem, Text } from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import Loading from 'components/Loading';
import { AccountContext } from '../../../AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import ChildHeader from '../ChildHeader';
import SelectField from 'components/SelectField';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';

function EscolhaProcedimento() {
  const navigate = useNavigate();
  const { user, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  const { medico } = state;
  const dadosMedico = medico.dados;
  const horarios = medico.horarios;
  const [agendas, setAgendas] = useState([]);
  const [procedimento, setProcedimento] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const avaliacao = Math.round(dadosMedico.avaliacao_media);
  const profilePic = `${process.env.REACT_APP_URL_PROFILEPICS_OPERADOR}/${dadosMedico.medico}/foto_profissional.webp`;

  let namesArray = typeof dadosMedico.nome === 'string' ? dadosMedico?.nome?.split(' ') : [''];
  namesArray = namesArray.filter((name) => !['da', 'das', 'do', 'dos', 'de', 'des', 'e'].includes(name.toLowerCase()));
  const nomeMedico = namesArray.length < 2 ? namesArray[0] : `${namesArray[0]} ${namesArray[1]}`;

  let titulo = 'Dr(a).';
  if (dadosMedico.sexo === 'M') {
    titulo = 'Dr.';
  } else if (dadosMedico.sexo === 'F') {
    titulo = 'Dra.';
  }

  let procedimentos = [];
  let nomesProcedimentos = [];
  horarios.forEach((horario) => {
    horario.procedimentos.forEach((procedimento) => {
      if (nomesProcedimentos.includes(procedimento.nome) === false) {
        procedimentos.push(procedimento);
        nomesProcedimentos.push(procedimento.nome);
      }
    });
  });

  const valorProcedimento = procedimento !== null ? procedimentos[procedimento]?.valor : 0;

  setPage('Escolha o procedimento');

  function Profile() {
    return (
      <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden">
        <Box w={1 / 3} bgSize="cover" align="center">
          <WrapItem ml="5px" mt="0.8em">
            <Avatar id={dadosMedico.medico_id} size="xl" name={dadosMedico.nome} src={profilePic} />
          </WrapItem>
          <chakra.a
            color="rgba(14, 170, 139, 1)"
            fontWeight="bold"
            fontSize="sm"
            onClick={() => navigate('/empresa/medico/curriculo', { state: { medico } })}
          >
            ver curriculo
          </chakra.a>
        </Box>
        <Box w={2 / 3} p={{ base: 4, md: 4 }} marginBottom="5%">
          <chakra.h2 fontSize="xl" fontWeight="bold">
            <Text casing="capitalize">{`${titulo} ${nomeMedico.toLowerCase()}`}</Text>
          </chakra.h2>
          <chakra.p fontSize="md">{dadosMedico.especialidade}</chakra.p>
          <chakra.p fontSize="sm">{dadosMedico.conselho}</chakra.p>
          <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}>
            {avaliacao >= 1 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
            {avaliacao >= 2 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
            {avaliacao >= 3 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
            {avaliacao >= 4 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
            {avaliacao >= 5 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
            <chakra.span fontSize="xs"></chakra.span>
          </HStack>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      {user.loggedIn === true ? <ChildHeader /> : <NoUserHeader />}
      <Formik initialValues={{ empresa: 0, dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
        <VStack w={{ base: '90%', md: '500px' }} m="auto" justify="center" spacing="10">
          <Profile />
          <SelectField
            name="procedimento"
            label="Escolha o procedimento: "
            w="95%"
            h={50}
            variant="outline"
            borderWidth={2}
            options={nomesProcedimentos}
            onChange={(value) => {
              setProcedimento(parseInt(value));
              setDisabled(false);
            }}
          />

          <ButtonGroup pt="1rem" pb="25%" width="100%">
            <Button
              w="100%"
              colorScheme="teal"
              onClick={() =>

                navigate('/atendimento/agendamento', {
                  state: {
                    ...state,
                    procedimentoId: procedimentos[procedimento].id,
                    procedimento: procedimentos[procedimento].nome,
                    price: valorProcedimento,
                    procedimentoParticular:
                      procedimentos &&
                        procedimentos.length > 0 &&
                        procedimentos[procedimento].procedimento_particular &&
                        procedimentos[procedimento].procedimento_particular.length > 0
                        ? procedimentos[procedimento].procedimento_particular[0].particular_id
                        : null,
                    valorParticular:
                      procedimentos &&
                        procedimentos.length > 0 &&
                        procedimentos[procedimento].procedimento_particular &&
                        procedimentos[procedimento].procedimento_particular.length > 0
                        ? procedimentos[procedimento].procedimento_particular[0].particular_valor
                        : null,
                  },
                })
              }
              disabled={disabled}
            >
              Continuar
            </Button>
          </ButtonGroup>
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  );
}

export default EscolhaProcedimento;
