import { Text, Box, Image, Tabs, TabList, TabPanels, TabPanel, useTab, Button, Center, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import NoUserHeader from '../AreaLivre/NoUserHeader';
import ChildHeader from '../Dashboard/Main/ChildHeader';
import { AccountContext } from '../AccountContext';
import Loading from '../LoadingWithHeader';
import MoneyMouth from '../../images/emoji-money-mouth.svg';
import { ReactComponent as Heart } from '../../images/heart.svg';
import { ReactComponent as FamiliaPlus } from '../../images/familia-plus.svg';
import { ReactComponent as Familia } from '../../images/plano-familia.svg';
import { ReactComponent as Individual } from '../../images/individual.svg';
import { ReactComponent as PlanoRecomendado } from '../../images/plano-recomendado.svg';
import DrawerIndicacao from './DrawerIndicacao';
import { loadPlans } from 'api/plans';

const BeneficiosItem = (props) => {
  return (
    <Box display={'flex'} alignItems={'flex-start'} gap={2} color={props.color} mb={1}>
      <Heart style={{ flexShrink: 0, marginTop: 4 }}></Heart>
      {props.children}
    </Box>
  );
};

const TabFrequenciaPlano = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];
  return (
    <Text
      {...tabProps}
      py={1}
      px={2}
      textAlign={'center'}
      bg={isSelected ? 'white' : 'transparent'}
      color={isSelected ? props.color : 'white'}
      borderRadius={'full'}
      border={`2px solid white`}
    >
      {tabProps.children}
    </Text>
  );
});

function TabFrequenciaPlanoSelect({ plano, state, navigate, user }) {
  const [lg] = useMediaQuery('(min-width: 425px)');

  const [frequenciaPagamento, setFrequenciaPagamento] = useState(0);

  const getTextFrequencia = useCallback((quantidade) => {
    switch (quantidade) {
      case 12:
        return 'Mensal';
      case 2:
        return 'Semestral';
      case 4:
        return 'Trimestral';
      case 1:
        return 'Anual';
      default:
        break;
    }
  }, []);

  function handleSubmit() {
    let params = {
      ...state,
      id_plano: plano.id,
      nome_plano: plano.nome,
      dependentes_plano: plano.qtd_dependentes,
      parcelsActive: true,
      valor_plano: plano.parcelas[frequenciaPagamento].min_valor,
      valor_plano_formatado: Number(plano.parcelas[frequenciaPagamento].min_valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      frequenciaText: getTextFrequencia(plano.parcelas[frequenciaPagamento].quantidade),
      frequencia: plano.parcelas[frequenciaPagamento].quantidade,
      navig_to: '/cadastro/analise',
      expiration_pix_in_min: 5,
      item: 'Contratação DeoVita',
      service: 'contract',
      voucher: user.voucher ? user.voucher : null,
    };

    let carrinho = [{ id: 0, nome: params.nome_plano, valor: params.valor_plano_formatado, valor_numerico: params.valor_plano }];
    navigate('/pagamentos', { state: { ...params, carrinho: carrinho } });
  }
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Tabs
        variant="solid-rounded"
        colorScheme={getPlanColor(plano.id)}
        index={frequenciaPagamento}
        bg={getPlanColor(plano.id)}
        onChange={(index) => {
          setFrequenciaPagamento(index);
        }}
        mt={4}
        borderRadius={20}
      >
        {plano.parcelas && (
          <>
            <TabList gap={2} m={4} mb={0} display={'flex'} flexWrap={'wrap'}>
              {plano.parcelas.map(({ quantidade }) => (
                <TabFrequenciaPlano color={getPlanColor(plano.id)}>{getTextFrequencia(quantidade)}</TabFrequenciaPlano>
              ))}
            </TabList>
            <TabPanels color={'white'}>
              {plano.parcelas.map(({ min_valor }) => (
                <TabPanel px={4}>
                  <Center flexDirection={'column'}>
                    <Text fontSize={lg ? 'xl' : 'md'} alignSelf={'flex-start'}>
                      A partir de
                    </Text>
                    <Text fontSize={lg ? '5xl' : '3xl'} fontWeight={'bold'} lineHeight={1} mb={4} alignSelf={'flex-start'}>
                      {Number(min_valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Text>
                    <Text fontSize={'xs'}>* O valor do plano será ajustado de acordo com a forma de pagamento escolhida.</Text>
                  </Center>
                </TabPanel>
              ))}
            </TabPanels>
          </>
        )}
      </Tabs>

      <Button bg={getPlanColor(plano.id)} color={'white'} onClick={() => handleSubmit()} mt={4} borderRadius={'full'} alignSelf={'center'}>
        Quero assinar agora!
      </Button>
    </Box>
  );
}

function getPlanColor(planID) {
  switch (planID) {
    case 29: // FAMILIA
      return '#EB8B2A';
    case 55: //INDIVIDUAL
      return '#529C94';

    default:
      return '#FF8000';
  }
}

function getPlanIcon(planID) {
  switch (planID) {
    case 29: // FAMILIA
      return <Familia height={20}></Familia>;
    case 55: //INDIVIDUAL
      return <Individual height={20}></Individual>;
    case 137:
      return <FamiliaPlus height={20}></FamiliaPlus>;

    default:
      return <Individual height={20}></Individual>;
  }
}

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Plano() {
  const [plans, setPlans] = useState([]);
  const { state } = useLocation();
  const { user, setPage } = useContext(AccountContext);
  const [error, setError] = useState(false);
  const [assinaturaLiberada, setAssinaturaLiberada] = useState(true);
  const disclosure = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [lg] = useMediaQuery('(min-width: 425px)');

  let clientId = state?.client_id ? state?.client_id : user.id_cliente_deovita;

  const navigate = useNavigate();

  useEffect(() => {
    setPage('Assinatura');
  }, [setPage]);

  useEffect(() => {
    (async () => {
      try {
        const data = await loadPlans();
        setPlans(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!user.voucher && assinaturaLiberada && !loading) {
      disclosure.onOpen();
    } else {
      disclosure.onClose();
    }
  }, [user, assinaturaLiberada, loading]);

  return plans.length ? (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}

      <DrawerIndicacao disclosure={disclosure} clientId={clientId} />
      <Box mx={10} mt={8}>
        <Image src={MoneyMouth} alignSelf={'flex-start'} mb={4} height={12} width={12}></Image>
        <Text fontWeight={'bold'} color={'#FF8000'} mb={4} fontSize={20}>
          Comece economizando!
        </Text>
        <Text color={'#585858'}>Assinando o plano Deovita você economiza em consultas e procedimentos.</Text>
      </Box>

      {user.voucher && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          bg={'linear-gradient(180deg, #F1F1F1 0%, #F7F7F7 100%)'}
          mt={4}
          mx={10}
          p={2}
          px={4}
          borderRadius={10}
        >
          <Box>
            <Text color="teal" fontSize={'xs'} textTransform={'uppercase'}>
              voucher aplicado
            </Text>
            <Text>{user.voucher.vendedor_id}</Text>
          </Box>

          <Button colorScheme="teal" size="xs" textTransform={'uppercase'} onClick={() => disclosure.onOpen()}>
            alterar
          </Button>
        </Box>
      )}

      <Box
        display={'flex'}
        gap={4}
        px={10}
        py={8}
        overflow={'visible'}
        overflowY={'auto'}
        overscrollBehaviorX={'contain'}
        scrollSnapType={'x mandatory'}
      >
        {plans.map((item) => (
          <Box
            key={item.id}
            plan={item}
            p={8}
            borderRadius={20}
            width={'100%'}
            flexShrink={0}
            scrollSnapAlign={'center'}
            bg={'linear-gradient(180deg, #F1F1F1 0%, #F7F7F7 100%)'}
            border={item.recomendado ? `2px solid ${getPlanColor(item.id)}` : 'none'}
            overflow={'visible'}
            position={'relative'}
            fontSize={lg ? 'md' : 'xs'}
          >
            {item.recomendado && (
              <PlanoRecomendado
                style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%) translateY(-100%)', zIndex: 1000 }}
              ></PlanoRecomendado>
            )}
            <Center bg={getPlanColor(item.id)} color={'white'} gap={2} p={2} borderRadius={10} textAlign={'center'}>
              {getPlanIcon(item.id)} Plano {item.nome}
            </Center>

            <TabFrequenciaPlanoSelect plano={item} user={user} navigate={navigate} state={state}></TabFrequenciaPlanoSelect>

            <Box pt={4} borderTop={'2px solid #D0D0D0'} mt={6} color={'#585858'}>
              <Text fontWeight={'bold'} fontSize={'md'} textAlign={'center'} mb={4}>
                Com o <span style={{ color: getPlanColor(item.id) }}>Plano {item.nome}</span>, você pode:
              </Text>
              {item.beneficios &&
                item.beneficios.map((beneficio) => (
                  <BeneficiosItem key={beneficio.beneficios_plano_id} color={getPlanColor(item.id)}>
                    {beneficio.descricao}
                  </BeneficiosItem>
                ))}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  ) : error ? (
    navigate('/alerts/erro/cadastro_plano')
  ) : (
    <Loading />
  );
}

export default Plano;
