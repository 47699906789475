import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  chakra,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import ChildHeader from 'components/Dashboard/Main/ChildHeader';
import React, { useContext, useEffect, useState } from 'react';
import SucessoLaranja from '../../../images/SucessoLaranja.svg';
import Excluir from '../../../images/ExcluirGenerico.svg';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import MainChildHeader from 'components/Dashboard/Main/MainChildHeader';
import ExcluirContaDrawer from './Modal/ExcluirContaDrawer';

function ExcluiContaPagina() {
    const toast = useToast();
    const navigate = useNavigate();
    const { setPage, setUser, user } = useContext(AccountContext);
    const [tipo, setTipo] = useState('');
    const [medico, setMedico] = useState(false);
    const [isMinHeight] = useMediaQuery('(max-height: 667px)');
    const [iphoneXr] = useMediaQuery('(max-height: 896px)');
    const [isInRange] = useMediaQuery('(max-height: 840px) and (max-height: 1000px)');

  function CardDadosPlano({ index }) {
    const currentColor = index === 1 ? '#529C94' : '#EB8B2A';

    const plano = <chakra.span color={currentColor}>plano</chakra.span>;

    const DataIndex1 = () => (
      <Box>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar quantos `}
          {<chakra.span color={currentColor}>dependentes</chakra.span>}
          {` quiser;`}
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar um `}
          {<chakra.span color={currentColor}>pet</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Usufruir de `}
          {<chakra.span color={currentColor}>consultas online</chakra.span>}
          {` gratuitamente`};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Tabela de `}
          {<chakra.span color={currentColor}>preços exclusivos</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {<chakra.span color={currentColor}>· Rede de atendimento</chakra.span>};
        </Text>
      </Box>
    );

    const DataIndex2 = () => (
      <Box>
        <Text fontSize="12px" color="#808080">
          {`· Adicionar um `}
          {<chakra.span color={currentColor}>pet</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Usufruir de `}
          {<chakra.span color={currentColor}>consultas online</chakra.span>}
          {` gratuitamente`};
        </Text>
        <Text fontSize="12px" color="#808080">
          {`· Tabela de `}
          {<chakra.span color={currentColor}>preços exclusivos</chakra.span>};
        </Text>
        <Text fontSize="12px" color="#808080">
          {<chakra.span color={currentColor}>· Rede de atendimento</chakra.span>};
        </Text>
      </Box>
    );

    const BoxPreco = ({ preco }) => (
      <Box>
        <Text ml={10} color="#FFF" textAlign="start">
          Por apenas:
        </Text>
        <Text ml={10} fontSize={40} fontWeight="bold" color="#FFF" textAlign="start">
          R$ {preco}
        </Text>
      </Box>
    );

    const BoxPreçoInfo = () => (
      <Box mt={2} display="flex" justifyContent="center" backgroundColor={currentColor} borderRadius={20} w="90%" overflow="hidden">
        <Tabs w="100%" colorScheme="teal">
          <TabList w="100%">
            <Tab
              w="50%"
              h="10%"
              fontSize="14px"
              color={index === 1 ? '#529C94' : '#D4B799'}
              backgroundColor={index === 1 ? '#DDF9F7' : '#FFF0E1'}
              _selected={{ color: '#FFF', bg: currentColor }}
            >
              Mensal
            </Tab>
            <Tab
              w="50%"
              h="10%"
              fontSize="14px"
              color={index === 1 ? '#529C94' : '#D4B799'}
              backgroundColor={index === 1 ? '#DDF9F7' : '#FFF0E1'}
              _selected={{ color: '#FFF', bg: currentColor }}
            >
              Anual
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <BoxPreco preco={index === 1 ? '29,90' : '19,90'} />
            </TabPanel>
            <TabPanel>
              <BoxPreco preco={index === 1 ? '299,00' : '199,00'} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
    return (
      <Box backgroundColor="#F1F1F1" w="315px" h="354px" borderRadius="20px" p={2} position="relative">
        <Text textAlign="center" pb={2}>
          {`Assinando nosso `}
          {plano}
          {` você pode:`}
        </Text>
        <Box pl={2} minH={100}>
          {index === 1 ? <DataIndex1 /> : <DataIndex2 />}
        </Box>
        <Center>
          <BoxPreçoInfo />
        </Center>

        <Box
          position="absolute"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          onClick={() => {
            if (user.id_contrato > 0) {
              if (user.contrato_pendente_pagamento === true) {
                toast({
                  title: 'Assinatura pendente',
                  description: 'O pagamento da sua assinatura está em analise. Aguarde a confirmação do pagamento para acessar esse serviço.',
                  status: 'warning',
                  duration: 4000,
                  isClosable: true,
                  position: 'bottom',
                  colorScheme: 'green',
                  containerStyle: {
                    paddingBottom: '5em',
                  },
                });
                return;
              } else {
                if (user.loggedIn === true && user.id_contrato > 0) {
                  if (medico === true) {
                    navigate('/telepet/termos');
                  } else {
                    toast({
                      title: 'Indisponível',
                      description: 'Temporariamente o TELEPET está funcionando apenas em horário comercial. de 8h às 18h.',
                      status: 'warning',
                      duration: 4000,
                      isClosable: true,
                      position: 'bottom',
                      colorScheme: 'green',
                      containerStyle: {
                        paddingBottom: '5em',
                      },
                    });
                  }
                } else {
                  setTipo('Veterinário');
                }
              }
            } else if (user.loggedIn === false) {
              setUser((prevState) => {
                return {
                  ...prevState,
                  service_type: 'assinar',
                };
              });
              navigate('/cadastro');
            } else {
              setUser((prevState) => {
                return {
                  ...prevState,
                  service: 'telemedicine',
                  service_type: 'assinar',
                };
              });
              navigate('/cadastro/plano', {
                state: {
                  client_id: user.id_cliente_deovita,
                  name: user.name,
                  cpf: user.cpf,
                  tipo: 'recontratar_plano',
                },
              });
            }
          }}
        >
          <Button bottom={0} color="#fff" backgroundColor={'#EB8B2A'} borderRadius={20}>
            Quero reativar meu contrato!
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <ChildHeader />
      <VStack mt={'1em'}>
        <Center>
          <Heading fontSize={'16px'} color={'#585858'}>
            Excluir Conta
          </Heading>
        </Center>
        <Center>
          <Image boxSize="66" objectFit="cover" src={Excluir} alt="" mt={'2em'} />
        </Center>
        <Center>
          <Text fontSize={'16px'} color={'#585858'} justify={'center'} fontWeight={'bold'} mt={'2em'}>
            Tem certeza que quer <br /> excluir sua conta?
          </Text>
        </Center>
        <Tabs w="100%" colorScheme="teal">
              <TabList w="100%">
                <Tab w="50%" fontSize="14px">
                  PLANO FAMÍLIA
                </Tab>
                <Tab w="50%" fontSize="14px">
                  PLANO INDIVIDUAL
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Center p={5}>
                    <CardDadosPlano index={1} />
                  </Center>
                </TabPanel>
                <TabPanel>
                  <Center p={5}>
                    <CardDadosPlano index={2} />
                  </Center>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <ButtonGroup sx={{ width: '85%' }} ml={isMinHeight ? '1.5em' : '2.2em'} mb="3em">
              <Button
                name="btn"
                color="#585858"
                bg={'#F3F3F3'}
                type="submit"
                w={{ base: '100%' }}
                h={'2em'}
                onClick={() => navigate('/perfil/assinaturas/confirma-conta-excluida')}
              >
                Excluir minha conta
              </Button>
            </ButtonGroup>
      </VStack>
    </>
  );
}

export default ExcluiContaPagina;
