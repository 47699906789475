import React, {  } from 'react';
import usePushNotificationToken from '../hooks/usePushNotificationToken';

// Higher Order Function component
const withPushNotifications = (WrappedComponent) => {
  // You can use hooks and state here
  const EnhancedComponent = (props) => {
    usePushNotificationToken();

    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
};


export default withPushNotifications;
