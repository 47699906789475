import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  DrawerOverlay,
  Button,
  VStack,
  Center,
  HStack,
  Text,
  Divider,
  TableContainer,
  Table,
  Tr,
  Td,
  ButtonGroup,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Badge,
  Input,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function DrawerCompraPlano(props) {
  console.log(props);
  const disclosure = props.disclosure;
  const recipientBalance = props.recipientBalance;
  const bankData = props.bankData;
  const [saque, setSaque] = useState(0);
  const [antecipar, setAntecipar] = useState(0);
  console.log(bankData);
  const { user } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  console.log(bankData);
  const saldoRestante = ((recipientBalance.available_amount - saque - 367) / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const saldoRestanteReceber = ((recipientBalance.waiting_funds_amount - antecipar - 5) / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  const transfer = async (recipient_id, amount) => {
    const response = await fetch(`${ENDPOINT}/entrepreneur/transfer/${recipient_id}/${amount}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    response.json().then((data) => {
      console.log(data);
      if (data.transferResponse.success === true) {
        navigate('/alerts/sucesso/transferencia');
      }
      if (data.transferResponse.success === false) {
        setDisabled(false);
        setError(data.transferResponse.data.errors[0].message);
      }
    });
  };

  const maskMoneyInput = (e) => {
    let maskValue = e.target.value;
    console.log(maskValue);
    console.log(e.target.value);
    maskValue = maskValue.replace(/\D/g, '');
    maskValue = maskValue.replace(/(\d{1,2})$/, ',$1');
    maskValue = maskValue.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.target.value = maskValue;
  };
  const antecipation = async (recipient_id, amount) => {
    const response = await fetch(`${ENDPOINT}/entrepreneur/antecipation/${recipient_id}/${amount}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    const data = await response.json().then((data) => {
      console.log(data);
      if (data.antecipationResponse.success === true) {
        navigate('/alerts/sucesso/antecipacao');
      }
      if (data.antecipationResponse.success === false) {
        setDisabled(false);
        setError(data.antecipationResponse.data.errors[0].message);
      }
    });
  };
  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} mb="-10" width="100%" h="70%">
          <DrawerBody mt="10" p="5%">
            <Stack direction="column" spacing={3} align="center" w="100%">
              <HStack w="100%">
                <Text>Dados:</Text>
                <VStack w="100%">
                  <Text>Agência</Text>
                  <Text>{bankData ? bankData.agencia : ''}</Text>
                </VStack>

                <VStack w="100%">
                  <Text>Conta</Text>
                  <Text>{bankData ? bankData.conta + '-' + bankData.digito_conta : ''}</Text>
                </VStack>
              </HStack>

              <VStack w="100%">
                <Tabs w="100%">
                  <TabList>
                    <Tab w="100%">DISPONÍVEL</Tab>
                    {/* <Tab w="50%">A RECEBER</Tab> */}
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <TableContainer w="100%">
                        <VStack>{error ? <Text color="red">{error}</Text> : <></>}</VStack>

                        <Center mt="5%">
                          <Badge ml="0.5em" borderRadius="10px" w="40%" backgroundColor="#1FAB8A" h="2.6em">
                            <Center h="2.5em" color="white">
                              {' '}
                              {(recipientBalance.available_amount / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Center>
                          </Badge>
                        </Center>
                        <VStack w="100%" mt="5%">
                          <Text>Valor desejado(R$)</Text>
                          <Input
                            w="80%"
                            type="text"
                            name="saque"
                            borderRadius="10px"
                            textAlign="center"
                            onChange={(e) => {
                              maskMoneyInput(e);
                              setSaque(
                                parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100 > 0
                                  ? parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100
                                  : 0
                              );
                            }}
                          />
                        </VStack>
                        <VStack w="70%" mt="5%" spacing={-1}>
                          <Text fontSize="10px" align="right" w="100%">
                            Valor mínimo para saque: R$ 3,67
                          </Text>
                          <Text fontSize="10px" align="right" w="100%">
                            Taxa por saque: R$ 3,67
                          </Text>
                        </VStack>
                        <Table mt="5%">
                          <Td textAlign="right" w="30%">
                            <Tr>Saldo Disponível:</Tr>
                            <Tr color="#1FAB8A">Saque:</Tr>
                            <Tr color="#FF003D">Taxa:</Tr>
                          </Td>
                          <Td>
                            <Tr>
                              {(recipientBalance.available_amount / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Tr>
                            <Tr color="#1FAB8A">
                              {(saque / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Tr>
                            <Tr color="#FF003D">R$ 3,67</Tr>
                          </Td>
                          <Divider></Divider>
                          <Tr w="100%" textAlign="right">
                            <Td textAlign="right">Saldo restante</Td>
                            <Td color={recipientBalance.available_amount - saque - 367 < 0 ? 'red' : 'black'}>{saldoRestante}</Td>
                          </Tr>
                        </Table>
                        <ButtonGroup mb="10%" w="100%">
                          <Button
                            w="100%"
                            colorScheme="teal"
                            isLoading={disabled}
                            loadingText="Saque em processamento, aguarde..."
                            onClick={() => {
                              if (saque >= 367) {
                                setError(null);
                                setDisabled(true);
                                transfer(user.recipient_id, saque);
                              } else {
                                setError('Valor inválido');
                              }
                            }}
                          >
                            Sacar
                          </Button>
                        </ButtonGroup>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel>
                      <TableContainer w="100%">
                        <VStack>{error ? <Text color="red">{error}</Text> : <></>}</VStack>

                        <Center mt="5%">
                          <Badge ml="0.5em" borderRadius="10px" w="40%" backgroundColor="#1FAB8A" h="2.6em">
                            <Center h="2.5em" color="white">
                              {' '}
                              {(recipientBalance.waiting_funds_amount / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Center>
                          </Badge>
                        </Center>
                        <VStack w="100%" mt="5%">
                          <Text>Antecipar(R$)</Text>
                          <Input
                            w="80%"
                            type="text"
                            id="antecipar"
                            borderRadius="10px"
                            name="antecipar"
                            textAlign="center"
                            onChange={(e) => {
                              maskMoneyInput(e);
                              setAntecipar(
                                parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100 > 0
                                  ? parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100
                                  : 0
                              );
                            }}
                          />
                        </VStack>
                        <VStack w="100%" mt="5%" spacing={-1}>
                          <Text fontSize="10px" align="center " w="100%">
                            Esta antecipação será analisada e poderá ser aprovada ou rejeitada.
                          </Text>
                          <Text fontSize="10px" align="center" w="100%">
                            Após a aprovação, o valor a ser antecipado é adicionado ao saldo a
                          </Text>
                          <Text fontSize="10px" align="center" w="100%">
                            {' '}
                            partir das 12 horas do dia selecionado.
                          </Text>
                        </VStack>
                        <Table mt="5%">
                          <Td textAlign="right" w="30%">
                            <Tr>Saldo Disponível:</Tr>
                            <Tr color="#1FAB8A">Saque:</Tr>
                            <Tr color="#FF003D">Taxa:</Tr>
                          </Td>
                          <Td>
                            <Tr>
                              {(recipientBalance.waiting_funds_amount / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Tr>
                            <Tr color="#1FAB8A">
                              {(antecipar / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Tr>
                            <Tr color="#FF003D">R$ 0,05</Tr>
                          </Td>
                          <Divider></Divider>
                          <Tr w="100%" textAlign="right">
                            <Td textAlign="right">Saldo restante</Td>
                            <Td color={recipientBalance.waiting_funds_amount - antecipar - 5 < 0 ? 'red' : 'black'}>{saldoRestanteReceber}</Td>
                          </Tr>
                        </Table>
                        <ButtonGroup mb="10%" w="100%">
                          <Button
                            w="100%"
                            colorScheme="teal"
                            isLoading={disabled}
                            loadingText="Antecipação em processamento, aguarde..."
                            onClick={() => {
                              if (antecipar >= 5) {
                                setError(null);
                                setDisabled(true);
                                antecipation(user.recipient_id, antecipar);
                              } else {
                                setError('Valor inválido');
                              }
                            }}
                          >
                            Antecipar
                          </Button>
                        </ButtonGroup>
                      </TableContainer>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </VStack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerCompraPlano;
