import React, {  } from 'react';
import {chakra, Center, Heading, Modal, ModalBody, ModalContent, ModalOverlay, VStack, Button } from '@chakra-ui/react';

import { GoAlertFill } from "react-icons/go";

const ModalProvaDeVida = ({ isOpen = true, onConfirmPress = ()=>{} }) => {
  return (
    <Modal isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent m={'5%'} borderRadius="0.8em">
        <ModalBody>
          <Center>
            <GoAlertFill color='orange' size={60} style={{marginTop: '1em'}}/>
          </Center>
          <Center>
            <Heading textAlign="center" color="#333333" mt="5" size="md" mb={'1em'}>
              Prova de vida
            </Heading>
          </Center>
          <VStack bg={'#E5E5E5'} p={'1'} borderRadius={'0.8em'}>
            <chakra.h3 mt="0.16em" color="#333333" textAlign="center" fontSize="1em">
              Para começar a usar a sua conta é necessário realizar a prova de vida.
            </chakra.h3>
          </VStack>
          <Center mt={'1em'} mb={'1em'}>
            <Button
              bg={'orange'}
              color={'white'}
              h={'2.5em'}
              borderRadius={'0.8em'}
              onClick={onConfirmPress}
            >
              Fazer prova de vida
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalProvaDeVida;