import { StarIcon } from '@chakra-ui/icons';
import {
  Img,
  Text,
  VStack,
  chakra,
  Tabs,
  Box,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Badge,
  WrapItem,
  Center,
  Image,
  Table,
  Flex,
  Button,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Back from '../../../images/back.svg';
import BottomNavBar from '../../BottomNavBar';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import DotsLoading from '../../Utils/Components/DotsLoading';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function CurriculoMedico() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { medico, pic } = state;

  const img = process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic;
  const options = { month: 'short', day: 'numeric' };
  const [exp, setExp] = useState([]);
  const [cv, setCv] = useState([]);
  const [fetchMore, setFetchMore] = useState(false);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const proximasDatas = [];
  let mesPorExtenso = [];

  useEffect(() => {
    var busyStack = false;
    var page = 1;
    const handleScroll = async () => {
      if (window.scrollY === 0 || (window.innerHeight + window.scrollY + 5 > document.body.offsetHeight && busyStack === false)) {
        setFetchMore(true);
        const newUrl = `${ENDPOINT}/schedule/getAvaliacoes/${medico.dados.medico}?page=${page + 1}&limit=${10}`;
        busyStack = true;

        let response = await fetch(newUrl, {
          headers: { 'Content-Type': 'application/json' },
          method: 'GET',
        });
        let data = await response.json();
        let lastPage = data.length === 0;
        if (!lastPage) busyStack = false;
        setAvaliacoes((prev) => [...prev, ...data]);
        page++;
        setFetchMore(false);
      }
    };
    console.log(avaliacoes);
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const url = `${ENDPOINT}/schedule/cv/${medico.dados.medico}`;
  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      });
      let data = await response.json();
      setExp(data.experiencia);
      setCv(data.formacao);
      return data;
    } catch (error) {
      console.log(error);

      return [];
    }
  }
  useEffect(() => {
    fetchData(url);
  }, [url]);

  const useEsp = exp.map((item, index) => {
    return <chakra.p>{item.experiencia}</chakra.p>;
  });

  const useExp = exp.map((item, index) => {
    return <chakra.p>{item.tempo_experiencia}</chakra.p>;
  });

  const Formacoes = cv.map((item) => (
    <>
      <Table variant="simple" size="lg" minW="100%" id="table-procedures">
        {item.nivel_formacao}
      </Table>
      <chakra.p>{item.formacao_academica + ' - ' + item.data_formacao}</chakra.p>
    </>
  ));

  const Dados = avaliacoes ? (
    avaliacoes.map((publico, index) => {
      const arrName = publico.paciente.split(' ');
      const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
      const nomePaciente = Array.isArray(arrName)
        ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name.toLowerCase()) === false).join(' ')
        : '';

      return (
        <>
          <Flex borderRadius="10px" shadow="md" mt="1em" width="95%" backgroundColor="#F0F0F0">
            <VStack ml="1em" align="start" w="100%" mt="1em">
              <chakra.p marginTop="5%" fontWeight="bold">
                <StarIcon color="orange" />
                {' ' + publico.avaliacao + ' - ' + nomePaciente}
              </chakra.p>
              <Text noOfLines={4} minH={55} color="#585858" fontWeight="bold" fontSize="0.8em">
                {publico.opiniao ? publico.opiniao : 'Não deixou comentário.'}
              </Text>
            </VStack>
          </Flex>
        </>
      );
    })
  ) : (
    <>
      <Text noOfLines={4} minH={55} color="#585858" fontWeight="bold" fontSize="0.8em">
        Sem avaliações disponíveis no momento.
      </Text>
    </>
  );

  return (
    <>
      <Img
        src={Back}
        boxSize="3em"
        padding={2}
        onClick={() => navigate(-1)}
        color="white"
        marginTop="2.5em"
        position="absolute"
        left="5"
        zIndex="100"
        borderRadius="25px"
        backgroundColor="teal"
      />
      <Image src={img} zIndex="-1" w="full" position="relative" fallbackSrc={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/perfil.svg'} />
      <chakra.header
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        marginTop="-8"
        shadow="md"
        backgroundColor="white"
        minHeight="100px"
        borderTopRadius="30px"
        zIndex="100"
      >
        <chakra.h1 fontWeight="bold" align="center" fontSize="xl">
          {medico.dados.nome}
        </chakra.h1>
        <chakra.h2 marginTop="1em" paddingBottom="0.5em" align="center">
          {' '}
          {parseFloat(medico.dados.avaliacao_media).toFixed(2)} <StarIcon color="orange" />{' '}
          {medico.fromExame ? <Text></Text> : '| ' + medico.dados.conselho}
        </chakra.h2>
      </chakra.header>
      <Tabs variant="soft-rounded" w="full" px={{ base: 2, sm: 4 }} py={8}>
        <TabList borderBottom="1px solid gray" paddingBottom="1em">
          <Tab _selected={{ color: 'white', bg: 'teal' }}>Experiência</Tab>
          <Tab _selected={{ color: 'white', bg: 'teal' }}>Formação</Tab>
          <Tab _selected={{ color: 'white', bg: 'teal' }}>Avaliações</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500">Especialidade</chakra.p>
                {exp && useEsp}
              </VStack>
              <VStack>
                <chakra.p color="gray.500">Experiência</chakra.p>
                {exp && useExp}
              </VStack>
            </HStack>
            {!medico?.fromExame ? (
              <>
                <chakra.p marginTop="5%" fontWeight="bold">
                  Sobre mim
                </chakra.p>
                <chakra.p>{medico.dados.descricaoPessoal}</chakra.p>
               {/* <chakra.p marginTop="1.5em" fontWeight="bold">
                  Desejar agendar?
                </chakra.p>
                <Button
                  minWidth="100%"
                  backgroundColor={'teal'}
                  mt={'1.5em'}
                  textColor="white"
                  onClick={() => {
                    if (!medico.horarios[0].procedimentos) {
                      navigate('/atendimento/agendamento', {
                        state: {
                          medico,
                          startNum: 0,
                          st: 0,
                          defaultDateTime: null,
                          procedimentoId: medico.horarios[0].ACTIVITY_LID,
                          procedimento: medico.horarios[0].ACTIVITY_NAME,
                          price: medico.horarios[0].PRICE,
                        },
                      });
                    } else {
                      if (medico.horarios[0].procedimentos.length === 1) {
                        navigate('/atendimento/agendamento', {
                          state: {
                            medico,
                            startNum: 0,
                            st: 0,
                            defaultDateTime: null,
                            procedimentoId: medico.horarios[0].procedimentos[0].id,
                            procedimento: medico.horarios[0].procedimentos[0].nome,
                            price: medico.horarios[0].procedimentos[0].valor,
                            procedimentoParticular:
                              medico.horarios &&
                              medico.horarios.length > 0 &&
                              medico.horarios[0].procedimentos &&
                              medico.horarios[0].procedimentos.length > 0 &&
                              medico.horarios[0].procedimentos[0].procedimento_particular &&
                              medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                                ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_id
                                : null,
                            valorParticular:
                              medico.horarios &&
                              medico.horarios.length > 0 &&
                              medico.horarios[0].procedimentos &&
                              medico.horarios[0].procedimentos.length > 0 &&
                              medico.horarios[0].procedimentos[0].procedimento_particular &&
                              medico.horarios[0].procedimentos[0].procedimento_particular.length > 0
                                ? medico.horarios[0].procedimentos[0].procedimento_particular[0].particular_valor
                                : null,
                          },
                        });
                      } else {
                        navigate('/atendimento/escolha-procedimento', {
                          state: { medico, startNum: 0, st: 0, defaultDateTime: null },
                        });
                      }
                    }
                  }}
                >
                  Agendar Agora
                </Button>*/}
              </>
            ) : (
              <></>
            )} 
          </TabPanel>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p></chakra.p>
              </VStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p></chakra.p>
              </VStack>
            </HStack>
            {cv && Formacoes}
            <chakra.h1 fontWeight="bold" fontSize="lg" mt="10" ml="2"></chakra.h1>
          </TabPanel>
          <TabPanel>
            <HStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p></chakra.p>
              </VStack>
              <VStack>
                <chakra.p color="gray.500"></chakra.p>
                <chakra.p></chakra.p>
              </VStack>
            </HStack>
            {Dados} {fetchMore && <DotsLoading />}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <WrapItem mt="35" />
      <BottomNavBar />
    </>
  );
}

export default CurriculoMedico;
