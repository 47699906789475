import {
  CloseButton,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import ReactPlayer from 'react-player';

export default function DemonstrativoTelmed(props) {
  console.log(props);
  const disclosure = props.disclosure;
  return (
    <>
      {' '}
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} size="full" isCentered>
        <ModalOverlay />
        <ModalHeader zIndex="9999">
          {' '}
          <ModalCloseButton mt="1em" backgroundColor={'teal'} zIndex="9999" size="lg" onCLick={() => disclosure.onClose()} />
        </ModalHeader>
        <ModalContent border-radius="16px" w="100vw" marginLeft="-3em" background={'transparent'}>
          <ModalBody w="100vw">
            <ReactPlayer
              url="https://youtu.be/1AFjRHfpDi8"
              playing={true}
              style={{ pointerEvents: 'none' }}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    controls: 0,
                    rel: 0, // Prevents related videos from showing
                  },
                },
              }}
              controls={false}
              zIndex="-1"
              width="100vw"
              height="100vh"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
