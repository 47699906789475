import React from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  chakra,
  Tabs,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
  ModalFooter,
  Stack,
  useToast,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

function AvisoDependentesExcluido(props) {
  const disclosure = props.disclosure;
  const modalDisclosure = useDisclosure();
  return (
    <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
    <ModalOverlay />
    <ModalContent maxW="85%" borderRadius="0.8EM" h={'12em'}>
      <ModalCloseButton />
      <ModalBody>
        <Center>
          <Heading textAlign="center" color="#808080" mt="5" size="md">
            Processando pagamento
          </Heading>
        </Center>
        <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
          Estamos processando o pagamento de exclusão deste dependente. Pode levar algum tempo até o pagamento ser processado. Se já realizou o pagamento, favor, desconsiderar
        </chakra.h4>
      </ModalBody>
      <Center>
     
      </Center>
      <ModalFooter></ModalFooter>
    </ModalContent>
  </Modal>
  )
}

export default AvisoDependentesExcluido