import React, { useContext, useState } from 'react';
import ChildHeader from './ChildHeader';
import { Box, VStack } from '@chakra-ui/react';
import ListaMedicos from './ListaMedicos';
import SearchContext from './SearchContext';
import { useLocation } from 'react-router-dom';
import Searchbox from './Searchbox';
import { AccountContext } from 'components/AccountContext';
import BottomNavBar from 'components/BottomNavBar';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';

function Medicos({ ...rest }) {
  const { user, setPage, setUser } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const state = useLocation();
  const { typeList } = state.state;
  const { especialidade } = state.state;
  setPage(typeList === 'Outros' ? 'Profissionais de Saúde' : typeList === 'Medicos' ? 'Médicos' : typeList);
  return (
    <>
      {user.loggedIn ? <ChildHeader /> : <NoUserHeader />}
      <SearchContext>
        <VStack align="center" direction={{ base: 'column-reverse' }} wrap="no-wrap" minH="10vh" px={8} {...rest} typeList={typeList}>
          <ListaMedicos isLoading={(value) => setLoading(value)} typeList={typeList} especialidade={especialidade} />
        </VStack>
      </SearchContext>
    </>
  );
}

export default Medicos;
