import React, { useCallback, useEffect, useState } from 'react';
import { VStack } from '@chakra-ui/react';
import BottomNavBar from '../../../BottomNavBar';
import MainChildHeader from '../MainChildHeader';
import { AccountContext } from '../../../AccountContext';
import { useContext } from 'react';
import Resumo from './Resumo';
import Menu from './Menu';
import ModalEmpreendedor from './ModalEmpreendedor';
import Loading from 'components/Loading';
import { useNavigate } from 'react-router-dom';
import ModalProvaDeVida from './ModalProvaDeVida';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const useProvaDeVidaPagarme = () => {
  const { user } = useContext(AccountContext);
  const [provaDeVida, setProvaDeVida] = useState({ expires_at: '', url: '', base64_qrcode: '' });

  useEffect(() => {
    if (!user.recipient_id) return;

    const fetchProvaDeVida = async () => {
      try {
        // const url = `${ENDPOINT}/entrepreneur/prova-de-vida/${user.id_vendedor}`;
        const url = `https://api.pagar.me/core/v5/recipients/${user.recipient_id}/kyc_link`;
        const key = `sk_a4577aa28506460a8ee0027ec51bb6c3`;
        const pass = btoa(key);

        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Basic ${pass}`,
          },
          method: 'POST',
        });
        const data = await response.json();
        console.log('res link prova de vida', data);
        if (data?.url) {
          setProvaDeVida({ ...data });
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchProvaDeVida();
  }, [user, setProvaDeVida]);
  return { ...provaDeVida };
};

function Main() {
  const { user, setPage, setUser } = useContext(AccountContext);
  setPage('Revendedor');

  const [contratos, setContratos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpenModalProvaDeVida, setIsOpenModalProvaDeVida] = useState(true);

  const navigate = useNavigate();

  const { url } = useProvaDeVidaPagarme();

  const handleRealizarProvaDeVida = useCallback(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        service: true,
        openInBrowser: true,
        url,
      })
    );
    setIsOpenModalProvaDeVida(false);
  }, [url]);

  const handlerOnPressGerarQrCode = () => {
    url ? setIsOpenModalProvaDeVida(true) : navigate('/empreendedor/qrcode');
  };

  const fetchContratos = async () => {
    const url = `${ENDPOINT}/entrepreneur/extrato/contratos/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, contratosEmpreendedor: fetchData.data.contratos }));
    setContratos(fetchData.data.contratos);
  };
  const fetchEmpresas = async () => {
    const url = `${ENDPOINT}/entrepreneur/extrato/credenciados/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, empresasEmpreendedor: fetchData.data }));
    setEmpresas(fetchData.data);
  };
  const fetchVendedores = async () => {
    const url = `${ENDPOINT}/entrepreneur/extrato/vendedores/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, vendedoresEmpreendedor: fetchData.data.result }));
    setVendedores(fetchData.data.result);
  };

  async function fetchDatas() {
    if (user.contratosEmpreendedor && Array.isArray(user.contratosEmpreendedor) && user.contratosEmpreendedor.length > 0) {
      setContratos(user.contratosEmpreendedor);
    } else {
      await fetchContratos();
    }
    if (user.empresasEmpreendedor && Array.isArray(user.empresasEmpreendedor) && user.empresasEmpreendedor.length > 0) {
      setEmpresas(user.empresasEmpreendedor);
    } else {
      await fetchEmpresas();
    }
    if (user.vendedoresEmpreendedor && Array.isArray(user.vendedoresEmpreendedor) && user.vendedoresEmpreendedor.length > 0) {
      setVendedores(user.vendedoresEmpreendedor);
    } else {
      await fetchVendedores();
    }
    console.log(user);
    setLoading(false);
  }

  useEffect(() => {
    fetchDatas();
  }, []);
  console.log(contratos);
  return user.empreendedor === false ? (
    <ModalEmpreendedor />
  ) : loading ? (
    <>
      <MainChildHeader />
      <Loading />
      <BottomNavBar name="empreendedor" />
    </>
  ) : (
    <>
      {url && <ModalProvaDeVida isOpen={isOpenModalProvaDeVida} onConfirmPress={handleRealizarProvaDeVida} />}
      <MainChildHeader />
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="10vh"
        px={3}
        mb="20"
      >
        <Resumo contratos={contratos} empresas={empresas} vendedores={vendedores} />

        <Menu contratos={contratos} empresas={empresas} vendedores={vendedores} onPressGerarQrCode={handlerOnPressGerarQrCode} />
      </VStack>
      <BottomNavBar name="empreendedor" />
    </>
  );
}

export default Main;
