import { React, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { AccountContext } from '../AccountContext';
import Loading from '../Loading';
import requestTimeout from '../Utils/Functions/requestTimeout';

const ENDPOINT = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const Cartao = () => {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const navigate = useNavigate();
  const { setUser } = useContext(AccountContext);
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;

  const requestOptions = {
    signal: requestTimeout(timeOutInSeconds).signal,
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };

  async function gravar_pix_pendente(vals) {
    const { forma_pgto, transaction_id, valor, client_id, id_plano, adicionais } = vals;

    const url = `${ENDPOINT}/invoicesApp/`;
    const payload = {
      forma_pagamento: forma_pgto,
      id_transacao_pagarme: transaction_id,
      descricao: 'assinatura deovita',
      tabela_estrangeira: 'paciente',
      id_tabela_estrangeira: client_id,
      sistema_origem: 'fidelidade',
      valor: valor,
      metadata: {
        contractData: {
          paciente_id: client_id,
          plano_id: id_plano,
          forma_pgto: forma_pgto,
          adicionais: adicionais,
        },
        instalmentsData: {
          valor: valor,
          taxa_adesao: true,
          plano_id: id_plano,
          id_trasacao_gatway: transaction_id,
          classe: 'ADESAO',
          forma_pgto: forma_pgto,
        },
      },
    };

    requestOptions['body'] = JSON.stringify(payload);
    try {
      const res = await fetch(url, requestOptions);
      if (res || res.ok || res.status < 400) {
        const response = res.json();
        if (response.success) {
          console.log(response.data);
        } else {
          console.log('deu erro');
        }
      } else {
        console.log(res);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  }

  function gravar_pagamento(vals) {
    function handleError() {
      setError(true);
      setLoading(false);
    }

    let installmentsData = [
      {
        paciente_contrato_id: vals.paciente_contrato_id,
        valor: vals.valor,
        taxa_adesao: true,
        plano_id: vals.id_plano,
        id_trasacao_gatway: vals.transaction_id,
        classe: 'ADESAO',
        forma_pgto: vals.forma_pgto,
      },
    ];

    requestOptions['body'] = JSON.stringify(installmentsData);
    fetch(`${ENDPOINT}/installments`, requestOptions)
      .catch((err) => {
        handleError();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          handleError();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        let returnRequest = data[0];
        if (returnRequest.success) {
          const vals = {
            username: onlyNumbers(state.cpf),
            password: state.senha,
          };
          fetch(`${ENDPOINT}/auth/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(vals),
          })
            .catch((err) => {
              handleError();
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                handleError();
                return;
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;
              setUser({ ...data });
              if (data.status) {
                handleError();
              } else if (data.loggedIn) {
                const payload = {
                  cpf: data.cpf,
                  numero: data.phone1,
                  nome: data.name,
                };
                fetch(`${ENDPOINT}/whatsapp/confirmacao_cadastro`, {
                  signal: requestTimeout(timeOutInSeconds).signal,
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(payload),
                });
                navigate('/dashboard');
              }
            });
        } else {
          handleError();
        }
      });
  }

  function sigplan(vals) {
    let contractsData = [
      {
        paciente_id: vals.client_id,
        plano_id: vals.id_plano,
        forma_pgto: vals.forma_pgto,
        adicionais: vals.adicionais,
      },
    ];

    requestOptions['body'] = JSON.stringify(contractsData);
    fetch(`${ENDPOINT}/contracts`, requestOptions)
      .catch((err) => {
        setLoading(false);
        setError(true);
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          setLoading(false);
          setError(true);
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        let returnRequest = data[0];
        if (returnRequest.success) {
          let contract = returnRequest.data;
          vals['paciente_contrato_id'] = contract.paciente_contrato_id;
          gravar_pagamento(vals);
        } else {
          setLoading(false);
          setError(true);
        }
      });
  }

  function gravar_cartao(vals) {
    
    const api_key = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_API_KEY_PAGARME_LIVE : process.env.REACT_APP_API_KEY_PAGARME_TEST;
    
    const options = {
      signal: requestTimeout(timeOutInSeconds).signal,
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        card_number: onlyNumbers(vals.cardNumber),
        card_expiration_date: onlyNumbers(vals.expirationDate),
        card_holder_name: vals.holder_name,
        card_cvv: `${vals.cvv}`,
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_URL_API_PAGARME}/cards?api_key=${api_key}`, options)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setLoading(false);
        if (response.valid) {
          fetch(`${ENDPOINT}/client/add_credit_card`, {
            signal: requestTimeout(timeOutInSeconds).signal,
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              paciente_id: vals.client_id,
              first_digits: response.first_digits,
              last_digits: response.last_digits,
              brand: response.brand,
              holder_name: response.holder_name,
              expiration_date: response.expiration_date,
              card_id_pagarme: response.id,
              fingerprint_card_pagarme: response.fingerprint,
              default: true,
            }),
          })
            .catch((error) => {
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                return;
              }
              return res.json();
            })
            .then((data) => {
              setLoading(false);
              if (!data) return;
              if (data.success) {
                setUser((prevState) => ({
                  ...prevState,
                  cards: [
                    {
                      paciente_cartao_credito_pagarme_id: data.id,
                      holder_name: response.holder_name,
                      first_digits: response.first_digits,
                      brand: response.brand,
                      last_digits: response.last_digits,
                      expiration_date: response.expiration_date,
                      card_id_pagarme: response.id,
                      default: true,
                    },
                  ],
                }));
              } else {
              }
            });
        } else {
        }
      })
      .catch((err) => {
        console.aviso(err);
      });
  }

  useEffect(() => {
    if (state.cardNumber && state.forma_pgto === 'card') gravar_cartao(state);
    state.paid === false && state.forma_pgto === 'pix' ? gravar_pix_pendente(state) : sigplan(state);
  }, []);

  return loading ? <Loading /> : error ? navigate('/alerts/erro/fim-cadastro') : navigate('/alerts/sucesso/fim-cadastro');
};

export default Cartao;
